import {barnManagerModule} from '../../index.module';

barnManagerModule.directive('bmPreviewEventDetailsCard', previewEventDetailsCard);

function previewEventDetailsCard() {

  PreviewCardController.$inject = ['$element', 'titlesService'];

  return {
    restricted: 'E',
    templateUrl: 'preview-event-details-card.html',
    controller: PreviewCardController,
    controllerAs: 'vm',
    bindToController: true,
    transclude: true,
    scope: {
      type: '@',
      item: '=',
      zoneCode: '=',
      typeDetails: '=',
      editable: '='
    }
  };

  function PreviewCardController($element, titlesService) {
    const vm = this;

    vm.getInitials = titlesService.getInitialsLabel;
  }
}
