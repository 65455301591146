import {barnManagerModule} from '../index.module';
import * as angular from 'angular';
import {cloneDeep, difference} from 'lodash';

barnManagerModule.factory('listsService', listsService);

listsService.$inject = ['$q', 'rAttachments', 'rModelLists', '$log', 'responseHandler'];
function listsService($q, rAttachments, rModelLists, $log, responseHandler) {
  let promises = [], resource = '',
      members = [], membersOrigin = [],
      modelType = '';

  function resetTo(_resource, attachmentModelType) {
    resource = _resource;
    modelType = attachmentModelType;
    cleanMembers();
  }

  function cleanMembers() {
    members = [];
    membersOrigin = [];
  }

  function setMembers(_members) {
    members = cloneDeep(_members);
  }

  function setMembersOrigin(_membersOrigin) {
    membersOrigin = cloneDeep(_membersOrigin);
  }

  function getIds(models) {
    const ids = [];

    check.apply(this);
    angular.forEach(models, function(model) {
      ids.push(model.id);
    });

    return ids;
  }

  function createString(string, params) {
    const values = [];

    check.apply(this);
    angular.forEach(params, function(value) {
      values.push(string + '=' + value);
    });
    return values.join('&');
  }

  function updateMembers(model, weirdModel?: any) {
    let newMembers = [], removedMembers = [];

    check.apply(this);
    model = angular.copy(model);

    if (weirdModel) {
      model.authorId = weirdModel.authorId;
      model.id = weirdModel.id;
    }

    if (members != membersOrigin) {
      removedMembers = difference(membersOrigin, members);
      newMembers = difference(members, membersOrigin);

      if (removedMembers.length) {
        const removedMemberParams = { model: resource, userId: model.authorId, modelId: model.id, usersIds: createString('userIds[]', removedMembers) };
        promises.push(rModelLists.removeUsers(removedMemberParams).$promise.catch(responseHandler.processError));
      }

      if (newMembers.length) {
        promises.push(rModelLists.addUsers({ model: resource, userId: model.authorId, modelId: model.id }, {
          userIds: newMembers
        }).$promise.catch(responseHandler.processError));
      }
    }
  }

  function updateAttachments(newFiles, removedFiles, model, weirdModel?: any) {
    check.apply(this);
    model = angular.copy(model);

    if (weirdModel) {
      model.authorId = weirdModel.authorId;
      model.id = weirdModel.id;
    }

    angular.forEach(newFiles, function(attachment) {
      if (attachment.new) {
        const payload = new FormData();
        payload.append('file', attachment.rawData);
        const promise = rAttachments.save({ modelType: modelType, modelId: model.id }, payload).$promise.catch(responseHandler.processError);
        promises.push(promise);
      }
    });

    angular.forEach(removedFiles, function(attachment) {
      if (attachment && attachment.id != null) {
        promises.push(rAttachments.delete({id: attachment.id}).$promise.catch(responseHandler.processError));
      }
    });
  }

  function getUsers(model, callback, setOrigin, weirdModel) {
    let _members = [], origin = setOrigin || true;

    check.apply(this);
    model = angular.copy(model);

    if (weirdModel) {
      model.authorId = weirdModel.authorId;
      model.id = weirdModel.id;
    }

    rModelLists.getUsers({
      model: resource,
      userId: model.authorId,
      modelId: model.id
    }, function(users) {
      _members = users;
      if (origin) {
        setMembersOrigin(getIds(_members));
      }
      callback(users, _members);
    });
  }

  function ready(fx) {
    check.apply(this);
    return $q.all(promises).then(function() {
      return fx();
    });
  }

  function check() {
    if (resource === '') {
      $log.error('listsService Error: resource not initialized.');
    }
  }

  return {
    resetTo: resetTo,
    ready: ready,
    updateAttachments: updateAttachments,
    updateMembers: updateMembers,
    setMembers: setMembers,
    getIds: getIds,
    getUsers: getUsers
  };
}
