import {Component} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import packageInfo from '@project';

@Component({
  selector: 'shared-footer-layout',
  templateUrl: './footer-layout.component.html',
  styleUrls: ['./footer-layout.component.scss'],
  providers: [DatePipe],
  standalone: true,
  imports: [CommonModule]
})
export class FooterLayoutComponent {
  currentDate: string = '';
  version: string = packageInfo.version;

  constructor(private datePipe: DatePipe) {
    this.currentDate = this.datePipe.transform(new Date(), 'yyyy');
  }
}
