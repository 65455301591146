import * as appModule from './index.module';
import './index.config';
import './index.constants';
import './index.route';
import './index.run';

import './components';
import './directives';
import './domain';
import './filters';
import './ng2-downgrades';
import './resources';
import './router';
import './services';
import './storages';
import './views';

export const barnManagerNg1ModuleName = appModule.barnManagerModuleName;
