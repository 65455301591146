import {barnManagerModule} from '../index.module';
import loadImage from 'blueimp-load-image';

barnManagerModule.factory('imageTransformer', ImageTransformer);

ImageTransformer.$inject = ['$q'];
function ImageTransformer($q) {

  return {
    fixOrientation: fixOrientation,
    resizeBase64ToBase64: resizeBase64ToBase64
  };

  function fixOrientation(imageBlob, maxWidth, maxHeight, crop, contentType) {
    const deferred = $q.defer();
    const loadImageParams = {
      maxWidth: maxWidth,
      maxHeight: maxHeight,
      crop: crop,
      canvas: true,
      orientation: true,
      crossOrigin: 'anonymous'
    };

    loadImage(imageBlob,  image => {
      if (image.hasOwnProperty('type') && image['type'] === 'error') {
        deferred.reject(image);
      } else {
        getCanvasBlob(image, contentType).then((blob: Blob) => {
          deferred.resolve(window.URL.createObjectURL(blob));
        });
      }
    }, loadImageParams);

    return deferred.promise;
  }

  function getCanvasBlob(canvas, contentType) {
    return new Promise(function(resolve, reject) {
      canvas.toBlob(function(blob) {
        resolve(blob)
      }, contentType && contentType !== '' ? contentType : 'image/jpeg')
    })
  }

  function resizeBase64ToBase64(image) {
    const iconSize = 150;
    const deferred = $q.defer();
    const img = new Image();
    img.onload = function () {
      const canvas = window.document.createElement('canvas');
      const canvasContext = canvas.getContext('2d');
      canvas.width = iconSize;
      canvas.height = iconSize;
      canvasContext.drawImage(img, 0, 0, canvas.width, canvas.height);
      const resized = canvas.toDataURL('image/jpeg', 0.8);
      deferred.resolve(resized);
    };
    img.src = image;

    return deferred.promise;
  }
}
