import {barnManagerModule} from '../../../../index.module';
import {HorseStallLocation} from "../../../../../app/horse/horse-stall-location";

barnManagerModule
  .component('bmHorseStallLocationsArea', {
    templateUrl: 'horse-stall-locations-area.html',
    controller: horseStallLocationController,
    controllerAs: 'vm',
    bindings: {
      stallLocations: '<',
      onChange: '<',
      existingStallLocation: '<'
    }
  });

horseStallLocationController.$inject = ['$scope', 'horseStallLocationRepository', 'responseHandler', 'MessageFrames'];

function horseStallLocationController($scope, horseStallLocationRepository, responseHandler, MessageFrames) {
  const vm = this;

  vm.allStallLocations = [];

  vm.onStallLocationsChange = onStallLocationsChange;
  vm.onArchive = onArchive;
  vm.onRestore = onRestore;

  loadAllStallLocations();

  $scope.$watch('vm.allStallLocations', (newStallLocations: HorseStallLocation[]) => {
    if (newStallLocations) {
      vm.stallLocationSelectItems = newStallLocations.filter(stallLocation => !stallLocation.archived);
      vm.settingBtnAvailable = vm.allStallLocations && vm.allStallLocations.some((stallLocation: HorseStallLocation) => stallLocation.id);
    }
  });

  $scope.$watch('vm.stallLocations', () => setStallLocationsByName());

  function loadAllStallLocations() {
    horseStallLocationRepository.all().then((stallLocations: HorseStallLocation[]) => {
      vm.allStallLocations = stallLocations;
      setStallLocationsByName();
    });
  }

  function setStallLocationsByName() {
    if (vm.stallLocations) {
      vm.selected = vm.allStallLocations.find((stallLocation: HorseStallLocation) => vm.stallLocations === stallLocation.stallLocation);
    } else {
      vm.selected = null;
    }
  }

  function onStallLocationsChange(selectedStallLocation: HorseStallLocation) {
    vm.selectedStallLocation = selectedStallLocation;
    if (vm.selectedStallLocation && vm.existingStallLocation && vm.existingStallLocation !== vm.selectedStallLocation.stallLocation && vm.selectedStallLocation.occupied) {
      vm.showErrorMessage = true;
    } else if (vm.selectedStallLocation && vm.selectedStallLocation.occupied && !vm.existingStallLocation) {
      vm.showErrorMessage = true;
    } else {
      vm.showErrorMessage = false;
    }
    if (selectedStallLocation) {
      const stallLocationNames = vm.allStallLocations.map((stallLocation: HorseStallLocation) => stallLocation.stallLocation);
      [selectedStallLocation].forEach((stallLocation: HorseStallLocation) => {
        if (!stallLocationNames.includes(stallLocation.stallLocation)) {
          vm.allStallLocations = [...vm.allStallLocations, stallLocation];
        }
      });
      vm.stallLocations = selectedStallLocation.stallLocation;
    } else {
      vm.stallLocations = null;
    }
    if (vm.onChange) {
      vm.onChange(vm.stallLocations, vm.selectedStallLocation);
    }
  }

  function onArchive(item: HorseStallLocation & { loading: boolean }) {
    item.loading = true;
    horseStallLocationRepository.archive(item.id).then(() => {
      const stallLocation = vm.allStallLocations.find((stallLocation: HorseStallLocation) => stallLocation.id === item.id);
      if (stallLocation) {
        stallLocation.archived = true;
      }
      vm.allStallLocations = [...vm.allStallLocations];
    }).catch(errorHandler).finally(() => item.loading = false);
  }

  function onRestore(item: HorseStallLocation & { loading: boolean }) {
    item.loading = true;
    horseStallLocationRepository.restore(item.id).then(() => {
      const stallLocation = vm.allStallLocations.find((stallLocation: HorseStallLocation) => stallLocation.id === item.id);
      if (stallLocation) {
        stallLocation.archived = false;
      }
      vm.allStallLocations = [...vm.allStallLocations];
    }).catch(errorHandler).finally(() => item.loading = false);
  }

  function errorHandler(error) {
    return responseHandler.processError(error, null, MessageFrames.TOP);
  }
}
