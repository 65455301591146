import {barnManagerModule} from '../../index.module';

barnManagerModule
  .component('bmCheckboxMonthdaySelect', {
    templateUrl: 'checkbox-monthday-select.html',
    controller: checkboxMonthdaySelectController,
    controllerAs: 'vm',
    bindings: {
      selected: '<',
      onChange: '<',
      error: '<'
    }
  });

function checkboxMonthdaySelectController() {
  const vm = this;

  vm.$onInit = function() {
    vm.items = getMonthDays();

    function getMonthDays() {
      let array = {};
      for (let i = 1; i <= 31; i++) {
        array[i] = {
          value: i,
          label: i
        };
      }
      return array;
    }

  };
}
