import {barnManagerModule} from '../../index.module';

barnManagerModule
  .component('bmPleaseUpgrade', {
    templateUrl: 'please-upgrade.html',
    controller: PleaseUpgradeController,
    controllerAs: 'vm'
  });

PleaseUpgradeController.$inject = ['versionCheckService'];
function PleaseUpgradeController(versionCheckService) {
  const vm = this;

  vm.upgrade = function() {
    location.reload();
    if (caches) {
      caches.keys().then(function(names) {
        names.forEach(caches.delete);
      });
    }
  };

  vm.$onInit = function() {
    vm.showPleaseUpgrade = false;

    versionCheckService.onNewVersion(function() {
      vm.showPleaseUpgrade = true;
    });
  };
}
