import {barnManagerModule} from '../../index.module';

barnManagerModule.controller('ActivityController', ActivityController);

ActivityController.$inject = [
  '$rootScope',
  '$scope',
  '$stateParams',
  'backLinkHistory',
  'activityRepository',
  'barnStorage',
  'responseHandler',
  'authenticationService',
  'hasPermission',
  'titleService',
  '$location',
  '$window'
];
function ActivityController(
  $rootScope,
  $scope,
  $stateParams,
  backLinkHistory,
  activityRepository,
  barnStorage,
  responseHandler,
  authenticationService,
  hasPermission,
  titleService,
  $location,
  $window
) {
  let vm = this,
    barn,
    pageSize = 20,
    totalPages = 1;

  backLinkHistory.pushLink('Activity');

  vm.items = [];
  vm.hasWhiteBoardPermission = hasPermission('whiteboard:read');
  vm.queryActivities = queryActivities;

  vm.loadMore = loadMore;
  vm.hasMore = hasMore;

  if (authenticationService.isLoggedIn()) {
    init();
  }

  function loadMore() {
    if (hasMore()) {
      pageSize += pageSize;
      queryActivities();
    }
  }

  function hasMore() {
    return totalPages > 1;
  }

  function queryActivities(isPinned?) {
    if (typeof barn !== 'undefined') {
      activityRepository.search(pageSize, isPinned).then(function(activities) {
        vm.items = activities.records.map(function(item) {
          switch (item.type) {
            case 'LIST_SIMPLE': {
              item.message = item.message.replace('details for ', '');
              break;
            }
            case 'HORSES_IMPORT': {
              item.message = `${item.message}: ${item.horses}`;
              item.horses = null;
              break;
            }
          }
          return item;
        });
        totalPages = activities.totalPages;
      }).catch(responseHandler.processError);
    }
  }

  function init() {
    barn = barnStorage.getEnv();
    titleService.setTitle('Activity');
    $rootScope.pageConf.class = 'page--barn';
    $rootScope.pageConf.htmlTagClass = 'overview-page';
    initCurrentSection();
    $scope.$on('$locationChangeSuccess', (event, newUrl, oldUrl) => {
      if (newUrl === oldUrl) {
        return;
      }
      initCurrentSection();
      $window.scrollTo(0, 0);
    });
  }

  function initCurrentSection() {
    const sectionParam = $stateParams.section;
    if (sectionParam) {
      if (sectionParam === 'whiteboard' && !vm.hasWhiteBoardPermission) {
        vm.currentSection = 'timeline';
        $location.search('section', vm.currentSection);
        return;
      }
      vm.currentSection = sectionParam;
    } else {
      vm.currentSection = vm.hasWhiteBoardPermission ? 'whiteboard' : 'timeline';
    }
  }
}
