import {barnManagerModule} from '../index.module';

barnManagerModule
  .factory('EventTypes', ['VendorTypes', 'TreatmentTypes', 'VendorRestrictions', (VendorTypes, TreatmentTypes, VendorRestrictions) => {
    function None() {
      this.name = 'None';
      this.value = 'NONE';
      this.vendorRestriction = new VendorRestrictions.None();
      this.treatment = TreatmentTypes.NONE;
    }

    function Vet() {
      this.name = 'Vet Appointment';
      this.value = 'VET';
      this.vendorRestriction = new VendorRestrictions.TypeWithOther(VendorTypes.VET, 'Vet');
      this.treatment = TreatmentTypes.NONE;
    }

    function Dentist() {
      this.name = 'Dentist Appointment';
      this.value = 'DENTIST';
      this.vendorRestriction = new VendorRestrictions.MultipleTypes([VendorTypes.DENTIST, VendorTypes.VET, VendorTypes.OTHER], 'Dentist');
      this.treatment = TreatmentTypes.NONE;
    }

    function Farrier() {
      this.name = 'Farrier Appointment';
      this.value = 'FARRIER';
      this.vendorRestriction = new VendorRestrictions.TypeWithOther(VendorTypes.FARRIER, 'Farrier');
      this.treatment = TreatmentTypes.NONE;
    }

    function Deworming() {
      this.name = 'Deworming';
      this.value = 'DEWORMING';
      this.vendorRestriction = new VendorRestrictions.Type(VendorTypes.OTHER, 'Vendor');
      this.treatment = TreatmentTypes.COMPOUND_PRODUCT;
    }

    function Medication() {
      this.name = 'Medication';
      this.value = 'MEDICATION';
      this.vendorRestriction = new VendorRestrictions.TypeWithOther(VendorTypes.VET, 'Vet');
      this.treatment = TreatmentTypes.MEDICATION;
    }

    function Vaccination() {
       this.name = 'Vaccination';
       this.value = 'VACCINATION';
       this.vendorRestriction = new VendorRestrictions.TypeWithOther(VendorTypes.VET, 'Vet');
       this.treatment = TreatmentTypes.VACCINE;
    }

    function Show() {
      this.name = 'Show';
      this.value = 'SHOW';
      this.vendorRestriction = new VendorRestrictions.Type(VendorTypes.OTHER, 'Vendor');
      this.treatment = TreatmentTypes.NONE;
    }

    function Lesson() {
      this.name = 'Lesson';
      this.value = 'LESSON';
      this.vendorRestriction = new VendorRestrictions.Type(VendorTypes.OTHER, 'Vendor');
      this.treatment = TreatmentTypes.NONE;
    }

    function Other() {
      this.name = 'Other';
      this.value = 'OTHER';
      this.vendorRestriction = new VendorRestrictions.Type(VendorTypes.OTHER, 'Vendor');
      this.treatment = TreatmentTypes.NONE;
    }

    return {
      NONE: new None(),
      VET: new Vet(),
      DENTIST: new Dentist(),
      FARRIER: new Farrier(),
      DEWORMING: new Deworming(),
      MEDICATION: new Medication(),
      VACCINATION: new Vaccination(),
      SHOW: new Show(),
      LESSON: new Lesson(),
      OTHER: new Other()
    };
  }]);
