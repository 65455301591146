import {barnManagerModule} from '../index.module';

export interface AllHorseCategories {
  statuses: Array<string>;
  healths: Array<string>;
  functions: Array<string>;
}

export interface HorseCategory {
  uuid: string;
  name: string;
  archived: string;
  loading?: boolean;
}

export enum HorseCategoryType {
  STATUS ='STATUS',
  HEALTH = 'HEALTH',
  FUNCTION = 'FUNCTION'
}

barnManagerModule.factory('horseCategoryRepository', HorseCategoryRepository);

HorseCategoryRepository.$inject = ['$http', 'barnStorage'];
function HorseCategoryRepository($http) {
  const url = '/api/horses/categories';

  return {
    all: all,
    byType: byType,
    archive: archive,
    restore: restore
  };

  function all() {
    return $http.get(url).then(results => results.data);
  }

  function byType(type: HorseCategoryType) {
    return $http.get(`${url}/by-type/${type}`).then(results => results.data);
  }

  function archive(id) {
    return $http.put(`${url}/${id}/archive`).then(result => result.data);
  }

  function restore(id) {
    return $http.put(`${url}/${id}/restore`).then(result => result.data);
  }
}
