import {barnManagerModule} from '../../index.module';

barnManagerModule
  .component('bmFeedingMembers', {
    templateUrl: 'feeding-members.html',
    controller: FeedingMembersController,
    controllerAs: 'vm',
    bindings: {
      horse: '<'
    }
  });

FeedingMembersController.$inject = ['userRepository', 'responseHandler'];
function FeedingMembersController(userRepository, responseHandler) {
  const vm = this;
  vm.saving = false;

  vm.saveMembers = function() {
    vm.saving = true;
    userRepository.saveFeedingMembersOfHorse(vm.horse, {
      memberIds: vm.model.memberIds,
      allTeamMembersNotifications: vm.model.allTeamMembersNotifications
    }).then(function() {
      responseHandler.successMessage('Notification preferences successfully updated.');
    }).finally(function() {
      vm.saving = false;
    });
  };

  vm.membersChanged = function(model) {
    vm.model = model;
  };

  vm.$onInit = function() {
    vm.model = null;
    userRepository.all().then(function(users) {
      vm.allUsers = users;
    });
    userRepository.feedingMembersOfHorse(vm.horse).then(function(members) {
      vm.model = {
        memberIds: members.memberIds,
        allTeamMembersNotifications: members.allTeamMembersNotifications
      };
    });
  };
}
