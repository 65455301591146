<shared-bm-ng-select [items]="colors"
                     [bindValue]="'uuid'"
                     [selected]="selectedColorUuid"
                     [multiple]="false"
                     [virtualScroll]="false"
                     [placeholder]="'Pick Event Color'"
                     [ngLabelTmp]="colorPickerLabelTmp"
                     [ngOptionTmp]="colorPickerOptionTmp"
                     (changeSelected)="changeSelected.emit($event)">
</shared-bm-ng-select>

<ng-template #colorPickerLabelTmp let-item>
  {{ item.name || selectedColor }}
</ng-template>

<ng-template #colorPickerOptionTmp let-item let-search="search">
  <span class="content-item__header__title__color mr-12" [ngStyle]="{'background': item.color}">
    Color
  </span>
  <span [ngOptionHighlight]="search">{{ item.name }}</span>
</ng-template>
