import {barnManagerModule} from '../index.module';
import * as angular from 'angular';
import * as moment from 'moment';
import momentTz from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';
import {includes, isEmpty} from 'lodash';

barnManagerModule
  .factory('Record', ['RecordTypes', 'dateTimeUtils', 'DATE_FORMAT', 'DATE_ISO_FORMAT', (
    RecordTypes,
    dateTimeUtils,
    DATE_FORMAT,
    DATE_ISO_FORMAT
  ) => {
    function Record(data) {
      angular.extend(this, angular.copy(data));
    }

    Record.prototype = {
      getType: function() {
        return RecordTypes[this.recordType];
      },
      enforceTitle: function(horses) {
        if (isEmpty(this.title) || !this.customTitle) {
          this.title = this.generateTitle(horses);
          this.customTitle = false;
        } else {
          this.customTitle = true;
        }
      },
      generateTitle: function(horses) {
        switch (this.getType()) {
          case RecordTypes.VET:
          case RecordTypes.FARRIER:
          case RecordTypes.DENTIST:
          case RecordTypes.OTHER:
            return this.defaultTitle();
          case RecordTypes.MEDICATION:
          case RecordTypes.VACCINATION:
          case RecordTypes.DEWORMING:
            return this.medTitle();
          case RecordTypes.LEASE:
            return this.leaseTitle(horses);
          default:
            return '';
        }
      },

      dateAsString: function() {
        if (this.date == null) {
          return null;
        }
        return momentTz.tz(dateTimeUtils.resetTimezone(this.date), 'UTC').format(DATE_FORMAT);
      },

      dateSuffix: function() {
        const dateAsString = this.dateAsString();
        return dateAsString ? (' - ' + dateAsString) : '';
      },

      defaultTitle: function() {
        if (!this.vendor) {
          return this.getType().asTitle + this.dateSuffix();
        } else {
          return this.getType().asTitle + ': ' + this.vendor.fullName() + this.dateSuffix();
        }
      },
      medTitle: function() {
        if (isEmpty(this.treatments)) {
          return this.getType().asTitle + this.dateSuffix();
        } else {
          return this.getType().asTitle + ': ' + this.treatments.join('/') + this.dateSuffix();
        }
      },
      leaseTitle: function(horses) {
        const horseName = horses[0].name;
        const startDate = moment(this.lease.startDate, DATE_ISO_FORMAT).format(DATE_FORMAT);
        const endDate = moment(this.lease.endDate, DATE_ISO_FORMAT).format(DATE_FORMAT);
        return horseName + '\'s ' + this.getType().asTitle.toLowerCase() + ' from ' + startDate + ' to ' + endDate;
      },
      hasTreatment: function() {
        return includes(
          ['MEDICATION', 'DEWORMING', 'VACCINATION'],
          this.recordType
        );
      }
    };

    return Record;
  }]);
