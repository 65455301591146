import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

@Component({
  selector: 'shared-header-layout',
  templateUrl: './header-layout.component.html',
  styleUrls: ['./header-layout.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class HeaderLayoutComponent {
  @Input() baseLink = '/n/activity';
  @Input() externalBaseLink: boolean = false;
  @Input() hideDropdown = false;
  @Input() hideTitle = false;
  @Output() logout: EventEmitter<any> = new EventEmitter<any>();

  onLogoutClick(event?: any): void {
    if (event) {
      event.preventDefault();
    }
    this.logout.emit();
  }
}
