import {barnManagerModule} from '../index.module';
import {merge} from 'lodash';

barnManagerModule
  .factory('horseRepository', HorseRepository);

HorseRepository.$inject = ['$http', 'barnStorage', 'utils'];
function HorseRepository($http, barnStorage, utils) {
  const url = '/api/horses/';

  return {
    all: all,
    allIncludingArchived: allIncludingArchived,
    allIdWithName: allIdWithName,
    search: search,
    find: find,
    saveFeedingNotes: saveFeedingNotes,
    update: update,
    reactivate: reactivate,
    archive: archive,
    togglePin: togglePin,
    saveNotes: saveNotes,
    nameChanges: nameChanges,
    horseDetailsReport: horseDetailsReport,
    dischargeReport: dischargeReport
  };

  function all() {
    return $http.get(url, {
      params: mergeParams({
        archived: 0,
        pagesize: 999
      })
    }).then(function(results) {
      return utils.sortByCaseInsensitive(results.data.records, 'name');
    });
  }

  function allIncludingArchived() {
    return $http.get(url, {
      params: mergeParams({
        pagesize: 999
      })
    }).then(function(results) {
      return utils.sortByCaseInsensitive(results.data.records, 'name');
    });
  }

  function allIdWithName() {
    return $http.get(`${url}/idWithName/`, {
      params: mergeParams({
        page: 0,
        pagesize: 10000,
        sort: 'name',
        archived: 0
      })
    }).then(function(results) {
      return results.data.records;
    });
  }

  function search(params) {
    return $http.get(url, {
      params: mergeParams(params)
    }).then(function(results) {
      return results.data;
    });
  }

  function find(id) {
    if (!id) {
      return Promise.resolve(null);
    }
    return $http.get(url + id, {
      params: mergeParams({})
    }).then(function(results) {
      return results.data;
    });
  }

  function update(id, horse) {
    return $http.put(url + id, horse);
  }

  function archive(id) {
    return $http.delete(url + id);
  }

  function reactivate(id) {
    return $http.put(url + id + '/reactivate');
  }

  function togglePin(id) {
    return $http.get(url + id + '/pin');
  }

  function saveNotes(id, notes) {
    return $http.put(url + id + '/notes', {
      notes: notes
    });
  }

  function nameChanges(id) {
    return $http.get(url + id + '/nameChanges');
  }

  function saveFeedingNotes(id, notes) {
    return find(id).then(function(horse) {
      horse.feedingNotes = notes;
      update(id, horse);
    });
  }

  function horseDetailsReport(id) {
    return $http.get(url + id + '/horse-details-report', null, { transformResponse: [ (data: any) => data] }).then((result) => result.data);
  }

  function dischargeReport(id, includeVetRecords, vetRecordsPeriod, notes) {
    return $http.post(url + 'v3/' + id + '/discharge-report', {
      includeVetRecords,
      vetRecordsStartDate: vetRecordsPeriod.startDate,
      vetRecordsEndDate: vetRecordsPeriod.endDate,
      notes},
      { transformResponse: [ (data: any) => data] }).then((result) => result.data);
  }

  function mergeParams(params) {
    const defaultParams = {
      tenantEnvironmentId: barnStorage.getEnvId()
    };
    return merge(defaultParams, params || {});
  }
}
