import {barnManagerModule} from '../../index.module';
import {get, merge} from 'lodash';

barnManagerModule
  .component('bmPlanSelect', {
    templateUrl: 'plan-select.html',
    controller: planSelectController,
    controllerAs: 'vm',
    bindings: {
      updateSubscriptionInfo: '<',
      cancelResubscribeAction: '<'
    }
  });

planSelectController.$inject = [
  '$q',
  '$timeout',
  '$state',
  'responseHandler',
  'ScrollToMessageFrame',
  'StripeCheckout',
  'subscriptionStorage',
  'billingPlan',
  'plansStorage',
  'PlanOptions',
  'userStorage',
  'utils',
  'confirmDialog',
  'barnStorage',
  '$window',
  'ng2Intercom'
];
function planSelectController(
  $q,
  $timeout,
  $state,
  responseHandler,
  ScrollToMessageFrame,
  StripeCheckout,
  subscriptionStorage,
  billingPlan,
  plansStorage,
  PlanOptions,
  userStorage,
  utils,
  confirmDialog,
  barnStorage,
  $window,
  ng2Intercom
) {
  const vm = this;

  vm.$onInit = function() {

    vm.selectedPlanTerm = 'monthly';

    vm.planOptions = PlanOptions;
    vm.filteredPlans = [];
    vm.showOptions = {};

    vm.timezone = utils.timezone;
    vm.changeTerm = changeTerm;
    vm.isSubscriptionCanceled = isSubscriptionCanceled;

    // active && subscribed
    vm.updatePaymentDetails = updatePaymentDetails;
    vm.startCancelSubscription = startCancelSubscription;

    // active && unsubscribed
    vm.reactivate = reactivate;

    // trial && unsubscribed
    vm.resubscribe = resubscribe;
    vm.cancelResubscribe = cancelResubscribe;

    vm.canSubscribe = canSubscribe;
    vm.wasSubscribed = wasSubscribed;
    vm.getCurrentPlanName = getCurrentPlanName;

    vm.selectNewPlan = selectNewPlan;
    vm.toggleOptions = toggleOptions;

    init();

    function selectNewPlan(plan) {
      const previousPlan = plansStorage.getPlanById(vm.subscription.planId);
      if (isSubscriptionCanceled()) {
        resubscribe(plan.id);
      } else {
        let message = 'Are you sure you want to enroll in the ' + plan.name + ' plan?';
        if (plan.type === 'pro' && !vm.subscription.isTrialing()) {
          message = 'Please confirm that you would like to upgrade to BarnManager Pro. Upon confirming, your card will be charged for the prorated difference between the pro plan and your current plan. Your future subscription renewals will be charged at the rate of the Pro Plan.';
        }

        confirmDialog.open(message).then(function() {
          subscriptionStorage.updatePaymentPlan(plan.id).then(function() {
            return userStorage.refreshUser();
          }).then(getSubscription).then(function(subscription) {
            if (previousPlan.type === 'starter' && plan.type === 'essential') {
              responseHandler.successMessage('Hooray! You’ve upgraded to the Essentials plan! All of your users with ' +
                '“Admin” status will automatically see the additional features. In order to give your other users access ' +
                'to all of the features in the Essentials plan, you must update their permissions located in User & ' +
                'Permissions settings.', 25000);
            } else if (plan.type === 'pro') {
              responseHandler.successMessage('Hooray! You’ve upgraded to the Pro plan! All of your users with ' +
                '“Admin” status will automatically see the additional features.', 25000);
            } else {
              responseHandler.successMessage('Subscription plan successfully changed');
            }
            return subscription;
          }).catch(errorHandler);
        }).catch(function() {});
      }
    }

    function changeTerm() {
      filterPlansBySelectedTerm();
    }

    function filterPlansBySelectedTerm() {
      vm.filteredPlans = plansStorage.filterByTerm(vm.selectedPlanTerm);
    }

    function isSubscriptionCanceled() {
      return get(vm.subscription, 'status') === 'canceled';
    }

    function getSubscription() {
      return subscriptionStorage.loadSubscription().then(updateSubscription).catch(errorHandler);
    }

    function updateSubscription(subscription) {
      getPaymentInfo();
      vm.subscription = subscription;
      vm.selectedPlanTerm = plansStorage.getSelectedPlanTerm();
      vm.updateSubscriptionInfo && vm.updateSubscriptionInfo(subscription);
      return subscription;
    }

      function getPaymentInfo() {
      vm.paymentInfoLoading = true;
      subscriptionStorage.paymentInfo().then(function(paymentInfo) {
        vm.paymentInfo = paymentInfo;
        vm.paymentInfoLoading = false;
      });
    }


    function getStripeOptions() {
      return {
        name: 'Barn Manager',
        image: '/assets/icons/android-chrome-144x144.png',
        description: 'Barn Subscription'
      };
    }

    function updatePaymentDetails() {
      StripeCheckout.configure({
        name: 'Barn Manager',
        token: function(token) {
          subscriptionStorage.updatePaymentInfo(token.id).then(getSubscription).catch(errorHandler);
        }
      }).open(merge({}, getStripeOptions(), {
        amount: null,
        panelLabel: 'Update'
      }));
    }

    function startCancelSubscription() {
      if ($window.churnkey != null) {
        billingPlan.stripe.churnkeyInfo({
          tenantEnvironmentId: barnStorage.getEnvId()
        }).$promise.then(function(info) {
          $window.churnkey.init('show', merge({
            record: true,
            handleSupportRequest: function(_customer) {
              ng2Intercom.showNewMessage('Attention: Off-boarding Customer Needs Help ASAP.\n');
            },
            onCancel: function(_customer, _surveyResponse, _freeformFeedback) {
              getSubscription();
            },
            onDiscount: function(_customer, _coupon) {
              getSubscription();
            }
          }, info));
        });
      } else {
        confirmDialog.open('Are you sure you want to cancel?').then(function() {
          subscriptionStorage.cancelSubscription().then(function() {
            vm.subscription = null;
            vm.paymentInfo = null;
            responseHandler.successMessage('Subscription was canceled');
            // $state.go('cancel-survey', { isRedirect: true });
          });
        });
      }
    }

    function reactivate() {
      subscriptionStorage.reactivate().then(updateSubscription).then(function(subscription) {
        responseHandler.successMessage('Subscription successfully reactivated');
        return subscription;
      }).catch(errorHandler);
    }

    function resubscribe(planId) {
      StripeCheckout.configure({
        name: 'Barn Manager',
        token: function(token) {
          subscriptionStorage.resubscribe(token.id, planId)
            .then(updateSubscription)
            .then(function(subscription) {
              responseHandler.successMessage('Subscription successfully renewed');
              return subscription;
            }).catch(function(error) {
              errorHandler(error);
            }).then(function() {
              $state.go('activity');
            });
        }
      }).open(merge({}, getStripeOptions(), {
        amount: plansStorage.getPlanById(planId).amount
      }));
    }

    function cancelResubscribe() {
      vm.cancelResubscribeAction && vm.cancelResubscribeAction();
    }

    function canSubscribe(plan) {
      return (vm.subscription && plan.id !== vm.subscription.planId && plan.amount > 0) ||
        (vm.subscription && plan.id === vm.subscription.planId && plan.amount > 0 && vm.subscription.legacy) ||
        (vm.isSubscriptionCanceled() && plan.amount);
    }

    function wasSubscribed(plan) {
      return vm.subscription && plan.id === vm.subscription.planId && !isSubscriptionCanceled() && !vm.subscription.legacy;
    }

    function getCurrentPlanName() {
      if (!vm.subscription) {
        return '';
      }
      return vm.subscription.planName;
    }

    function toggleOptions(type) {
      vm.showOptions[type] = !vm.showOptions[type];
    }

    function errorHandler(error) {
      ScrollToMessageFrame();

      return responseHandler.processError(error, function(errorData) {
        if (errorData.message === 'Card declined') {
          return 'Your credit card was declined';
        }
        return errorData.message;
      });
    }

    function init() {
      plansStorage.getAllPlans().then(function() {
        return getSubscription();
      }).then(function(response) {
        filterPlansBySelectedTerm();
        return response;
      }).catch(errorHandler);
    }

  };
}
