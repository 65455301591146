import {barnManagerModule} from '../../index.module';
import {get} from 'lodash';

barnManagerModule
  .component('bmCharacterCounter', {
    templateUrl: 'character-counter.html',
    controller: characterCounterController,
    controllerAs: 'vm',
    bindings: {
      content: '<',
      maxCount: '<',
      showErrors: '<',
      errorMessage: '<'
    }
  });

function characterCounterController() {
  const vm = this;

  vm.$onInit = function() {

    vm.getCount = getCount;
    vm.hasError = hasError;

    function getCount() {
      return get(vm.content, 'length', 0);
    }

    function hasError() {
      return getCount() > vm.maxCount;
    }

  };
}
