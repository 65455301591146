import {barnManagerModule} from '../../../../index.module';
import {includes} from 'lodash';
import {HorseLocation} from '../../../../../app/horse/horse-location';

barnManagerModule
  .component('bmHorseLocationsArea', {
    templateUrl: 'horse-locations-area.html',
    controller: horseLocationController,
    controllerAs: 'vm',
    bindings: {
      locations: '<',
      onChange: '<'
    }
  });

horseLocationController.$inject = ['$scope', 'horseLocationRepository', 'responseHandler', 'MessageFrames'];
function horseLocationController($scope, horseLocationRepository, responseHandler, MessageFrames) {
  const vm = this;

  vm.allLocations = [];

  vm.onLocationsChange = onLocationsChange;
  vm.onArchive = onArchive;
  vm.onRestore = onRestore;

  loadAllLocations();

  $scope.$watch('vm.allLocations', (newLocations: HorseLocation[]) => {
    if (newLocations) {
      vm.locationSelectItems = newLocations.filter(location => !location.archived);
      vm.settingBtnAvailable = vm.allLocations && vm.allLocations.some((location: HorseLocation) => location.id);
    }
  });

  $scope.$watch('vm.locations', () => setLocationsByName());

  function loadAllLocations() {
    horseLocationRepository.all().then((locations: HorseLocation[]) => {
      vm.allLocations = locations;
      setLocationsByName();
    });
  }

  function setLocationsByName() {
    if (vm.locations?.length > 0 ) {
      vm.selected = vm.allLocations.find((location: HorseLocation) => vm.locations[0] === location.location);
    } else {
      vm.selected = null;
    }
  }

  function onLocationsChange(selectedLocation: HorseLocation) {
    if (selectedLocation) {
      const locationNames = vm.allLocations.map((location: HorseLocation) => location.location);
      [selectedLocation].forEach((location: HorseLocation) => {
        if (!locationNames.includes(location.location)) {
          vm.allLocations = [...vm.allLocations, location];
        }
      });
      vm.locations = [selectedLocation.location];
    } else {
      vm.locations = null;
    }
    if (vm.onChange) {
      vm.onChange(vm.locations);
    }
  }

  function onArchive(item: HorseLocation & { loading: boolean }) {
    item.loading = true;
    horseLocationRepository.archive(item.id).then(() => {
      const location = vm.allLocations.find((location: HorseLocation) => location.id === item.id);
      if (location) {
        location.archived = true;
      }
      vm.allLocations = [...vm.allLocations];
    }).catch(errorHandler).finally(() => item.loading = false);
  }

  function onRestore(item: HorseLocation & { loading: boolean }) {
    item.loading = true;
    horseLocationRepository.restore(item.id).then(() => {
      const location = vm.allLocations.find((location: HorseLocation) => location.id === item.id);
      if (location) {
        location.archived = false;
      }
      vm.allLocations = [...vm.allLocations];
    }).catch(errorHandler).finally(() => item.loading = false);
  }

  function errorHandler(error) {
    return responseHandler.processError(error, null, MessageFrames.TOP);
  }
}
