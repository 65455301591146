import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef, Inject,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {Ng1BootstrapService} from './core/ng1/ng1-bootstrap.service';
import {Intercom} from '@shared/lib/intercom';
import {ENVIRONMENT} from '@shared-models/environment-type';
import {BmEnvironmentType} from '../environments/bm-environment-type';
import {GoNativeService} from '@shared/lib/go-native/go-native.service';
import {combineLatest} from 'rxjs';

@Component({
  selector: 'bm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  ng1Bootstrapped: boolean;
  ng1Active: boolean;

  constructor(
    private intercom: Intercom,
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private elRef: ElementRef,
    private ng1BootstrapService: Ng1BootstrapService,
    private cdr: ChangeDetectorRef,
    private goNativeService: GoNativeService,
    @Inject(ENVIRONMENT) public environment: BmEnvironmentType
  ) {
    if (this.goNativeService.isGoNative) {
      document.documentElement.classList.add('go-native');
    }
  }

  ngOnInit() {
    this.subscribeOnNg1BootstrappedAndNg1ActiveChange();
    this.ng1BootstrapService.bootstrap(this.elRef.nativeElement);
    this.subscribeOnRouterEventsChange();
    if (this.environment.intercomSettings.enabled) {
      this.intercom.boot();
    }
  }

  private subscribeOnNg1BootstrappedAndNg1ActiveChange(): void {
    combineLatest([this.ng1BootstrapService.ng1Bootstrapped$, this.ng1BootstrapService.ng1Active$]).subscribe(([ng1Bootstrapped, ng1Active]) => {
      this.ng1Bootstrapped = ng1Bootstrapped;
      this.ng1Active = ng1Active;
      this.cdr.detectChanges();
    })
  }

  private subscribeOnRouterEventsChange(): void {
    const defaultTitle = 'BarnManager';
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      filter((event: NavigationEnd) => !event.urlAfterRedirects.startsWith('/n/')),
      map(() => {
        let child = this.activatedRoute.firstChild;
        while (child.firstChild) {
          child = child.firstChild;
        }
        if (child.snapshot.data['title']) {
          return `${child.snapshot.data['title']} :: ${defaultTitle}`;
        }
        return defaultTitle;
      })
    ).subscribe((title: string) => {
      this.titleService.setTitle(title);
    });
  }
}
