import {barnManagerModule} from '../../index.module';

barnManagerModule
  .component('bmBackLink', {
    templateUrl: 'backLink.html',
    controller: BackLinkController,
    controllerAs: 'vm',
    bindings: {
      viewDisabled: '<',
      viewType: '@',
      classes: '@'
    }
  });

BackLinkController.$inject = ['$rootScope', '$location', '$window', 'backLinkHistory']

function BackLinkController($rootScope, $location, $window, backLinkHistory) {
  const vm = this;
  vm.backLink = '/n/activity';
  vm.backLinkName = '...';

  vm.onClick = onClick;

  function onClick(e) {
    // do not change the behavior of the link click event if the special keys were pressed
    // for Mac - Mac68K | MacPPC | MacIntel
    if ($window.navigator.platform.toUpperCase().indexOf('MAC') === 0 ) {
      // Mac: Cmd+click | Cmd+Shift+click | Cmd+Option+click | Cmd+Option+Shift+click
      if (e.metaKey || (e.metaKey && e.shiftKey) || (e.metaKey && e.altKey) || (e.metaKey && e.altKey && e.shiftKey)) {
        return;
      }
    } else {
      // PC (Windows, Linux): Ctrl+click | Shift+click | Ctrl+Shift+click
      if (e.ctrlKey || e.shiftKey) {
        return;
      }
    }
    if (e.preventDefault) {
      e.preventDefault();
    }
    backLinkHistory.goBack();
  }

  vm.$onInit = function() {
    if (vm.viewType !== 'button') {
      vm.viewType = 'link';
    }
    backLinkHistory.getBackLink().then(updateData.bind(null));
  };

  const backLinkChanged = $rootScope.$on('backLinkChanged', function(e, data) {
    updateData(data);
  });
  $rootScope.$on('$destroy', backLinkChanged);

  function updateData(data) {
    if (data.external) {
      vm.backLink = '/invoicing' + data.url;
    } else {
      vm.backLink = data.url;
    }
    vm.backLinkName = data.title;
  }

}
