import {barnManagerModule} from '../../../index.module';

barnManagerModule
  .component('bmContactActionsMenu', {
    templateUrl: 'contact-actions-menu.html',
    controller: contactActionsMenuController,
    controllerAs: 'vm',
    bindings: {
      contact: '=',
      showPrint: '=',
      onSuccessArchive: '<',
      onSuccessRestore: '<'
    }
  });

contactActionsMenuController.$inject = [
  'hasPermission',
  'goNative',
  'confirmDialog',
  'htmlGenerator',
  'rContacts',
  'responseHandler'
];
function contactActionsMenuController(
  hasPermission,
  goNative,
  confirmDialog,
  htmlGenerator,
  rContacts,
  responseHandler
) {
  const vm = this;

  vm.hasContactsFullPermission = hasPermission('contacts:full');
  vm.isGoNative = goNative.isGoNative();

  vm.print = print;
  vm.toggleArchive = toggleArchive;

  function print() {
    window.print();
  }

  function toggleArchive() {
    if (!vm.contact.archived) {
      confirmDialog.open('Are you sure you want to archive this contact?').then(function() {
        doToggleArchive();
      }).catch(function() {});
    } else {
      doToggleArchive();
    }
  }

  function doToggleArchive() {
    if (vm.contact.archiving) {
      return;
    }
    vm.contact.archiving = true;
    const archived = !vm.contact.archived;

    if (archived) {
      const undoLink = htmlGenerator.link('contactDetails', {id: vm.contact.id}, 'Undo');
      rContacts.delete({id: vm.contact.id}).$promise.then(function() {
        vm.contact.archived = archived;
        if (vm.onSuccessArchive) {
          vm.onSuccessArchive();
        }
        responseHandler.successMessage('You successfully archived ' + vm.contact.name + '. ' + undoLink + '.');
      }).finally(() => vm.contact.archiving = false);
    } else {
      rContacts.reactivate({id: vm.contact.id}).$promise.then(function() {
        vm.contact.archived = archived;
        if (vm.onSuccessRestore) {
          vm.onSuccessRestore();
        }
        responseHandler.successMessage('You successfully unarchived ' + vm.contact.name + '.');
      }).finally(() => vm.contact.archiving = false);
    }
  }
}
