import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SafePipe} from './safe-pipe/safe.pipe';
import {GroupByPipe} from './group-by-pipe/group-by.pipe';
import {KeysPipe} from './keys-pipe/keys.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    SafePipe,
    GroupByPipe,
    KeysPipe
  ],
  exports: [
    SafePipe,
    GroupByPipe,
    KeysPipe
  ]
})
export class SharedPipesModule { }
