import {barnManagerModule} from '../../index.module';
import {isNil} from 'lodash';

barnManagerModule
  .component('bmEventAssignments', {
    templateUrl: 'event-assignments.html',
    controller: EventAssignments,
    controllerAs: 'vm',
    bindings: {
      viewMode: '@',
      event: '=',
      assignments: '=',
      contacts: '<',
      horses: '<',
      onAddAssignments: '&',
      onToggleAttendance: '&'
    }
  });

function EventAssignments() {
  const vm = this;

  vm.$onInit = function() {
    vm.selectedAssignments = null;

    vm.addAssignments = addAssignments;
    vm.removeAssignments = removeAssignments;
    vm.changeAttendance = changeAttendance;
    vm.onSelectContact = onSelectContact;
    vm.onSelectHorse = onSelectHorse;

    function addAssignments($event) {
      $event.preventDefault();

      if (!isNil(vm.selectedAssignments) && !isNil(vm.selectedAssignments.contact) && !isNil(vm.selectedAssignments.horse)) {
        vm.assignments.push({
          contact: vm.selectedAssignments.contact,
          horse: vm.selectedAssignments.horse
        });
        vm.onAddAssignments({ $selectedAssignments: vm.selectedAssignments });
        vm.selectedAssignments = null;
      }
    }

    function removeAssignments($event, index) {
      $event.preventDefault();
      vm.assignments.splice(index, 1);
    }

    function changeAttendance(assignment) {
      assignment.attended = !assignment.attended;
      vm.onToggleAttendance({$attendance: assignment});
    }

    function onSelectContact(contact) {
      vm.selectedAssignments = { ...vm.selectedAssignments, contact };
    }

    function onSelectHorse(horse) {
      vm.selectedAssignments = { ...vm.selectedAssignments, horse };
    }
  };
}
