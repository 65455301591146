<shared-bm-ng-select [items]="products"
                     [selected]="selectedProduct"
                     [multiple]="false"
                     [tagging]="true"
                     [virtualScroll]="false"
                     [placeholder]="'Select or enter a new product'"
                     [searchFn]="customSearchFn"
                     [ngOptionTmp]="productOptionTmp"
                     (changeSelected)="changeSelected.emit($event)">
</shared-bm-ng-select>

<ng-template #productOptionTmp let-item let-search="search">
  <span [ngOptionHighlight]="search">{{ item.name }}</span>
  <small *ngIf="item.brand" class="product-select__brand">(Brand: <span [ngOptionHighlight]="search">{{ item.brand }}</span>)</small>
</ng-template>

