import {barnManagerModule} from '../index.module';
import * as angular from 'angular';

barnManagerModule.factory('userSettingsStorage', UserSettingsStorage);

UserSettingsStorage.$inject = ['rModelLists'];
function UserSettingsStorage(rModelLists) {
  let userSettings = null;

  return {
    getUserSettings: getUserSettings,
    setUserSettings: setUserSettings,
    hasUserSettings: hasUserSettings,
    loadUserSettings: loadUserSettings,
    clean: clean
  };

  function getUserSettings() {
    return angular.copy(userSettings);
  }

  function setUserSettings(_userSettings) {
    userSettings = angular.copy(_userSettings);
  }

  function hasUserSettings() {
    return !!userSettings;
  }

  function loadUserSettings(id) {
    return rModelLists.getUserPreferences({ userId: id }).$promise.then(function(_userSettings) {
      setUserSettings(_userSettings);
      return userSettings;
    });
  }

  function clean() {
    userSettings = null;
  }
}
