import {barnManagerModule} from '../../../index.module';
import {cloneDeep, find, isEmpty, isNull, map, values} from 'lodash';
import {copy, isUndefined} from 'angular';

barnManagerModule.controller('RecordFormController', RecordFormController);

RecordFormController.$inject = [
  '$stateParams',
  '$rootScope',
  '$scope',
  '$state',
  '$q',
  'backLinkHistory',
  'userStorage',
  'barnStorage',
  'responseHandler',
  'listsService',
  'recordRepository',
  'rAttachments',
  'userRepository',
  'rContacts',
  'Record',
  'recordMapper',
  'RecordTypes',
  'Vendor',
  'vendorRepository',
  'treatmentRepository',
  'NOTE_LENGTH_LIMIT',
  'Permission',
  'AccessTypes',
  'ScrollToMessageFrame',
  'PermissionTypes',
  'MessageFrames',
  'titleService',
  'hasPermission'
];
function RecordFormController(
  $stateParams,
  $rootScope,
  $scope,
  $state,
  $q,
  backLinkHistory,
  userStorage,
  barnStorage,
  responseHandler,
  listsService,
  recordRepository,
  rAttachments,
  userRepository,
  rContacts,
  Record,
  recordMapper,
  RecordTypes,
  Vendor,
  vendorRepository,
  treatmentRepository,
  NOTE_LENGTH_LIMIT,
  Permission,
  AccessTypes,
  ScrollToMessageFrame,
  PermissionTypes,
  MessageFrames,
  titleService,
  hasPermission
) {
  const vm = this, removedFiles = [];

  titleService.setTitle('Record Form');
  $rootScope.pageConf.class = 'page--interior';

  vm.hasVendorsFullPermission = hasPermission('vendors:full');
  vm.currentUser = userStorage.getUser();
  vm.recordTypes = values(RecordTypes);
  vm.isLeaseType = false;
  vm.rType = vm.recordTypes[0];
  vm.recordVendor = null;
  vm.allVendors = [];
  vm.contacts = [];
  vm.reminders = { leaseEnd: [], leaseBuyout: [] };

  vm.noteLengthLimit = NOTE_LENGTH_LIMIT;
  vm.attachmentsReady = false;
  vm.done = true;
  vm.status = {
    updating: false,
    submitAttempt: false
  };
  vm.editMode = $state.current.name === 'recordEdit' || $state.current.name === 'recordHorseEdit';
  vm.isCalendarOpen = false;
  vm.treatments = {};
  vm.showTreatmentConfigureModal = false;
  vm.assignedHorses = [];
  vm.horseId = $state.params.horseId;

  vm.queueDelete = queueDelete;
  vm.save = save;
  vm.openCalendar = openCalendar;
  vm.onTitleChange = onTitleChange;

  vm.onHorsesSet = onHorsesSet;
  vm.onHorsesChange = onHorsesChange;
  vm.onTagsChange = onTagsChange;
  vm.onRTypeChange = onRTypeChange;
  vm.onRecordVendorChange = onRecordVendorChange;
  vm.onLesseeChange = onLesseeChange;
  vm.onLessorChange = onLessorChange;
  vm.onLeaseDateChange = onLeaseDateChange;
  vm.onTreatmentsChange = onTreatmentsChange;
  vm.onMembersChange = onMembersChange;
  vm.onArchiveTreatment = onArchiveTreatment;
  vm.onRestoreTreatment = onRestoreTreatment;
  vm.getTreatmentNames = getTreatmentNames;
  vm.onToggleArchiveVendor = onToggleArchiveVendor;
  vm.setRecordVendor = setRecordVendor;
  vm.initiateSaveVendor = initiateSaveVendor;
  vm.onAddVendorSuccess = onAddVendorSuccess;

  initializeRecord(vm.editMode);

  function onMembersChange(membersModel) {
    vm.record.memberIds = membersModel.memberIds;
    vm.record.allTeamMembersNotifications = membersModel.allTeamMembersNotifications;
  }

  function onHorsesChange(horseIds) {
    vm.record.horseIds = horseIds;
  }

  function onHorsesSet(horses) {
    vm.assignedHorses = horses;
  }

  function onTagsChange(tags) {
    vm.record.tags = tags;
  }

  function onLeaseDateChange(item) {
    const fields = ['startDate', 'endDate', 'buyoutDate', 'extensionDate'];
    fields.forEach(function(field) {
      if (vm.record.lease[field] !== item[field]) {
        vm.record.lease[field] = item[field];
      }
    });
  }

  function onLessorChange(item) {
    vm.lessor = item;
    vm.record.lease.lessorId = item && item.id ? item.id : null;
  }

  function onLesseeChange(item) {
    vm.lessee = item;
    vm.record.lease.lesseeId = item && item.id ? item.id : null;
  }

  function onTitleChange() {
    vm.record.customTitle = !isEmpty(vm.record.title);
  }

  function queueDelete(file) {
    removedFiles.push(file);
  }

  function goToRecord() {
    return listsService.ready(function() {}).then(function() {
      vm.status.updating = false;
      responseHandler.successOnSave('Record', vm.editMode);
      backLinkHistory.goBack();
    });
  }

  function setRType(type) {
    vm.rType = type;
    onRTypeChange();
  }

  function onRTypeChange(manual = false) {
    if (!!vm.rType && !!vm.record) {
      vm.record.recordType = vm.rType.value;
      if (vm.rType.value === RecordTypes.LEASE.value) {
        vm.record.horseIds = vm.record.horseIds.length ? [vm.record.horseIds[0]] : [];
        vm.isLeaseType = true;
        if (!vm.record.lease) {
          vm.record.lease = {};
        }
      } else {
        vm.isLeaseType = false;
      }

      if (
        (vm.recordVendor &&
        vm.rType.vendorRestriction.type &&
        vm.recordVendor.vendorType === vm.rType.vendorRestriction.type.value) ||
        vm.rType.vendorRestriction.name === 'All'
      ) {
        return;
      }

      vm.recordVendor = null;
      vm.record.vendorId = null;
      if (manual) {
        vm.record.treatments = [];
      }
    }
  }

  function setRecordVendor(item) {
    vm.recordVendor = item;
    onRecordVendorChange();
  }

  function onRecordVendorChange() {
    if (!isUndefined(vm.recordVendor) && !isEmpty(vm.record)) {
      vm.record.vendorId = isNull(vm.recordVendor) ? null : vm.recordVendor.id;
      vm.record.vendor = vm.recordVendor;
    }
  }

  function updateRelatedData(record, attachments) {
    listsService.updateAttachments(attachments.new, attachments.removed, record);
  }

  function save(form) {
    vm.status.submitAttempt = true;

    if (!form.$valid) {
      return;
    }
    vm.record.enforceTitle(vm.assignedHorses);
    vm.status.updating = true;

    if (vm.record.recordType === RecordTypes.LEASE.value) {
      updateLeaseData().then(function(persons) {
        vm.record.lease.lesseeId = persons.lessee && persons.lessee.id ? persons.lessee.id : null;
        vm.record.lease.lessorId = persons.lessor && persons.lessor.id ? persons.lessor.id : null;
        saveRecord();
      });
    } else {
      saveRecord();
    }
  }

  function saveRecord() {
    // Server can mutate sent data, so resource also will change it
    // and because of this we will lose actual state of this data
    // TODO: Remove cloning after rewriting of listsService
    const clonedRecord = cloneDeep(vm.record);

    const recordPayload = recordMapper.recordToPayload(vm.record);

    if (vm.editMode) {
      // Server will ignore some of our changes in record model after what angular will mutate model also.
      // So, we update data related to record (attachments, tags, etc.) first, then we update record itself
      // TODO: Remove cloning after rewriting of listsService
      updateRelatedData(clonedRecord, {
        new: vm.attachments,
        removed: removedFiles
      });

      recordRepository.update(recordPayload)
        .then(goToRecord)
        .catch(errorHandler);
    } else {
      recordRepository.create(recordPayload)
        .then(function(record) {
          // We need to get id of new record from the response and explicitly add it to the record model
          clonedRecord.id = record.id;
          return updateRelatedData(clonedRecord, {
            new: vm.attachments,
            removed: []
          });
        }).then(goToRecord)
        .catch(errorHandler);
    }
  }

  function updateLeaseData() {
    const requests: any = {};
    if (vm.lessee) {
      if (vm.lessee.id) {
        requests.lessee = rContacts.update(vm.lessee).$promise.catch(errorHandler);
      } else {
        requests.lessee = rContacts.save(vm.lessee).$promise.catch(errorHandler);
      }
    }
    if (vm.lessor) {
      if (vm.lessor.id) {
        requests.lessor = rContacts.update(vm.lessor).$promise.catch(errorHandler);
      } else {
        requests.lessor = rContacts.save(vm.lessor).$promise.catch(errorHandler);
      }
    }
    return $q.all(requests);
  }

  function errorHandler(error, position) {
    vm.status.updating = false;
    ScrollToMessageFrame();
    return responseHandler.processError(error, null, position);
  }

  function openCalendar() {
    vm.isCalendarOpen = true;
  }

  function createBaseRecord() {
    const newRecord: any =       {
      id: null,
      tenantEnvironmentId: barnStorage.getEnvId(),
      authorId: vm.currentUser.id,
      pinned: 0,
      archived: 0,
      read: 0,
      notes: '',
      title: '',
      allHorsesAccess: false,
      allTeamMembersNotifications: false,
      tags: null,
      lease: {},
      horseIds: [],
      vendorId: null,
      treatments: []
    };
    const leaseHorseId = Number($stateParams.leaseHorseId);
    if (!isNaN(leaseHorseId) && leaseHorseId > 0) {
      newRecord.horseIds = [leaseHorseId];
      newRecord.lease.buyoutDateReminders = [];
      newRecord.lease.endDateReminders = [];
      newRecord.lease.extensionDateReminders = [];
      setRType(RecordTypes.LEASE);
      vm.isLeaseType = true;
    } else {
      newRecord.date = new Date();
    }
    newRecord.recordType = vm.rType.value;
    return newRecord;
  }

  function loadContacts() {
    const queryParams = { tenantEnvironmentId: barnStorage.getEnvId(), pagesize: 999, archived: 0 };
    return rContacts.query(queryParams).$promise.then(function(response) {
      vm.contacts = response.records;
      return response.records;
    });
  }

  function loadVendors() {
    return vendorRepository.allIncludingArchived().then(function(vendors) {
      vm.allVendors = map(vendors.records, (vendorData) => new Vendor(vendorData))
        .sort((a, b) => sortAlphabetically(a.fullName(), b.fullName()));
      return vendors.records;
    });
  }

  function loadTreatments() {
    return treatmentRepository.all().then(function(treatments) {
      const treatmentsTypes = [RecordTypes.DEWORMING.value, RecordTypes.MEDICATION.value, RecordTypes.VACCINATION.value];
      treatmentsTypes.forEach(type => vm.treatments[type] = { items: [] });
      treatments.forEach(item => vm.treatments[item.type].items.push(item));
      return treatments;
    });
  }

  function loadAllUsers() {
    return userRepository.all().then(function(users) {
      vm.allUsers = users;
      return users;
    });
  }

  function getRecord(recordId) {
    return recordRepository.find(recordId).then(function(record) {
      vm.record = recordMapper.recordFromPayload(record, vm.allVendors);
      vm.record.read = 1;

      setRecordVendor(find(vm.allVendors, { id: record.vendorId }));
      setRType(RecordTypes[record.recordType]);
      vm.isRTypeSelectDisabled = vm.editMode && vm.isLeaseType;

      return vm.record;
    });
  }

  function getAttachments(record) {
    return rAttachments.query({ modelType: 'Record', modelId: record.id }).$promise.then(function(attachments) {
      vm.attachments = attachments;
    }).finally(function() {
      vm.attachmentsReady = true;
    });
  }

  function initializeRecord(editMode) {
    vm.loading = true;
    listsService.resetTo('records', 'RECORD');

    watchVendorsForCurrentRecordTypeAndRecordTypeChange();
    watchShowAddVendorChange();
    watchTreatmentsAndRecordTypeChanges();

    $q.all([loadVendors(), loadTreatments(), loadAllUsers()]).then(function() {
      // hot-fix to allow users with no permissions to Contacts (for lease records)
      // to be able to add any other record types
      if (vm.currentUser.hasPermission(new Permission(PermissionTypes.contacts, AccessTypes.read))) {
        return loadContacts();
      }
    }).then(function() {
      if (editMode) {
        const currentRecordId = $state.current.name === 'recordHorseEdit' ? $stateParams.recordId : $stateParams.id;
        getRecord(currentRecordId).then(function(record) {
          vm.loading = false;
          backLinkHistory.pushLink(record.title);
          getAttachments(record);
        }).catch(function (error) {
          vm.loading = false;
          vm.error = true;
          responseHandler.processError(error);
        });
      } else {
        vm.loading = false;
        backLinkHistory.pushLink('New record');
        vm.attachmentsReady = true;
        vm.record = new Record(createBaseRecord());
        if (vm.horseId) {
          vm.record.horseIds = [Number(vm.horseId)];
        }
      }
    });
  }

  function watchTreatmentsAndRecordTypeChanges() {
    $scope.$watchCollection('[vm.treatments, vm.rType.value]', ([treatments, recordType]) => {
      if (treatments && recordType && treatments[recordType]?.items) {
        vm.filteredTreatments = treatments[recordType].items.filter(item => !item.archived);
        vm.treatmentSettingBtnAvailable = treatments[recordType].items && treatments[recordType].items.some(i => i.id);
      }
    });
  }

  function onTreatmentsChange(selected) {
    vm.record.treatments = selected.map(i => i.name || i);
    const allTreatmentNames = vm.treatments[vm.rType.value].items.map(i => i.name);
    selected.forEach(i => {
      if (!allTreatmentNames.includes(i.name || i)) {
        vm.treatments[vm.rType.value].items.push({ ...i, archived: false });
        vm.treatments[vm.rType.value].items = vm.treatments[vm.rType.value].items.sort((a, b) => sortAlphabetically(a.name, b.name));
      }
    });
  }

  function onArchiveTreatment(item) {
    item.loading = true;
    treatmentRepository.archive(item.id).then(function(data) {
      const treatment = vm.treatments[vm.rType.value].items.find(i => i.id === item.id);
      if (treatment) {
        treatment.archived = true;
      }
      vm.treatments = { ...vm.treatments };
      return data;
    }).catch(error => errorHandler(error, MessageFrames.TOP)).finally(() => item.loading = false);
  }

  function onRestoreTreatment(item) {
    item.loading = true;
    treatmentRepository.restore(item.id).then(function(data) {
      const treatment = vm.treatments[vm.rType.value].items.find(i => i.id === item.id);
      if (treatment) {
        treatment.archived = false;
      }
      vm.treatments = { ...vm.treatments };
      return data;
    }).catch(error => errorHandler(error, MessageFrames.TOP)).finally(() => item.loading = false);
  }

  function getTreatmentNames() {
    return vm.treatments[vm.rType.value].items.filter(item => !item.archived).map(item => item.name);
  }

  function watchVendorsForCurrentRecordTypeAndRecordTypeChange() {
    $scope.$watch('vm.record.recordType', () => vm.vendorsForCurrentRecordType = getVendorsForCurrentRecordType());
    $scope.$watch('vm.vendorsForCurrentRecordType', () => vm.filteredVendors = [ ...vm.vendorsForCurrentRecordType.filter(vendor => !vendor.archived) ]);
  }

  function getVendorsForCurrentRecordType() {
    const filterVendors = (vendor) => {
      if (vm.rType.vendorRestriction) {
        return vm.rType.vendorRestriction.filter(vendor);
      } else {
        return true;
      }
    };

    return [ ...vm.allVendors.filter(vendor => filterVendors(vendor)) ];
  }

  function onToggleArchiveVendor(item) {
    const vendor = vm.allVendors.find(i => i.id === item.id);
    if (vendor) {
      item.loading = true;
      const archived = vendor.archived ? 0 : 1;
      const payload = Object.assign(copy(vendor), { archived });
      vendorRepository.update(item.id, payload).then(function() {
        vendor.archived = item.archived = archived;
        vm.vendorsForCurrentRecordType = [ ...vm.vendorsForCurrentRecordType ];
      }).catch(error => errorHandler(error, MessageFrames.TOP)).finally(() => item.loading = false);
    }
  }

  function initiateSaveVendor() {
    $scope.$broadcast ('initiateSaveVendor');
  }

  function onAddVendorSuccess(vendor) {
    vm.allVendors.push(new Vendor(vendor));
    vm.allVendors = vm.allVendors.sort((a, b) => sortAlphabetically(a.fullName(), b.fullName()));
    vm.vendorsForCurrentRecordType = getVendorsForCurrentRecordType();
    vm.showAddVendor = false;
    vm.showVendorConfigureModal = false;
  }

  function watchShowAddVendorChange() {
    $scope.$watch('vm.showAddVendor', () => vm.addingVendor = false);
  }

  function sortAlphabetically(a, b) {
    const nameA = a.toUpperCase();
    const nameB = b.toUpperCase();
    return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
  }
}
