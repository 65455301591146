import {barnManagerModule} from '../../index.module';

barnManagerModule.directive('bmMessageBox', messageBox);
function messageBox() {
  return {
    restricted: 'E',
    templateUrl: 'message-box.html',
    controller: MessageBoxController,
    controllerAs: 'message',
    bindToController: true,
    transclude: {
      action: '?bmMessageBoxAction'
    },
    scope: {}
  };
  function MessageBoxController() { }
}
