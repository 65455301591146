import {barnManagerModule} from '../../../index.module';

barnManagerModule
  .component('bmConversationActionsMenu', {
    templateUrl: 'conversation-actions-menu.html',
    controller: conversationActionsMenuController,
    controllerAs: 'vm',
    bindings: {
      conversation: '=',
      horseId: '=',
      showPrint: '=',
      onSuccessArchive: '<',
      onSuccessRestore: '<',
      onSuccessRemove: '<'
    }
  });

conversationActionsMenuController.$inject = [
  'hasPermission',
  'goNative',
  'confirmDialog',
  'htmlGenerator',
  'rUserConversations',
  'responseHandler'
];
function conversationActionsMenuController(
  hasPermission,
  goNative,
  confirmDialog,
  htmlGenerator,
  rUserConversations,
  responseHandler
) {
  const vm = this;

  vm.hasAdminFullPermission = hasPermission('admin:full');
  vm.hasConversationsFullPermission = hasPermission('conversations:full');
  vm.hasConversationsReadPermission = hasPermission('conversations:read');
  vm.isGoNative = goNative.isGoNative();

  vm.print = print;
  vm.toggleArchive = toggleArchive;
  vm.remove = remove;

  function print() {
    window.print();
  }

  function toggleArchive() {
    if (!vm.conversation.archived) {
      confirmDialog.open('Are you sure you want to archive this conversation?').then(function() {
        doToggleArchive();
      }).catch(function() {});
    } else {
      doToggleArchive();
    }
  }

  function doToggleArchive() {
    vm.conversation.archived = !vm.conversation.archived;

    if (vm.conversation.archived) {
      vm.archiving = true;
      let entityLink;
      if (vm.horseId > 0) {
        entityLink = htmlGenerator.link('conversationHorseDetails', { id: vm.horseId, conversationId: vm.conversation.id, author: vm.conversation.authorId }, vm.conversation.title);
      } else {
        entityLink = htmlGenerator.link('conversationDetails', { id: vm.conversation.id, author: vm.conversation.authorId }, vm.conversation.title);
      }
      rUserConversations.archive({
        userId: vm.conversation.authorId,
        conversationId: vm.conversation.id
      }).$promise.then(function() {
        if (vm.onSuccessArchive) {
          vm.onSuccessArchive();
        }
        responseHandler.successMessage('You successfully archived ' + entityLink + '.');
      }).finally(function() {
        vm.archiving = false;
      });
    } else {
      rUserConversations.restore({
        userId: vm.conversation.authorId,
        conversationId: vm.conversation.id
      }).$promise.then(function() {
        if (vm.onSuccessRestore) {
          vm.onSuccessRestore();
        }
        responseHandler.successMessage('You successfully unarchived ' + vm.conversation.title + '.');
      }).finally(function() {
        vm.archiving = false;
      });
    }
  }

  function remove() {
    confirmDialog.open('Are you sure you want to delete this conversation?').then(function() {
      rUserConversations.delete({
        userId: vm.conversation.authorId,
        conversationId: vm.conversation.id
      }).$promise.then(function() {
        if (vm.onSuccessRemove) {
          vm.onSuccessRemove();
        }
        responseHandler.successMessage('Conversation successfully deleted');
      }).catch(responseHandler.processError);
    }).catch(function() {});
  }
}
