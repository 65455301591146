import {barnManagerModule} from '../../../index.module';

barnManagerModule
  .constant('usefStatuses', {
    'EXISTS_ALREADY': 'Horse Exists Already',
    'CONSENT_REQUIRED': 'Consent Required',
    'CONSENT_REQUESTED': 'Consent Requested',
    'CAN_BE_IMPORTED': 'Can Be Imported',
    'IMPORTED': 'Imported',
    'CONSENT_DENIED': 'Consent Denied',
    'CONSENT_REMOVED': 'Consent Removed',
    'CA_OWNER': 'CA Owner',
    'MINOR_OWNER': 'Minor Owner',
    'USEF_ERROR': 'USEF Error',
    'USEF_UNKNOWN_ERROR': 'USEF Unknown Error',
    'COULD_NOT_BE_IMPORTED': 'Could Not Be Imported',
    'USEF_ACCOUNT_NOT_LINKED': 'USEF Account Is Not Linked',
    'CONSENT_CANNOT_BE_GRANTED_OWNER_IS_MINOR': 'Consent cannot be granted. Owner is a minor.'
  })
  .constant('usefMessages', {
    serverError: {
      text: 'Server error, please try again later.',
      type: 'error'
    },
    hasInvalidUsefNumbers: {
      text: 'Your USEF horse IDs list has invalid values',
      type: 'alert'
    },
    noVailidUsefNumbers: {
      text: 'Your USEF horse IDs list has no valid values',
      type: 'error'
    },
    importError: {
      text: 'Some horses could not be imported',
      type: 'error'
    },
    deleted: {
      text: 'Request was deleted.'
    }
  })
  .constant('usefParams', {
    breed: 'Breed',
    breedRegistry: 'Breed Registry',
    color: 'Color',
    dam: 'Dam',
    feiPassportNumber: 'Fei Passport Number',
    foalDate: 'Foal Date',
    hands: 'Hands',
    markings: 'Markings',
    microchipCode: 'Microchip Code',
    name: 'Name',
    sire: 'Sire',
    type: 'Type',
    usefHorseCard: 'USEF Horse Card',
    usefHorseResults: 'USEF Horse Results',
    usefMembership: 'USEF Membership',
    usefNumber: 'USEF Number'
  });
