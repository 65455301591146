import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewEncapsulation} from '@angular/core';
import {Ng1BootstrapService} from '../../ng1/ng1-bootstrap.service';

@Component({
  selector: 'bm-ng1-layout',
  template: ``,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class Ng1LayoutComponent implements AfterViewInit, OnDestroy {
  constructor(
    private ng1BootstrapService: Ng1BootstrapService
  ) {
  }

  ngAfterViewInit() {
    this.ng1BootstrapService.ng1Active = true;
  }

  ngOnDestroy() {
    this.ng1BootstrapService.ng1Active = false;
  }
}
