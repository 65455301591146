import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatChipsModule} from '@angular/material/chips';
import {EventInstance} from "../../../../../barn-manager-pro/src/app/shared/entities/events";
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'shared-chip-list',
  templateUrl: './chip-list-dates.component.html',
  styleUrls: ['./chip-list-dates.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatChipsModule
  ]
})
export class ChipListDatesComponent {
  private vm = this;

  @Input() event: EventInstance;
  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute

  ) { }

  link(day: any): string {
    return "n/events/details/" + this.event.id + '?instance=' + day;
  }

  viewInstance(dateDate: any): void {
      this.router.navigate(['n/events/details/' + this.event.id + '?author=' + this.event.authorId], { queryParams: {
          author: this.event.authorId,
          instance: dateDate
        }});
  }
}

