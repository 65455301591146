import {barnManagerModule} from '../index.module';

export enum InitLoadRejectionTypes {
  NotAuthenticated = 'NotAuthenticated',
  LoadUserError = 'LoadUserError',
  ServerError = 'ServerError'
}

barnManagerModule.factory('authenticationService', authenticationService);

authenticationService.$inject = [
  '$q',
  '$state',
  '$window',
  '$rootScope',
  'messageBus',
  'globalNotificationService',
  'sessionService',
  'sessionStorageService',
  'subscriptionStorage',
  'userStorage',
  'barnStorage',
  'userSettingsStorage',
  'intercomService',
  'goNative',
  'ng2AuthorizedStorage',
  'utils',
  'ng2ResponseHandlerService',
  'responseHandler',
  'MessageFrames'
];
function authenticationService(
  $q,
  $state,
  $window,
  $rootScope,
  messageBus,
  globalNotificationService,
  sessionService,
  sessionStorageService,
  subscriptionStorage,
  userStorage,
  barnStorage,
  userSettingsStorage,
  intercomService,
  goNative,
  ng2AuthorizedStorage,
  utils,
  ng2ResponseHandlerService,
  responseHandler,
  MessageFrames
) {
  let loginInfo;
  let isUserLoggedIn;

  init();
  watchLoginInfoStorageChange();
  subscribeOnNg2Unauthorized401Error$();

  return {
    logout: logout,
    isLoggedIn: isLoggedIn,
    withUser: withUser,
    logoutOnNotAuthenticated: logoutOnNotAuthenticated
  };

  function loadSubscriptionWithUser(user) {
    const envId = user.getBarn().id;
    return subscriptionStorage.loadSubscriptionByEnvId(envId).then(function(subscription) {
      sessionService.updateUser(user, false);
      sessionStorageService.setUser(user);
      return subscription;
    });
  }

  function logout(returnUrl?: string, expiryFlag?: boolean) {
    $rootScope.$broadcast('userAuthenticated', null);
    $rootScope.$broadcast('$showLoader', true);
    clearAuthorization();
    ng2AuthorizedStorage.loggedOut();
    loginInfo = {};
    barnStorage.clean();
    sessionService.clean();
    sessionStorageService.removeAll([sessionStorageService.keys.testApiEndpoint]);
    messageBus.clearAll();
    userSettingsStorage.clean();
    goNative.oneSignalRemoveExternalUserId();
    intercomService.resetIntercom();
    globalNotificationService.disconnect();
    const params = {};
    if (returnUrl) {
      params['returnUrl'] = returnUrl;
    }
    if (expiryFlag) {
      params['expiryFlag'] = true;
    }
    window.location.href = utils.buildUrl('/auth/login', params);
  }

  function isLoggedIn() {
    return isUserLoggedIn;
  }

  function init() {
    if (sessionStorageService.getLoginInfo()) {
      isUserLoggedIn = true;
      loginInfo = sessionStorageService.getLoginInfo();
    }
  }

  function watchLoginInfoStorageChange() {
    window.addEventListener('storage', (event) => {
      if (event.key === sessionStorageService.keys.loginInfo) {
        if (!isUserLoggedIn) {
          return;
        }
        if (!event.newValue && event.oldValue) {
          logoutOnNotAuthenticated();
        } else if (event.newValue && event.oldValue) {
          const newValue = JSON.parse(event.newValue), oldValue = JSON.parse(event.oldValue);
          if (newValue.userId !== oldValue.userId) {
            location.reload();
          }
        }
      }
    });
  }

  function subscribeOnNg2Unauthorized401Error$() {
    ng2ResponseHandlerService.unauthorized401Error$.subscribe(() => $rootScope.$broadcast('401UnauthorizedError'));
  }

  function clearAuthorization() {
    isUserLoggedIn = false;
    $window.sessionStorage.clear();
  }

  function withUser() {
    init();
    const deferred = $q.defer();
    if (sessionStorageService.getLoginInfo()) {
      loginInfo = sessionStorageService.getLoginInfo();

      if (userStorage.hasUser() && barnStorage.getEnv() && subscriptionStorage.hasSubscription() && userSettingsStorage.hasUserSettings()) {
        sessionService.loadFromStorage();
        deferred.resolve();
      } else {
        userStorage.loadUser(loginInfo.userId).then(function(user) {
          const requests = {
            subscription: loadSubscriptionWithUser(user),
            userSettings: userSettingsStorage.loadUserSettings(loginInfo.userId)
          };
          if (user && user.uuid) {
            goNative.oneSignalRegisterAndSetExternalUserId(user.uuid);
          }
          intercomService.bootIntercom(user);

          return $q.all(requests).then(function(result) {
            messageBus.clearAll();
            deferred.resolve({ user: user, subscription: result.subscription });
          });
        }).catch(error => {
          if (error.status === 401 || error.status === 403) {
            return deferred.reject(InitLoadRejectionTypes.LoadUserError);
          } else {
            responseHandler.processError(error, null, MessageFrames.TOP);
            return deferred.reject(InitLoadRejectionTypes.ServerError);
          }
        });
      }
    } else {
      deferred.reject(InitLoadRejectionTypes.NotAuthenticated);
    }

    return deferred.promise.then(function(authData) {
      if (authData) {
        $rootScope.$broadcast('userAuthenticated', authData.user);
      }

      return $q.resolve(authData);
    }).catch((rejection: InitLoadRejectionTypes) => {
      switch (rejection) {
        case InitLoadRejectionTypes.NotAuthenticated: {
          logoutOnNotAuthenticated();
          break;
        }
        case InitLoadRejectionTypes.LoadUserError: {
          logoutOnNotAuthenticated(true);
          break;
        }
        case InitLoadRejectionTypes.ServerError: {
          handleInitLoadError();
          break;
        }
      }

      return $q.reject(rejection);
    });
  }

  function logoutOnNotAuthenticated(expiryFlag?: boolean) {
    if (isPublicPage() || goNative.isGoNative()) {
      logout();
      return;
    }

    let returnUrl = getParameterByName('returnUrl');
    if (!returnUrl) {
      returnUrl = `${$window.location.pathname + $window.location.search}`;
    }
    logout(returnUrl, expiryFlag);
  }

  function handleInitLoadError() {
    $rootScope.$broadcast('initLoadServerError');
  }

  function getParameterByName(name: string, url: string = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&'); // eslint-disable-line
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  function isPublicPage() {
    if ($state && $state.data) {
      return $state.data.isPublic;
    }
    return false;
  }
}
