import {barnManagerModule} from '../../index.module';
import {map} from 'lodash';
import {merge} from 'angular';

barnManagerModule.controller('ContactsController', ContactsController);

ContactsController.$inject = [
  'backLinkHistory',
  'rContacts',
  'barnStorage',
  'ContactRoles',
  '$scope',
  '$state',
  '$timeout',
  '$log',
  'PAGE_SIZE',
  'titleService',
  'hasPermission',
  'goNative'
];
function ContactsController(
  backLinkHistory,
  rContacts,
  barnStorage,
  ContactRoles,
  $scope,
  $state,
  $timeout,
  $log,
  PAGE_SIZE,
  titleService,
  hasPermission,
  goNative
) {

  let vm = this, pagesize = PAGE_SIZE, barn, timeout;

  backLinkHistory.pushLink('Contacts');

  vm.hasContactsFullPermission = hasPermission('contacts:full');
  vm.isGoNative = goNative.isGoNative();
  vm.contacts = [];
  vm.totalPages = 1;
  vm.roles = ContactRoles;

  vm.sortBy = [
    { name: 'All Contacts', section: 'Activity', value: {} },
    { name: 'Active', section: 'Activity', value: { 'archived': 0 } },
    { name: 'Archived', section: 'Activity', value: { 'archived': 1 } }
  ];
  map(vm.roles, function(value, key) {
    vm.sortBy.push({ name: value, section: 'Role', value: { 'role': key, 'archived': 0 } });
  });

  vm.status = {
    empty: false,
    error: false,
    loaded: false,
    busy: false,
    query: false,
    message: '',
    errorMessage: ''
  };

  vm.loadMore = loadMore;
  vm.routeUrl = routeUrl;
  vm.pinContact = pinContact;
  vm.search = search;

  init();

  function loadMore() {
    if (vm.totalPages > 1) {
      pagesize += pagesize;
      search();
    }
  }

  function routeUrl(stateParams) {
    return $state.href('contactDetails', stateParams);
  }

  function init() {
    titleService.setTitle('Contacts');
    timer();
    barn = barnStorage.getEnv();

    const queryParams = {
      tenantEnvironmentId: barn.id,
      pagesize: pagesize,
      archived: 0
    };

    rContacts.query(queryParams, function(response) {
      vm.contacts = response.records;
      vm.totalPages = response.totalPages;
      vm.status.loaded = true;
      vm.status.empty = !response.records.length;
      vm.totalRecords = response.totalRecords;
      watchFilters();
    }, function(error) {
      $timeout.cancel(timeout);
      vm.status.loaded = true;
      vm.status.error = true;
      vm.status.empty = true;
      vm.status.errorMessage = 'Can\'t load contacts. Error: ' + error.status + ' ' + error.statusText;
    });
  }

  function watchFilters() {
    let timeoutPromise, delay = 600;
    $scope.$watch('vm.filters', function(newFilters, oldFilters) {
      if (newFilters !== oldFilters) {
        pagesize = PAGE_SIZE;
        if (newFilters.keyword !== (oldFilters || {}).keyword) {
          $timeout.cancel(timeoutPromise);
          timeoutPromise = $timeout(search, delay);
        } else {
          search();
        }
      }
    }, true);
  }

  function pinContact(contact) {
    rContacts.toggleContactPin({id: contact.id}, function() {
      contact.pinned = !contact.pinned;
      vm.contacts = vm.contacts.sort((a, b) => a.name.localeCompare(b.name)).sort((a, b) => b.pinned - a.pinned);
    });
  }

  function search() {
    timer();
    vm.status.busy = true;

    const queryParams = {
      pagesize: pagesize,
      tenantEnvironmentId: barn.id,
      text: vm.filters.keyword
    };

    rContacts.query(merge({}, queryParams, vm.filters.by.value), function(response) {
      vm.status.busy = false;
      vm.contacts = response.records;
      vm.totalPages = response.totalPages;
      vm.totalRecords = response.totalRecords;
    }, function(error) {
      $log.error(error);
      vm.status.error = true;
      vm.status.errorMessage = 'Can\'t load contacts. Error: ' + error.status + ' ' + error.statusText;
    });
  }

  function timer() {
    vm.status.message = '';
    $timeout.cancel(timeout);
    timeout = $timeout(function() {
      vm.status.message = 'Oops! It is taking more time than expected.';
    }, 4000);
  }
}
