import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'checkSelected'})
export class CheckSelectedPipe implements PipeTransform {

  transform(selected: any[], items: any[], bindValue: string = 'id'): any[] {
    if (!selected || selected.length === 0 || !items || items.length === 0) {
      return [];
    }
    const itemsIdentifiers = items.map(item => item[bindValue || 'id'] || item);
    return selected.filter(selected => {
      const selectedIdentifier = selected[bindValue || 'id'] || selected;
      return itemsIdentifiers.includes(selectedIdentifier);
    });
  }
}
