import {barnManagerModule} from '../../index.module';
import {get, includes, isInteger, values, filter} from 'lodash';
import {copy, extend} from 'angular';

barnManagerModule.factory('messageBus', MessageBus);

MessageBus.$inject = ['$rootScope', '$q', 'MessageFrames'];
function MessageBus($rootScope, $q, MessageFrames) {
  const types = ['error', 'alert', 'info'];
  const frames = values(MessageFrames);
  const messages = initMessages();

  detectSystemWake();

  const message = {
    text: '',
    title: '',
    type: '',
    timeout: 0
  };

  return {
    push: push,
    pull: pull,
    clear: clear,
    clearAll: clearAll
  };

  function push(options) {
    const pushedMessage = extend({}, message, { text: options.text });
    if (get(options, 'title')) {
      pushedMessage.title = options.title;
    }
    if (get(options, 'type') && includes(types, options.type)) {
      pushedMessage.type = options.type;
    }
    if (get(options, 'timeout') && isInteger(options.timeout)) {
      pushedMessage.timeout = options.timeout;
    }

    let frame = get(options, 'frame');
    if (!includes(frames, frame)) {
      frame = MessageFrames.DEFAULT;
    }

    // prevent duplication
    if (filter(messages[frame], { text: pushedMessage.text }).length === 0 ) {
      messages[frame].push(pushedMessage);
    }
    $rootScope.$broadcast('messagesAdded');
  }

  function clear(frame) {
    messages[frame] = [];
    $rootScope.$broadcast('messagesCleared', frame);
  }

  function clearAll() {
    frames.forEach(clear);
  }

  function pull(frame) {
    const pulledMessages = copy(messages[frame]);
    messages[frame] = [];
    return pulledMessages;
  }

  function initMessages() {
    return frames.reduce(function(acc, frame) {
      acc[frame] = [];
      return acc;
    }, {});
  }

  function detectSystemWake() {
    let lastTime = (new Date()).getTime();

    setInterval(function() {
      const currentTime = (new Date()).getTime();
      if (currentTime > (lastTime + 2000*2)) {  // ignore small delays
        clearAll(); // clear all messages on system wake
      }
      lastTime = currentTime;
    }, 2000);
  }
}
