import {barnManagerModule} from '../../index.module';

barnManagerModule.directive('bmSortSwitcher', sortSwitcher);

function sortSwitcher() {
  return {
    restricted: 'E',
    templateUrl: 'sort-switcher.html',
    controller: SortSwitcherController,
    controllerAs: 'switcher',
    bindToController: true,
    transclude: {
      firstOption: '?bmSortSwitcherFirstOption',
      secondOption: '?bmSortSwitcherSecondOption'
    },
    scope: {
      sortByLastUpdated: '='
    }
  };

  function SortSwitcherController() {
    const vm = this;

    vm.$onInit = function() {
      vm.toggleOption = function(value) {
        vm.sortByLastUpdated = value;
      };
    };
  }
}
