import {barnManagerModule} from '../../index.module';
import {copy} from 'angular';

barnManagerModule
  .component('bmColorSettingsModal', {
    templateUrl: 'color-settings-modal.html',
    controller: ColorSettingsModal,
    controllerAs: 'vm',
    bindings: {
      visible: '=',
      colors: '=',
      colorUpdated: '<',
      colorsChanged: '&'
    }
  });

ColorSettingsModal.$inject = ['confirmDialog', 'responseHandler', 'colorsRepository', 'MessageFrames'];
function ColorSettingsModal(confirmDialog, responseHandler, colorsRepository, MessageFrames) {
  const vm = this;
  vm.visible = false;
  vm.showAddColor = false;
  vm.loading = false;
  vm.color = null;
  vm.colorOptions = {
    placeholder: 'Select Event Color',
    inputClass: 'text-input text-input--large',
    format: 'hexString',
    round: true,
    allowEmpty: true,
    hue: false,
    saturation: false,
    lightness: true,
    alpha: false,
    dynamicHue: true,
    dynamicSaturation: false,
    dynamicLightness: true,
    dynamicAlpha: false,
    close: {
      show: true,
      label: 'Close',
      class: 'btn',
    },
    clear: {
      show: true,
      label: 'Clear',
      class: 'btn',
    }
  };

  vm.colorPickerApi = null;
  vm.close = close;
  vm.editColor = editColor;
  vm.deleteColor = deleteColor;
  vm.backLinkClick = backLinkClick;
  vm.openColorPicker = openColorPicker;
  vm.saveColor = saveColor;

  function close() {
    vm.color = null;
    vm.loading = false;
    vm.showAddColor = false;
    vm.visible = false;
  }

  function editColor(color) {
    vm.color = copy(color);
    vm.showAddColor = true;
  }

  function deleteColor(color) {
    const text = 'Are you sure you want to delete this color?';
    return confirmDialog.open(text).then(function() {
      return colorsRepository.remove(color.uuid).then(function() {
        responseHandler.successMessage('Color successfully deleted');
        const index = vm.colors.findIndex(i => i.uuid === color.uuid);
        if (index !== -1) {
          vm.colors.splice(index, 1);
          vm.colorsChanged({colors: vm.colors});
        }
      }).catch(function(error) {
        responseHandler.processError(error, null, MessageFrames.TOP);
      });
    }).catch(() => {});
  }

  function backLinkClick() {
    vm.color = null;
    vm.loading = false;
    vm.showAddColor = false;
  }

  function openColorPicker() {
    vm.colorPickerApi.open();
  }

  function saveColor(colorForm) {
    if (vm.loading) {
      return;
    }

    if (!colorForm.$valid) {
      colorForm.$setDirty();
      colorForm.$$controls.forEach(control => control.$setDirty());
      return;
    }

    vm.loading = true;

    return colorsRepository.update(vm.color).then(function(color) {
      const existingColorIndex = vm.colors.findIndex(c => c.uuid === color.uuid);

      if (existingColorIndex !== -1) {
        responseHandler.successMessage('Color successfully updated');
        vm.colors[existingColorIndex].color = color.color;
        vm.colors[existingColorIndex].name = color.name;
        vm.colorUpdated(color);
      } else {
        responseHandler.successMessage('Color successfully added');
        vm.colors.push(color);
      }
      vm.colors = vm.colors.sort((a, b) => sortAlphabetically(a.name, b.name));
      vm.colorsChanged({colors: vm.colors});
      vm.color = null;
      vm.showAddColor = false;
    }).catch(function(error) {
      responseHandler.processError(error, null, MessageFrames.TOP);
    }).finally(() => vm.loading = false);
  }

  function sortAlphabetically(a, b) {
    const nameA = a.toUpperCase();
    const nameB = b.toUpperCase();
    return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
  }
}
