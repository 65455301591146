import {barnManagerModule} from '../../../index.module';

barnManagerModule
  .component('bmEntitiesEnumeration', {
    templateUrl: 'entities-enumeration.html',
    controller: EntitiesEnumeration,
    controllerAs: 'vm',
    bindings: {
      entities: '=',
      url: '@'
    }
  });

EntitiesEnumeration.$inject = ['SidebarJS'];
function EntitiesEnumeration(SidebarJS) {
  const vm = this;
  vm.sidebarName = 'notifications-sidebar';
  vm.navigate = navigate;

  function navigate() {
    if (SidebarJS.isVisible(vm.sidebarName)) {
      SidebarJS.close(vm.sidebarName);
    }
  }
}
