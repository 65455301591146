import {barnManagerModule} from '../../index.module';

barnManagerModule.controller('NoaccessController', NoaccessController);

NoaccessController.$inject = ['$rootScope', 'hasPermission']
function NoaccessController($rootScope, hasPermission) {
  const vm = this;

  $rootScope.$broadcast('$showLoader', false);
  vm.hasAdminFullPermission = hasPermission('admin:full');
}
