import {barnManagerModule} from '../index.module';

barnManagerModule
  .factory('authInterceptor', ['$q', '$rootScope', 'sessionStorageService', ($q, $rootScope, sessionStorageService) =>{
    return {
      request: function(config) {
        config.headers = config.headers || {};
        const loginInfo = sessionStorageService.getLoginInfo();
        if (loginInfo && loginInfo.accessToken) {
          config.headers.Authorization = 'Bearer ' + loginInfo.accessToken;
        }
        return config || $q.when(config);
      },
      response: function(response) {
        if (response.status === 401) {
          $rootScope.$broadcast('401UnauthorizedError');
        }
        return response || $q.when(response);
      },
      responseError: function(rejection) {
        if (rejection.status === 401) {
          $rootScope.$broadcast('401UnauthorizedError');
        }
        return $q.reject(rejection);
      }
    };
  }]);
