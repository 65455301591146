import {HttpParams} from '@angular/common/http';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

export enum ApiType {
  defaultApi = 'defaultApi',
  externalApi = 'externalApi'
}

export class ApiBaseParameters<B = any> {
  type?: ApiType = ApiType.defaultApi;
  path: string;
  body?: B;
  headers?: Headers;
  params?: HttpParams | {
    [param: string]: string | string[] | number | number[];
  };
  responseType?: 'json' | 'blob' | 'arraybuffer' | 'text';
  withoutErrorMessage?: boolean;
  endpoint?: string;
  skipIntercept?: boolean;
}

export interface Headers {
  [header: string]: string;
}
