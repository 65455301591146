import {barnManagerModule} from '../../index.module';
import {range} from 'lodash';
import {copy} from 'angular';

barnManagerModule.directive('bmTableList', tableList);

function tableList() {

  TableList.$inject = ['$scope', 'XLSXReaderService'];

  return {
    restricted: 'E',
    templateUrl: 'table-list.html',
    controller: TableList,
    controllerAs: 'tableList',
    bindToController: true,
    scope: {
      ngModel: '=',
      editMode: '='
    }
  };

  function TableList($scope, XLSXReaderService) {
    let vm = this, table;

    vm.$onInit = function() {

      vm.maxColsReached = false;

      vm.deleteCol = deleteCol;
      vm.updateTable = updateTable;
      vm.deleteRow = deleteRow;

      init();

      function init() {

        if (vm.editMode) {
          vm.tableMethod = 'table_scratch';
        }

        vm.rows = vm.ngModel.length - 1;
        vm.cols = vm.ngModel[0].length;
        vm.rowsRange = range(0, vm.rows);
        vm.colsRange = range(0, vm.cols);

        $scope.$watch('tableList.tableMethod', function(newVal) {
          if (typeof newVal !== 'undefined') {
            vm.maxColsReached = false;
          }
        });

        $scope.$watch('tableList.tableFile', function(newVal) {
          if (typeof newVal !== 'undefined') {
            XLSXReaderService({file: newVal, readCells: true, toJSON: false}).then(function(xlsxData) {
              const table = xlsxData.sheets[Object.keys(xlsxData.sheets)[0]];
              if (table.col_size > 50) {
                vm.maxColsReached = true;
              } else {
                vm.maxColsReached = false;
                vm.rows = table.row_size - 1;
                vm.cols = table.col_size;
                vm.ngModel = table.data;
                updateTable();
              }
            });
          }
        });
      }

      function deleteRow(index) {
        table = copy(vm.ngModel);
        table.splice(index, 1);
        vm.rowsRange.pop();
        vm.rows = vm.rows - 1;
        vm.ngModel = table;
      }

      function deleteCol(index) {
        table = copy(vm.ngModel);
        for (let i = 0; i <= vm.rows; i++) {
          table[i].splice(index, 1);
        }
        vm.colsRange.pop();
        vm.cols = vm.cols - 1;
        vm.ngModel = table;
      }

      function updateTable() {
        const newCols = parseInt(vm.cols);
        const newRows = parseInt(vm.rows);

        if (newCols > 50 || newRows > 99 || !newCols || !newRows) {
          return;
        }
        const oldColsN = vm.ngModel[0].length;
        const oldRowsN = vm.ngModel.length;

        if (oldColsN < newCols) {
          const colsToAdd = newCols - oldColsN;
          for (let rowI = 0; rowI < oldRowsN; rowI++) {
            for (let colI = 0; colI < colsToAdd; colI++) {
              vm.ngModel[rowI].push('');
            }
          }
        } else if (newCols < oldColsN) {
          const colsToRemove = oldColsN - newCols;
          for (let k = 0; k <= vm.rows; k++) {
            for (let l = 0; l < colsToRemove; l++) {
              vm.ngModel[k].pop();
            }
          }
        }

        const oldRows = vm.ngModel.length - 1;

        if (oldRows < newRows) {
          const rowsToAdd = newRows - oldRows;
          const row = [];
          for (let m = 0; m < vm.cols; m++) {
            row.push('');
          }
          for (let n = 0; n < rowsToAdd; n++) {
            vm.ngModel.push(copy(row));
          }
        } else if (newRows < oldRows) {
          const rowsToRemove = oldRows - newRows;
          for (let p = 0; p < rowsToRemove; p++) {
            vm.ngModel.pop();
          }
        }

        vm.rowsRange = range(0, vm.rows);
        vm.colsRange = range(0, vm.cols);
      }

    };
  }
}
