import {barnManagerModule} from '../../index.module';

barnManagerModule
  .constant('HorseBreeds',
    [
      'Alpha',
      'American Saddlebred',
      'American Sport Pony',
      'American Trakehner',
      'American Warmblood',
      'Andalusian',
      'Anglo Arab',
      'Appaloosa',
      'Appendix',
      'Ara-Appaloosa',
      'Arabian',
      'Argentine',
      'Australian Warmblood',
      'Baden Wurttemburg',
      'Bavarian Warmblood',
      'Belgian cross',
      'Belgium Sporthorse',
      'Berlin Brandeburg',
      'Bodenhausen Stud',
      'Boulonnais',
      'British Hanoverian',
      'British Trakehner',
      'British Warmblood',
      'Canadian Trakehner',
      'Canadian Warmblood',
      'Chincoteague Pony',
      'Clydesdale',
      'Connemara',
      'Danish Warmblood',
      'Danish Warmblood America',
      'Donkey',
      'Dutch Warmblood',
      'Finnish Warmblood',
      'Friesian',
      'Gaited grade',
      'Gelderlanderei',
      'German Riding Horse',
      'Grade',
      'Grade pony',
      'Gypsy Vanner',
      'Hackney pony',
      'Haflinger',
      'Half Arabian',
      'Hanoverian',
      'Hessen/Hessian',
      'Holsteiner',
      'Icelandic',
      'Irish Sport Horse',
      'Knabstrupper',
      'Lusitano',
      'Mecklenburger',
      'Mini donkey',
      'Miniature',
      'Missouri Fox Trotter',
      'Morab',
      'Morgan',
      'Mule',
      'Mustang',
      'National Show Horse',
      'New Zealand Trakehner',
      'New Zealand Warmblood',
      'Newfoundland Pony',
      'Norwegian Fjord',
      'Oldenburg',
      'Otjozonjati Stud',
      'Paint',
      'Paso Fino',
      'Percheron',
      'Polish Warmblood',
      'Pony',
      'Qh cross',
      'Quarterhorse',
      'Rheinland',
      'Rheinland Pfalz-Sarr',
      'Rocky Mountain horse',
      'Sachsen',
      'Sachsen-Anhalt',
      'Selle-Francais',
      'Shagya Arabian',
      'Spotted draft',
      'Standardbred',
      'Swedish Warmblood',
      'Swiss Warmblood',
      'Tennessee Walking Horse',
      'Thoroughbred',
      'Thoroughbred Cross',
      'Thoroughbred/Hanoverian',
      'Thoroughbred/Quarter Horse',
      'Thuringen',
      'Trakehner',
      'Unknown',
      'Walkaloosa',
      'Warmblood',
      'Welsh Pony',
      'Welsh Pony Cross',
      'Westfalen',
      'Westphalian',
      'Zangersheide'
    ]
  );
