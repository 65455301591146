import {barnManagerModule} from '../index.module';

barnManagerModule
  .factory('treatmentRepository', ['$http', 'barnStorage', ($http, barnStorage) =>{
    return {
      all: all,
      archive: archive,
      restore: restore
    };

    function all() {
      const config = {
        params: {
          tenantEnvironmentId: barnStorage.getEnvId(),
          pagesize: 10000
        }
      };
      return $http.get('/api/treatments/v2', config).then(function(result) {
        return result.data;
      });
    }

    function archive(id) {
      return $http.put(`/api/treatments/v2/archive/${id}?tenantEnvironmentId=${barnStorage.getEnvId()}`).then(function(result) {
        return result.data;
      });
    }

    function restore(id) {
      return $http.put(`/api/treatments/v2/restore/${id}?tenantEnvironmentId=${barnStorage.getEnvId()}`).then(function(result) {
        return result.data;
      });
    }

  }]);
