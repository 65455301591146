import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgSelectModule} from '../../ng-select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {BmNgSelectComponent} from './bm-ng-select.component';
import {CheckSelectedPipe} from './check-selected.pipe';
import {NgOptionHighlightModule} from '../../ng-select/ng-option-highlight/ng-option-highlight.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    MatCheckboxModule,
    NgOptionHighlightModule
  ],
  declarations: [
    BmNgSelectComponent,
    CheckSelectedPipe
  ],
  exports: [
    BmNgSelectComponent,
    CheckSelectedPipe
  ]
})
export class BmNgSelectModule { }
