import {barnManagerModule} from '../../index.module';

barnManagerModule.controller('ReportsController', ReportsController);

ReportsController.$inject = ['$state', 'backLinkHistory'];
function ReportsController($state, backLinkHistory) {
  const vm = this;
  vm.horseId = $state.params.id;
  backLinkHistory.pushLink('Reports');
}
