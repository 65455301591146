import {NameValueType} from '../../core/utils/name-value-type';

export class RecordTypes {
  static get All(): NameValueType[] {
    return [
      RecordTypes.Vet,
      RecordTypes.Dentist,
      RecordTypes.Farrier,
      RecordTypes.Deworming,
      RecordTypes.Medication,
      RecordTypes.Vaccination
    ];
  }

  static AllRecordTypes: NameValueType = { name: 'All Record Types', value: 'ALL' };
  static Vet: NameValueType = { name: 'Vet', value: 'VET' };
  static Dentist: NameValueType = { name: 'Dentist', value: 'DENTIST' };
  static Farrier: NameValueType = { name: 'Farrier', value: 'FARRIER' };
  static Deworming: NameValueType = { name: 'Deworming', value: 'DEWORMING' };
  static Medication: NameValueType = { name: 'Medication ', value: 'MEDICATION' };
  static Vaccination: NameValueType = { name: 'Vaccination', value: 'VACCINATION' };
}
