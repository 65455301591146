import {barnManagerModule} from '../../index.module';
import * as angular from 'angular';

barnManagerModule.directive('bmWhiteBoard', whiteBoard);

function whiteBoard() {

  WhiteBoardController.$inject = [
    '$scope',
    '$rootScope',
    '$q',
    'moduleAccessService',
    'userStorage',
    'barnStorage',
    'MessageFrames',
    'responseHandler',
    'whiteboardRepository',
    'rAttachments',
    'confirmDialog',
    'Paginator',
    'hasPermission'
  ];

  return {
    restricted: 'E',
    templateUrl: 'white-board.html',
    controller: WhiteBoardController,
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      customClass: '@'
    }
  };
  function WhiteBoardController(
    $scope,
    $rootScope,
    $q,
    moduleAccessService,
    userStorage,
    barnStorage,
    MessageFrames,
    responseHandler,
    whiteboardRepository,
    rAttachments,
    confirmDialog,
    Paginator,
    hasPermission
  ) {
    let vm = this,
        barnId,
        currentUser,
        removedFiles = [],
        promises = [],
        pageSize = 20;

    vm.$onInit = function() {

      vm.hasWhiteboardFullPermission = hasPermission('whiteboard:full');
      vm.showInput = false;
      vm.files = [];
      vm.items = [];
      vm.post = {};
      vm.editMode = false;
      vm.attachmentsList = [];
      vm.saved = false;
      vm.savingAttachments = false;

      vm.canPost = canPost;
      vm.toggleInput = toggleInput;
      vm.postToWhiteboard = postToWhiteboard;
      vm.edit = edit;
      vm.remove = remove;
      vm.queueDelete = queueDelete;

      vm.paginator = Paginator(pageSize, loadWhiteboardPosts);

      vm.state = 'Initializing';

      init();

      function queueDelete(file) {
        removedFiles.push(file);
      }

      function updateAttachments(newFiles, item) {
        vm.savingAttachments = true;
        angular.forEach(newFiles, function(attachment) {
          if (attachment.new) {
            var payload = new FormData();
            payload.append('file', attachment.rawData);
            promises.push(rAttachments.save({modelType: 'WHITEBOARD', modelId: item.id}, payload).$promise);
          }
        });
        angular.forEach(removedFiles, function(attachment) {
          if (attachment && attachment.id != null) {
            promises.push(rAttachments.delete({id: attachment.id}).$promise.then(function(response) {
              removedFiles = removedFiles.filter(function(attachmentId) {
                attachmentId !== attachment.id;
              });
              return response;
            }));
          }
        });
        $q.all(promises).then(function() {
          vm.savingAttachments = false;
          vm.paginator.reload();
        }).catch(function(error) {
          vm.savingAttachments = false;
          vm.paginator.reload();
          return responseHandler.processError(error, null, MessageFrames.RIGHT);
        });
      }

      function remove(id) {
        confirmDialog.open('Are you sure you want to delete this post?').then(function() {
          whiteboardRepository.remove(id).then(function() {
            responseHandler.successMessage('Post successfully deleted');
            vm.paginator.reload();
          }).catch(function(error) {
            return responseHandler.processError(error, null, MessageFrames.RIGHT);
          });
        }).catch(function() {});
      }

      function postToWhiteboard() {
        vm.postLoading = true;
        if (!vm.editMode) {

          vm.post.userId = currentUser.id;
          vm.post.tenantEnvironmentId = barnId;
          vm.post.archived = 0;

          whiteboardRepository.add(vm.post).then(function(post) {
            responseHandler.successOnSave('Post', false);
            updateAttachments(vm.attachmentsList, post);
            vm.editMode = false;
            vm.saved = true;
            toggleInput();
          }).catch(function(error) {
            return responseHandler.processError(error, null, MessageFrames.RIGHT);
          }).finally(() => vm.postLoading = false);
        } else {
          whiteboardRepository.update({
            id: vm.post.id,
            tenantEnvironmentId: barnId,
            title: vm.post.title,
            content: vm.post.content,
            archived: vm.post.archived
          }).then(function(post) {
            responseHandler.successOnSave('Post', true);
            updateAttachments(vm.attachmentsList, post);
            vm.saved = true;
            toggleInput();
          }).catch(function(error) {
            return responseHandler.processError(error, null, MessageFrames.RIGHT);
          }).finally(() => vm.postLoading = false);
        }
      }

      function getAttachmentsList(id) {
        rAttachments.query({ modelType: 'WHITEBOARD', modelId: id }).$promise.then(function(attachmentsList) {
          vm.attachmentsList = attachmentsList;
        }).catch(function(error) {
          return responseHandler.processError(error, null, MessageFrames.RIGHT);
        });
      }

      function edit(id) {
        whiteboardRepository.find(id).then(function(post) {
          vm.editMode = true;
          vm.showInput = true;
          vm.post = post;
          getAttachmentsList(id);
        }).catch(function(error) {
          return responseHandler.processError(error, null, MessageFrames.RIGHT);
        });
      }

      function toggleInput() {
        vm.showInput = !vm.showInput;
        clear();
      }

      function clear() {
        vm.editMode = false;
        vm.post.title = '';
        vm.post.content = '';
        vm.attachmentsList = [];
      }

      function loadWhiteboardPosts(pageSize) {
        if (vm.state !== 'Initializing') {
          vm.state = 'Loading';
        }
        return whiteboardRepository.search(pageSize).then(function(response) {
          vm.state = 'Loaded';
          vm.items = response.records;
          $rootScope.$broadcast('whiteboard:push', vm.items);
          return response;
        }).catch(function(error) {
          vm.state = 'PostsLoadError';
          return responseHandler.processError(error, null, MessageFrames.RIGHT);
        });
      }

      function canPost() {
        return !vm.post.content;
      }

      function init() {
        currentUser = userStorage.getUser();
        barnId = barnStorage.getEnvId();

        vm.post = {
          id: 0,
          userId: currentUser.id,
          tenantEnvironmentId: barnId,
          title: '',
          content: '',
          archived: 0
        };

        loadWhiteboardPosts(pageSize).then(function(response) {
          vm.paginator.initialLoaded(response);
        });
      }
    };
  }
}
