import {barnManagerModule} from '../../index.module';
import {groupBy} from 'lodash';
import {forEach} from 'angular';

barnManagerModule.directive('bmTimeline', timeline);

function timeline() {

  TimelineController.$inject = [
    '$scope',
    'activityRepository',
    'responseHandler',
    'EntityUrl',
    'dateTimeUpgraded'
  ];

  return {
    restricted: 'E',
    templateUrl: 'timeline.html',
    controller: TimelineController,
    controllerAs: 'timeline',
    bindToController: true,
    scope: {
      query: '=',
      items: '=',
      isHorseLevel: '<',
      currentHorseId: '<',
      title: '@'
    }
  };

  function TimelineController(
    $scope,
    activityRepository,
    responseHandler,
    EntityUrl,
    dateTimeUpgraded
  ) {
    const vm = this;

    vm.$onInit = function() {

      vm.showPinned = false;
      vm.hasTime = hasTime;
      vm.togglePin = togglePin;
      vm.serverTimeStampToLocalDateTimeFormatted = dateTimeUpgraded.serverTimeStampToLocalDateTimeFormatted;

      init();

      function init() {
        generateModelLink();

        $scope.$watch('timeline.showPinned', function(newVal) {
          if (typeof newVal !== 'undefined' && newVal) {
            vm.query(true);
          } else if (typeof newVal !== 'undefined' && !newVal) {
            vm.query(false);
          }
        });
      }

      function togglePin(activity) {
        const item = vm.items.find(i => i.id === activity.id);
        activityRepository.pin(item.id).then(function() {
          item.pinned = item.pinned ? 0 : 1;
          sortAndGroupItems();

        }).catch(responseHandler.processError);
      }

      function generateModelLink() {
        $scope.$watch('timeline.items', function(newVal) {
          if (typeof newVal !== 'undefined') {
            forEach(vm.items, function(item, index) {
              vm.items[index].shortDate = dateTimeUpgraded.serverTimeStampToLocalDateTimeFormatted(item.created, 'DATE');
              vm.items[index].url = EntityUrl.fromActivity(item, vm.isHorseLevel, vm.currentHorseId);
              vm.items[index].timestamp = new Date(item.created).getTime();
            });
            sortAndGroupItems();
          }
        });
      }

      function sortAndGroupItems() {
        vm.items = vm.items.sort((a, b) => b.pinned - a.pinned || b.timestamp - a.timestamp);
        vm.groupedItems = groupBy(vm.items, item => `"${item['shortDate']}-${item['pinned']}"`);
      }

      function hasTime(sameTime) {
        if (sameTime) {
          return 'hide';
        } else {
          return 'show';
        }
      }
    };
  }
}
