import {barnManagerModule} from '../../../index.module';
import moment from 'moment';
import {get, keys, some} from 'lodash';
import {getEntryTypesArray} from '../../../domain/feeding/EntryTypes';
import angular from 'angular';

barnManagerModule.factory('EntryFormHelpers', EntryFormHelpers);

EntryFormHelpers.$inject = ['RRuleHelper', 'DATE_ISO_FORMAT'];
function EntryFormHelpers(RRuleHelper, DATE_ISO_FORMAT) {

  const validators = {
    horse: horse,
    type: type,
    product: product,
    brand: brand,
    quantity: quantity,
    unit: unit,
    scheduleDtStart: scheduleDtStart,
    scheduleUntil: scheduleUntil,
    scheduleByWeekDay: scheduleByWeekDay,
    administrationTimes: administrationTimes
  };

  return {
    validate: validate,
    prepareFormForUi: prepareFormForUi,
    prepareFormForApi: prepareFormForApi
  };

  function validate(form) {
    const errors = keys(validators).reduce(function(acc, key) {
      acc[key] = validators[key](form);
      return acc;
    }, {});
    return {
      errors: errors,
      isFormValid: !some(errors)
    };
  }

  function horse(form) {
    return !get(form, 'item.horseId') && !get(form, 'allHorsesAccess');
  }

  function type(form) {
    return !get(form, 'item.type');
  }

  function product(form) {
    return !get(form, 'item.productId') && !get(form, 'product.name');
  }

  function brand(_form) {
    return false;
  }

  function quantity(form) {
    return !get(form, 'item.measure.quantity');
  }
  function unit(form) {
    return !get(form, 'item.measure.unit');
  }

  function scheduleDtStart(form) {
    if (!get(form, 'item.type.dateBinding')) {
      return false;
    }
    return !get(form, 'item.schedule.dtstart');
  }

  function scheduleUntil(form) {
    if (!get(form, 'item.type.dateBinding')) {
      return false;
    }
    const dtstart = get(form, 'item.schedule.dtstart');
    const until = get(form, 'item.schedule.rrule.until');

    if (!dtstart || !until) {
      return false;
    }
    const dtstartMoment = moment(dtstart, DATE_ISO_FORMAT);

    return dtstartMoment.isAfter(until);
  }

  function scheduleByWeekDay(form) {
    if (!get(form, 'item.type.dateBinding')) {
      return false;
    } else if (get(form, 'item.schedule.rrule.freq') !== 'WEEKLY') {
      return false;
    }
    return !get(form, 'item.schedule.rrule.byweekday.length');
  }

  function administrationTimes(form) {
    return !some(get(form, 'item.administrationTimes'));
  }

  function prepareFormForUi(item) {
    const form: any = {
      product: item.product,
      brand: item.product.brand
    };
    form.item = item;

    form.item.type = getEntryTypesArray().find(type => type.value === form.item.type);

    if (item.highlightColor) {
      form.highlightEntry = true;
    }

    return form;
  }

  function prepareFormForApi(form) {
    const body = angular.copy(form.item);
    if (body.type.dateBinding) {
      body.schedule.rrule = RRuleHelper.filterRRule(body.schedule.rrule);
    } else {
      body.schedule = null;
    }
    body.type = body.type.value;

    if (form.highlightEntry) {
      body.highlightColor = '#8b4d50';
    } else {
      body.highlightColor = null;
    }

    return body;
  }


}
