import {barnManagerModule} from '../index.module';

barnManagerModule.factory('tenantRepository', tenantRepository);

tenantRepository.$inject = ['$http', 'barnStorage'];
function tenantRepository($http, barnStorage) {
  const url = '/api/tenants';

  return {
    all: all,
    update: update,
    unreads: unreads,
    upgradeToPro: upgradeToPro
  };

  function all() {
    return $http.get(url, {
      params: {
        pagesize: 9999
      }
    }).then(function(result) {
      return result.data.records;
    });
  }

  function unreads(tenantId) {
    return $http.get('/api/users/me/unread/' + tenantId).then(function(result) {
      return result.data;
    });
  }

  function update(id, tenant) {
    return $http.put(url + '/' + id, tenant).then(function(result) {
      return result.data;
    });
  }

  function upgradeToPro() {
    return $http.post(url + '/upgrade-to-pro?tenantEnvironmentId=' + barnStorage.getEnvId());
  }
}
