import {barnManagerModule} from '../index.module';

barnManagerModule.factory('newFeaturesPolicy', NewFeaturesPolicy);
function NewFeaturesPolicy() {
  // var enabledTenants = [
  //   { tenantEnvId: 1, tenantId: 1, name: 'Admin Barn' },
  //   { tenantEnvId: 72, tenantId: 72, name: 'Stonehenge Stables' },
  //   { tenantEnvId: 172, tenantId: 244, name: 'Lakin Horses' },
  //   { tenantEnvId: 451, tenantId: 534, name: 'John Madden Sales' },
  //   { tenantEnvId: 504, tenantId: 587, name: 'The British Riding School' },
  //   { tenantEnvId: 533, tenantId: 618, name: 'Ethel Walker (Ethel Walker School)' },
  //   { tenantEnvId: 551, tenantId: 637, name: 'CMJ Sporthorses LLC' },
  //   { tenantEnvId: 585, tenantId: 674, name: 'Beacon Hill (Beacon Hill Show Stables)' }
  // ];

  return {
    canShowModule: canShowModule,
    canShowFeed: canShowFeed
  };
  function canShowModule(moduleName) {
    switch(moduleName) {
      case 'feed':
        return canShowFeed();
      default:
        return true;
    }
  }

  function canShowFeed() {
    // return _.includes(
    //   _.map(enabledTenants, 'tenantEnvId'),
    //   barnStorage.getEnvId()
    // );

    return true;
  }
}
