import {barnManagerModule} from '../../../index.module';

barnManagerModule.controller('BarnProfileController', BarnProfileController);

BarnProfileController.$inject = [
  'backLinkHistory',
  'responseHandler',
  'ScrollToMessageFrame',
  'barnStorage',
  'sessionService',
  'userStorage',
  'tenantRepository',
  'PATTERNS',
  'rAttachments',
  'listsService'
];
function BarnProfileController(
  backLinkHistory,
  responseHandler,
  ScrollToMessageFrame,
  barnStorage,
  sessionService,
  userStorage,
  tenantRepository,
  PATTERNS,
  rAttachments,
  listsService
) {
  const vm = this;
  backLinkHistory.pushLink('Barn Profile');

  vm.removedFiles = [];
  vm.update = update;
  vm.refresh = refresh;
  vm.barn = {};
  vm.user = {};
  vm.patterns = PATTERNS;
  vm.files = [];
  vm.status = {
    updating: false,
    submitAttempt: false
  };

  vm.queueDelete = queueDelete;
  vm.changeTimezone = changeTimezone;

  init();

  function init() {
    listsService.resetTo('Barn', 'BARN');
    if (userStorage.hasUser()) {
      vm.barn = barnStorage.getEnv();
      vm.user = userStorage.getUser();
      getLogo();
    }
  }

  function queueDelete(file) {
    vm.removedFiles.push(file);
  }

  function changeTimezone(tz) {
    vm.barn.tenant.timeZone = tz;
  }

  function refresh(form) {
    vm.barn = barnStorage.getEnv();
    getLogo();
    form.$setPristine();
  }

  function getLogo() {
    rAttachments.query({ modelType: 'Barn', modelId: vm.barn.id }, function(attachments) {
      vm.files = attachments;
      vm.removedFiles = [];
    });
  }

  function update(form) {
    vm.status.submitAttempt = true;

    if (!form.$valid) {
      return;
    }

    vm.status.updating = true;
    vm.barn.timeZone = vm.barn.tenant.timeZone;
    vm.barn.name = vm.barn.tenant.name;

    tenantRepository.update(vm.barn.tenant.id, vm.barn).then(function(data) {
      listsService.updateAttachments(vm.files, vm.removedFiles, { id: vm.barn.id });
      listsService.ready(function() {
        responseHandler.successOnSave('Barn profile', true);
        form.$setPristine();
        if (sessionService.isImpersonatedEnv()) {
          barnStorage.updateEnvTenant(data);
        } else {
          sessionService.refreshUser();
        }
        vm.status.updating = false;
        getLogo();
      });
    }).catch(function(error) {
      form.$setPristine();
      vm.barn = barnStorage.getEnv();
      vm.status.updating = false;
      ScrollToMessageFrame();
      responseHandler.processError(error);
    });
  }
}
