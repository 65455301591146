import {barnManagerModule} from '../../index.module';
import {includes, values} from 'lodash';

barnManagerModule.factory('ScrollToMessageFrame', ScrollToMessageFrame);

ScrollToMessageFrame.$inject = ['$window', 'MessageFrames'];
function ScrollToMessageFrame($window, MessageFrames) {
  const frames = values(MessageFrames);

  return function(frame) {
    if (!includes(frames, frame)) {
      frame = MessageFrames.DEFAULT;
    }
    const topPosition = offsetTop('#message-frame-' + frame);
    $window.scrollTo(0, topPosition - 100);
  };

  function offsetTop(selector) {
    const element = $window.document.querySelector(selector);
    const rect = element.getBoundingClientRect();
    const scrollTop = $window.pageYOffset || $window.document.documentElement.scrollTop;
    return rect.top + scrollTop;
  }
}
