import {barnManagerModule} from '../../index.module';
import {copy, merge} from 'angular';

barnManagerModule.directive('bmPersonSelect', personSelect);

function personSelect() {
  return {
    restricted: 'E',
    templateUrl: 'person-select.html',
    controller: PersonsSelectController,
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      selected: '<',
      persons: '<',
      setPerson: '<',
      emptyPerson: '<'
    }
  };
  function PersonsSelectController() {
    const vm = this;
    let emptyPerson;

    vm.onChange = onChange;

    vm.$onInit = () => {
      emptyPerson = copy(vm.emptyPerson);
    };

    function onChange(value) {
      if (!value) {
        vm.setPerson(null);
      } else {
        if (value.id) {
          vm.setPerson(value);
        } else {
          vm.setPerson(merge(emptyPerson, {
            id: null,
            name: value.name
          }));
        }
      }
    }
  }
}
