import {barnManagerModule} from '../../../../index.module';
import {assign, clone, each, every, find, get, includes, map, remove, sortBy} from 'lodash';

barnManagerModule
  .component('bmUsefRequestsTable', {
    templateUrl: 'usef-requests-table.html',
    controller: UsefRequestsTableController,
    controllerAs: 'vm',
    bindings: {
      requests: '<',
      type: '<'
    }
  });

UsefRequestsTableController.$inject = [
  '$scope',
  '$q',
  'usefUtils',
  'messageBus',
  'MessageFrames',
  'usefMessages',
  'horseRepository',
  'rUsefImportRequests',
  'confirmDialog'
];
function UsefRequestsTableController(
  $scope,
  $q,
  usefUtils,
  messageBus,
  MessageFrames,
  usefMessages,
  horseRepository,
  rUsefImportRequests,
  confirmDialog
) {
  const vm = this;

  vm.$onInit = function() {

    vm.requestsList = vm.requests;
    vm.allImportSelected = false;
    vm.importing = false;
    vm.hasSelected = false;
    vm.horses = [];
    vm.horsesInited = [];

    vm.humanReadableStatus = usefUtils.humanReadableStatus;
    vm.allImportSelectedChanged = allImportSelectedChanged;
    vm.checkSelectedRequest = checkSelectedRequest;
    vm.typeMatches = typeMatches;
    vm.importSelected = importSelected;
    vm.checkStatus = checkStatus;
    vm.deleteRequest = deleteRequest;
    vm.setMergeHorse = setMergeHorse;
    vm.clearHorse = clearHorse;
    vm.onMergeChange = onMergeChange;
    vm.onMergeImport = onMergeImport;
    vm.isImportable = isImportable;

    function isImportable(request) {
      return includes(['CAN_BE_IMPORTED', 'EXISTS_ALREADY'], request.status);
    }

    function getPendingRequest() {
      const requests = vm.requestsList.filter(isImportable);
      vm.hasPending = requests.length > 0;
      return requests;
    }

    function checkSelectedRequest() {
      const requests = getPendingRequest();
      vm.hasSelected = vm.hasPending && checkAllSelected(requests);
      vm.allImportSelected = vm.hasPending && every(requests, '$import');
    }

    function allImportSelectedChanged() {
      const requests = getPendingRequest();
      each(requests, function(request) {
        request.$import = vm.allImportSelected;
      });
      vm.hasSelected = vm.hasPending && checkAllSelected(requests);
    }

    function checkAllSelected(requests) {
      let checked = false;
      each(requests, function(request) {
        if (
          request.$import && (
            request.status === 'CAN_BE_IMPORTED' || (
              request.status === 'EXISTS_ALREADY' && get(request, '_horse.id')
            )
          )
        ) {
          checked = true;
        }
      });
      return checked;
    }

    function typeMatches(request) {
      if (vm.type === 'PENDING') {
        return includes([
          'CONSENT_REQUIRED',
          'CONSENT_REQUESTED',
          'CAN_BE_IMPORTED',
          'USEF_ACCOUNT_NOT_LINKED',
          'EXISTS_ALREADY'
        ], request.status);
      } else if (vm.type === 'IMPORTED') {
        return request.status === 'IMPORTED';
      } else {
        return !includes([
          'CONSENT_REQUIRED',
          'CONSENT_REQUESTED',
          'CAN_BE_IMPORTED',
          'USEF_ACCOUNT_NOT_LINKED',
          'EXISTS_ALREADY',
          'IMPORTED'
        ], request.status);
      }
    }

    function importSelected() {
      if (!vm.hasSelected) {
        return;
      }
      vm.importing = true;
      const requests = getPendingRequest();
      const promises = [];

      each(requests, function(request) {
        if (request.$import) {
          request.$loading = true;
          promises.push(
            createPromise(request).then(function(response) {
              assign(request, response);
              request.$import = false;
            }).finally(function() {
              request.$loading = false;
            })
          );
        }
      });
      messageBus.clear(MessageFrames.DEFAULT);
      $q.all(promises).then(function(_result) {
        messageBus.push({
          text: _result.length + (_result.length === 1 ? ' horse' : ' horses') + ' imported successfully!'
        });
      }).catch(function() {
        messageBus.push(usefMessages.importError);
      }).finally(function() {
        vm.importing = false;
        vm.allImportSelected = false;
      });
    }

    function checkStatus(request) {
      request.$loading = true;
      messageBus.clear(MessageFrames.DEFAULT);

      return rUsefImportRequests.checkStatus(request.id).then(function(response) {
        assign(request, response);
      }).finally(function() {
        request.$loading = false;
        checkSelectedRequest();
      });
    }

    function deleteRequest(request) {
      confirmDialog.open('Are you sure you want to delete this request?').then(function() {
        rUsefImportRequests.delete(request.id).then(function(_response) {
          remove(vm.requestsList, function(r: any) {
            return r.id === request.id;
          });
          messageBus.clear(MessageFrames.DEFAULT);
          messageBus.push(usefMessages.deleted);
          checkSelectedRequest();
        });
      }).catch(function() {});
    }

    function createPromise(request) {
      if (get(request, '_horse.id')) {
        return rUsefImportRequests.mergeHorse({
          id: request.id,
          horseId: request._horse.id,
          horseAttributes: request._merged
        });
      } else {
        return rUsefImportRequests.createNewHorse(request.id);
      }
    }

    function onMergeImport(id) {
      const request = find(vm.requestsList, { id: id });
      request.$loading = true;
      messageBus.clear(MessageFrames.DEFAULT);

      const requestUpdated = function(response) {
        assign(request, response);
        request.$loading = false;
        messageBus.push(usefUtils.mapStatusToMessage(response));
        checkSelectedRequest();
      };
      createPromise(request).then(requestUpdated)
        .catch(function(response) {
          if (response.status === 422) {
            requestUpdated(response.data);
          } else {
            request.$loading = false;
            messageBus.push(usefMessages.serverError);
          }
        });
    }

    function setMergeHorse(request, horse) {
      request._horse = horse;
      if (!get(request, '_horse.id')) {
        clearHorse(request);
      }
    }

    function clearHorse(request) {
      request._horse = undefined;
      request._merged = undefined;
    }

    function onMergeChange(mergedProperties, id) {
      find(vm.requestsList, { id: id })._merged = mergedProperties;
    }

    function initHorse(request) {
      if (request.status === 'EXISTS_ALREADY' && !includes(vm.horsesInited, request.id)) {
        const horse = find(vm.horses, { id: request.horseId });
        if (horse) {
          vm.horsesInited.push(request.id);
          request._horse = horse;
        }
      }
      return request;
    }

    horseRepository.allIncludingArchived().then(function(horses) {
      return sortBy(horses, ['archived', function(o) {
        return o.name.toUpperCase();
      }]).map(function(horse) {
        horse._nameWithArchived = horse.archived ? 'Archived: ' + horse.name : horse.name;
        return horse;
      });
    }).then(function(horses) {
      vm.horses = clone(horses);
      vm.horses.unshift({ _nameWithArchived: 'New Horse' });
      vm.requestsList = vm.requestsList.map(initHorse);
    }).then(function() {
      $scope.$watchCollection('vm.requests', function(requests) {
        vm.requestsList = map(requests, function(newRequest) {
          const request = find(vm.requestsList, { id: newRequest.id }) || {};
          newRequest = initHorse(newRequest);
          return assign(request, newRequest);
        });
        checkSelectedRequest();
      });
    });

  };
}
