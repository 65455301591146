import {barnManagerModule} from '../../../index.module';
import {clone} from 'lodash';
import moment from 'moment';

barnManagerModule
  .component('bmLeaseDates', {
    templateUrl: 'lease-dates.html',
    controller: horseLeaseController,
    controllerAs: 'vm',
    bindings: {
      form: '<',
      submitAttempt: '<',
      lease: '<',
      onDatesChange: '<'
    }
  });

horseLeaseController.$inject = ['DATE_ISO_FORMAT', 'DATE_FORMAT', 'PATTERNS'];
function horseLeaseController(DATE_ISO_FORMAT, DATE_FORMAT, PATTERNS) {
  const vm = this;

  vm.calendarOpened = {};
  vm.patterns = PATTERNS;

  vm.errorMessages = {
    startDate: '',
    buyoutDate: '',
    extensionDate: ''
  };

  vm.valid = {
    startDate: true,
    endDate: true,
    buyoutDate: true,
    extensionDate: true
  };

  vm.dateOptions = {
    startDate: {},
    endDate: {},
    buyoutDate: {},
    extensionDate: {}
  };

  vm.openCalendar = openCalendar;
  vm.onDateChange = onDateChange;

  function setError(fieldName, message) {
    vm.valid[fieldName] = null;
    vm.errorMessages[fieldName] = message;
  }

  function clearError(fieldName) {
    vm.valid[fieldName] = true;
    vm.errorMessages[fieldName] = '';
  }

  function updateDateOptions() {
    if (vm.item.buyoutDate && vm.item.startDate && vm.item.buyoutDate.getTime() < vm.item.startDate.getTime()) {
      setError('buyoutDate', 'Buyout date cannot be before start date');
    } else if (vm.item.buyoutDate && vm.item.endDate && vm.item.buyoutDate.getTime() > vm.item.endDate.getTime()) {
      setError('buyoutDate', 'Buyout date cannot be after end date');
    } else {
      clearError('buyoutDate');
    }

    vm.dateOptions.startDate.maxDate = vm.item.endDate ? clone(vm.item.endDate) : null;

    vm.dateOptions.endDate.minDate = vm.item.startDate ? clone(vm.item.startDate) : null;

    vm.dateOptions.buyoutDate.minDate = vm.item.startDate ? clone(vm.item.startDate) : null;
    vm.dateOptions.buyoutDate.maxDate = vm.item.endDate ? clone(vm.item.endDate) : null;
  }

  function onDateChange() {
    vm.onDatesChange({
      startDate: isoDate(vm.item.startDate),
      endDate: isoDate(vm.item.endDate),
      buyoutDate: isoDate(vm.item.buyoutDate),
      extensionDate: isoDate(vm.item.extensionDate)
    });
    updateDateOptions();
  }

  function openCalendar(calendar) {
    vm.calendarOpened[calendar] = true;
  }

  function isoDate(jsDate) {
    return jsDate ? moment(jsDate).format(DATE_ISO_FORMAT) : null;
  }

  function jsDate(isoDate) {
    return isoDate ? moment(isoDate, DATE_ISO_FORMAT).toDate() : null;
  }

  function updateLease(item) {
    if (item) {
      vm.item = {
        startDate: jsDate(item.startDate),
        endDate: jsDate(item.endDate),
        buyoutDate: jsDate(item.buyoutDate),
        extensionDate: jsDate(item.extensionDate)
      };
    } else {
      vm.item = {};
    }
    updateDateOptions();
  }

  vm.$onInit = function() {
    updateLease(vm.lease);
  };

  vm.$onChanges = function(changes) {
    if (changes.lease) {
      updateLease(changes.lease.currentValue);
    }
  };
}
