import {barnManagerModule} from '../../index.module';

barnManagerModule
  .component('bmTestApiEndpointNotification', {
    templateUrl: 'test-api-endpoint-notification.html',
    controller: TestApiEndpointNotificationController,
    controllerAs: 'vm'
  });

TestApiEndpointNotificationController.$inject = ['$rootScope', 'sessionStorageService'];
function TestApiEndpointNotificationController($rootScope, sessionStorageService) {
  const vm = this;
  vm.testApiEndpoint = sessionStorageService.getTestApiEndpoint();

  const testApiEndpointChange = $rootScope.$on('developmentMode:testApiEndpoint', function(event, data) {
    vm.testApiEndpoint = data;
  });
  $rootScope.$on('$destroy', testApiEndpointChange);
}
