<ng-select class="bm-ng-select"
           [ngModel]="checkSelectedInItems ? (selected | checkSelected:items:bindValue) : selected"
           (ngModelChange)="selected = $event"
           [items]="items"
           [multiple]="multiple"
           [groupBy]="groupBy"
           [selectableGroup]="true"
           [selectableGroupAsModel]="false"
           [disabled]="disabled"
           [searchable]="searchable"
           [clearable]="clearable"
           [addTag]="tagging"
           [closeOnSelect]="!multiple"
           [clearOnBackspace]="false"
           [virtualScroll]="virtualScroll"
           [placeholder]="placeholder"
           [bindValue]="bindValue"
           [bindLabel]="bindLabel"
           [dropdownPosition]="dropdownPosition"
           [appendTo]="appendTo"
           [searchFn]="searchFn"
           [compareWith]="compareFunction"
           (blur)="onTouched()"
           (change)="onSelectedChange($event)">
  <!-- Label Tmp -->
  <ng-template *ngIf="ngLabelTmp" ng-label-tmp let-item="item">
    <ng-container *ngTemplateOutlet="ngLabelTmp; context: { $implicit: item }"></ng-container>
  </ng-template>

  <!-- Multi Label Tmp -->
  <ng-template *ngIf="ngMultiLabelTmp" ng-multi-label-tmp let-items="items" let-clear="clear">
    <ng-container *ngTemplateOutlet="ngMultiLabelTmp; context: { $implicit: items, clear: clear }"></ng-container>
  </ng-template>

  <!-- Optgroup Tmp -->
  <ng-template *ngIf="ngOptgroupTmp" ng-optgroup-tmp let-item$="item$">
    <ng-container *ngTemplateOutlet="ngOptgroupTmp; context: { $implicit: item$ }"></ng-container>
  </ng-template>

  <!-- Option Tmp -->
  <ng-container *ngIf="ngOptionTmp; else defaultOptionTmp">
    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
      <span class="ng-option-label">
        <ng-container *ngTemplateOutlet="ngOptionTmp; context: { $implicit: item, search: search }"></ng-container>
      </span>
    </ng-template>
  </ng-container>

  <ng-template #defaultOptionTmp>
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-search="searchTerm">
      <ng-container *ngIf="multiple; else single">
        <mat-checkbox color="primary" class="checkbox" [(ngModel)]="item$.selected" (click)="$event.preventDefault()" [title]="item[bindLabel] || item">
          <span [ngOptionHighlight]="search">{{ item[bindLabel] || item }}</span>
        </mat-checkbox>
      </ng-container>
      <ng-template #single>
        <div class="ng-option-label" [ngOptionHighlight]="search" [title]="item[bindLabel] || item" [ngClass]="{'disabled': item?.occupied}">{{ item[bindLabel] || item }}</div>
      </ng-template>
    </ng-template>
  </ng-template>
</ng-select>
