import {ParamMap} from '@angular/router';
import {DateRange, DateRangeMomentPlain, PrimitiveDateRange, today} from '@shared/lib/utils/date-range';
import {DateConverter} from '@shared/lib/utils/date-converter';
import * as moment from 'moment';
import {DynamicKeyType} from '../core/utils/dynamic-key-type';
import {EventTypes} from '../event/models/event-types';
import {RecordTypes} from '../record/models/record-types';

export enum ReportTypeFilterField {
  Horse = 'horse',
  DateRange = 'dateRange',
  EventType = 'eventType',
  RecordType = 'recordType'
}

export abstract class ReportTypeFilterFieldConfig<K, N> {
  protected constructor(
    public readonly type: string,
    public readonly value?: N
  ) {
  }

  abstract getValueFromFormValues(formValues: DynamicKeyType): K;

  abstract getValuesFromQueryParams(queryParamMap: ParamMap): N;
}

export class HorseFilterFieldConfig extends ReportTypeFilterFieldConfig<{ horseIds: any }, number[]> {
  constructor(public readonly value?: number[]) {
    super(ReportTypeFilterField.Horse, value);
  }

  getValueFromFormValues(formValues: DynamicKeyType): { horseIds: any } {
    return { horseIds: formValues[this.type] } ;
  }
  getValuesFromQueryParams(queryParamMap: ParamMap): number[] {
    return queryParamMap.get('horseIds')?.split(',')?.map(id => Number(id));
  }
}

export class DateRangeFilterFieldConfig extends ReportTypeFilterFieldConfig<{ start: string, end: string }, PrimitiveDateRange> {
  constructor(
    public readonly value: PrimitiveDateRange = DateRange.currentYear().toPrimitiveDateRange()
  ) {
    super(ReportTypeFilterField.DateRange, value);
  }

  getValueFromFormValues(formValues: DynamicKeyType): { start: string, end: string } {
    const dateRange: DateRangeMomentPlain = formValues[this.type];
    return { start: moment(dateRange.start).format('YYYY-MM-DD'), end: moment(dateRange.end).format('YYYY-MM-DD') };
  }

  getValuesFromQueryParams(queryParamMap: ParamMap): PrimitiveDateRange {
    const selectedDateRange = new PrimitiveDateRange(DateConverter.ISODateToDate(queryParamMap.get('start')), DateConverter.ISODateToDate(queryParamMap.get('end')));
    if (selectedDateRange.start && selectedDateRange.end) {
      return selectedDateRange;
    } else {
      return null;
    }
  }
}

export class EventTypeFilterFieldConfig extends ReportTypeFilterFieldConfig<{ eventTypes: any[] }, string[]> {
  constructor(public readonly value: string[] = [ ...EventTypes.All.map(type => type.value) ]) {
    super(ReportTypeFilterField.EventType, value);
  }

  getValueFromFormValues(formValues: DynamicKeyType): { eventTypes: any[] } {
    if (formValues[this.type]?.length > 0) {
      let eventTypes: string[]= formValues[this.type];
      if (eventTypes?.length === RecordTypes.All.length) {
        eventTypes = [ EventTypes.AllEventTypes.value ];
      }
      return { eventTypes };
    } else {
      return null;
    }
  }
  getValuesFromQueryParams(queryParamMap: ParamMap): string[] {
    const eventTypes = queryParamMap.get('eventTypes')?.split(',');
    if (eventTypes?.length > 0) {
      if (eventTypes.length === 1 && eventTypes[0] === EventTypes.AllEventTypes.value) {
        return [ ...EventTypes.All.map(type => type.value) ];
      }
      return eventTypes;
    }
  }
}

export class RecordTypeFilterFieldConfig extends ReportTypeFilterFieldConfig<{ recordTypes: any[] }, string[]> {
  constructor(public readonly value: string[] = [ ...RecordTypes.All.map(type => type.value) ]) {
    super(ReportTypeFilterField.RecordType, value);
  }

  getValueFromFormValues(formValues: DynamicKeyType): { recordTypes: any[] } {
    if (formValues[this.type]?.length > 0) {
      let recordTypes: string[] = formValues[this.type];
      if (recordTypes?.length === RecordTypes.All.length) {
        recordTypes = [ RecordTypes.AllRecordTypes.value ];
      }
      return { recordTypes };
    } else {
      return null;
    }
  }
  getValuesFromQueryParams(queryParamMap: ParamMap): string[] {
    const recordTypes = queryParamMap.get('recordTypes')?.split(',');
    if (recordTypes?.length > 0) {
      if (recordTypes.length === 1 && recordTypes[0] === RecordTypes.AllRecordTypes.value) {
        return [ ...RecordTypes.All.map(type => type.value) ];
      }
      return recordTypes;
    }
  }
}

export declare type ReportTypeFilterFieldConfigType = Array<HorseFilterFieldConfig | DateRangeFilterFieldConfig | EventTypeFilterFieldConfig | RecordTypeFilterFieldConfig>;

export interface ReportTypeFilterValues {
  type: ReportTypeSpecification;
  start?: string;
  end?: string;
  horseIds?: number[];
  eventTypes?: string[];
  recordTypes?: string[];
}

export class ReportTypeSpecification {
  static ALL: ReportTypeSpecification[] = [];
  static readonly UpcomingExpirationsReport = new ReportTypeSpecification('Upcoming Expirations Report', 'EXPIRATIONS', [
    new HorseFilterFieldConfig(),
    new DateRangeFilterFieldConfig(),
  ]);
  static readonly UpcomingAppointmentsReport = new ReportTypeSpecification('Appointments/Events Report', 'EVENTS', [
    new HorseFilterFieldConfig(),
    new DateRangeFilterFieldConfig(new DateRange(moment(today), moment(today).endOf('year')).toPrimitiveDateRange()),
    new EventTypeFilterFieldConfig()
  ]);
  static readonly HorseRecordReport = new ReportTypeSpecification('Horse Health Records Report', 'HEALTH_RECORDS',  [
    new HorseFilterFieldConfig(),
    new DateRangeFilterFieldConfig(new DateRange(moment(today).startOf('year'), moment(today).endOf('day')).toPrimitiveDateRange()),
    new RecordTypeFilterFieldConfig()
  ]);
  static readonly HorseLeaseReport = new ReportTypeSpecification('Horse Lease Report', 'LEASE',  [
      new HorseFilterFieldConfig(),
      new DateRangeFilterFieldConfig(),
  ]);
   static readonly MultipleCogginsCertificates = new ReportTypeSpecification('Multiple Coggins Certificates', 'COGGINS',  [
     new HorseFilterFieldConfig()
  ]);
  static readonly FeedReport = new ReportTypeSpecification('Feed Report', 'FEED',  [
    new HorseFilterFieldConfig(),
  ]);
  private constructor(
    public readonly name: string,
    public readonly value: string,
    private readonly filterFieldConfigs: ReportTypeFilterFieldConfigType
  ) {
    ReportTypeSpecification.ALL.push(this);
  }

  getFilterConfigs(horseId: number): ReportTypeFilterFieldConfigType {
    return this.filterFieldConfigs.filter(config => !horseId ? true : config.type !== ReportTypeFilterField.Horse);
  }
}
