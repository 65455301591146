import {barnManagerModule} from '../index.module';

barnManagerModule.factory('EntityUrl', ['$state', ($state) => {

  return {
    fromActivity: fromActivity
  };

  function fromActivity(activity, isHorseLevel, horseId) {
    let url = '';
    switch (activity.type) {
      case 'WHITEBOARD':
        url = $state.href('activity', { section: 'whiteboard' });
        break;
      case 'RECORD':
      case 'RECORD_COMMENT':
        url = $state.href('recordDetails', {
          id: activity.modelId,
          author: activity.resourceCreatorId
        });
        if (isHorseLevel) {
          url = $state.href('recordHorseDetails', {
            id: horseId,
            recordId: activity.modelId,
            author: activity.resourceCreatorId
          });
        }
        break;
      case 'CONVERSATION':
      case 'CONVERSATION_COMMENT':
        url = $state.href('conversationDetails', {
          id: activity.modelId,
          author: activity.resourceCreatorId
        });
        if (isHorseLevel) {
          url = $state.href('conversationHorseDetails', {
            id: horseId,
            conversationId: activity.modelId,
            author: activity.resourceCreatorId
          });
        }
        break;
      case 'LIST_SIMPLE':
      case 'LIST_SIMPLE_COMMENT':
        url = $state.href('listDetails', {
          id: activity.modelId,
          author: activity.resourceCreatorId
        });
        if (isHorseLevel) {
          url = $state.href('listHorseDetails', {
            id: horseId,
            listId: activity.modelId,
            author: activity.resourceCreatorId
          });
        }
        break;
      case 'HORSE':
        url = $state.href('horseDetails', {
          id: activity.modelId,
          author: activity.resourceCreatorId
        });
        break;
      case 'HORSE_NOTES':
        url = $state.href('activityHorse', {
          id: activity.modelId
        });
        break;
      case 'CONTACT':
        url = $state.href('contactDetails', {
          id: activity.modelId,
          author: activity.resourceCreatorId
        });
        break;
      case 'EVENT':
      case 'EVENT_COMMENT':
        url = $state.href('eventDetails', {
          id: activity.modelId,
          instance: activity.instanceId,
          author: activity.resourceCreatorId
        });
        if (isHorseLevel) {
          url = $state.href('eventHorseDetails', {
            id: horseId,
            eventId: activity.modelId,
            instance: activity.instanceId,
            author: activity.resourceCreatorId
          });
        }
        break;
      case 'HORSES_IMPORT':
        url = $state.href('horses');
    }
    return url;
  }
}]);
