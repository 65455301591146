import {barnManagerModule} from '../../index.module';
import * as angular from 'angular';

barnManagerModule
  .factory('HorseNameChangeLog', ['dateTimeUpgraded', (dateTimeUpgraded) => {
    function HorseNameChangeLog(data) {
      angular.extend(this, angular.copy(data));
      this.date = dateTimeUpgraded.fromUTCDateToLocalDateTimeFormatted(this.created);
    }

    HorseNameChangeLog.prototype = {
      hasNameChanges: function() {
        return this.oldName && this.newName && this.oldName !== this.newName;
      },
      hasShowNameChanges: function() {
        return this.oldDisplayName && this.newDisplayName && this.oldDisplayName !== this.newDisplayName;
      }
    };

    return HorseNameChangeLog;
  }]);
