import {barnManagerModule} from '../index.module';

barnManagerModule.directive('syncFocusWith', syncFocusWith);

syncFocusWith.$inject = ['$timeout'];
function syncFocusWith($timeout) {
  return {
    restrict: 'A',
    scope: {
      focusValue: '=syncFocusWith'
    },
    link: function ($scope, $element) {
      $scope.$watch('focusValue', function (currentValue, previousValue) {
        $timeout(function () {
          $element[0].focus();
          if (currentValue === true && !previousValue) {
            $element[0].focus();
          } else if (currentValue === false && previousValue) {
            $element[0].blur();
          }
        });
      });
    }
  };
}
