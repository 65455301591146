import {barnManagerModule} from '../index.module';

barnManagerModule.factory('tenantEnvironmentIdEnricherInterceptor', TenantEnvironmentIdEnricherInterceptor);

TenantEnvironmentIdEnricherInterceptor.$inject = ['barnStorage'];
function TenantEnvironmentIdEnricherInterceptor(barnStorage) {
  return {
    request: request
  };

  function request(config) {
    const env = barnStorage.getEnv() || {};
    if (config.url.match(/^\/api\//)) {
      config.headers.TenantEnvironmentId = env.id;
    }
    return config;
  }
}
