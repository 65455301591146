import {barnManagerModule} from '../../index.module';
import {includes} from 'lodash';

barnManagerModule
  .component('bmTagArea', {
    templateUrl: 'tag-area.html',
    controller: tagAreaController,
    controllerAs: 'vm',
    bindings: {
      previewMode: '<',
      showSettingsBtn: '<',
      tags: '=',
      onChange: '<'
    }
  });

tagAreaController.$inject = ['$scope', 'tagRepository', 'responseHandler', 'MessageFrames']
function tagAreaController($scope, tagRepository, responseHandler, MessageFrames) {
  const vm = this;
  vm.allTags = [];

  vm.onTagsChange = onTagsChange;
  vm.onArchiveTag = onArchiveTag;
  vm.onRestoreTag = onRestoreTag;

  $scope.$watch('vm.previewMode', function(newVal) {
    if (!newVal) {
      tagRepository.all().then(function(tags) {
        vm.allTags = tags;
        setTagsByName();
      });
    }
  });

  $scope.$watch('vm.allTags', function(newVal) {
    if (newVal) {
      vm.tagSelectItems = newVal.filter(i => !i.archived);
      vm.settingBtnAvailable = vm.allTags && vm.allTags.some(i => i.id);
    }
  });

  $scope.$watch('vm.tags', function() {
    setTagsByName();
  });

  function setTagsByName() {
    vm.selected = vm.allTags.filter((tag) => includes(vm.tags, tag.name));
  }

  function onTagsChange(selected: any[]) {
    const tagNames = vm.allTags.map(tag => tag.name);
    selected.forEach(i => {
      if (!tagNames.includes(i.name)) {
        vm.allTags = [...vm.allTags, i];
      }
    });
    vm.tags = selected.map(i => i.name);
    if (vm.onChange) {
      vm.onChange(vm.tags);
    }
  }

  function onArchiveTag(item) {
    item.loading = true;
    tagRepository.archive(item.id).then(function() {
      const tag = vm.allTags.find(i => i.id === item.id);
      if (tag) {
        tag.archived = true;
      }
      vm.allTags = [...vm.allTags];
    }).catch(errorHandler).finally(() => item.loading = false);
  }

  function onRestoreTag(item) {
    item.loading = true;
    tagRepository.restore(item.id).then(function() {
      const tag = vm.allTags.find(i => i.id === item.id);
      if (tag) {
        tag.archived = false;
      }
      vm.allTags = [...vm.allTags];
    }).catch(errorHandler).finally(() => item.loading = false);
  }

  function errorHandler(error) {
    return responseHandler.processError(error, null, MessageFrames.TOP);
  }
}
