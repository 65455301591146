<h3 mat-dialog-title>{{ file.name }}</h3>
<div [hidden]="(isLoaded$ | async) === false" class="pdf-action-buttons" style="text-align: center;">
  <div class="pdf-action-buttons__page">
    <input #pageInput
           type="number"
           [value]="page$ | async"
           (blur)="onPageChange(pageInput.value)"
           (keyup.enter)="pageInput.blur()"
           (input)="adjustPageInputWidth(pageInput.value)">
    / {{ totalPages }}
  </div>
  <div class="pdf-action-buttons__zoom">
    <button type="button" class="zoom-out" (click)="zoomOut()" title="Reduce size">
      <mat-icon aria-hidden="false" aria-label="Reduce size" fontIcon="remove"></mat-icon>
    </button>
    <div class="zoom-input">
      <input #zoomInput type="number" [min]="zoomMin" [max]="zoomMax" [value]="zoom$ | async" (blur)="onZoomChange(zoomInput.value)" (keyup.enter)="zoomInput.blur()">%
    </div>
    <button type="button" class="zoom-in" (click)="zoomIn()" title="Increase size">
      <mat-icon aria-hidden="false" aria-label="Increase size" fontIcon="add"></mat-icon>
    </button>
  </div>
  <div *ngIf="isPrintAvailable" class="pdf-action-buttons__print" title="Print" (click)="print()">
    <i class="fa fa-print"></i>
  </div>
</div>
<mat-dialog-content #matDialogContent class="mat-typography">
  <pdf-viewer class="pdf-viewer"
              [src]="file.blobUrl"
              [fit-to-page]="true"
              [page]="page$ | async"
              [zoom]="(zoom$ | async)/100"
              (pageChange)="page$.next($event)"
              (after-load-complete)="afterLoadComplete($event)"
  ></pdf-viewer>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button type="button" mat-button mat-stroked-button color="primary" matRipple mat-dialog-close>Close</button>
  <button type="submit" *ngIf="!file.new" mat-button mat-flat-button color="primary" matRipple matRippleColor="white" (click)="download()">Download</button>
</mat-dialog-actions>
