import {barnManagerModule} from '../index.module';

barnManagerModule.factory('billingPlan', billingPlan);

billingPlan.$inject = ['$resource', '$http'];
function billingPlan($resource, $http) {
  return {
    // TODO: BILLING: remove vm.selectedPlanTerm and do not change plan on updatePaymentInfo (it is a strange behavior)
    updatePaymentInfo: function(data) {
      return $http({
        method: 'POST',
        url: '/api/tenants/stripe/paymentInfo?tenantEnvironmentId=' + data.tenantEnvironmentId + '&sourceToken=' + data.sourceToken
      });
    },
    coupon: $resource('/api/tenants/stripe/coupon?tenantEnvironmentId=:teid&couponId=:coupon', {}, {
      submit: {
        method: 'POST',
        params: {
          teid: '@teid',
          coupon: '@coupon'
        }
      }
    }),
    usef: $resource('/api/tenants/stripe/usef?tenantEnvironmentId=:teid&usefId=:usefId', {}, {
      submit: {
        method: 'POST',
        params: {
          teid: '@teid',
          usefId: '@usefId'
        }
      }
    }),
    stripe: $resource('/api/tenants/stripe/', {}, {
      subscription: {
        method: 'GET',
        url: '/api/tenants/stripe/subscription'
      },
      paymentInfo: {
        method: 'GET',
        url: '/api/tenants/stripe/paymentInfo'
      },
      // TODO: BILLING: remove vm.selectedPlanTerm and do not change plan on updatePaymentInfo (it is strange behavior)
      updatePaymentInfo: {
        method: 'POST',
        url: '/api/tenants/stripe/paymentInfo?tenantEnvironmentId=:teid&sourceToken=:token',
        params: {
          teid: '@tenantEnvironmentId',
          token: '@sourceToken'
        }
      },

      // TODO: BILLING: updatePaymentTerm deprecated, remove
      updatePaymentTerm: {
        method: 'POST',
        url: '/api/tenants/stripe/paymentInfo/term?tenantEnvironmentId=:teid&term=:term',
        params: {
          teid: '@tenantEnvironmentId',
          term: '@term'
        }
      },
      // TODO: BILLING: updatePaymentPlan new
      updatePaymentPlan: {
        method: 'POST',
        url: '/api/tenants/stripe/paymentInfo/plan?tenantEnvironmentId=:teid&planId=:planId',
        params: {
          teid: '@tenantEnvironmentId',
          planId: '@planId'
        }
      },

      cancel: {
        method: 'POST',
        url: '/api/tenants/stripe/cancel?tenantEnvironmentId=:teid',
        params: {
          teid: '@tenantEnvironmentId'
        }
      },

      resubscribe: {
        method: 'POST',
        url: '/api/tenants/stripe/resubscribe?tenantEnvironmentId=:teid&sourceToken=:token&planId=:planId',
        params: {
          teid: '@tenantEnvironmentId',
          token: '@sourceToken',
          planId: '@planId'
        }
      },

      reactivate: {
        method: 'POST',
        url: '/api/tenants/stripe/reactivate?tenantEnvironmentId=:teid',
        params: {
          teid: '@tenantEnvironmentId'
        }
      },

      churnkeyInfo: {
        method: 'GET',
        url: '/api/tenants/stripe/churnkey',
        params: {
          teid: '@tenantEnvironmentId'
        }
      }
    })
  };
}
