import {barnManagerModule} from '../index.module';

barnManagerModule.factory('reminderRepository', ReminderRepository);

ReminderRepository.$inject = ['$http'];
function ReminderRepository($http) {
  const horseUrl = function(id, path) {
    return '/api/horses/' + id + '/reminders/' + path;
  };

  return {
    getFeiReminder: getFeiReminder,
    addFeiReminder: addFeiReminder,
    getCertificateReminder: getCertificateReminder,
    addCertificateReminder: addCertificateReminder
  };

  function getFeiReminder(id) {
    return $http.get(horseUrl(id, 'certificate/fei')).then(transformResult);
  }

  function addFeiReminder(id, body) {
    return $http.post(horseUrl(id, 'certificate/fei'), body);
  }

  function getCertificateReminder(id) {
    return $http.get(horseUrl(id, 'certificate/coggings')).then(transformResult);
  }

  function addCertificateReminder(id, body) {
    return $http.post(horseUrl(id, 'certificate/coggings'), body);
  }

  function transformResult(result) {
    return result.data;
  }

}
