import {barnManagerModule} from '../../index.module';
import {flatten, flattenDeep, values} from 'lodash';
import {entityTimes, getEntryTypesArray} from '../../domain/feeding/EntryTypes';
import angular from 'angular';

barnManagerModule
  .component('bmFeedTable', {
    templateUrl: 'feed-table.html',
    controller: FeedTableController,
    controllerAs: 'vm',
    bindings: {
      entryTypes: '<',
      horseId: '<',
      entries: '<',
      showActions: '<',
      onDelete: '<',
      fontSize: '<'
    }
  });

FeedTableController.$inject = ['$scope', '$attrs', 'hasPermission'];
function FeedTableController($scope, $attrs, hasPermission) {
  const vm = this;

  vm.hasFeedFullPermission = hasPermission('feed:full');
  vm.entityTimes = entityTimes;
  vm.deletingEntry = [];
  vm.hasEntries = hasEntries;
  vm.removeItem = removeItem;

  $scope.$watch('vm.entries', function () {
    if (!hasEntries()) {
      return;
    }

    if (!$attrs.$attr.hasOwnProperty('entryTypes')) {
      vm.entryTypes = getEntryTypesArray();
    }

    vm.entryTypes = angular.copy(vm.entryTypes).filter(type => {
      if (type.alwaysShowInFeedTable) {
        return true;
      } else {
        return flatten(Object.values(vm.entries[type.value]))?.length > 0;
      }
    });
  });

  function removeItem(item) {
    vm.deletingEntry[item.id] = true;
    vm.onDelete(item).finally(function() {
      vm.deletingEntry[item.id] = false;
    });
  }

  function hasEntries() {
    return !!flattenDeep(
      values(vm.entries).map(function(entries) {
        return values(entries);
      })
    ).length;
  }
}
