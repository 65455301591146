import {barnManagerModule} from '../../index.module';
import {isFunction, isNumber} from 'lodash';

barnManagerModule.directive('bmAttachFile', attachFile);

function attachFile() {

  AttachFile.$inject = ['$q', '$scope', '$window', 'imageTransformer', 'goNative'];

  return {
    restricted: 'E',
    templateUrl: 'attach-file.html',
    controller: AttachFile,
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      maskText: '@',
      replace: '=',
      useMask: '=',
      accept: '@',
      mode: '@',
      files: '=',
      text: '@',
      icon: '=',
      minorId: '<',
      onAdd: '<',
      onChange: '<'
    }
  };

  function AttachFile($q, $scope, $window, imageTransformer, goNative) {
    const vm = this;
    const url = $window.URL || $window.webkitURL;

    vm.uploading = false;
    vm.imageSrc = '';
    vm.hasFile = false;
    vm.isGoNative = goNative.isGoNative();

    function calcImageSrc(blob) {
      if (blob.type === '' || blob.type.match(/image.*/)) {
        return url.createObjectURL(blob);
      } else {
        return '';
      }
    }

    function blobFromFile(file) {
      return new Blob([file], {type: file.type});
    }

    function blobToUrl(blob) {
      const deferred = $q.defer();
      if (blob.type === '' || blob.type.match(/image.*/)) {
        imageTransformer.fixOrientation(blob, 240, 240, false, blob.type).then(function (imageUrl) {
          deferred.resolve(imageUrl);
        });
      } else {
        deferred.resolve(url.createObjectURL(blob));
      }
      return deferred.promise;
    }

    vm.$onInit = function () {
      $scope.$watch('vm.files', function (newVal, oldVal) {
        if (typeof newVal !== 'undefined') {
          if (newVal !== oldVal && newVal !== '' && newVal.name || newVal instanceof ArrayBuffer) {// eslint-disable-line
            vm.files = newVal;
            vm.imageSrc = calcImageSrc(blobFromFile(vm.files));
            vm.hasFile = true;
            if (vm.replace && typeof oldVal[0] !== 'undefined' && oldVal[0].id) {
              vm.replace.push(oldVal[0]);
            }
          } else {
            vm.files = newVal;
            vm.imageSrc = '';
            vm.hasFile = false;
          }
        }
      });

      $scope.$watch('vm.attachedFile', function (newVal) {
        if (typeof newVal !== 'undefined') {
          vm.uploading = true;
          const blob = blobFromFile(newVal);
          vm.hasFile = true;
          if (vm.icon) {
            vm.files = newVal;
            vm.imageSrc = calcImageSrc(blob);
            vm.uploading = false;
            if (isFunction(vm.onChange)) {
              vm.onChange(newVal);
            }
          } else {
            if (typeof vm.files === 'undefined') {
              vm.files = [];
            }
            if (vm.replace) {
              if (vm.mode === 'single') {
                vm.files.forEach(file => {
                  vm.replace.push(file);
                });
                vm.files = [];
              } else {
                vm.files.pop();
              }
            }

            blobToUrl(blob).then(function (blobUrl) {
              vm.files.push({
                minorId: isNumber(vm.minorId) ? vm.minorId : -1,
                ready: true,
                rawData: newVal,
                name: newVal.name,
                data: blob,
                contentType: blob.type,
                new: true,
                imageSrc: blobUrl
              });
              if (isFunction(vm.onAdd)) {
                vm.onAdd(vm.files[vm.files.length - 1]);
              }
              vm.uploading = false;
            });
          }
        }
      });
    };
  }
}
