import {barnManagerModule} from '../../index.module';

barnManagerModule.directive('bmRemindersPreviewArea', RemindersPreviewArea);

function RemindersPreviewArea() {
  return {
    restricted: 'E',
    templateUrl: 'reminders-preview-area.html',
    controller: RemindersPreviewAreaController,
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      reminders: '='
    }
  };

  function RemindersPreviewAreaController() {
  }
}
