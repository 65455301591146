import {barnManagerModule} from '../../index.module';

barnManagerModule
  .factory('ZoneToCC', function() {
    const zones = [{
      cc: 'AD', tz: 'Europe/Andorra' }, {
      cc: 'AE', tz: 'Asia/Dubai' }, {
      cc: 'AF', tz: 'Asia/Kabul' }, {
      cc: 'AG', tz: 'America/Antigua' }, {
      cc: 'AI', tz: 'America/Anguilla' }, {
      cc: 'AL', tz: 'Europe/Tirane' }, {
      cc: 'AM', tz: 'Asia/Yerevan' }, {
      cc: 'AO', tz: 'Africa/Luanda' }, {
      cc: 'AQ', tz: 'Antarctica/McMurdo' }, {
      cc: 'AQ', tz: 'Antarctica/Rothera' }, {
      cc: 'AQ', tz: 'Antarctica/Palmer' }, {
      cc: 'AQ', tz: 'Antarctica/Mawson' }, {
      cc: 'AQ', tz: 'Antarctica/Davis' }, {
      cc: 'AQ', tz: 'Antarctica/Casey' }, {
      cc: 'AQ', tz: 'Antarctica/Vostok' }, {
      cc: 'AQ', tz: 'Antarctica/DumontDUrville' }, {
      cc: 'AQ', tz: 'Antarctica/Syowa' }, {
      cc: 'AQ', tz: 'Antarctica/Troll' }, {
      cc: 'AR', tz: 'America/Argentina/Buenos_Aires' }, {
      cc: 'AR', tz: 'America/Argentina/Cordoba' }, {
      cc: 'AR', tz: 'America/Argentina/Salta' }, {
      cc: 'AR', tz: 'America/Argentina/Jujuy' }, {
      cc: 'AR', tz: 'America/Argentina/Tucuman' }, {
      cc: 'AR', tz: 'America/Argentina/Catamarca' }, {
      cc: 'AR', tz: 'America/Argentina/La_Rioja' }, {
      cc: 'AR', tz: 'America/Argentina/San_Juan' }, {
      cc: 'AR', tz: 'America/Argentina/Mendoza' }, {
      cc: 'AR', tz: 'America/Argentina/San_Luis' }, {
      cc: 'AR', tz: 'America/Argentina/Rio_Gallegos' }, {
      cc: 'AR', tz: 'America/Argentina/Ushuaia' }, {
      cc: 'AS', tz: 'Pacific/Pago_Pago' }, {
      cc: 'AT', tz: 'Europe/Vienna' }, {
      cc: 'AU', tz: 'Australia/Lord_Howe' }, {
      cc: 'AU', tz: 'Antarctica/Macquarie' }, {
      cc: 'AU', tz: 'Australia/Hobart' }, {
      cc: 'AU', tz: 'Australia/Currie' }, {
      cc: 'AU', tz: 'Australia/Melbourne' }, {
      cc: 'AU', tz: 'Australia/Sydney' }, {
      cc: 'AU', tz: 'Australia/Broken_Hill' }, {
      cc: 'AU', tz: 'Australia/Brisbane' }, {
      cc: 'AU', tz: 'Australia/Lindeman' }, {
      cc: 'AU', tz: 'Australia/Adelaide' }, {
      cc: 'AU', tz: 'Australia/Darwin' }, {
      cc: 'AU', tz: 'Australia/Perth' }, {
      cc: 'AU', tz: 'Australia/Eucla' }, {
      cc: 'AW', tz: 'America/Aruba' }, {
      cc: 'AX', tz: 'Europe/Mariehamn' }, {
      cc: 'AZ', tz: 'Asia/Baku' }, {
      cc: 'BA', tz: 'Europe/Sarajevo' }, {
      cc: 'BB', tz: 'America/Barbados' }, {
      cc: 'BD', tz: 'Asia/Dhaka' }, {
      cc: 'BE', tz: 'Europe/Brussels' }, {
      cc: 'BF', tz: 'Africa/Ouagadougou' }, {
      cc: 'BG', tz: 'Europe/Sofia' }, {
      cc: 'BH', tz: 'Asia/Bahrain' }, {
      cc: 'BI', tz: 'Africa/Bujumbura' }, {
      cc: 'BJ', tz: 'Africa/Porto-Novo' }, {
      cc: 'BL', tz: 'America/St_Barthelemy' }, {
      cc: 'BM', tz: 'Atlantic/Bermuda' }, {
      cc: 'BN', tz: 'Asia/Brunei' }, {
      cc: 'BO', tz: 'America/La_Paz' }, {
      cc: 'BQ', tz: 'America/Kralendijk' }, {
      cc: 'BR', tz: 'America/Noronha' }, {
      cc: 'BR', tz: 'America/Belem' }, {
      cc: 'BR', tz: 'America/Fortaleza' }, {
      cc: 'BR', tz: 'America/Recife' }, {
      cc: 'BR', tz: 'America/Araguaina' }, {
      cc: 'BR', tz: 'America/Maceio' }, {
      cc: 'BR', tz: 'America/Bahia' }, {
      cc: 'BR', tz: 'America/Sao_Paulo' }, {
      cc: 'BR', tz: 'America/Campo_Grande' }, {
      cc: 'BR', tz: 'America/Cuiaba' }, {
      cc: 'BR', tz: 'America/Santarem' }, {
      cc: 'BR', tz: 'America/Porto_Velho' }, {
      cc: 'BR', tz: 'America/Boa_Vista' }, {
      cc: 'BR', tz: 'America/Manaus' }, {
      cc: 'BR', tz: 'America/Eirunepe' }, {
      cc: 'BR', tz: 'America/Rio_Branco' }, {
      cc: 'BS', tz: 'America/Nassau' }, {
      cc: 'BT', tz: 'Asia/Thimphu' }, {
      cc: 'BW', tz: 'Africa/Gaborone' }, {
      cc: 'BY', tz: 'Europe/Minsk' }, {
      cc: 'BZ', tz: 'America/Belize' }, {
      cc: 'CA', tz: 'America/St_Johns' }, {
      cc: 'CA', tz: 'America/Halifax' }, {
      cc: 'CA', tz: 'America/Glace_Bay' }, {
      cc: 'CA', tz: 'America/Moncton' }, {
      cc: 'CA', tz: 'America/Goose_Bay' }, {
      cc: 'CA', tz: 'America/Blanc-Sablon' }, {
      cc: 'CA', tz: 'America/Toronto' }, {
      cc: 'CA', tz: 'America/Nipigon' }, {
      cc: 'CA', tz: 'America/Thunder_Bay' }, {
      cc: 'CA', tz: 'America/Iqaluit' }, {
      cc: 'CA', tz: 'America/Pangnirtung' }, {
      cc: 'CA', tz: 'America/Resolute' }, {
      cc: 'CA', tz: 'America/Atikokan' }, {
      cc: 'CA', tz: 'America/Rankin_Inlet' }, {
      cc: 'CA', tz: 'America/Winnipeg' }, {
      cc: 'CA', tz: 'America/Rainy_River' }, {
      cc: 'CA', tz: 'America/Regina' }, {
      cc: 'CA', tz: 'America/Swift_Current' }, {
      cc: 'CA', tz: 'America/Edmonton' }, {
      cc: 'CA', tz: 'America/Cambridge_Bay' }, {
      cc: 'CA', tz: 'America/Yellowknife' }, {
      cc: 'CA', tz: 'America/Inuvik' }, {
      cc: 'CA', tz: 'America/Creston' }, {
      cc: 'CA', tz: 'America/Dawson_Creek' }, {
      cc: 'CA', tz: 'America/Vancouver' }, {
      cc: 'CA', tz: 'America/Whitehorse' }, {
      cc: 'CA', tz: 'America/Dawson' }, {
      cc: 'CC', tz: 'Indian/Cocos' }, {
      cc: 'CD', tz: 'Africa/Kinshasa' }, {
      cc: 'CD', tz: 'Africa/Lubumbashi' }, {
      cc: 'CF', tz: 'Africa/Bangui' }, {
      cc: 'CG', tz: 'Africa/Brazzaville' }, {
      cc: 'CH', tz: 'Europe/Zurich' }, {
      cc: 'CI', tz: 'Africa/Abidjan' }, {
      cc: 'CK', tz: 'Pacific/Rarotonga' }, {
      cc: 'CL', tz: 'America/Santiago' }, {
      cc: 'CL', tz: 'Pacific/Easter' }, {
      cc: 'CM', tz: 'Africa/Douala' }, {
      cc: 'CN', tz: 'Asia/Shanghai' }, {
      cc: 'CN', tz: 'Asia/Harbin' }, {
      cc: 'CN', tz: 'Asia/Chongqing' }, {
      cc: 'CN', tz: 'Asia/Urumqi' }, {
      cc: 'CN', tz: 'Asia/Kashgar' }, {
      cc: 'CO', tz: 'America/Bogota' }, {
      cc: 'CR', tz: 'America/Costa_Rica' }, {
      cc: 'CU', tz: 'America/Havana' }, {
      cc: 'CV', tz: 'Atlantic/Cape_Verde' }, {
      cc: 'CW', tz: 'America/Curacao' }, {
      cc: 'CX', tz: 'Indian/Christmas' }, {
      cc: 'CY', tz: 'Asia/Nicosia' }, {
      cc: 'CZ', tz: 'Europe/Prague' }, {
      cc: 'DE', tz: 'Europe/Berlin' }, {
      cc: 'DE', tz: 'Europe/Busingen' }, {
      cc: 'DJ', tz: 'Africa/Djibouti' }, {
      cc: 'DK', tz: 'Europe/Copenhagen' }, {
      cc: 'DM', tz: 'America/Dominica' }, {
      cc: 'DO', tz: 'America/Santo_Domingo' }, {
      cc: 'DZ', tz: 'Africa/Algiers' }, {
      cc: 'EC', tz: 'America/Guayaquil' }, {
      cc: 'EC', tz: 'Pacific/Galapagos' }, {
      cc: 'EE', tz: 'Europe/Tallinn' }, {
      cc: 'EG', tz: 'Africa/Cairo' }, {
      cc: 'EH', tz: 'Africa/El_Aaiun' }, {
      cc: 'ER', tz: 'Africa/Asmara' }, {
      cc: 'ES', tz: 'Europe/Madrid' }, {
      cc: 'ES', tz: 'Africa/Ceuta' }, {
      cc: 'ES', tz: 'Atlantic/Canary' }, {
      cc: 'ET', tz: 'Africa/Addis_Ababa' }, {
      cc: 'FI', tz: 'Europe/Helsinki' }, {
      cc: 'FJ', tz: 'Pacific/Fiji' }, {
      cc: 'FK', tz: 'Atlantic/Stanley' }, {
      cc: 'FM', tz: 'Pacific/Chuuk' }, {
      cc: 'FM', tz: 'Pacific/Pohnpei' }, {
      cc: 'FM', tz: 'Pacific/Kosrae' }, {
      cc: 'FO', tz: 'Atlantic/Faroe' }, {
      cc: 'FR', tz: 'Europe/Paris' }, {
      cc: 'GA', tz: 'Africa/Libreville' }, {
      cc: 'GB', tz: 'Europe/London' }, {
      cc: 'GD', tz: 'America/Grenada' }, {
      cc: 'GE', tz: 'Asia/Tbilisi' }, {
      cc: 'GF', tz: 'America/Cayenne' }, {
      cc: 'GG', tz: 'Europe/Guernsey' }, {
      cc: 'GH', tz: 'Africa/Accra' }, {
      cc: 'GI', tz: 'Europe/Gibraltar' }, {
      cc: 'GL', tz: 'America/Godthab' }, {
      cc: 'GL', tz: 'America/Danmarkshavn' }, {
      cc: 'GL', tz: 'America/Scoresbysund' }, {
      cc: 'GL', tz: 'America/Thule' }, {
      cc: 'GM', tz: 'Africa/Banjul' }, {
      cc: 'GN', tz: 'Africa/Conakry' }, {
      cc: 'GP', tz: 'America/Guadeloupe' }, {
      cc: 'GQ', tz: 'Africa/Malabo' }, {
      cc: 'GR', tz: 'Europe/Athens' }, {
      cc: 'GS', tz: 'Atlantic/South_Georgia' }, {
      cc: 'GT', tz: 'America/Guatemala' }, {
      cc: 'GU', tz: 'Pacific/Guam' }, {
      cc: 'GW', tz: 'Africa/Bissau' }, {
      cc: 'GY', tz: 'America/Guyana' }, {
      cc: 'HK', tz: 'Asia/Hong_Kong' }, {
      cc: 'HN', tz: 'America/Tegucigalpa' }, {
      cc: 'HR', tz: 'Europe/Zagreb' }, {
      cc: 'HT', tz: 'America/Port-au-Prince' }, {
      cc: 'HU', tz: 'Europe/Budapest' }, {
      cc: 'ID', tz: 'Asia/Jakarta' }, {
      cc: 'ID', tz: 'Asia/Pontianak' }, {
      cc: 'ID', tz: 'Asia/Makassar' }, {
      cc: 'ID', tz: 'Asia/Jayapura' }, {
      cc: 'IE', tz: 'Europe/Dublin' }, {
      cc: 'IL', tz: 'Asia/Jerusalem' }, {
      cc: 'IM', tz: 'Europe/Isle_of_Man' }, {
      cc: 'IN', tz: 'Asia/Kolkata' }, {
      cc: 'IO', tz: 'Indian/Chagos' }, {
      cc: 'IQ', tz: 'Asia/Baghdad' }, {
      cc: 'IR', tz: 'Asia/Tehran' }, {
      cc: 'IS', tz: 'Atlantic/Reykjavik' }, {
      cc: 'IT', tz: 'Europe/Rome' }, {
      cc: 'JE', tz: 'Europe/Jersey' }, {
      cc: 'JM', tz: 'America/Jamaica' }, {
      cc: 'JO', tz: 'Asia/Amman' }, {
      cc: 'JP', tz: 'Asia/Tokyo' }, {
      cc: 'KE', tz: 'Africa/Nairobi' }, {
      cc: 'KG', tz: 'Asia/Bishkek' }, {
      cc: 'KH', tz: 'Asia/Phnom_Penh' }, {
      cc: 'KI', tz: 'Pacific/Tarawa' }, {
      cc: 'KI', tz: 'Pacific/Enderbury' }, {
      cc: 'KI', tz: 'Pacific/Kiritimati' }, {
      cc: 'KM', tz: 'Indian/Comoro' }, {
      cc: 'KN', tz: 'America/St_Kitts' }, {
      cc: 'KP', tz: 'Asia/Pyongyang' }, {
      cc: 'KR', tz: 'Asia/Seoul' }, {
      cc: 'KW', tz: 'Asia/Kuwait' }, {
      cc: 'KY', tz: 'America/Cayman' }, {
      cc: 'KZ', tz: 'Asia/Almaty' }, {
      cc: 'KZ', tz: 'Asia/Qyzylorda' }, {
      cc: 'KZ', tz: 'Asia/Aqtobe' }, {
      cc: 'KZ', tz: 'Asia/Aqtau' }, {
      cc: 'KZ', tz: 'Asia/Oral' }, {
      cc: 'LA', tz: 'Asia/Vientiane' }, {
      cc: 'LB', tz: 'Asia/Beirut' }, {
      cc: 'LC', tz: 'America/St_Lucia' }, {
      cc: 'LI', tz: 'Europe/Vaduz' }, {
      cc: 'LK', tz: 'Asia/Colombo' }, {
      cc: 'LR', tz: 'Africa/Monrovia' }, {
      cc: 'LS', tz: 'Africa/Maseru' }, {
      cc: 'LT', tz: 'Europe/Vilnius' }, {
      cc: 'LU', tz: 'Europe/Luxembourg' }, {
      cc: 'LV', tz: 'Europe/Riga' }, {
      cc: 'LY', tz: 'Africa/Tripoli' }, {
      cc: 'MA', tz: 'Africa/Casablanca' }, {
      cc: 'MC', tz: 'Europe/Monaco' }, {
      cc: 'MD', tz: 'Europe/Chisinau' }, {
      cc: 'ME', tz: 'Europe/Podgorica' }, {
      cc: 'MF', tz: 'America/Marigot' }, {
      cc: 'MG', tz: 'Indian/Antananarivo' }, {
      cc: 'MH', tz: 'Pacific/Majuro' }, {
      cc: 'MH', tz: 'Pacific/Kwajalein' }, {
      cc: 'MK', tz: 'Europe/Skopje' }, {
      cc: 'ML', tz: 'Africa/Bamako' }, {
      cc: 'MM', tz: 'Asia/Rangoon' }, {
      cc: 'MN', tz: 'Asia/Ulaanbaatar' }, {
      cc: 'MN', tz: 'Asia/Hovd' }, {
      cc: 'MN', tz: 'Asia/Choibalsan' }, {
      cc: 'MO', tz: 'Asia/Macau' }, {
      cc: 'MP', tz: 'Pacific/Saipan' }, {
      cc: 'MQ', tz: 'America/Martinique' }, {
      cc: 'MR', tz: 'Africa/Nouakchott' }, {
      cc: 'MS', tz: 'America/Montserrat' }, {
      cc: 'MT', tz: 'Europe/Malta' }, {
      cc: 'MU', tz: 'Indian/Mauritius' }, {
      cc: 'MV', tz: 'Indian/Maldives' }, {
      cc: 'MW', tz: 'Africa/Blantyre' }, {
      cc: 'MX', tz: 'America/Mexico_City' }, {
      cc: 'MX', tz: 'America/Cancun' }, {
      cc: 'MX', tz: 'America/Merida' }, {
      cc: 'MX', tz: 'America/Monterrey' }, {
      cc: 'MX', tz: 'America/Matamoros' }, {
      cc: 'MX', tz: 'America/Mazatlan' }, {
      cc: 'MX', tz: 'America/Chihuahua' }, {
      cc: 'MX', tz: 'America/Ojinaga' }, {
      cc: 'MX', tz: 'America/Hermosillo' }, {
      cc: 'MX', tz: 'America/Tijuana' }, {
      cc: 'MX', tz: 'America/Santa_Isabel' }, {
      cc: 'MX', tz: 'America/Bahia_Banderas' }, {
      cc: 'MY', tz: 'Asia/Kuala_Lumpur' }, {
      cc: 'MY', tz: 'Asia/Kuching' }, {
      cc: 'MZ', tz: 'Africa/Maputo' }, {
      cc: 'NA', tz: 'Africa/Windhoek' }, {
      cc: 'NC', tz: 'Pacific/Noumea' }, {
      cc: 'NE', tz: 'Africa/Niamey' }, {
      cc: 'NF', tz: 'Pacific/Norfolk' }, {
      cc: 'NG', tz: 'Africa/Lagos' }, {
      cc: 'NI', tz: 'America/Managua' }, {
      cc: 'NL', tz: 'Europe/Amsterdam' }, {
      cc: 'NO', tz: 'Europe/Oslo' }, {
      cc: 'NP', tz: 'Asia/Kathmandu' }, {
      cc: 'NR', tz: 'Pacific/Nauru' }, {
      cc: 'NU', tz: 'Pacific/Niue' }, {
      cc: 'NZ', tz: 'Pacific/Auckland' }, {
      cc: 'NZ', tz: 'Pacific/Chatham' }, {
      cc: 'OM', tz: 'Asia/Muscat' }, {
      cc: 'PA', tz: 'America/Panama' }, {
      cc: 'PE', tz: 'America/Lima' }, {
      cc: 'PF', tz: 'Pacific/Tahiti' }, {
      cc: 'PF', tz: 'Pacific/Marquesas' }, {
      cc: 'PF', tz: 'Pacific/Gambier' }, {
      cc: 'PG', tz: 'Pacific/Port_Moresby' }, {
      cc: 'PH', tz: 'Asia/Manila' }, {
      cc: 'PK', tz: 'Asia/Karachi' }, {
      cc: 'PL', tz: 'Europe/Warsaw' }, {
      cc: 'PM', tz: 'America/Miquelon' }, {
      cc: 'PN', tz: 'Pacific/Pitcairn' }, {
      cc: 'PR', tz: 'America/Puerto_Rico' }, {
      cc: 'PS', tz: 'Asia/Gaza' }, {
      cc: 'PS', tz: 'Asia/Hebron' }, {
      cc: 'PT', tz: 'Europe/Lisbon' }, {
      cc: 'PT', tz: 'Atlantic/Madeira' }, {
      cc: 'PT', tz: 'Atlantic/Azores' }, {
      cc: 'PW', tz: 'Pacific/Palau' }, {
      cc: 'PY', tz: 'America/Asuncion' }, {
      cc: 'QA', tz: 'Asia/Qatar' }, {
      cc: 'RE', tz: 'Indian/Reunion' }, {
      cc: 'RO', tz: 'Europe/Bucharest' }, {
      cc: 'RS', tz: 'Europe/Belgrade' }, {
      cc: 'RW', tz: 'Africa/Kigali' }, {
      cc: 'SA', tz: 'Asia/Riyadh' }, {
      cc: 'SB', tz: 'Pacific/Guadalcanal' }, {
      cc: 'SC', tz: 'Indian/Mahe' }, {
      cc: 'SD', tz: 'Africa/Khartoum' }, {
      cc: 'SE', tz: 'Europe/Stockholm' }, {
      cc: 'SG', tz: 'Asia/Singapore' }, {
      cc: 'SH', tz: 'Atlantic/St_Helena' }, {
      cc: 'SI', tz: 'Europe/Ljubljana' }, {
      cc: 'SJ', tz: 'Arctic/Longyearbyen' }, {
      cc: 'SK', tz: 'Europe/Bratislava' }, {
      cc: 'SL', tz: 'Africa/Freetown' }, {
      cc: 'SM', tz: 'Europe/San_Marino' }, {
      cc: 'SN', tz: 'Africa/Dakar' }, {
      cc: 'SO', tz: 'Africa/Mogadishu' }, {
      cc: 'SR', tz: 'America/Paramaribo' }, {
      cc: 'SS', tz: 'Africa/Juba' }, {
      cc: 'ST', tz: 'Africa/Sao_Tome' }, {
      cc: 'SV', tz: 'America/El_Salvador' }, {
      cc: 'SX', tz: 'America/Lower_Princes' }, {
      cc: 'SY', tz: 'Asia/Damascus' }, {
      cc: 'SZ', tz: 'Africa/Mbabane' }, {
      cc: 'TC', tz: 'America/Grand_Turk' }, {
      cc: 'TD', tz: 'Africa/Ndjamena' }, {
      cc: 'TF', tz: 'Indian/Kerguelen' }, {
      cc: 'TG', tz: 'Africa/Lome' }, {
      cc: 'TH', tz: 'Asia/Bangkok' }, {
      cc: 'TJ', tz: 'Asia/Dushanbe' }, {
      cc: 'TK', tz: 'Pacific/Fakaofo' }, {
      cc: 'TL', tz: 'Asia/Dili' }, {
      cc: 'TM', tz: 'Asia/Ashgabat' }, {
      cc: 'TN', tz: 'Africa/Tunis' }, {
      cc: 'TO', tz: 'Pacific/Tongatapu' }, {
      cc: 'TR', tz: 'Europe/Istanbul' }, {
      cc: 'TT', tz: 'America/Port_of_Spain' }, {
      cc: 'TV', tz: 'Pacific/Funafuti' }, {
      cc: 'TW', tz: 'Asia/Taipei' }, {
      cc: 'TZ', tz: 'Africa/Dar_es_Salaam' }, {
      cc: 'UA', tz: 'Europe/Kiev' }, {
      cc: 'UA', tz: 'Europe/Uzhgorod' }, {
      cc: 'UA', tz: 'Europe/Zaporozhye' }, {
      cc: 'UG', tz: 'Africa/Kampala' }, {
      cc: 'UM', tz: 'Pacific/Johnston' }, {
      cc: 'UM', tz: 'Pacific/Midway' }, {
      cc: 'UM', tz: 'Pacific/Wake' }, {
      cc: 'US', tz: 'America/New_York' }, {
      cc: 'US', tz: 'America/Detroit' }, {
      cc: 'US', tz: 'America/Kentucky/Louisville' }, {
      cc: 'US', tz: 'America/Kentucky/Monticello' }, {
      cc: 'US', tz: 'America/Indiana/Indianapolis' }, {
      cc: 'US', tz: 'America/Indiana/Vincennes' }, {
      cc: 'US', tz: 'America/Indiana/Winamac' }, {
      cc: 'US', tz: 'America/Indiana/Marengo' }, {
      cc: 'US', tz: 'America/Indiana/Petersburg' }, {
      cc: 'US', tz: 'America/Indiana/Vevay' }, {
      cc: 'US', tz: 'America/Chicago' }, {
      cc: 'US', tz: 'America/Indiana/Tell_City' }, {
      cc: 'US', tz: 'America/Indiana/Knox' }, {
      cc: 'US', tz: 'America/Menominee' }, {
      cc: 'US', tz: 'America/North_Dakota/Center' }, {
      cc: 'US', tz: 'America/North_Dakota/New_Salem' }, {
      cc: 'US', tz: 'America/North_Dakota/Beulah' }, {
      cc: 'US', tz: 'America/Denver' }, {
      cc: 'US', tz: 'America/Boise' }, {
      cc: 'US', tz: 'America/Phoenix' }, {
      cc: 'US', tz: 'America/Los_Angeles' }, {
      cc: 'US', tz: 'America/Anchorage' }, {
      cc: 'US', tz: 'America/Juneau' }, {
      cc: 'US', tz: 'America/Sitka' }, {
      cc: 'US', tz: 'America/Yakutat' }, {
      cc: 'US', tz: 'America/Nome' }, {
      cc: 'US', tz: 'America/Adak' }, {
      cc: 'US', tz: 'America/Metlakatla' }, {
      cc: 'US', tz: 'Pacific/Honolulu' }, {
      cc: 'UY', tz: 'America/Montevideo' }, {
      cc: 'UZ', tz: 'Asia/Samarkand' }, {
      cc: 'UZ', tz: 'Asia/Tashkent' }, {
      cc: 'VA', tz: 'Europe/Vatican' }, {
      cc: 'VC', tz: 'America/St_Vincent' }, {
      cc: 'VE', tz: 'America/Caracas' }, {
      cc: 'VG', tz: 'America/Tortola' }, {
      cc: 'VI', tz: 'America/St_Thomas' }, {
      cc: 'VN', tz: 'Asia/Ho_Chi_Minh' }, {
      cc: 'VU', tz: 'Pacific/Efate' }, {
      cc: 'WF', tz: 'Pacific/Wallis' }, {
      cc: 'WS', tz: 'Pacific/Apia' }, {
      cc: 'YE', tz: 'Asia/Aden' }, {
      cc: 'YT', tz: 'Indian/Mayotte' }, {
      cc: 'ZA', tz: 'Africa/Johannesburg' }, {
      cc: 'ZM', tz: 'Africa/Lusaka' }, {
      cc: 'ZW', tz: 'Africa/Harare' }, {
      cc: 'CA', tz: 'America/Montreal'
    }];
    return zones.reduce(function(acc, zone) {
      acc[zone.tz] = zone.cc;
      return acc;
    }, {});
  })

  // Country code to country name map
  .factory('CCToCountryName', function() {
    const codes = [{
      cc: 'AF', name: 'Afghanistan' }, {
      cc: 'AX', name: 'Aland Islands' }, {
      cc: 'AL', name: 'Albania' }, {
      cc: 'DZ', name: 'Algeria' }, {
      cc: 'AS', name: 'American Samoa' }, {
      cc: 'AD', name: 'Andorra' }, {
      cc: 'AO', name: 'Angola' }, {
      cc: 'AI', name: 'Anguilla' }, {
      cc: 'AQ', name: 'Antarctica' }, {
      cc: 'AG', name: 'Antigua And Barbuda' }, {
      cc: 'AR', name: 'Argentina' }, {
      cc: 'AM', name: 'Armenia' }, {
      cc: 'AW', name: 'Aruba' }, {
      cc: 'AU', name: 'Australia' }, {
      cc: 'AT', name: 'Austria' }, {
      cc: 'AZ', name: 'Azerbaijan' }, {
      cc: 'BS', name: 'Bahamas' }, {
      cc: 'BH', name: 'Bahrain' }, {
      cc: 'BD', name: 'Bangladesh' }, {
      cc: 'BB', name: 'Barbados' }, {
      cc: 'BY', name: 'Belarus' }, {
      cc: 'BE', name: 'Belgium' }, {
      cc: 'BZ', name: 'Belize' }, {
      cc: 'BJ', name: 'Benin' }, {
      cc: 'BM', name: 'Bermuda' }, {
      cc: 'BT', name: 'Bhutan' }, {
      cc: 'BO', name: 'Bolivia' }, {
      cc: 'BA', name: 'Bosnia And Herzegovina' }, {
      cc: 'BW', name: 'Botswana' }, {
      cc: 'BV', name: 'Bouvet Island' }, {
      cc: 'BR', name: 'Brazil' }, {
      cc: 'IO', name: 'British Indian Ocean Territory' }, {
      cc: 'BN', name: 'Brunei Darussalam' }, {
      cc: 'BG', name: 'Bulgaria' }, {
      cc: 'BF', name: 'Burkina Faso' }, {
      cc: 'BI', name: 'Burundi' }, {
      cc: 'KH', name: 'Cambodia' }, {
      cc: 'CM', name: 'Cameroon' }, {
      cc: 'CA', name: 'Canada' }, {
      cc: 'CV', name: 'Cape Verde' }, {
      cc: 'KY', name: 'Cayman Islands' }, {
      cc: 'CF', name: 'Central African Republic' }, {
      cc: 'TD', name: 'Chad' }, {
      cc: 'CL', name: 'Chile' }, {
      cc: 'CN', name: 'China' }, {
      cc: 'CX', name: 'Christmas Island' }, {
      cc: 'CC', name: 'Cocos (Keeling) Islands' }, {
      cc: 'CO', name: 'Colombia' }, {
      cc: 'KM', name: 'Comoros' }, {
      cc: 'CG', name: 'Congo' }, {
      cc: 'CD', name: 'Congo (Democratic Republic)' }, {
      cc: 'CK', name: 'Cook Islands' }, {
      cc: 'CR', name: 'Costa Rica' }, {
      cc: 'CI', name: 'Cote D\'Ivoire' }, {
      cc: 'HR', name: 'Croatia' }, {
      cc: 'CU', name: 'Cuba' }, {
      cc: 'CY', name: 'Cyprus' }, {
      cc: 'CZ', name: 'Czech Republic' }, {
      cc: 'DK', name: 'Denmark' }, {
      cc: 'DJ', name: 'Djibouti' }, {
      cc: 'DM', name: 'Dominica' }, {
      cc: 'DO', name: 'Dominican Republic' }, {
      cc: 'EC', name: 'Ecuador' }, {
      cc: 'EG', name: 'Egypt' }, {
      cc: 'SV', name: 'El Salvador' }, {
      cc: 'GQ', name: 'Equatorial Guinea' }, {
      cc: 'ER', name: 'Eritrea' }, {
      cc: 'EE', name: 'Estonia' }, {
      cc: 'ET', name: 'Ethiopia' }, {
      cc: 'FK', name: 'Falkland Islands (Malvinas)' }, {
      cc: 'FO', name: 'Faroe Islands' }, {
      cc: 'FJ', name: 'Fiji' }, {
      cc: 'FI', name: 'Finland' }, {
      cc: 'FR', name: 'France' }, {
      cc: 'GF', name: 'French Guiana' }, {
      cc: 'PF', name: 'French Polynesia' }, {
      cc: 'TF', name: 'French Southern Territories' }, {
      cc: 'GA', name: 'Gabon' }, {
      cc: 'GM', name: 'Gambia' }, {
      cc: 'GE', name: 'Georgia' }, {
      cc: 'DE', name: 'Germany' }, {
      cc: 'GH', name: 'Ghana' }, {
      cc: 'GI', name: 'Gibraltar' }, {
      cc: 'GR', name: 'Greece' }, {
      cc: 'GL', name: 'Greenland' }, {
      cc: 'GD', name: 'Grenada' }, {
      cc: 'GP', name: 'Guadeloupe' }, {
      cc: 'GU', name: 'Guam' }, {
      cc: 'GT', name: 'Guatemala' }, {
      cc: 'GG', name: 'Guernsey' }, {
      cc: 'GN', name: 'Guinea' }, {
      cc: 'GW', name: 'Guinea-Bissau' }, {
      cc: 'GY', name: 'Guyana' }, {
      cc: 'HT', name: 'Haiti' }, {
      cc: 'HM', name: 'Heard Island & Mcdonald Islands' }, {
      cc: 'VA', name: 'Holy See (Vatican City State)' }, {
      cc: 'HN', name: 'Honduras' }, {
      cc: 'HK', name: 'Hong Kong' }, {
      cc: 'HU', name: 'Hungary' }, {
      cc: 'IS', name: 'Iceland' }, {
      cc: 'IN', name: 'India' }, {
      cc: 'ID', name: 'Indonesia' }, {
      cc: 'IR', name: 'Iran (Islamic Republic Of)' }, {
      cc: 'IQ', name: 'Iraq' }, {
      cc: 'IE', name: 'Ireland' }, {
      cc: 'IM', name: 'Isle Of Man' }, {
      cc: 'IL', name: 'Israel' }, {
      cc: 'IT', name: 'Italy' }, {
      cc: 'JM', name: 'Jamaica' }, {
      cc: 'JP', name: 'Japan' }, {
      cc: 'JE', name: 'Jersey' }, {
      cc: 'JO', name: 'Jordan' }, {
      cc: 'KZ', name: 'Kazakhstan' }, {
      cc: 'KE', name: 'Kenya' }, {
      cc: 'KI', name: 'Kiribati' }, {
      cc: 'KR', name: 'Korea' }, {
      cc: 'KW', name: 'Kuwait' }, {
      cc: 'KG', name: 'Kyrgyzstan' }, {
      cc: 'LA', name: 'Lao People\'s Democratic Republic' }, {
      cc: 'LV', name: 'Latvia' }, {
      cc: 'LB', name: 'Lebanon' }, {
      cc: 'LS', name: 'Lesotho' }, {
      cc: 'LR', name: 'Liberia' }, {
      cc: 'LY', name: 'Libyan Arab Jamahiriya' }, {
      cc: 'LI', name: 'Liechtenstein' }, {
      cc: 'LT', name: 'Lithuania' }, {
      cc: 'LU', name: 'Luxembourg' }, {
      cc: 'MO', name: 'Macao' }, {
      cc: 'MK', name: 'Macedonia' }, {
      cc: 'MG', name: 'Madagascar' }, {
      cc: 'MW', name: 'Malawi' }, {
      cc: 'MY', name: 'Malaysia' }, {
      cc: 'MV', name: 'Maldives' }, {
      cc: 'ML', name: 'Mali' }, {
      cc: 'MT', name: 'Malta' }, {
      cc: 'MH', name: 'Marshall Islands' }, {
      cc: 'MQ', name: 'Martinique' }, {
      cc: 'MR', name: 'Mauritania' }, {
      cc: 'MU', name: 'Mauritius' }, {
      cc: 'YT', name: 'Mayotte' }, {
      cc: 'MX', name: 'Mexico' }, {
      cc: 'FM', name: 'Micronesia (Federated States Of)' }, {
      cc: 'MD', name: 'Moldova' }, {
      cc: 'MC', name: 'Monaco' }, {
      cc: 'MN', name: 'Mongolia' }, {
      cc: 'ME', name: 'Montenegro' }, {
      cc: 'MS', name: 'Montserrat' }, {
      cc: 'MA', name: 'Morocco' }, {
      cc: 'MZ', name: 'Mozambique' }, {
      cc: 'MM', name: 'Myanmar' }, {
      cc: 'NA', name: 'Namibia' }, {
      cc: 'NR', name: 'Nauru' }, {
      cc: 'NP', name: 'Nepal' }, {
      cc: 'NL', name: 'Netherlands' }, {
      cc: 'AN', name: 'Netherlands Antilles' }, {
      cc: 'NC', name: 'New Caledonia' }, {
      cc: 'NZ', name: 'New Zealand' }, {
      cc: 'NI', name: 'Nicaragua' }, {
      cc: 'NE', name: 'Niger' }, {
      cc: 'NG', name: 'Nigeria' }, {
      cc: 'NU', name: 'Niue' }, {
      cc: 'NF', name: 'Norfolk Island' }, {
      cc: 'MP', name: 'Northern Mariana Islands' }, {
      cc: 'NO', name: 'Norway' }, {
      cc: 'OM', name: 'Oman' }, {
      cc: 'PK', name: 'Pakistan' }, {
      cc: 'PW', name: 'Palau' }, {
      cc: 'PS', name: 'Palestinian Territory (Occupied)' }, {
      cc: 'PA', name: 'Panama' }, {
      cc: 'PG', name: 'Papua New Guinea' }, {
      cc: 'PY', name: 'Paraguay' }, {
      cc: 'PE', name: 'Peru' }, {
      cc: 'PH', name: 'Philippines' }, {
      cc: 'PN', name: 'Pitcairn' }, {
      cc: 'PL', name: 'Poland' }, {
      cc: 'PT', name: 'Portugal' }, {
      cc: 'PR', name: 'Puerto Rico' }, {
      cc: 'QA', name: 'Qatar' }, {
      cc: 'RE', name: 'Reunion' }, {
      cc: 'RO', name: 'Romania' }, {
      cc: 'RW', name: 'Rwanda' }, {
      cc: 'BL', name: 'Saint Barthelemy' }, {
      cc: 'SH', name: 'Saint Helena' }, {
      cc: 'KN', name: 'Saint Kitts And Nevis' }, {
      cc: 'LC', name: 'Saint Lucia' }, {
      cc: 'MF', name: 'Saint Martin' }, {
      cc: 'PM', name: 'Saint Pierre And Miquelon' }, {
      cc: 'VC', name: 'Saint Vincent And Grenadines' }, {
      cc: 'WS', name: 'Samoa' }, {
      cc: 'SM', name: 'San Marino' }, {
      cc: 'ST', name: 'Sao Tome And Principe' }, {
      cc: 'SA', name: 'Saudi Arabia' }, {
      cc: 'SN', name: 'Senegal' }, {
      cc: 'RS', name: 'Serbia' }, {
      cc: 'SC', name: 'Seychelles' }, {
      cc: 'SL', name: 'Sierra Leone' }, {
      cc: 'SG', name: 'Singapore' }, {
      cc: 'SK', name: 'Slovakia' }, {
      cc: 'SI', name: 'Slovenia' }, {
      cc: 'SB', name: 'Solomon Islands' }, {
      cc: 'SO', name: 'Somalia' }, {
      cc: 'SS', name: 'South Sudan' }, {
      cc: 'ZA', name: 'South Africa' }, {
      cc: 'GS', name: 'South Georgia And Sandwich Isl.' }, {
      cc: 'ES', name: 'Spain' }, {
      cc: 'LK', name: 'Sri Lanka' }, {
      cc: 'SD', name: 'Sudan' }, {
      cc: 'SR', name: 'Suriname' }, {
      cc: 'SJ', name: 'Svalbard And Jan Mayen' }, {
      cc: 'SZ', name: 'Swaziland' }, {
      cc: 'SE', name: 'Sweden' }, {
      cc: 'CH', name: 'Switzerland' }, {
      cc: 'SY', name: 'Syrian Arab Republic' }, {
      cc: 'TW', name: 'Taiwan' }, {
      cc: 'TJ', name: 'Tajikistan' }, {
      cc: 'TZ', name: 'Tanzania' }, {
      cc: 'TH', name: 'Thailand' }, {
      cc: 'TL', name: 'Timor-Leste' }, {
      cc: 'TG', name: 'Togo' }, {
      cc: 'TK', name: 'Tokelau' }, {
      cc: 'TO', name: 'Tonga' }, {
      cc: 'TT', name: 'Trinidad And Tobago' }, {
      cc: 'TN', name: 'Tunisia' }, {
      cc: 'TR', name: 'Turkey' }, {
      cc: 'TM', name: 'Turkmenistan' }, {
      cc: 'TC', name: 'Turks And Caicos Islands' }, {
      cc: 'TV', name: 'Tuvalu' }, {
      cc: 'UG', name: 'Uganda' }, {
      cc: 'UA', name: 'Ukraine' }, {
      cc: 'AE', name: 'United Arab Emirates' }, {
      cc: 'GB', name: 'United Kingdom' }, {
      cc: 'US', name: 'United States' }, {
      cc: 'UM', name: 'United States Outlying Islands' }, {
      cc: 'UY', name: 'Uruguay' }, {
      cc: 'UZ', name: 'Uzbekistan' }, {
      cc: 'VU', name: 'Vanuatu' }, {
      cc: 'VE', name: 'Venezuela' }, {
      cc: 'VN', name: 'Viet Nam' }, {
      cc: 'VG', name: 'Virgin Islands (British)' }, {
      cc: 'VI', name: 'Virgin Islands (U.S.)' }, {
      cc: 'WF', name: 'Wallis And Futuna' }, {
      cc: 'EH', name: 'Western Sahara' }, {
      cc: 'YE', name: 'Yemen' }, {
      cc: 'ZM', name: 'Zambia' }, {
      cc: 'ZW', name: 'Zimbabwe' }, {
      cc: 'SX', name: 'Sint Maarten' }, {
      cc: 'CW', name: 'Curacao' }, {
      cc: 'BQ', name: 'Bonaire' }, {
      cc: 'KP', name: 'North Korea'
    }];
    return codes.reduce(function(acc, code) {
      acc[code.cc] = code.name;
      return acc;
    }, {});
  });

