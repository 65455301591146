(function() {
  'use strict';

  angular
    .module('barnManager')
    .constant('PlanOptions', {
      starter: [
        '14 Day Free Trial',
        'Unlimited Users',
        'Unlimited Horses',
        'Free Mobile Apps',
        'Detailed Horse Profiles',
        'Team Conversations',
        'List Maker',
        'Contacts Management',
        'Document Storage',
        'Digital Whiteboard'
      ],
      essential: [
        '14 Day Free Trial',
        'Unlimited Users',
        'Unlimited Horses',
        'Free Mobile Apps',
        'Everything in Starter Plus:',
        'Organized and Searchable Digital Records',
        'Shared Calendar for Detailed Scheduling',
        'Vendor Management',
        'Feed, Supplement and Medication Management',
        'Event and Expiration Reminders'
      ],
      pro: [
        '30 Day Free Trial',
        'Everything in Essentials Plus:',
        'Easy Invoice Creation',
        'Automated Recurring Invoices',
        'Custom Quick Add Settings',
        'Split Charges Across Invoices',
        'Easy 5 Minute Set Up',
        'Safe and Secure Payments',
        'Comprehensive Revenue Tracking and Analysis',
        'Integrate with Quickbooks Online'
      ]
    });

})();
