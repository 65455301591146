import {barnManagerModule} from '../../index.module';

barnManagerModule
  .constant('Countries', [
    {
      key: 'Australia',
      value: 'Australia'
    },
    {
      key: 'Canada',
      value: 'Canada'
    },
    {
      key: 'Dominican Republic',
      value: 'Dominican Republic'
    },
    {
      key: 'New Zealand',
      value: 'New Zealand'
    },
    {
      key: 'South Africa',
      value: 'South Africa'
    },
    {
      key: 'USA',
      value: 'USA'
    }
  ]);
