import {barnManagerModule} from '../../../index.module';
import {compact, includes, isFunction} from 'lodash';
import {copy} from 'angular';
import moment from 'moment';

barnManagerModule
  .component('bmHorseRegistrations', {
    templateUrl: 'horse-registrations.html',
    controller: horseRegistrationsController,
    controllerAs: 'vm',
    bindings: {
      items: '<',
      attachments: '=',
      onAttachmentRemove: '<',
      onChange: '<',
      showErrors: '<',
      isReady: '<'
    }
  });

horseRegistrationsController.$inject = ['$scope', 'registrationsHelpers', 'PATTERNS', 'DATE_ISO_FORMAT', 'confirmDialog'];
function horseRegistrationsController($scope, registrationsHelpers, PATTERNS, DATE_ISO_FORMAT, confirmDialog) {
  const vm = this;
  let newId = 1;

  vm.$onInit = function() {

    vm.patterns = PATTERNS;
    vm.calendarOpened = {};
    vm.attachmentsFiltered = {};

    vm.changeData = changeData;
    vm.onAdd = onAdd;
    vm.onRemove = onRemove;
    vm.hasError = hasError;
    vm.openCalendar = openCalendar;
    vm.onAttachmentAdd = onAttachmentAdd;
    vm.onAttachedFileRemove = onAttachedFileRemove;

    init();

    function newOrganization() {
      newId += 1;
      return {
        id: newId,
        expiresOn: null,
        number: '',
        name: '',
        attachmentIds: []
      };
    }

    function changeData() {
      const items = copy(vm.registrations).map(function(organization) {
        if (organization.expiresOn) {
          organization.expiresOn = moment(organization.expiresOn).format(DATE_ISO_FORMAT);
        } else {
          organization.expiresOn = null;
        }
        return organization;
      });
      isFunction(vm.onChange) && vm.onChange(items);
    }

    function openCalendar(id) {
      vm.calendarOpened[id] = true;
    }

    function onAttachmentAdd() {
      filterAttachmentsByIds();
    }

    function filterAttachmentsByIds() {
      vm.attachmentsFiltered = vm.registrations.reduce(function(acc, organization) {
        acc[organization.id] = vm.attachments.filter(function(file) {
          if (includes(organization.attachmentIds, file.id) || file.minorId === organization.id) {
            file.minorId = organization.id;
            return true;
          }
          return false;
        });
        organization.hasAttachments = acc[organization.id].length > 0;
        return acc;
      }, {});
      vm.changeData();
    }

    function onAttachedFileRemove(file) {
      vm.attachments = vm.attachments.filter(function(item) {
        return item !== file;
      });
      if (file.id) {
        vm.onAttachmentRemove(file);
      }
      vm.changeData();
    }

    function onAdd() {
      vm.registrations.push(newOrganization());
      vm.changeData();
    }

    function doRemove(organization) {
      vm.registrations = vm.registrations.filter(function(item) {
        return organization.id !== item.id;
      });
      vm.attachments = compact(vm.attachments.map(function(file) {
        if (file.minorId === organization.id) {
          if (includes(organization.attachmentIds, file.id)) {
            vm.onAttachmentRemove(file);
          }
          return null;
        }
        return file;
      }));
      vm.changeData();
    }

    function onRemove(organization) {
      if (!registrationsHelpers.organizationNotEmpty(organization)) {
        doRemove(organization);
      } else {
        confirmDialog.open('Are you sure you want to delete this organization?').then(function() {
          doRemove(organization);
        }).catch(function() {});
      }
    }

    function hasError(organization) {
      return vm.showErrors && !registrationsHelpers.organizationNotEmpty(organization);
    }

    function init() {
      vm.registrations = (vm.items || []).map(function(organization, index) {
        if (organization.expiresOn) {
          organization.expiresOn = moment(organization.expiresOn).toDate();
        } else {
          organization.expiresOn = null;
        }
        organization.id = index + 1;
        return organization;
      });
      newId = vm.registrations.length;
      filterAttachmentsByIds();
    }

    $scope.$watch('vm.isReady', function() {
      init();
    });

  };
}
