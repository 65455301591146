import {barnManagerModule} from '../../index.module';
import {clone, cloneDeep, get, isEmpty, isFunction, keys, set} from 'lodash';
import moment from 'moment';

barnManagerModule
  .component('bmRecurrenceRule', {
    templateUrl: 'recurrence-rule.html',
    controller: recurrenceRuleController,
    controllerAs: 'vm',
    bindings: {
      schedule: '<',
      onChange: '<',
      alwaysAllDays: '<',
      errors: '<'
    }
  });

recurrenceRuleController.$inject = [
  '$scope',
  '$timeout',
  'WEEKDAYS',
  'DATE_ISO_FORMAT',
  'RRuleHelper',
  'barnStorage'
];
function recurrenceRuleController(
  $scope,
  $timeout,
  WEEKDAYS,
  DATE_ISO_FORMAT,
  RRuleHelper,
  barnStorage
) {
  const vm = this;
  const barn = barnStorage.getEnv();

  vm.$onInit = function() {

    const noRule = {
      interval: null,
      freq: null,
      until: null,
      byweekday: [],
      bymonthday: [],
      bymonth: [],
      wkst: null
    };

    const defaultRule = {
      interval: 1,
      freq: 'DAILY',
      until: null,
      byweekday: [],
      bymonthday: [],
      bymonth: [],
      wkst: 'SU'
    };

    const defaultSchedule = {
      dtstart: null,
      dtend: null,
      tzid: barn.timeZone || 'America/New_York',
      rrule: {}
    };

    if (isEmpty(vm.schedule)) {
      vm.schedule = clone(defaultSchedule);
    }

    vm.options = {
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      weekDays: keys(WEEKDAYS),
      freq: {
        Day: 'DAILY',
        Week: 'WEEKLY',
        Month: 'MONTHLY' /*,
        Year: 'YEARLY'*/
      }
    };

    vm.state = {
      repeat: false,
      allDay: 1,
      hasEndDate: '0',
      startTime: null,
      endTime: null
    };

    vm.calendars = {
      dtstart: false,
      dtend: false,
      until: false
    };

    vm.dates = {
      dtstart: null,
      dtend: null,
      until: null
    };

    vm.dateOptions = {
      dtstart: {
        maxDate: vm.dates.until
      },
      until: {
        minDate: vm.dates.dtstart
      }
    };

    const datesMap = {
      dtstart: 'dtstart',
      dtend: 'dtend',
      until: 'rrule.until'
    };

    vm.openCalendar = openCalendar;
    vm.onScheduleChange = onScheduleChange;
    vm.onWeekdaysChange = onWeekdaysChange;
    vm.onMonthdaysChange = onMonthdaysChange;
    vm.repeatToggled = repeatToggled;
    vm.onFreqChange = onFreqChange;
    vm.onEndDateChange = onEndDateChange;
    vm.onDateChange = onDateChange;

    init();

    function openCalendar(calendar) {
      vm.calendars[calendar] = true;
    }

    function onWeekdaysChange(weekdays) {
      vm.schedule.rrule.byweekday = weekdays;
      onScheduleChange();
    }

    function onMonthdaysChange(monthdays) {
      vm.schedule.rrule.bymonthday = monthdays;
      onScheduleChange();
    }

    function onScheduleChange() {
      const event = cloneDeep(vm.schedule);
      event.rrule = RRuleHelper.filterRRule(event.rrule);
      isFunction(vm.onChange) && vm.onChange(event);
    }

    function repeatToggled() {
      if (vm.state.repeat) {
        vm.schedule.rrule = clone(defaultRule);
      } else {
        vm.schedule.rrule = clone(noRule);
      }
      init();
      vm.onScheduleChange();
    }

    function onFreqChange() {
      if (vm.schedule.rrule.freq === 'WEEKLY') {
        vm.schedule.rrule.byweekday = vm.options.weekDays;
      } else {
        vm.schedule.rrule.byweekday = [];
      }
      vm.schedule.rrule.bymonthday = [];
      vm.schedule.rrule.bymonth = [];
      vm.onScheduleChange();
    }

    function onEndDateChange() {
      if (vm.state.hasEndDate == '0') {
        vm.schedule.rrule.until = null;
        vm.onScheduleChange();
      }
    }

    function onDateChange(type) {
      if (vm.dates[type]) {
        set(vm.schedule, datesMap[type], moment(vm.dates[type]).utcOffset(0, true).format(DATE_ISO_FORMAT));
      } else {
        set(vm.schedule, datesMap[type], null);
      }
      vm.onScheduleChange();
    }

    function init() {
      vm.schedule.rrule = vm.schedule.rrule || clone(noRule);
      vm.schedule.tzid = vm.schedule.tzid || defaultSchedule.tzid;

      if (vm.schedule.dtstart) {
        vm.dates.dtstart = RRuleHelper.parseDate(vm.schedule.dtstart);
      }
      if (vm.schedule.dtend) {
        vm.dates.dtend = RRuleHelper.parseDate(vm.schedule.dtend);
      }
      if (vm.schedule.rrule.until) {
        vm.dates.until = RRuleHelper.parseDate(vm.schedule.rrule.until);
      }
      vm.dateOptions.dtstart.maxDate = vm.dates.until;
      vm.dateOptions.until.minDate = vm.dates.dtstart;

      vm.state.repeat = !!get(vm.schedule, 'rrule.freq');
      if (vm.schedule.rrule.until) {
        vm.state.hasEndDate =  '1';
      }
    }

    $scope.$watch('vm.schedule', init);
  };
}
