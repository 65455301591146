import {barnManagerModule} from '../../index.module';

barnManagerModule.controller('HeaderBarnController', HeaderBarnController);

HeaderBarnController.$inject = [
  '$rootScope',
  '$window',
  'barnStorage',
  'unreadService',
  'authenticationService',
  'rAttachments',
  'userStorage',
  'goNative',
  'hasPermission'
];
function HeaderBarnController(
  $rootScope,
  $window,
  barnStorage,
  unreadService,
  authenticationService,
  rAttachments,
  userStorage,
  goNative,
  hasPermission
) {
  const vm = this, url = $window.URL || $window.webkitURL;

  vm.hasEventsReadPermission =( hasPermission('events:read') || hasPermission('lessons:read'));
  vm.hasConversationsReadPermission = hasPermission('conversations:read');
  vm.hasRecordsReadPermission = hasPermission('records:read');
  vm.hasListsReadPermission = hasPermission('lists:read');
  vm.hasContactsReadPermission = hasPermission('contacts:read');
  vm.hasFeedReadPermission = hasPermission('feed:read');
  vm.hasReportsPermission = hasPermission('reports:full');
  vm.hasInvoicingFullPermission = hasPermission('invoicing:full');

  vm.unreads = {};
  vm.logoUrl = '';
  vm.hasLogo = false;
  vm.done = false;
  vm.isGoNative = goNative.isGoNative();
  vm.showInvoicing = showInvoicing();
  vm.navigateToInvoicing = navigateToInvoicing;

  if (authenticationService.isLoggedIn()) {
    init();
  }

  const envListener = $rootScope.$on('environmentChange', restart);

  function restart() {
    vm.unreads = {};
    vm.logoUrl = '';
    vm.hasLogo = false;
    vm.done = false;
    init();
  }

  function getLogo(attachmentId) {
    rAttachments.get({ id: attachmentId }).$promise.then(function(attachment) {
      vm.logoUrl = url.createObjectURL(new Blob([attachment.data]));
      vm.done = true;
    });
  }

  function showInvoicing() {
    const currentUser = userStorage.getUser();
    if (!currentUser) {
      return false;
    }
    return currentUser.isInvoicingEnabled;
  }

  function navigateToInvoicing(event: any) {
    event.preventDefault();
    window.location.href = `${window.location.origin}/invoicing`;
  }

  function init() {
    vm.barn = barnStorage.getEnv();
    unreadService.query(vm.barn.id, function(response) {
      vm.unreads = response;
    });
    rAttachments.query({ modelType: 'Barn', modelId: vm.barn.id }).$promise.then(function(attachments) {
      if (attachments.length) {
        vm.hasLogo = true;
        getLogo(attachments[0].id);
      } else {
        vm.done = true;
      }
    }).catch(function() {
      vm.done = true;
    });
  }

  $rootScope.$on('$destroy', envListener);
}
