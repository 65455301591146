import {barnManagerModule} from '../index.module';
import * as angular from 'angular';
import {get, pick} from 'lodash';

barnManagerModule.factory('barnStorage', BarnStorage);

BarnStorage.$inject = ['sessionStorageService'];
function BarnStorage(sessionStorageService) {
  let env: any = {};

  return {
    getEnv: getEnv,
    setEnv: setEnv,
    getEnvId: getEnvId,
    updateEnvTenant: updateEnvTenant,
    clean: clean
  };

  function getEnv() {
    if (env && env.id) {
      return angular.copy(env);
    } else if (sessionStorageService.getUser()?.userRoles[0]?.tenantEnvironment?.id) {
      return sessionStorageService.getUser().userRoles[0].tenantEnvironment;
    } else {
      return null;
    }
  }

  function getEnvId() {
    return getEnv()?.id;
  }

  function setEnv(_env) {
    if (_env && _env.id) {
      env = angular.copy(_env);
    } else {
      throw new Error('Env does not exist in storage');
    }
  }

  function updateEnvTenant(tenant) {
    env.tenant = pick(tenant, ['created', 'id', 'name', 'timeZone', 'updated']);
    return env;
  }

  function clean() {
    env = {};
  }
}
