import * as angular from 'angular';
import '@uirouter/angularjs';
import 'ng-quill';
import 'angular-filter';
import 'angular-sanitize';
import 'checklist-model';
import 'angular-bootstrap-calendar';
import 'angular-aria';
import 'angular-cookies';
import 'ng-idle';
import 'angular-messages';
import 'angular-resource';
import 'ng-stomp';
import 'angular-touch';
import 'angular-stripe-checkout';
import 'angular-ui-bootstrap';
import 'angularjs-color-picker';
import 'angular-sidebarjs';
import '@js-joda/timezone/dist/js-joda-timezone-1970-2030.js';

export const barnManagerModuleName = 'barnManager';
export const barnManagerModule = angular.module(barnManagerModuleName, [
  'ngQuill',
  'angular.filter',
  'checklist-model',
  'mwl.calendar',
  'ngAria',
  'ngCookies',
  'ngIdle',
  'ngMessages',
  'ngResource',
  'ngSanitize',
  'ngStomp',
  'ngTouch',
  'stripe.checkout',
  'ui.bootstrap',
  'ui.router',
  'color.picker',
  'ngSidebarJS'
]);

