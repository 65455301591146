import {barnManagerModule} from '../index.module';
import {merge} from 'lodash';

barnManagerModule
  .factory('whiteboardRepository', ['$http', 'userStorage', 'barnStorage', ($http, userStorage, barnStorage) => {

    return {
      remove: remove,
      find: find,
      add: add,
      update: update,
      search: search
    };

    function url() {
      const currentUser = userStorage.getUser();

      return '/api/users/' + currentUser.id + '/whiteboards';
    }

    function recordUrl(id) {
      return url() + '/' + id;
    }

    function remove(id) {
      return $http.delete(recordUrl(id)).then(transformResult);
    }

    function find(id) {
      return $http.get(recordUrl(id)).then(transformResult);
    }

    function add(body) {
      return $http.post(url(), body).then(transformResult);
    }

    function update(body) {
      return $http.put(recordUrl(body.id), body).then(transformResult);
    }

    function search(pageSize) {
      return $http.get(url(), {
        params: mergeParams({
          pagesize: pageSize
        })
      }).then(transformResult);
    }

    function transformResult(result) {
      return result.data;
    }

    function mergeParams(params) {
      return merge({
        tenantEnvironmentId: barnStorage.getEnvId()
      }, params || {});
    }
  }]);
