import { Directive, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'bm-notifications-sidebar'
})
export class NotificationsSidebarNg2Directive extends UpgradeComponent {
  constructor(elementRef: ElementRef, injector: Injector) {
    super('bmNotificationsSidebar', elementRef, injector);
  }
}
