import {barnManagerModule} from '../../index.module';

barnManagerModule
  .constant('AccessTypes', {
    none: new AccessType('none', 'None'),
    read: new AccessType('read', 'Read only'),
    full: new AccessType('full', 'Full access')
  });

function AccessType(name, label) {
  this.name = name;
  this.label = label;
}
