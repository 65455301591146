import {barnManagerModule} from '../../index.module';

barnManagerModule.directive('bmMembershipForm', MembershipForm);

function MembershipForm() {

  MembershipFormController.$inject = ['PATTERNS'];

  return {
    restricted: 'E',
    templateUrl: 'membership-form.html',
    controller: MembershipFormController,
    controllerAs: 'form',
    bindToController: true,
    transclude: true,
    scope: {
      membership: '=',
      allUsers: '=',
      contactForm: '=',
      index: '<'
    }
  };

  function MembershipFormController(PATTERNS) {
    const vm = this;

    vm.$onInit = function() {

      vm.patterns = PATTERNS;
      vm.calendarOpened = false;

      vm.openCalendar = function() {
        vm.calendarOpened = !vm.calendarOpened;
      };

    };
  }
}
