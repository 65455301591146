import {barnManagerModule} from '../../index.module';
import {concat, filter, includes, values} from 'lodash';

barnManagerModule
  .component('bmMessageFrame', {
    templateUrl: 'message-frame.html',
    controller: MessageFrameController,
    controllerAs: 'vm',
    bindings: {
      frame: '@'
    }
  });

MessageFrameController.$inject = ['$scope', '$timeout', 'messageBus', 'MessageFrames'];
function MessageFrameController($scope, $timeout, messageBus, MessageFrames) {
  const vm = this;

  vm.$onInit = function() {

    const frames = values(MessageFrames);

    if (!includes(frames, vm.frame)) {
      vm.frame = MessageFrames.DEFAULT;
    }
    vm.messages = pullMessages();

    vm.removeMessage = removeMessage;

    const messagesAddedSubscriber = $scope.$on('messagesAdded', messagesAdded);
    $scope.$on('$destroy', messagesAddedSubscriber);

    const messagesClearedSubscriber = $scope.$on('messagesCleared', messagesCleared);
    $scope.$on('$destroy', messagesClearedSubscriber);

    function removeMessage(currentMessage) {
      vm.messages = vm.messages.filter(function(message) {
        return message !== currentMessage;
      });
    }

    function messagesAdded() {
      const newMessages = pullMessages();
      if (newMessages.length) {
        vm.messages = concat(vm.messages, newMessages);
      }
    }

    function messagesCleared(event, frame) {
      if (vm.frame === frame) {
        vm.messages = [];
      }
    }

    function pullMessages() {
      const messages = messageBus.pull(vm.frame);

      messages.forEach(function(message) {
        // prevent duplication
        filter(vm.messages, { text: message.text }).map(removeMessage);
        if (message.timeout) {
          $timeout(function() {
            removeMessage(message);
          }, message.timeout);
        }
      });
      return messages;
    }
  };
}
