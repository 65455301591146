import {barnManagerModule} from '../../../index.module';

barnManagerModule.controller('ConversationDetailsController', ConversationDetailsController);

ConversationDetailsController.$inject = [
  'backLinkHistory',
  'responseHandler',
  'rUserConversations',
  'listsService',
  '$stateParams',
  '$rootScope',
  '$state',
  'rAttachments',
  'goNative',
  'userStorage',
  'titleService',
  'hasPermission'
];
function ConversationDetailsController(
  backLinkHistory,
  responseHandler,
  rUserConversations,
  listsService,
  $stateParams,
  $rootScope,
  $state,
  rAttachments,
  goNative,
  userStorage,
  titleService,
  hasPermission
) {
  const vm = this;

  vm.hasAdminFullPermission = hasPermission('admin:full');
  vm.hasConversationsFullPermission = hasPermission('conversations:full');
  vm.hasConversationsReadPermission = hasPermission('conversations:read');
  vm.attachments = [];
  vm.conversationMembers = [];

  vm.isAccessForbidden = false;

  vm.conversationId = -1;
  vm.curHorseId = -1;

  vm.conversationTypes = [
    { name: 'Dentist', value: 'dentist' },
    { name: 'Farrier', value: 'farrier' },
    { name: 'Vet', value: 'vet' }
  ];

  vm.isGoNative = goNative.isGoNative();
  vm.togglePin = togglePin;
  vm.onSuccessArchive = onSuccessArchive;
  vm.onSuccessRemove = onSuccessRemove;

  init();

  function togglePin() {
    vm.conversation.pinned = !vm.conversation.pinned;
    rUserConversations.togglePin({
      userId: vm.conversation.authorId,
      conversationId: vm.conversation.id
    }).$promise.catch(responseHandler.processError);
  }

  function getConversation() {
    vm.loading = true;

    const currentUser = userStorage.getUser();
    listsService.resetTo('conversations', null);

    rUserConversations.get({
      conversationId: vm.conversationId,
      userId: $stateParams.author || currentUser.id
    }).$promise.then(function(conversation) {
      vm.loading = false;
      backLinkHistory.pushLink(conversation.title + '\'s details');
      vm.conversation = conversation;
      vm.conversation.read = 1;
      rUserConversations.markAsRead({ conversationId: vm.conversation.id, userId: vm.conversation.authorId });
      rAttachments.query({ modelType: 'Conversation', modelId: vm.conversation.id }, function(attachments) {
        vm.attachments = attachments;
      });
      listsService.getUsers(vm.conversation, function(allUsers, members) {
        vm.conversationMembers = members;
      }, false);
      if (typeof vm.conversation.comments === 'undefined') {
        vm.conversation.comments = [];
      }
    }).catch(function(error) {
      vm.loading = false;
      responseHandler.handleNotFoundOrAccess(error, 'conversation', function(errorMessage) {
        vm.error = errorMessage;
      });
    }).catch(function(error) {
      responseHandler.processError(error);
    });
  }

  function init() {
    titleService.setTitle('Conversation Details');
    $rootScope.pageConf.class = 'page--interior';

    const currentStateName = $state.current.name;

    if (currentStateName === 'conversationDetails' && (isNaN($stateParams['id']) || !$stateParams['id'])) {
      $state.go('conversations');
      return;
    }

    if (currentStateName === 'conversationHorseDetails' && (isNaN($stateParams['conversationId']) || !$stateParams['conversationId'])) {
      $state.go('conversationsHorse');
      return;
    }

    vm.conversationId = currentStateName === 'conversationDetails' ? $stateParams.id : $stateParams.conversationId;

    if (currentStateName === 'conversationHorseDetails') {
      vm.curHorseId = $stateParams.id;
    }

    getConversation();
  }

  function onSuccessArchive() {
    if (vm.curHorseId > 0) {
      $state.go('conversationsHorse', { id: vm.curHorseId });
    } else {
      $state.go('conversations');
    }
  }

  function onSuccessRemove() {
    backLinkHistory.goBack();
  }
}
