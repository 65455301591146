import {barnManagerModule} from '../index.module';
import {LocalDateTime} from '@js-joda/core';

barnManagerModule
  .filter('dateFormat', dateFormat)
  .filter('dateTimeFormat', dateTimeFormat)
  .filter('fullDateTimeFormat', fullDateTimeFormat)
  .filter('timeFormat', timeFormat)
  .filter('dateFormatTruncatingTime', dateFormatTruncatingTime);

dateFormat.$inject = ['dateTimeUpgraded'];
function dateFormat(dateTimeUpgraded) {
  // expect "2020-04-12"
  return function(input) {
    if (input == null || input === '') {
      return '';
    }
    return dateTimeUpgraded.dateToLocalDateFormatted(input);
  };
}

dateFormatTruncatingTime.$inject = ['dateTimeUpgraded'];
function dateFormatTruncatingTime(dateTimeUpgraded) {
  // expect "2020-04-12T00:00:00"
  return function(input) {
    if (input == null || input === '') {
      return '';
    }
    const localDate = LocalDateTime.parse(input).toLocalDate();
    return localDate.format(dateTimeUpgraded.DATE_FORMATTER);
  };
}

dateTimeFormat.$inject = ['dateTimeUpgraded'];
function dateTimeFormat(dateTimeUpgraded) {
  return function(input) {
    if (input == null || input === '') {
      return '';
    }
    return dateTimeUpgraded.serverTimeStampToLocalDateTimeFormatted(input);
  };
}

fullDateTimeFormat.$inject = ['dateTimeUpgraded'];
function fullDateTimeFormat(dateTimeUpgraded) {
  return function(input) {
    if (input == null || input === '') {
      return '';
    }
    return dateTimeUpgraded.serverTimeStampToLocalDateTimeFormatted(input, 'ISO_LOCAL_DATE_TIME');
  };
}

timeFormat.$inject = ['dateTimeUpgraded'];
function timeFormat(dateTimeUpgraded) {
  return dateTimeUpgraded.timeFormatted;
}
