import {AfterViewInit, Directive, ElementRef, EventEmitter, Inject, OnDestroy, Output} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {filter, takeWhile} from 'rxjs/operators';
import {fromEvent, Observable} from 'rxjs';

@Directive({
  selector: '[sharedClickOutsideDirective]',
  exportAs: 'ClickOutsideModule',
  standalone: true
})
export class ClickOutsideDirective implements AfterViewInit, OnDestroy {
  private alive: boolean = true;
  @Output() clickOutside: EventEmitter<any> = new EventEmitter<any>();

  get nativeEl(): HTMLElement {
    return this.el.nativeElement;
  }

  get window(): Window {
    const { defaultView: window } = this.document;
    return window;
  }

  constructor(
    @Inject(DOCUMENT) protected document: Document,
    protected el: ElementRef
  ) {
  }

  ngAfterViewInit() {
    setTimeout(() => this.init(), 0);
  }

  ngOnDestroy() {
    this.alive = false;
  }

  private init(): void {
    this.clickWindow().pipe(
      filter((el: Event) => !this.contains(el.target)),
      takeWhile(() => this.alive)
    ).subscribe(this.clickOutside);
  }

  private clickWindow(): Observable<Event> {
    return fromEvent(window, 'click');
  }

  private contains(target: EventTarget | Event): boolean {
    const t: EventTarget = target instanceof Event ? target.target : target;
    return this.nativeEl.contains(<Node>t);
  }
}
