import {barnManagerModule} from '../index.module';
import * as angular from 'angular';

barnManagerModule.filter('bmSearchIn', bmSearchIn);

function bmSearchIn() {
  return function(items, properties, value) {
    if (!value || !value.length) {
      return items;
    }

    const filteredResults = [];

    const uniques = function(list) {
      const result = [];
      angular.forEach(list, function(val) {
        if (result.indexOf(val) < 0) {
          result.push(val);
        }
      });
      return result;
    };

    for(let i = 0; i < items.length; i++) {
      const item = items[i];
      for(let x = 0; x < properties.length; x++) {
        if (typeof item[properties[x]] != 'undefined') {
          if ((item[properties[x]] || '').toLowerCase().indexOf(value.toLowerCase()) >= 0) {
            filteredResults.push(item);
          }
        }
      }
    }

    return uniques(filteredResults);
  };
}
