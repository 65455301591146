import {barnManagerModule} from '../../index.module';
import {filter, get, groupBy, includes, map} from 'lodash';

barnManagerModule.controller('EntryChangesController', EntryChangesController);

EntryChangesController.$inject = [
  '$state',
  'backLinkHistory',
  'feedingChangeRepository',
  'EntryHelper',
  'DATE_FORMAT',
  'utils',
  'dateTimeUpgraded'
];
function EntryChangesController(
  $state,
  backLinkHistory,
  feedingChangeRepository,
  EntryHelper,
  DATE_FORMAT,
  utils,
  dateTimeUpgraded
) {
  const vm = this;
  backLinkHistory.pushHorseLink('feed change log', $state.params.id);

  vm.entryChanges = [];
  vm.deletedIds = [];
  vm.timezone = utils.timezone;
  vm.serverTimeStampToLocalDateTimeFormatted = dateTimeUpgraded.serverTimeStampToLocalDateTimeFormatted;
  vm.wasDeleted = wasDeleted;

  init();

  function wasDeleted(entryChange) {
    return includes(vm.deletedIds, entryChange.became.entryId);
  }

  function init() {
    feedingChangeRepository.search($state.params.id).then(function(entryChanges) {
      vm.deletedIds = filter(entryChanges, { type: 'deleted' })
        .map(function(entryChange) {
          return get(entryChange, 'was.entryId');
        });
      vm.entryChanges = map(entryChanges, function(entryChange) {
        entryChange.shortDate = dateTimeUpgraded.serverTimeStampToLocalDateTimeFormatted(entryChange.created, 'DATE');
        if (entryChange.entityType === 'Entry') {
          if (entryChange.was) {
            if (entryChange.was.schedule) {
              entryChange.was.viewSchedule = EntryHelper.fullSchedule(entryChange.was);
            }
            entryChange.was.viewTime = EntryHelper.viewTime(entryChange.was);
          }
          if (entryChange.became) {
            if (entryChange.became.schedule) {
              entryChange.became.viewSchedule = EntryHelper.fullSchedule(entryChange.became);
            }
            entryChange.became.viewTime = EntryHelper.viewTime(entryChange.became);
          }
        }
        return entryChange;
      });
      vm.groupedChanges = groupBy(vm.entryChanges, 'shortDate');
    });
  }
}
