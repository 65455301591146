import {barnManagerModule} from '../index.module';
import * as angular from 'angular';
import * as moment from 'moment';
import {last} from 'lodash';

barnManagerModule
  .factory('Subscription', () => {
    function Subscription(data) {
      angular.extend(this, angular.copy(data));
    }

    Subscription.prototype = {
      isTrialing: function() {
        return this.status === 'trialing';
      },
      isCanceled: function() {
        return this.status === 'canceled';
      },
      isActive: function() {
        return this.status === 'active';
      },
      periodEndDate: function() {
        return moment.unix(this.currentPeriodEnd || this.trialEnd);
      },
      hasPeriodEnded: function() {
        // TODO: check status
        return (moment().isAfter(this.periodEndDate(), 'day'));
      },
      getAccessPolicy: function() {
        return last(this.planId.split('_'));
      }
    };

    return Subscription;
  });
