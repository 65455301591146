import {barnManagerModule} from '../../index.module';

barnManagerModule
  .constant('NotificationsReceiveChannelType', {
    PUSH: 'push',
    EMAIL: 'email',
    BOTH: 'both'
  });

barnManagerModule.factory('NotificationsReceiveChannelAdapter', NotificationsReceiveChannelAdapter);

NotificationsReceiveChannelAdapter.$inject = ['NotificationsReceiveChannelType'];
function NotificationsReceiveChannelAdapter(NotificationsReceiveChannelType) {
  function getNotificationsReceiveChannel(pushNotificationEnabled, emailNotificationEnabled) {
    if (!!pushNotificationEnabled && !emailNotificationEnabled) {
      return NotificationsReceiveChannelType.PUSH;
    } else if (!pushNotificationEnabled && !!emailNotificationEnabled) {
      return NotificationsReceiveChannelType.EMAIL;
    } else {
      return NotificationsReceiveChannelType.BOTH;
    }
  }

  function isPropertyEnabled(notificationsReceiveChannel, type) {
    return notificationsReceiveChannel === type || notificationsReceiveChannel === NotificationsReceiveChannelType.BOTH;
  }

  return {
    getNotificationsReceiveChannel: getNotificationsReceiveChannel,
    isPropertyEnabled: isPropertyEnabled
  };
}
