import {barnManagerModule} from '../index.module';

barnManagerModule
  .factory('rEvents', ['$resource', ($resource) => {
    return $resource('/api/events/v2', {},
    {
      'delete': {
        method: 'DELETE',
        url: '/api/events/v2/:eventId',
        params: {
          eventId: '@eventId'
        }
      },
      'query': {
        method: 'GET',
        url: '/api/events/v2/instances',
        isArray: true
      },
      'get': {
        method: 'GET',
        url: '/api/events/v2/:eventId',
        params: {
          eventId: '@eventId'
        }
      },
      'closestInstance': {
        method: 'GET',
        url: '/api/events/v2/:eventId/closest-instance',
        params: {
          eventId: '@eventId',
        }
      },
      'getInstance': {
        method: 'GET',
        url: '/api/events/v2/:eventId/instances/:instance',
        params: {
          eventId: '@eventId',
          instance: '@instance'
        }
      },
      'deleteInstance': {
        method: 'DELETE',
        url: '/api/events/v2/:eventId/instance/:instance',
        params: {
          eventId: '@eventId',
          instance: '@instance'
        }
      },
      'deleteAllFollowingInstances': {
        method: 'DELETE',
        url: '/api/events/v2/:eventId/instance/:instance/allfollowing',
        params: {
          eventId: '@eventId',
          instance: '@instance'
        }
      },
      'updateAttendance': {
        method: 'PUT',
        url: '/api/events/v2/:eventId/attendance',
        params: {
          eventId: '@eventId'
        }
      },
      'update': {
        method: 'PUT',
        url: '/api/events/v2/:eventId',
        params: {
          eventId: '@eventId'
        }
      },
      'updateSingle': {
        method: 'PUT',
        url: '/api/events/v2/:eventId/instances/:instance',
        params: {
          eventId: '@eventId',
          instance: '@instance'
        }
      },
      'updateThisAndAllFollowingInstances': {
        method: 'PUT',
        url: '/api/events/v2/:eventId/instances/:instance/allfollowing',
        params: {
          eventId: '@eventId',
          instance: '@instance'
        }
      },
      'togglePin': {
        method: 'GET',
        url: '/api/events/v2/:eventId/pin',
        params: {
          eventId: '@eventId'
        }
      },
      'toggleInstancePin': {
        method: 'PUT',
        url: '/api/events/v2/:eventId/instances/:instance/pin',
        params: {
          eventId: '@eventId',
          instance: '@instance'
        }
      },
      'subscribeToken': {
        method: 'GET',
        url: '/api/events/v2/calendar/:tenantEnvironmentId',
        isArray: false
      }
    });
  }]);
