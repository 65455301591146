import {barnManagerModule} from '../index.module';

barnManagerModule
  .factory('rIntercom', ['$resource', ($resource) => {
    return $resource('/api/intercom/', {
      message: '@message'
    }, {
      'hash':  {
        url: '/api/intercom/hash',
        method: 'GET'
      },
      'getUser': {
        url: '/api/intercom/users',
        method: 'GET',
        params: {
          email: '@email'
        }
      }
    });
  }]);
