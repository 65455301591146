import {NgModule} from '@angular/core';
import {RouterModule, Routes, UrlSegment} from '@angular/router';
import {AuthorizedGuard, NotAuthorizedGuard} from './core/authorized';
import {Ng2LayoutComponent} from './core/layouts/ng2-layout/ng2-layout.component';
import {environment} from '@environment';
import {Ng1LayoutComponent} from './core/layouts/ng1-layout/ng1-layout.component';

export function isAngularJSUrl(url: UrlSegment[]) {
  return (url.length > 0 && url.length !== 1 && url[0].path.startsWith('n')) ? ({ consumed: url }) : null;
}

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: environment.authorizedLink
  },
  {
    path: '',
    component: Ng2LayoutComponent,
    children: [
      // {
      //   path: '',
      //   component: Ng2AuthorizedLayoutComponent,
      //   canActivate: [AuthGuard],
      //   children: [
      //   ]
      // },
      {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
        canActivate: [NotAuthorizedGuard]
      }
    ]
  },
  {
    matcher: isAngularJSUrl,
    component: Ng1LayoutComponent,
    canActivate: [AuthorizedGuard]
  },
  {
    path: '**',
    redirectTo: environment.authorizedLink
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'disabled', enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
