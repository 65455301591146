import {barnManagerModule} from '../index.module';
import * as angular from 'angular';
import {includes} from 'lodash';

barnManagerModule
  .factory('User', ['PermissionTypes', 'AccessTypes', 'Permission', (PermissionTypes, AccessTypes, Permission) => {
    function User(data) {
      angular.extend(this, angular.copy(data));
    }

    User.prototype = {
      // TODO refactor all roles with getRole
      getBarn: function() {
        return this.userRoles[0].tenantEnvironment;
      },
      wasInvitedToInvoicing: function() {
        return this.userTenants[0].tenant.invoicingInvitedAt != null;
      },
      isAdmin: function() {
        return includes(this.permissions, 'admin:full');
      },
      hasPermission: function(permission) {
        if (this.isAdmin()) {
          return true;
        }
        if (includes(this.permissions, permission.asString())) {
          return true;
        } else if (permission.accessType === AccessTypes.read) {
          return this.hasPermission(new Permission(permission.permissionType, AccessTypes.full));
        } else if (permission.isDefault()) {
          return !this.hasPermission(new Permission(permission.permissionType, AccessTypes.read));
        } else {
          return false;
        }
      }
    };

    return User;
  }]);
