import {barnManagerModule} from '../../../../index.module';
import {includes} from 'lodash';
import {HorseCategory, HorseCategoryType} from '../../../../resources/horseCategoryRepository';

export const horseCategoryTypeSettings: Record<HorseCategoryType, { title: string, placeholder: string, modalTitle: string }> = {
  [HorseCategoryType.STATUS]: {
    title: 'Status(es)',
    placeholder: 'Select or enter new status(es)',
    modalTitle: 'horse statuses'
  },
  [HorseCategoryType.HEALTH]: {
    title: 'Health(s)',
    placeholder: 'Select or enter new health(s)',
    modalTitle: 'horse healths'
  },
  [HorseCategoryType.FUNCTION]: {
    title: 'Function(s)',
    placeholder: 'Select or enter new function(s)',
    modalTitle: 'horse functions'
  }
};

barnManagerModule
  .component('bmHorseCategoriesArea', {
    templateUrl: 'horse-categories-area.html',
    controller: horseCategoriesAreaController,
    controllerAs: 'vm',
    bindings: {
      categoryType: '<',
      initializeSelected: '<',
      categories: '='
    }
  });

horseCategoriesAreaController.$inject = ['$scope', 'horseCategoryRepository', 'responseHandler', 'MessageFrames']
function horseCategoriesAreaController($scope, horseCategoryRepository, responseHandler, MessageFrames) {
  const vm = this;

  vm.allCategories = [];
  vm.horseCategoryTypeSettings = horseCategoryTypeSettings;

  vm.onCategoriesChange = onCategoriesChange;
  vm.onArchive = onArchive;
  vm.onRestore = onRestore;

  $scope.$watch('vm.categoryType', () => {
    loadAllCategories();
  });

  $scope.$watch('vm.allCategories', (newValue) => {
    if (newValue) {
      vm.categorySelectItems = newValue.filter(i => !i.archived);
      vm.settingBtnAvailable = vm.allCategories && vm.allCategories.some(i => i.uuid);
    }
  });

  function loadAllCategories() {
    horseCategoryRepository.byType(vm.categoryType).then((categories: Array<HorseCategory>) =>{
      vm.allCategories = categories?.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      setCategoriesByName();
      if (vm.initializeSelected && categories.length > 0) {
        vm.selected = [categories.filter(i => !i.archived)[0]];
        onCategoriesChange(vm.selected);
      }
    });
  }

  function setCategoriesByName() {
    vm.selected = vm.allCategories.filter(category => includes(vm.categories, category.name));
  }

  function onCategoriesChange(selected: HorseCategory[]) {
    const categoryNames = vm.allCategories.map(category => category.name);
    selected.forEach(i => {
      if (!categoryNames.includes(i.name)) {
        vm.allCategories = [...vm.allCategories, i];
      }
    });
    vm.categories = selected.map(i => i.name);
  }

  function onArchive(item: HorseCategory) {
    item.loading = true;
    horseCategoryRepository.archive(item.uuid).then(function() {
      const category = vm.allCategories.find(i => i.uuid === item.uuid);
      if (category) {
        category.archived = true;
      }
      vm.allCategories = [...vm.allCategories];
    }).catch(errorHandler).finally(() => item.loading = false);
  }

  function onRestore(item: HorseCategory) {
    item.loading = true;
    horseCategoryRepository.restore(item.uuid).then(function() {
      const category = vm.allCategories.find(i => i.uuid === item.uuid);
      if (category) {
        category.archived = false;
      }
      vm.allCategories = [...vm.allCategories];
    }).catch(errorHandler).finally(() => item.loading = false);
  }

  function errorHandler(error) {
    return responseHandler.processError(error, null, MessageFrames.TOP);
  }
}
