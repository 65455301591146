import {barnManagerModule} from '../../index.module';
import {reduce, values} from 'lodash';

barnManagerModule
  .factory('PermissionCollection', ['Permission', 'PermissionTypes', 'AccessTypes', (Permission, PermissionTypes, AccessTypes) => {
    return {
      regularUserPermissions: function() {
        return reduce(values(PermissionTypes), function(result, permissionType) {
          if (permissionType !== PermissionTypes.admin) {
            let permission = new Permission(permissionType, AccessTypes.full);
            result.push(permission.asString());
          }

          return result;
        }, []);
      }
    };
  }]);
