import {BmEnvironmentType} from './bm-environment-type';

export const environment: BmEnvironmentType = {
  environmentName: 'production',
  production: true,
  apiUrl: '/',
  intercomSettings: {
    appId: '5qur5uee',
    enabled: true
  },
  authorizedLink: '/n/activity',
  stripeCredentials: {
    key: 'pk_live_C2ukRJ87uIFeIFBF40VWbBOb'
  },
  notificationsRequestIntervalEnabled: true
};
