import {barnManagerModule} from '../index.module';

barnManagerModule.config(barnRoutes);

barnRoutes.$inject = ['$stateProvider', '$urlMatcherFactoryProvider'];
function barnRoutes($stateProvider, $urlMatcherFactoryProvider) {

  const barn = {
    templateUrl: 'header-barn.html',
    controller: 'HeaderBarnController',
    controllerAs: 'vm'
  };

  setGoNativeBarnMenu.$inject = ['$q', '$timeout', 'goNative', '_authenticated'];
  function setGoNativeBarnMenu($q, $timeout, goNative, _authenticated) {
    const deferred = $q.defer();
    $timeout(function() {
      goNative.setGoNativeBarnMenu();
      deferred.resolve();
    }, 10);
    return deferred.promise;
  }

  $urlMatcherFactoryProvider.strictMode(false);

  $stateProvider
    .state('barnMain', {
      url: '/n',
      abstract: true,
      resolve: {
        _authenticated: ['authenticationService', function(authenticationService) {
          return authenticationService.withUser();
        }],
        setGoNativeBarnMenu: setGoNativeBarnMenu
      },
      views: {
        content: {
          templateUrl: 'glue.html',
          controller: 'GlueController',
          controllerAs: 'vm'
        },
        header: barn
      }
    })
    .state('activity', {
      url: '/activity?section',
      parent: 'barnMain',
      reloadOnSearch: false,
      resolve: {
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('activity');
        }]
      },
      views: {
        content: {
          templateUrl: 'activity.html',
          controller: 'ActivityController',
          controllerAs: 'vm'
        }
      }
    })
    .state('horses', {
      url: '/horses',
      parent: 'barnMain',
      resolve: {
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('horses');
        }]
      },
      views: {
        content: {
          templateUrl: 'horses.html',
          controller: 'HorsesController',
          controllerAs: 'vm'
        }
      }
    })
    .state('calendar', {
      url: '/calendar?calendarView&type',
      parent: 'barnMain',
      reloadOnSearch: false,
      resolve: {
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('events');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissionsList(['events:read', 'lessons:read']);
        }]
      },
      views: {
        content: {
          templateUrl: 'calendar.html',
          controller: 'CalendarController',
          controllerAs: 'vm'
        }
      }
    })
    .state('conversations', {
      url: '/conversations',
      parent: 'barnMain',
      resolve: {
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('conversations');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('conversations:read');
        }]
      },
      views: {
        content: {
          templateUrl: 'conversations.html',
          controller: 'ConversationsController',
          controllerAs: 'vm'
        }
      }
    })
    .state('records', {
      url: '/records',
      parent: 'barnMain',
      resolve: {
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('records');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('records:read');
        }]
      },
      views: {
        content: {
          templateUrl: 'records.html',
          controller: 'RecordsController',
          controllerAs: 'vm'
        }
      }
    })
    .state('lists', {
      url: '/lists',
      parent: 'barnMain',
      resolve: {
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('lists');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('lists:read');
        }]
      },
      views: {
        content: {
          templateUrl: 'lists.html',
          controller: 'ListsController',
          controllerAs: 'vm'
        }
      }
    })
    .state('contacts', {
      url: '/contacts',
      parent: 'barnMain',
      resolve: {
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('contacts');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('contacts:read');
        }]
      },
      views: {
        content: {
          templateUrl: 'contacts.html',
          controller: 'ContactsController',
          controllerAs: 'vm'
        }
      }
    })
    .state('entries', {
      url: '/entries',
      parent: 'barnMain',
      resolve: {
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('feed');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('feed:read');
        }]
      },
      views: {
        content: {
          templateUrl: 'entries.html',
          controller: 'EntriesController',
          controllerAs: 'vm'
        }
      }
    })
    .state('reports', {
      url: '/reports',
      parent: 'barnMain',
      resolve: {
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('feed');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('feed:read');
        }]
      },
      views: {
        content: {
          templateUrl: 'reports.html',
          controller: 'ReportsController',
          controllerAs: 'vm'
        }
      }
    });
}
