import {barnManagerModule} from '../../../index.module';
import moment from "moment/moment";

barnManagerModule
  .component('bmHorseActionsMenu', {
    templateUrl: 'horse-actions-menu.html',
    controller: horseActionsMenuController,
    controllerAs: 'vm',
    bindings: {
      horse: '=',
      onSuccessArchive: '<',
      onSuccessRestore: '<'
    }
  });

horseActionsMenuController.$inject = [
  'DATE_ISO_FORMAT',
  'hasPermission',
  'goNative',
  'confirmDialog',
  'horseRepository',
  'htmlGenerator',
  'responseHandler',
  'FileOpener',
  'moduleAccessService'
];

function horseActionsMenuController(
  DATE_ISO_FORMAT,
  hasPermission,
  goNative,
  confirmDialog,
  horseRepository,
  htmlGenerator,
  responseHandler,
  FileOpener,
  moduleAccessService
) {
  const vm = this;

  vm.hasHorsesFullPermission = hasPermission('horses:full');
  vm.canShowDischargeReport = moduleAccessService.hasModuleAccess('feed');
  vm.isGoNative = goNative.isGoNative();
  vm.downloadNew = downloadNew;
  vm.toggleArchivedHorse = toggleArchivedHorse;
  vm.printDischargeReportNew = printDischargeReportNew;
  vm.openDischargeReport = openDischargeReport;
  vm.visible = false;
  vm.includeVetRecords = false;
  vm.notes = null;
  vm.showDatePicker = {
    startDate: false,
    endDate: false
  };
  vm.dates = {
    startDate: null,
    endDate: null
  };
  vm.close = close;
  vm.confirmActionNew = confirmActionNew;
  vm.openDatePicker = openDatePicker;
  vm.onIncludeVetChange = onIncludeVetChange;

  function close() {
    vm.includeVetRecords = false;
    vm.visible = false;
    vm.notes = null;
  }

  function openDischargeReport() {
    vm.visible = true;
  }

  function confirmActionNew() {
    let formattedPeriod = {};
    if (vm.includeVetRecords) {
      formattedPeriod = {
        startDate: formatDate(vm.dates.startDate),
        endDate: formatDate(vm.dates.endDate)
      };
    }
    vm.printDischargeReportNew(vm.includeVetRecords, formattedPeriod, vm.notes);
    close();
  }

  function openDatePicker(type) {
    vm.showDatePicker[type] = true;
  }

  function formatDate(date) {
    if (!date) {
      return null;
    }
    return moment(date).utcOffset(0, true).format(DATE_ISO_FORMAT);
  }

  function onIncludeVetChange() {
    if (vm.includeVetRecords) {
      vm.dates = {
        startDate: moment().subtract(1, 'years').toDate(),
        endDate: new Date()
      };
    }
  }

  function downloadNew() {
    if (vm.horse.loading) {
      return;
    }
    vm.horse.loading = true;
    horseRepository.horseDetailsReport(vm.horse.id)
      .then((data: Blob) => {
        const fileName = 'horseDetails';
        FileOpener(data, `${fileName}.pdf`);
      })
      .finally(() => vm.horse.loading = false);
  }

  function printDischargeReportNew(includeVetRecords, vetRecordsPeriod, notes) {
    vm.horse.loading = true;
    horseRepository.dischargeReport(vm.horse.id, includeVetRecords, vetRecordsPeriod, notes)
      .then((data: Blob) => {
        const fileName = 'dischargeReport';
        FileOpener(data, `${fileName}.pdf`);
      })
      .finally(() => vm.horse.loading = false);
  }


  function toggleArchivedHorse() {
    if (vm.horse.archived) {
      doToggleArchivedHorse();
    } else {
      confirmDialog.open(`Note: If this horse is part of any recurring events on the Calendar,
      archiving this horse will hide their name from Horses added to the event, but the event will still appear on your
      Calendar.

      You may end recurring events for this horse or manually remove this horse from recurring events with multiple
      horses by editing those events in the Calendar prior to archiving this horse.

      Are you sure you want to archive this horse?`).then(function () {
        doToggleArchivedHorse();
      }).catch(function () {
      });
    }
  }

  function doToggleArchivedHorse() {
    if (vm.horse.loading) {
      return;
    }
    vm.horse.loading = true;

    let done;
    const archived = vm.horse.archived ? 0 : 1;

    if (archived) {
      done = horseRepository.archive(vm.horse.id);
    } else {
      done = horseRepository.reactivate(vm.horse.id);
    }
    done.then(function () {
      vm.horse.archived = archived;
      if (vm.horse.archived) {
        if (vm.onSuccessArchive) {
          vm.onSuccessArchive();
        }
        const undoLink = htmlGenerator.link('horseDetails', {id: vm.horse.id}, 'Undo');
        responseHandler.successMessage('You successfully archived  ' + vm.horse.name + '. ' + undoLink + '.');
      } else {
        if (vm.onSuccessRestore) {
          vm.onSuccessRestore();
        }
        const editLink = htmlGenerator.link('horseEdit', {id: vm.horse.id}, 'Edit');
        responseHandler.successMessage('You successfully reactivated ' + vm.horse.name + '. ' + editLink + '.');
      }
    }).finally(() => vm.horse.loading = false);
  }
}
