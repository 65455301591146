import {barnManagerModule} from '../../../../index.module';
import {every, isFunction, keys, some} from 'lodash';
import moment from 'moment';

barnManagerModule
  .component('bmUsefMergeTable', {
    templateUrl: 'usef-merge-table.html',
    controller: UsefMergeTableController,
    controllerAs: 'vm',
    bindings: {
      requestId: '<',
      usefProperty: '<',
      barnProperty: '<',
      onChange: '<',
      onImport: '<'
    }
  });

UsefMergeTableController.$inject = ['$q', '$scope', 'usefParams', 'DATE_ISO_FORMAT'];
function UsefMergeTableController($q, $scope, usefParams, DATE_ISO_FORMAT) {
  const vm = this;

  vm.$onInit = function() {

    vm.show = false;
    vm.selecrtedAllUsef = true;
    vm.selecrtedAllBarn = false;

    vm.selectedBarn = {};
    vm.mergedProperties = {};

    vm.usefParams = usefParams;

    vm.selectAllUsef = selectAllUsef;
    vm.selectAllBarn = selectAllBarn;
    vm.selectUsef = selectUsef;
    vm.selectBarn = selectBarn;
    vm.isUsef = isUsef;
    vm.importRequest = importRequest;

    init();

    function afterChanging() {
      vm.mergedProperties = vm.propertyList.reduce(function(acc, key) {
        if (vm.selectedBarn[key]) {
          acc[key] = vm.barnProperty[key];
        } else {
          acc[key] = vm.usefProperty[key];
        }
        return acc;
      }, {});
      isFunction(vm.onChange) && vm.onChange(vm.mergedProperties, vm.requestId);
    }

    function checkSelected() {
      const selectedArray = vm.propertyList.map(function(key) {
        return vm.selectedBarn[key] || false;
      });
      if (every(selectedArray)) {
        vm.selecrtedAllUsef = false;
        vm.selecrtedAllBarn = true;
      } else if (!some(selectedArray)) {
        vm.selecrtedAllUsef = true;
        vm.selecrtedAllBarn = false;
      } else {
        vm.selecrtedAllUsef = false;
        vm.selecrtedAllBarn = false;
      }
      afterChanging();
    }

    function selectAllUsef() {
      vm.propertyList.forEach(function(key) {
        vm.selectedBarn[key] = false;
      });
      checkSelected();
    }

    function selectAllBarn() {
      vm.propertyList.forEach(function(key) {
        vm.selectedBarn[key] = true;
      });
      checkSelected();
    }

    function selectUsef(key) {
      vm.selectedBarn[key] = false;
      checkSelected();
    }
    function selectBarn(key) {
      vm.selectedBarn[key] = true;
      checkSelected();
    }

    function isUsef(key) {
      return !vm.selectedBarn[key];
    }

    function importRequest() {
      vm.onImport(vm.requestId);
    }

    function init() {
      vm.selectedBarn = {};
      if (vm.barnProperty) {
        if (vm.barnProperty.foalYear) {
          vm.barnProperty.foalDate = moment({
            year: vm.barnProperty.foalYear,
            month: vm.barnProperty.foalMonth,
            day: vm.barnProperty.foalDay
          }).format(DATE_ISO_FORMAT);
        } else {
          vm.barnProperty.foalDate = null;
        }
      }
      vm.propertyList = keys(vm.usefProperty);
      afterChanging();
    }

    $scope.$watch('vm.barnProperty', init);

  };
}
