import {barnManagerModule} from '../index.module';
import {Locale} from '@js-joda/locale_en-us';
import {DateTimeFormatter, LocalDate, LocalDateTime, LocalTime, ZonedDateTime, ZoneId} from '@js-joda/core';

barnManagerModule.factory('dateTimeUpgraded', dateTimeUpgraded);

dateTimeUpgraded.$inject = ['barnStorage'];
function dateTimeUpgraded(barnStorage) {
  const HOURS_FORMATTER = DateTimeFormatter.ofPattern('hh:mm a').withLocale(Locale.ENGLISH);
  const WEEKDAY_FORMATTER = DateTimeFormatter.ofPattern('EEEE').withLocale(Locale.ENGLISH);
  const DATE_FORMATTER = DateTimeFormatter.ofPattern('MM/dd/yyyy').withLocale(Locale.ENGLISH);
  const ISO_LOCAL_DATE_TIME_FORMATTER = DateTimeFormatter.ISO_LOCAL_DATE_TIME.withLocale(Locale.ENGLISH);
  const DEFAULT_FORMATTER = DateTimeFormatter.ofPattern("MM/dd/yyyy 'at' hh:mm a").withLocale(Locale.ENGLISH);

  return {
    timeFormatted: timeFormatted,
    HOURS_FORMATTER: HOURS_FORMATTER,
    DATE_FORMATTER: DATE_FORMATTER,
    dateToLocalDateFormatted: dateToLocalDateFormatted,
    fromUTCDateToLocalDateTimeFormatted: fromUTCDateToLocalDateTimeFormatted,
    serverTimeStampToLocalDateTimeFormatted: serverTimeStampToLocalDateTimeFormatted
  };

  // takes "19:00", or "7:00:00" and outputs "7:00 AM" and "7:00 PM"
  function timeFormatted(input) {
    if (input == null || input === '') {
      return '';
    }
    return LocalTime.parse(input).format(DateTimeFormatter.ofPattern('h:mm a').withLocale(Locale.ENGLISH));
  }

  function dateToLocalDateFormatted(dateAsString) {
    if (dateAsString == null) {
      return '';
    }
    let localDate = LocalDate.parse(dateAsString);
    return localDate.format(DATE_FORMATTER);
  }

  function fromUTCDateToLocalDateTimeFormatted(dateTimeAsString, formatType) {
    const barn = barnStorage.getEnv();
    if (dateTimeAsString == null || !barn) {
      return '';
    }
    let currentTimeZone = barn.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;
    let zonedDateTime = ZonedDateTime.parse(dateTimeAsString);
    zonedDateTime = zonedDateTime.withZoneSameInstant(ZoneId.of(currentTimeZone));
    let formatter;
    if (formatType === 'HOURS') {
      formatter = HOURS_FORMATTER;
    } else if (formatType === 'WEEKDAY') {
      formatter = WEEKDAY_FORMATTER;
    } else if (formatType === 'DATE') {
      formatter = DATE_FORMATTER;
    } else if (formatType === 'ISO_LOCAL_DATE_TIME') {
      formatter = ISO_LOCAL_DATE_TIME_FORMATTER;
    } else {
      formatter = DEFAULT_FORMATTER;
    }
    return zonedDateTime.format(formatter.withLocale(Locale.ENGLISH));
  }

  function serverTimeStampToLocalDateTimeFormatted(dateTimeAsString, formatType) {
    const barn = barnStorage.getEnv();
    if (dateTimeAsString == null || !barn) {
      return '';
    }
    let currentTimeZone = barn.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;

    let zonedDateTime = ZonedDateTime.of(LocalDateTime.parse(dateTimeAsString), ZoneId.UTC);
    zonedDateTime = zonedDateTime.withZoneSameInstant(ZoneId.of(currentTimeZone));
    let formatter;
    if (formatType === 'HOURS') {
      formatter = HOURS_FORMATTER;
    } else if (formatType === 'WEEKDAY') {
      formatter = WEEKDAY_FORMATTER;
    } else if (formatType === 'DATE') {
      formatter = DATE_FORMATTER;
    } else if (formatType === 'ISO_LOCAL_DATE_TIME') {
      formatter = ISO_LOCAL_DATE_TIME_FORMATTER;
    } else {
      formatter = DEFAULT_FORMATTER;
    }
    return zonedDateTime.format(formatter);
  }
}
