import {barnManagerModule} from '../index.module';

barnManagerModule
  .constant('ModulePermissionList', {
    starter: [
      'activity',
      'noaccess',
      'profile',
      'search',
      'admin',
      'horses',
      'conversations',
      'lists',
      'contacts'
    ],
    essential: [
      'activity',
      'noaccess',
      'profile',
      'search',
      'admin',
      'horses',
      'conversations',
      'lists',
      'contacts',
      'feed',
      'records',
      'events',
      'reports',
      'vendors'
    ]
  });
