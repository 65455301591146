import {barnManagerModule} from '../../index.module';

barnManagerModule.directive('bmHeader', header);

header.$inject = ['moduleAccessService'];
function header(moduleAccessService) {

  HeaderController.$inject = ['$rootScope', '$state', 'authenticationService', 'userStorage', 'subscriptionStorage', 'SidebarJS', 'hasPermission'];

  return {
    restricted: 'E',
    templateUrl: 'header.html',
    controller: HeaderController,
    controllerAs: 'header',
    bindToController: true,
    bindings: {
      ng1BootstrappedAndActive: '='
    }
  };

  function HeaderController(
    $rootScope,
    $state,
    authenticationService,
    userStorage,
    subscriptionStorage,
    SidebarJS,
    hasPermission
  ) {
    const vm = this;

    vm.$onInit = function() {
      vm.showMenu = true;
      vm.isLoggedIn = false;
      vm.isReactivate = ($state.current.name === 'reactivate');
      vm.hasModuleAccess = hasModuleAccess;
      vm.user = null;
      vm.sidebarName = 'notifications-sidebar';

      vm.toggleNotificationsSidebar = toggleNotificationsSidebar;
      vm.dropdownClick = dropdownClick;
      vm.logout = logout;

      init();

      function init() {
        const unreadNotificationsChanged = $rootScope.$on('unreadNotificationsChanged', function(event, unreadNotifications) {
          vm.unreadNotifications = unreadNotifications;
        });
        $rootScope.$on('$destroy', unreadNotificationsChanged);
      }

      function toggleNotificationsSidebar($event) {
        $event.preventDefault();
        $rootScope.$broadcast('notificationsSidebarToggle', !SidebarJS.isVisible(vm.sidebarName));
      }

      function dropdownClick() {
        if (SidebarJS.isVisible(vm.sidebarName)) {
          SidebarJS.close(vm.sidebarName);
        }
      }

      function logout() {
        authenticationService.logout();
      }

      function isPublicPage(state) {
        if (state && state.data) {
          return state.data.isPublic;
        }
        return false;
      }

      function hasModuleAccess(moduleName) {
        return moduleAccessService.hasModuleAccess(moduleName);
      }

      const onUserAuthenticated = $rootScope.$on('userAuthenticated', (event, user) => {
        vm.isLoggedIn = authenticationService.isLoggedIn();
        vm.user = userStorage.getUser();
        vm.hasAdminFullPermission = hasPermission('admin:full');
        vm.hasVendorsReadPermission = hasPermission('vendors:read');
        if (!user) {
          vm.unreadNotifications = 0;
        }
      });
      $rootScope.$on('$destroy', onUserAuthenticated);

      /*Verify if navigation should be showed  **NOT showed for authentication screens */
      const stateChangeSuccess = $rootScope.$on('$stateChangeSuccess', (event, toState) => {
        vm.showMenu = !isPublicPage(toState);

        const subscription = subscriptionStorage.getSubscription();
        if (vm.user && !vm.user.sysAdmin && subscription && (subscription.isCanceled() || subscription.hasPeriodEnded())) {

          vm.isReactivate = true;

          if (toState.name === 'barnProfile' || toState.name === 'payment') {
            $state.go('payment');
          } else if (toState.name === 'profile') {
            return;
          } else {
            $state.go('reactivate');
          }
          return;
        }

        vm.isReactivate = false;

        if (toState.name === 'reactivate' && vm.isLoggedIn) {
          $state.go('activity');
          return;
        }
      });
      $rootScope.$on('$destroy', stateChangeSuccess);
    };
  }
}
