import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import {TestApiEndpointNotificationComponent} from '@shared-ui';
import {RouterModule} from '@angular/router';

@Component({
  selector: 'bm-ng2-layout',
  templateUrl: './ng2-layout.component.html',
  styleUrls: ['./ng2-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterModule,
    TestApiEndpointNotificationComponent
  ]
})
export class Ng2LayoutComponent implements OnInit, OnDestroy {
  constructor(
    private renderer: Renderer2
  ) {
  }

  ngOnInit() {
    this.renderer.addClass(document.body, 'bm-theme');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'bm-theme');
  }
}
