import {barnManagerModule} from '../../../index.module';
import moment from 'moment';
import {find} from 'lodash';
import {getEntryTypeOrder} from '../../../domain/feeding/EntryTypes';
import {FeedFileType, FeedReportModalData, FeedReportParams, FeedReportModalComponent} from '../../../../app/feed';
import {MatDialogRef} from '@angular/material/dialog';
import {take} from 'rxjs/operators';

barnManagerModule.controller('EntriesController', EntriesController);

EntriesController.$inject = [
  '$state',
  '$q',
  '$location',
  'backLinkHistory',
  'responseHandler',
  'horseCache',
  'feedService',
  'reportGenerator',
  'barnStorage',
  'entryRepository',
  'horseRepository',
  'EntryHelper',
  'confirmDialog',
  'hasPermission',
  'FileOpener',
  'ng2MatDialog'
];
function EntriesController(
  $state,
  $q,
  $location,
  backLinkHistory,
  responseHandler,
  horseCache,
  feedService,
  reportGenerator,
  barnStorage,
  entryRepository,
  horseRepository,
  EntryHelper,
  confirmDialog,
  hasPermission,
  FileOpener,
  ng2MatDialog
) {
  let vm = this, barn;

  function replaceLastHistory() {
    backLinkHistory.popLink();
    pushHistory();
  }

  function pushHistory() {
    if ($state.params.id) {
      backLinkHistory.pushHorseLink('feed', $state.params.id);
    } else {
      backLinkHistory.pushLink('Feed');
    }
  }
  pushHistory();

  // 0 - current, 1 - next
  const weeksDurations = [0, 1];
  let selectedPeriodStart = $state.params.weekStart;

  vm.hasFeedFullPermission = hasPermission('feed:full');
  vm.periods = [];
  vm.barnHorses = [];
  vm.selectedPeriod = {};
  vm.deletingEntry = {};
  vm.horseId = $state.params.id;
  vm.isSingleHorse = !!$state.params.id;
  vm.deleteEntry = deleteEntry;
  vm.openFeedReportModal = openFeedReportModal;
  vm.exportReport = exportReport;
  vm.downloadSingleHorseReport = downloadSingleHorseReport;
  vm.downloadMultiHorsesReport = downloadMultiHorsesReport;
  vm.selectPeriod = selectPeriod;
  vm.isFeedReportDownloading = false;

  vm.entries = [];
  vm.entryTypes = getEntryTypeOrder();

  vm.getEntryTitle = EntryHelper.getEntryTitle;
  vm.onFeedingNotesUpdate = onFeedingNotesUpdate;
  vm.deleteEntry = deleteEntry;
  vm.showHeaderColumn = showHeaderColumn;

  init();

  function deleteEntry(entry) {
    return confirmDialog.open('Are you sure you want to delete this entry?').then(function() {
      vm.deletingEntry[entry.id] = true;
      return entryRepository.remove(entry.id).then(function() {
        return loadEntries().then(function(entries) {
          responseHandler.successMessage('Entry successfully deleted');
          return entries;
        });
      }).finally(function() {
        vm.deletingEntry[entry.id] = false;
      });
    }).catch(function() {
      return $q.when();
    });
  }

  function onFeedingNotesUpdate(notes) {
    vm.horse.feedingNotes = notes;
    return horseRepository.saveFeedingNotes(vm.horseId, notes);
  }

  function openFeedReportModal() {
    const dialogRef: MatDialogRef<FeedReportModalComponent> = ng2MatDialog.open(FeedReportModalComponent, {
      data: <FeedReportModalData>{
        title: vm.isSingleHorse ? 'Horse Feed Download Options' : 'Multiple Horses Feed Download Options',
        feedType: true,
        isSingleHorse: vm.isSingleHorse,
        horses: vm.barnHorses
      }
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe((params: FeedReportParams) => {
      if (!params?.fileType) return;
      switch (params.fileType) {
        // case FeedFileType.PDF: {
        //   if (vm.isSingleHorse) {
        //     downloadSingleHorseReport(params);
        //   } else {
        //     downloadMultiHorsesReport(params);
        //   }
        //   break;
        // }
        case FeedFileType.PDF_BE: {
          downloadPdf(params);
          break;
        }
        case FeedFileType.CSV: {
          exportReport(params);
          break;
        }
      }
    });
  }

  function downloadPdf(params: FeedReportParams) {
    vm.isFeedReportDownloading = true;
    const period = feedService.calculateWeek(vm.selectedPeriod.date);

    entryRepository.exportToPdf({
      dateRangeStart: period.dateRangeStart,
      dateRangeEnd: period.dateRangeEnd,
      types: params.selectedEntryTypes.map(type => type.value),
      horseIds: vm.isSingleHorse ? [vm.horseId] : [...params.selectedHorses.map(horse => horse.id)],
      orientation: params.orientation,
      fontSize: params.fontSize,
      horsePerPage: params.horsePerPage
    })
      .then((data: Blob) => {
        const fileName = 'feed';
        FileOpener(data, `${fileName}.pdf`);
      })
      .catch(responseHandler.processError)
      .finally(() => vm.isFeedReportDownloading = false);
  }

  function exportReport(params: FeedReportParams) {
    vm.isFeedReportDownloading = true;
    const period = feedService.calculateWeek(vm.selectedPeriod.date);

    entryRepository.exportToCsv({
      dateRangeStart: period.dateRangeStart,
      dateRangeEnd: period.dateRangeEnd,
      types: params.selectedEntryTypes.map(type => type.value),
      horseIds: vm.isSingleHorse ? [vm.horseId] : [...params.selectedHorses.map(horse => horse.id)]
    })
      .then((data: Blob) => {
        const fileName = 'feed';
        FileOpener(data, `${fileName}.csv`);
      })
      .catch(responseHandler.processError)
      .finally(() => vm.isFeedReportDownloading = false);
  }

  function downloadSingleHorseReport(params: FeedReportParams) {
    vm.isFeedReportDownloading = true;
    horseRepository.find(vm.horseId).then(horse => {
      reportGenerator.printOneHorseFeedReport({
        barn: barn,
        horse: horse,
        entries: vm.entriesScheduled,
        period: vm.selectedPeriod,
        entryTypes: params.selectedEntryTypes,
        fontSize: params.fontSize,
        orientation: params.orientation
      }).then(() => vm.isFeedReportDownloading = false);
    });
  }

  function downloadMultiHorsesReport(params: FeedReportParams) {
    vm.isFeedReportDownloading = true;
    reportGenerator.printMultiHorsesFeedReport({
      barn: barn,
      horses: params.selectedHorses,
      period: vm.selectedPeriod,
      horsePerPage: params.horsePerPage,
      entryTypes: params.selectedEntryTypes,
      fontSize: params.fontSize,
      orientation: params.orientation
    }).then(() => vm.isFeedReportDownloading = false);
  }

  function loadEntries() {
    if (vm.isSingleHorse) {
      return feedService.calculateSchedule(vm.selectedPeriod.date, vm.horseId).then(function(result) {
        vm.entriesGrouped = result.entriesGrouped;
        vm.entriesScheduled = result.entriesScheduled;
        return result;
      });
    }

    return feedService.calculateAllSchedules(vm.selectedPeriod.date, vm.barnHorses).then(function(multiHorses) {
      vm.multiHorses = multiHorses;
      return multiHorses;
    });
  }

  function loadHorses() {
    return horseRepository.all().then(function(horses) {
      vm.barnHorses = horses;
      return vm.barnHorses;
    }).finally(() => vm.barnHorsesLoaded = true);
  }

  function loadHorse(horseId) {
    const horse = horseCache.horse();
    if (horse && Number(horse.id) === Number(horseId)) {
      vm.horse = horse;
      return Promise.resolve(horse);
    } else {
      return horseRepository.find(horseId).then(function(data) {
        vm.horse = data;
        return vm.horse;
      });
    }
  }

  function getPeriod(duration) {
    const date = moment().add(duration, 'week');
    return {
      date: date,
      start: feedService.calculateStartWeek(date),
      label: feedService.calculateLabel(date)
    };
  }

  function selectPeriod(period) {
    vm.selectedPeriod = period;
    selectedPeriodStart = period.start;
    if (vm.isSingleHorse) {
      setTimeout(() => {
        $location.search('weekStart', period.start);
        replaceLastHistory();
      }, 0);
    }
    loadEntries();
  }

  function init() {
    barn = barnStorage.getEnv();

    vm.periods = weeksDurations.map(function(duration) {
      return getPeriod(duration);
    });
    let promise;
    if (vm.isSingleHorse) {
      promise = loadHorse(vm.horseId);
    } else {
      promise = loadHorses();
    }
    promise.then(function() {
      const weekInList = find(vm.periods, { start: selectedPeriodStart });
      if (weekInList) {
        vm.selectPeriod(weekInList);
      } else {
        vm.selectPeriod(vm.periods[0]);
      }
    });
  }

  function showHeaderColumn(type, property) {
    return vm.entriesGrouped[type]?.some(entry => !!entry[property]);
  }
}
