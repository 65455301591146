import {ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CsvImportService, DoImportHorsesResponse, HorseImportRow} from './csv-import.service';
import {BehaviorSubject, fromEvent, Observable, of, Subject} from 'rxjs';
import {catchError, filter, finalize, first, map, shareReplay, switchMap, takeUntil, tap} from 'rxjs/operators';
import {FormsModule} from '@angular/forms';
import {SelectionModel} from '@angular/cdk/collections';
import {Router} from '@angular/router';
import {ResponseHandlerService} from '../../core/response-handler/response-handler.service';
import {ButtonLoaderComponent} from '@shared-ui';
import {MatTooltipModule} from '@angular/material/tooltip';

interface HorseImportColDef {
  name: string;
  field: string;
  relatedFields?: string[];
}

interface HorseImportRowData {
  row: HorseImportCellData[];
  position: number;
  rowCssClass?: string;
}

class HorseImportCellData {
  value: any;
  error?: string;

  constructor(
    horsesRow: HorseImportRow,
    col: HorseImportColDef
  ) {
    this.setValue(horsesRow, col);
    if (horsesRow.errors.length > 0) {
      this.setError(horsesRow, col);
    }
  }

  private setValue(horsesRow: HorseImportRow, col: HorseImportColDef): void {
    this.value = col.field.split('.').reduce((a, b) => a[b], horsesRow);
  }

  private setError(horsesRow: HorseImportRow, col: HorseImportColDef): void {
    const error = horsesRow.errors.find(error => {
      return error.resourceField === col.field || col.relatedFields?.includes(error.resourceField);
    });
    if (error) {
      this.error = error.errorText;
      if (!this.value) {
        this.value = 'Invalid value';
      }
    }
  }
}

@Component({
  selector: 'bm-csv-import',
  templateUrl: './csv-import.component.html',
  styleUrls: ['./csv-import.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ButtonLoaderComponent,
    MatTooltipModule
  ],
  providers: [
    CsvImportService
  ]
})
export class CsvImportComponent implements OnInit, OnDestroy {
  columnDefs: HorseImportColDef[] = [
    { name: 'Name', field: 'name'},
    { name: 'Show name', field: 'displayName'},
    { name: 'Sex', field: 'type'},
    { name: 'USEF ID', field: 'usefNumber'},
    { name: 'USEF Membership', field: 'usefMembership'},
    { name: 'FEI Passport ID', field: 'feiPassportNumber'},
    { name: 'FEI Passport Expires', field: 'feiPassportExpirationDate'},
    { name: 'Insurance Company', field: 'insuranceCompany'},
    { name: 'Insurance Policy', field: 'insurancePolicy'},
    { name: 'Insurance Plan', field: 'insurancePlan'},
    { name: 'Insurance Date', field: 'insuranceDate'},
    { name: 'Microchip', field: 'microchipCode'},
    { name: 'Height Hands', field: 'height.hands'},
    { name: 'Height Inches', field: 'height.inches'},
    { name: 'Foal Date', field: 'foalDate', relatedFields: ['foalYear', 'foalMonth', 'foalDay']},
    { name: 'Weight', field: 'weight'},
    { name: 'Breed', field: 'breed'},
    { name: 'Color', field: 'color'},
    { name: 'Sire', field: 'sire'},
    { name: 'Dam', field: 'dam'},
  ];
  file$: BehaviorSubject<File> = new BehaviorSubject<File>(null);
  private horsesRows$: Observable<HorseImportRow[]> = this.getHorsesRows$();
  rowData$: Observable<HorseImportRowData[]> = this.getHorseRowData$();
  fileLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  allImporting$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  selectedImporting$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  selection: SelectionModel<number> = new SelectionModel<number>(true, []);
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef<HTMLElement>;
  private destroy$ = new Subject<void>();

  constructor(
    private csvImportService: CsvImportService,
    private router: Router,
    private responseHandlerService: ResponseHandlerService
  ) { }

  ngOnInit() {
    this.subscribeOnFileInputChange();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private subscribeOnFileInputChange(): void {
    fromEvent(this.fileInput.nativeElement, 'change').pipe(
      takeUntil(this.destroy$),
      map(event => (<HTMLInputElement>event.target).files as FileList),
      filter(files => files.length > 0),
      map(files => files[0])
    ).subscribe(file => {
      this.file$.next(file);
      this.fileInput.nativeElement['value'] = null;
      this.selection.clear();
      this.rowData$ = this.getHorseRowData$();
    });
  }

  clearFile(): void {
    this.file$.next(null);
    this.rowData$ = this.getHorseRowData$();
  }

  isAllSelected(rowData: HorseImportRowData[]): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = rowData.length;
    return numSelected === numRows;
  }

  toggleAllRows(rowData: HorseImportRowData[]): void {
    if (this.isAllSelected(rowData)) {
      this.selection.clear();
      return;
    }
    this.selection.select(...rowData.map((rows, index) => index));
  }

  private getHorsesRows$(): Observable<HorseImportRow[]> {
    return this.file$
      .pipe(
        tap(() => this.selection.clear()),
        switchMap(file => {
          if (!file) {
            return of([]);
          } else {
            return of(file).pipe(
              tap(file => this.fileLoading$.next(true)),
              switchMap((file: File) => {
                const fileReader = new FileReader();
                const loadEvent = fromEvent(fileReader, 'load')
                fileReader.readAsText(file);
                return loadEvent.pipe(map((event: ProgressEvent) => (<FileReader>event.target).result));
              }),
              switchMap(file => {
                return this.csvImportService.getHorseImportRows(file).pipe(
                  map(response => response.horses.map(horse => new HorseImportRow(horse))),
                  finalize(() => this.fileLoading$.next(false)),
                  catchError(() => ([]))
                );
              })
            );
          }
        }),
        shareReplay(1)
      )
  }

  private getHorseRowData$(): Observable<HorseImportRowData[]> {
    return this.horsesRows$.pipe(
      map(horsesRows => horsesRows.map(horsesRow => {
        return {
          row: this.columnDefs.map(col => new HorseImportCellData(horsesRow, col)),
          position: horsesRow.position
        }
      }))
    );
  }

  importSelectedHorses(): void {
    if (!this.selection.hasValue()) {
      return;
    }
    this.horsesRows$.pipe(
      tap(() => this.selectedImporting$.next(true)),
      first(),
      map(horsesRows => this.selection.selected.map(index => horsesRows[index])),
      switchMap(selectedHorsesRows => this.csvImportService.importHorses(selectedHorsesRows).pipe(finalize(() => this.selectedImporting$.next(false))))
    ).subscribe(response => this.onHorsesImportSuccess(response));
  }

  importAllHorses(): void {
    this.horsesRows$.pipe(
      tap(() => this.allImporting$.next(true)),
      first(),
      switchMap(horsesRows => this.csvImportService.importHorses(horsesRows).pipe(finalize(() => this.allImporting$.next(false))))
    ).subscribe(response => this.onHorsesImportSuccess(response));
  }

  private onHorsesImportSuccess(response: DoImportHorsesResponse): void {
    if (response.importedHorses.length > 0) {
      const importedHorsesNamesString = response.importedHorses.map(horse => horse.name).join(',');
      this.responseHandlerService.emitSuccessMessage(`Successfully imported horses: ${importedHorsesNamesString}`);
    }
    if (response.couldNotImportHorses?.length > 0) {
      this.rowData$ = this.getHorseRowData$().pipe(map(rows => {
        return rows.map(row => {
          if (response.couldNotImportHorses.find(horse => horse.position === row.position)) {
            return { ...row, rowCssClass: 'hasError' };
          } else if (response.importedHorses.find(horse => horse.position === row.position)) {
            return { ...row, rowCssClass: 'success' };
          }
          return { ...row };
        });
      }));
      this.responseHandlerService.emitErrorMessage(`Some horses were not imported`, 5000);
    } else {
      this.router.navigate(['/n/horses']);
    }
  }
}
