import {ChangeDetectionStrategy, Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LocalStorageService} from 'ngx-webstorage';
import {Observable} from 'rxjs';
import {startWith} from 'rxjs/operators';
import {StorageKeys} from '../../storage-keys';

@Component({
  selector: 'shared-test-api-endpoint-notification',
  templateUrl: './test-api-endpoint-notification.component.html',
  styleUrls: ['./test-api-endpoint-notification.component.scss'],
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestApiEndpointNotificationComponent {
  testApiEndpoint$: Observable<string> = this.localStorage.observe(StorageKeys.TEST_API_ENDPOINT).pipe(
    startWith(this.localStorage.retrieve(StorageKeys.TEST_API_ENDPOINT))
  );

  constructor(
    private localStorage: LocalStorageService,
  ) {
  }
}
