import {barnManagerModule} from '../../index.module';

barnManagerModule.directive('bmPreviewCard', previewCard);

function previewCard() {

  PreviewCardController.$inject = ['$element', 'titlesService', 'dateTimeUpgraded'];

  return {
    restricted: 'E',
    templateUrl: 'preview-card.html',
    controller: PreviewCardController,
    controllerAs: 'vm',
    bindToController: true,
    transclude: true,
    scope: {
      type: '@',
      item: '=',
      url: '<',
      zoneCode: '=',
      editable: '='
    }
  };

  function PreviewCardController($element, titlesService, dateTimeUpgraded) {
    const vm = this;

    vm.$onInit = function() {

      vm.hasUrl = !!$element.attr('url');
      vm.serverTimeStampToLocalDateTimeFormatted = dateTimeUpgraded.serverTimeStampToLocalDateTimeFormatted;
      vm.getInitials = titlesService.getInitialsLabel;

    };
  }
}
