import {barnManagerModule} from '../../../index.module';

barnManagerModule.controller('ContactSupportController', ContactSupportController);

ContactSupportController.$inject = ['$window', 'backLinkHistory', 'goNative'];
function ContactSupportController($window, backLinkHistory, goNative) {
  const vm = this;
  backLinkHistory.pushLink('Contact Support');

  vm.mailTo = mailTo;
  vm.subject = '';
  vm.message = '';

  function subject() {
    vm.subject = vm.feedback ? 'Feedback' : 'Support';
  }

  function mailTo(form) {
    vm.submitAttempt = true;

    if (!form.$valid) {
      return;
    }

    subject();

    const url = 'mailto:' + vm.subject.toLowerCase() + '@barnmanager.com?subject=' + vm.subject + '&body=' + vm.message;
    goNative.windowOpen(url);
  }
}
