import {barnManagerModule} from '../../../index.module';
import {every, forEach, get, indexOf, map, some} from 'lodash';
import {copy} from 'angular';

barnManagerModule.factory('registrationsHelpers', registrationsHelpers);

registrationsHelpers.$inject = ['$q', 'rAttachments', 'rHorses'];
function registrationsHelpers($q, rAttachments, rHorses) {

  return {
    hasErrors: hasErrors,
    organizationNotEmpty: organizationNotEmpty,
    updateAttachments: updateAttachments
  };

  function organizationNotEmpty(organization) {
    return some(map(['name', 'number', 'expiresOn', 'hasAttachments'], function(key) {
      return get(organization, key);
    }));
  }

  function hasErrors(registrations) {
    const checkList = registrations.map(organizationNotEmpty);
    return !every(checkList);
  }

  // Method must return promise with horse object on success
  function updateAttachments(allFiles, removedFiles, horse, registrations) {
    horse = copy(horse);
    if (allFiles.length === 0 && removedFiles.length === 0) {
      return $q.when(horse);
    }
    const registrationIds = map(registrations, 'id');

    const addPromises = allFiles.reduce(function(acc, attachment, index) {
      if (attachment.new) {
        const payload = new FormData();
        payload.append('file', attachment.rawData);
        acc[index] = rAttachments.save({
          modelType: 'HORSE_ORGANIZATION',
          modelId: horse.id
        }, payload).$promise.then(function(res) {
          return {
            index: indexOf(registrationIds, attachment.minorId),
            registrationId: attachment.minorId,
            attachmentId: res.id
          };
        });
      }
      return acc;
    }, {});

    const delPromises = removedFiles.reduce(function(acc, attachment) {
      acc[attachment.id] = rAttachments.delete({
        id: attachment.id
      }).$promise.then(function() {
        return {
          index: indexOf(registrationIds, attachment.minorId),
          registrationId: attachment.minorId,
          attachmentId: attachment.id
        };
      });
      return acc;
    }, {});

    return $q.all({
      added: $q.all(addPromises),
      deleted: $q.all(delPromises)
    }).then(function(res) {
      forEach(res.added, function(val) {
        horse.registrations[val.index].attachmentIds.push(val.attachmentId);
      });
      forEach(res.deleted, function(val) {
        if (val.index > -1) {
          horse.registrations[val.index].attachmentIds = horse.registrations[val.index].attachmentIds.filter(function(id) {
            return val.attachmentId !== id;
          });
        }
      });
      return rHorses.update({ skipActivityTimeline: true }, horse).$promise;
    });
  }

}
