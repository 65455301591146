import {Injectable} from '@angular/core';
import {RestService} from '@shared/lib/rest/rest.service';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {AuthorizedStorage} from '../core/authorized';
import {ResponseHandlerService} from '../core/response-handler/response-handler.service';
import {FeedPrintOrientation} from './feed-report-modal';

export interface FeedExportRequestModel {
  types: string[];
  horseIds: number[];
  orientation?: FeedPrintOrientation;
  fontSize?: number;
  horsePerPage?: string;
}

@Injectable({ providedIn: 'root' })
export class FeedService {
  constructor(
    private rest: RestService,
    private authorizedStorage: AuthorizedStorage,
    private responseHandlerService: ResponseHandlerService
  ) {
  }

  exportToPdf(model: FeedExportRequestModel): Observable<string> {
    return this.rest.get({
      path: 'feeding/entries/exportToPdf', params: { ...model, tenantEnvironmentId: this.authorizedStorage.tenantEnvironmentId }, responseType: 'text'
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.responseHandlerService.emitHttpError(error);
        return throwError(error);
      })
    )
  }

  exportToCsv(model: FeedExportRequestModel): Observable<string> {
    return this.rest.get({
      path: 'feeding/entries/exportToCsv', params: { ...model, tenantEnvironmentId: this.authorizedStorage.tenantEnvironmentId },  responseType: 'text'
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.responseHandlerService.emitHttpError(error);
        return throwError(error);
      })
    )
  }
}
