import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BmNgSelectModule} from '@shared-ui/bm-ng-select/bm-ng-select.module';
import {NgOptionHighlightModule} from '@shared/lib/ng-select/ng-option-highlight/ng-option-highlight.module';

interface Product {
  id: number;
  tenantEnvId: number;
  name: string;
  brand: string;
  type: string;
}

@Component({
  selector: 'bm-product-select',
  templateUrl: './product-select.component.html',
  styleUrls: ['./product-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    BmNgSelectModule,
    NgOptionHighlightModule
  ]
})
export class ProductSelectComponent {
  @HostBinding('class') classes = 'bm-product-select';
  @Input() products: Product[];
  @Input() selectedProduct: Product;
  @Output() changeSelected: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('productOptionTmp', {static: true}) readonly productOptionTmp: TemplateRef<HTMLElement>;

  customSearchFn(term: string, item: Product): boolean {
    term = term.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().indexOf(term) > -1 || item.brand.toLocaleLowerCase().indexOf(term) > -1;
  }
}
