import {barnManagerModule} from '../../index.module';

barnManagerModule
  .controller('HeaderInteriorController', HeaderInteriorController)
  .component('bmHeaderInterior', {
    templateUrl: 'header-interior.html',
    controller: HeaderInteriorController,
    controllerAs: 'vm',
  });

HeaderInteriorController.$inject = [
  '$state',
  '$scope',
  '$rootScope',
  '$stateParams',
  'barnStorage',
  'horseRepository',
  'horseCache',
  'DefaultAvatars'
];
function HeaderInteriorController(
  $state,
  $scope,
  $rootScope,
  $stateParams,
  barnStorage,
  horseRepository,
  horseCache,
  DefaultAvatars
) {
  const vm = this;
  $rootScope.$broadcast('$showLoader', false);

  vm.horse = {};
  vm.isEditOrAddPage = false;

  const backLinkChanged = $rootScope.$on('backLinkChanged', function(e, data) {
    const currentUrl = window.location.pathname;
    const backLinkUrl = data.url;
    vm.isEditOrAddPage = currentUrl === backLinkUrl;
  });
  $scope.$on('$destroy', backLinkChanged);
  $rootScope.$on('$destroy', $rootScope.$on('$stateChangeSuccess', init));

  init();

  function isHorseHeader() {
    return typeof $state.current.data !== 'undefined' && $state.current.data.horseHeader;
  }

  function init() {
    if (isHorseHeader()) {
      if (horseCache.inStorage()) {
        vm.horse = horseCache.loadFromStorage();
        vm.name = vm.horse.name;
      } else if (horseCache.horse()) {
        vm.horse = horseCache.horse();
        vm.name = vm.horse.name;
      } else {
        horseRepository.find($stateParams.id).then(function(horse) {
          vm.horse = horse;
          vm.name = vm.horse.name;
          horseCache.setHorse(horse);
        });
      }
      vm.class = 'app-main__header--interior--horse';
      vm.avatar = DefaultAvatars.horse;
    } else {
      vm.barn = barnStorage.getEnv();
      vm.name = vm.barn.tenant.name;
      vm.avatar = './assets/images/barn-avatar.png';
    }
  }
}
