<article class="app-main__content-area content-area">
  <header class="app-main__content-area__header filters-area">
    <div class="flex-area">
      <div class="flex-area__col flex-area__col--as-start page-name-area">
        <h2 id="content_primary_title" class="primary-heading ng-binding">Reports</h2>
      </div>
      <div *ngIf="builtReport$ | async" class="flex-area__col flex-area__col--unset">
        <button type="button" class="btn-icon" (click)="downloadReport()">
          <i class="fa fa-print" aria-hidden="true"></i>
          <i [ngStyle]="{'visibility': (reportLoading$ | async) === true ? 'visible' : 'hidden'}" class="fa fa-spinner fa-spin" aria-hidden="true"></i>
          <span class="btn-icon__text">Download Report</span>
        </button>
      </div>
    </div>
    <bm-report-filters
      [horseId]="horseId"
      (filtersChanged)="filters$.next($event)"
      (downloadFeedReport)="onDownloadFeedReport($event)">
    </bm-report-filters>
  </header>

  <hr class="smaller">

  <mat-progress-bar [ngStyle]="{'visibility': (reportLoading$ | async) === true ? 'visible' : 'hidden'}" mode="query" color="blue"></mat-progress-bar>

  <ng-container *ngIf="builtReport$ | async as builtReport">
    <div *ngIf="builtReport.rows?.length > 0" class="report-table" [ngStyle]="{'height': containerHeight$ | async}">
      <cdk-virtual-scroll-viewport #scrollViewport itemSize="40" class="table-viewport">
        <table class="table table-striped">
          <thead>
          <tr>
            <th *ngFor="let col of builtReport.headers" scope="col" class="cursor-pointer">
              <div>
                <span>{{ col.name }}</span>
              </div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *cdkVirtualFor="let row of builtReport.rows">
            <td *ngFor="let col of builtReport.headers">
              <ng-container *ngIf="row[col.key] as value">
                <ng-container [ngSwitch]="col.type">
                  <ng-container *ngSwitchCase="BuildReportResponseHeaderType.Color">
                    <span class="content-item__header__title__color" style="background-color: {{ value }}">Color</span>
                  </ng-container>

                  <ng-container *ngSwitchCase="BuildReportResponseHeaderType.Date">
                    {{ value | date : 'MM.dd.yyyy' }}
                  </ng-container>
                  <ng-container *ngSwitchCase="BuildReportResponseHeaderType.Time">
                    {{ value | date : 'shortTime' }}
                  </ng-container>
                  <ng-container *ngSwitchCase="BuildReportResponseHeaderType.Details">
                    <div *ngFor="let mapEntry of value | keyvalue">
                      <a [href]="mapEntry.value" target="_blank"> {{ mapEntry.key }} </a>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="BuildReportResponseHeaderType.TooltipString">
                    <span #tooltip="matTooltip" matTooltipClass="bm-tooltip" [matTooltip]="value">{{ value }}</span>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <span>{{ value }}</span>
                  </ng-container>
                </ng-container>
              </ng-container>
            </td>
          </tr>
          </tbody>
        </table>
      </cdk-virtual-scroll-viewport>
    </div>
  </ng-container>

  <div *ngIf="(builtReport$ | async) === null || (builtReport$ | async)?.rows?.length === 0" class="app-table border-bottom">
    <div class="app-table__placeholder font-italic">
      <ng-container *ngIf="filters$.value?.valid; else showRequiredFieldsMessage">
        <ng-container *ngIf="(reportLoading$ | async) === true; else reportLoaded">Loading...</ng-container>
        <ng-template #reportLoaded>No preview available</ng-template>
      </ng-container>
      <ng-template #showRequiredFieldsMessage>{{ getRequiredFieldsMessage() }}</ng-template>
    </div>
  </div>
</article>
