import * as moment from 'moment';
import {Moment, MomentInput} from 'moment';

export class DateConverter {
  static momentAsISOString(momentDate: Moment): string {
    if (!momentDate) {
      return null;
    }
    const date: Date = momentDate.toDate();
    const tzOffset: number = date.getTimezoneOffset() * 60000;
    return (new Date(date.getTime() - tzOffset)).toISOString();
  }

  static momentAsDateString(momentDate: Moment): string {
    if (!momentDate) {
      return null;
    }

    return momentDate.format('YYYY-MM-DD');
  }

  static ISOStringAsMoment(datetime: string): Moment {
    if (!datetime) {
      return null;
    }
    const date: string = datetime.split('T')[0];
    return moment(date, 'YYYY-MM-DD');
  }

  static momentFormat(datetime: MomentInput, format: string): string {
    if (!datetime) {
      return null;
    }
    return moment(datetime, moment.HTML5_FMT.DATETIME_LOCAL_MS).format(format);
  }

  static ISODateToDate(date: string): Date {
    if (!date) {
      return null;
    }
    return moment(date).toDate();
  }
}

