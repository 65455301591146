import {barnManagerModule} from '../../index.module';
import {copy} from 'angular';

barnManagerModule.directive('bmCreateComment', createComment);

function createComment() {

  CreateComment.$inject = [
    'commentRepository',
    'responseHandler',
    'rAttachments',
    'userStorage',
    'listsService'
  ];

  return {
    restricted: 'E',
    templateUrl: 'create-comment.html',
    controller: CreateComment,
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      members: '=',
      modelType: '=',
      model: '=',
      comments: '=',
      allUsers: '<',
      onAdd: '<',
      maxCommentLength: '='
    }
  };

  function CreateComment(
    commentRepository,
    responseHandler,
    rAttachments,
    userStorage,
    listsService
  ) {
    let vm = this, removedFiles = [], membersOrigin = [];

    vm.$onInit = function() {

      vm.user = {};
      vm.toggle = toggle;
      vm.addComment = addComment;
      vm.comment = {
        message: '',
        mentionedUserIds: [],
        allTeamMembersNotifications: false
      };
      vm.editMode = false;
      vm.show = show;
      vm.hide = hide;
      vm.showErrors = false;
      vm.membersChanged = membersChanged;

      init();

      function init() {
        vm.files = [];
        vm.collapsed = true;
        vm.user = userStorage.getUser();
        vm.user.initials = vm.user.firstName.charAt(0) + vm.user.lastName.charAt(0);
      }

      function copyNotifications() {
        membersOrigin = copy(vm.members);
      }

      function resetNotifications() {
        vm.members = membersOrigin;
      }

      function toggle() {
        vm.collapsed = !vm.collapsed;
      }

      function hide() {
        vm.showErrors = false;
        resetNotifications();
        toggle();
      }

      function show() {
        copyNotifications();
        toggle();
      }

      function clean() {
        vm.comment.message = '';
        vm.files = [];
        removedFiles = [];
        vm.showErrors = false;
      }

      function membersChanged(model) {
        vm.comment.mentionedUserIds = model.memberIds;
        vm.comment.allTeamMembersNotifications = model.allTeamMembersNotifications;
      }

      function addComment() {
        if (vm.loading) {
          return;
        }
        if (vm.comment.message && vm.comment.message.length > vm.maxCommentLength) {
          vm.showErrors = true;
          return;
        }

        vm.loading = true;

        const body = {
          message: vm.comment.message ? vm.comment.message : '',
          mentionedUserIds: vm.comment.mentionedUserIds ? vm.comment.mentionedUserIds : [],
          allTeamMembersNotifications: !!vm.comment.allTeamMembersNotifications
        };

        commentRepository.add(vm.modelType, vm.model, body, vm.isNotified).then(function(comment) {
          responseHandler.successOnSave('Comment', false);
          listsService.resetTo('comments', 'COMMENT');
          listsService.updateAttachments(vm.files, removedFiles, { id: comment.id });
          listsService.ready(function() {
            vm.isNotified = false;
            comment.displayName = vm.user.displayName;
            comment.initials = vm.user.initials;
            comment.canEdit = true;
            comment.new = true;
            comment.memberIds = comment.mentionedUserIds;
            rAttachments.query({ modelType: 'Comment', modelId: comment.id }).$promise.then(function(attachments) {
              comment.files = attachments;
              vm.onAdd(comment);
              toggle();
              clean();
            });
          });
        }).catch(responseHandler.processError).finally(() => vm.loading = false);
      }

    };
  }
}
