import {Inject, Injectable, InjectionToken} from '@angular/core';
import {GoNativeNavigationItem} from './go-native-navigation-item';

export const IS_GO_NATIVE = new InjectionToken<boolean>('Is Go Native');

@Injectable()
export class GoNativeService {
  origin: string = `${window.location.origin}/`;

  constructor(
    @Inject(IS_GO_NATIVE) public isGoNative: boolean
  ) {
  }

  isIOS() {
    return navigator.userAgent.indexOf('GoNativeIOS') > -1;
  }

  isAndroid() {
    return navigator.userAgent.indexOf('GoNativeAndroid') > -1;
  }

  setGoNativeSidebarItems(items: Array<GoNativeNavigationItem>, enabled: boolean, persist: boolean): void {
    const jsonItems = JSON.stringify(items);
    window.location.href = `gonative://sidebar/setItems?items=${encodeURIComponent(jsonItems)}&enabled=${enabled}&persist=${persist}`;
  }

  oneSignalRemoveExternalUserId(): void {
    if (this.isGoNative) {
      window['gonative'].onesignal.externalUserId.remove();
    }
  }

  loggedOut(): void {
    if (this.isGoNative) {
      this.setGoNativeSidebarItems([], false, false);
    }
  }

  downloadImage(url: string): void {
    window.location.href = 'gonative://share/downloadImage?url=' + encodeURIComponent(url);
  }

  downloadFile(url: string): void {
    window.location.href = 'gonative://share/downloadFile?url=' + encodeURIComponent(url);
  }
}
