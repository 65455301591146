import {barnManagerModule} from '../index.module';
import * as angular from 'angular';

barnManagerModule
  .factory('rAttachments', ['$resource', ($resource) => {

    return $resource('/api/attachments/:id', {
      id: '@id'
    }, {
      'query' : {
        method: 'GET',
        url: '/api/attachments',
        isArray: true
      },
      'delete': {
        method: 'DELETE'
      },
      'get': {
        method: 'GET',
        responseType: 'arraybuffer',
        transformResponse: function(data, headers) {
          return { data : data, headers : headers };
        }
      },
      'save': {
        url: '/api/attachments/',
        headers : {'Content-Type': undefined},
        transformRequest: angular.identity,
        method: 'POST'
      }
    });
  }]);
