import {barnManagerModule} from '../../index.module';

barnManagerModule.directive('bmMainNavigation', bmNavigation);

bmNavigation.$inject = ['moduleAccessService'];
function bmNavigation(moduleAccessService) {

  MainNavigation.$inject = ['$state'];

  return {
    restricted: 'E',
    templateUrl: 'main-navigation.html',
    controller: MainNavigation,
    controllerAs: 'nav',
    bindToController: true,
    transclude: true
  };
  function MainNavigation($state) {
    const vm = this;

    vm.$onInit = function() {

      vm.navOpen = false;

      vm.isCurrent = isCurrent;
      vm.canShowModule = canShowModule;
      vm.closeMenu = closeMenu;

      function canShowModule(name) {
        return moduleAccessService.hasModuleAccess(name);
      }

      function isCurrent(stateName) {
        return stateName == $state.current.name ? 'page' : '';
      }

      function closeMenu() {
        vm.navOpen = false;
      }
    };
  }
}
