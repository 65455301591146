import {barnManagerModule} from '../index.module';
import {merge} from 'lodash';

barnManagerModule.factory('rUsefImportRequests', rUsefImportRequests);

rUsefImportRequests.$inject = ['$http', 'barnStorage'];
function rUsefImportRequests($http, barnStorage) {
  const url = '/api/usef-import-requests';

  return {
    requests: requests,
    requestImport: requestImport,
    delete: deleteRequest,
    createNewHorse: createNewHorse,
    mergeHorse: mergeHorse,
    checkStatus: checkStatus
  };

  function requests() {
    const config = {
      params: mergeParams()
    };
    return $http.get(url, config).then(transformResult);
  }

  function requestImport(body) {
    return $http.post(url, body).then(transformResult);
  }

  function deleteRequest(id) {
    return $http.delete(url + '/' + id).then(transformResult);
  }

  function createNewHorse(id) {
    return $http.post(url + '/' + id + '/create-new-horse').then(transformResult);
  }

  function mergeHorse(body) {
    return $http.post(url + '/' + body.id + '/merge-horse', body).then(transformResult);
  }

  function checkStatus(id) {
    return $http.post(url + '/' + id + '/check-status').then(transformResult);
  }

  function transformResult(result) {
    return result.data;
  }

  function mergeParams(params?) {
    const defaultParams = {
      tenantEnvId: barnStorage.getEnvId()
    };
    return merge(defaultParams, params || {});
  }
}
