import {barnManagerModule} from '../index.module';
import {includes} from 'lodash';

barnManagerModule.directive('bmFocusableError', focusableError);

function focusableError() {
  /**
    For using with bm-accessible-form
    Use bm-focusable-error attribute for custom errors (non-angular)
      <span bm-focusable-error="vm.error">Text</span>
    WARNING!
      uib-datepicker-popup conflicts with bm-focusable-error directive
  */
  return {
    restrict: 'A',
    scope: {
      error: '<bmFocusableError'
    },
    link: function(scope, elem) {
      const nodeName = elem[0].nodeName;
      if (!includes(['INPUT', 'SELECT', 'TEXTAREA', 'A', 'BUTTON', 'FORM'], nodeName)) {
        // tabindex="-1" means that the element is not reachable via sequential keyboard navigation,
        // but could be focused with Javascript or visually.
        elem.attr('tabindex', '-1');
      }
      scope.$watch('error', function(error) {
        if (error) {
          elem[0].classList.add('bm-custom-invalid');
        } else {
          elem[0].classList.remove('bm-custom-invalid');
        }
      });
    }
  };
}
