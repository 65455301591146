import {barnManagerModule} from '../../../index.module';

barnManagerModule
  .component('bmEventActionsMenu', {
    templateUrl: 'event-actions-menu.html',
    controller: horseActionsMenuController,
    controllerAs: 'vm',
    bindings: {
      event: '=',
      horseId: '=',
      instance: '=',
      showPrint: '=',
      onSuccessRemove: '<'
    }
  });

horseActionsMenuController.$inject = [
  'hasPermission',
  'goNative',
  'eventsService'
];
function horseActionsMenuController(
  hasPermission,
  goNative,
  eventsService
) {
  const vm = this;

  vm.hasFullPermission = hasPermission('events:full') || hasPermission('lessons:full') ;
  vm.isGoNative = goNative.isGoNative();

  vm.print = print;
  vm.edit = edit;
  vm.remove = remove;

  function print() {
    window.print();
  }

  function edit() {
    eventsService.navigateToEdit(vm.event,  vm.horseId, vm.instance);
  }

  function remove() {
    eventsService.remove(vm.event, vm.instance).then(function() {
      if (vm.onSuccessRemove) {
        vm.onSuccessRemove();
      }
    });
  }


}
