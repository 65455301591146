import {barnManagerModule} from '../index.module';

barnManagerModule.factory('notificationRepository', NotificationsRepository);

NotificationsRepository.$inject = ['$rootScope', '$http'];
function NotificationsRepository($rootScope, $http) {
  const url = '/api/notifications';

  let unreadNotifications = 0;

  return {
    all: all,
    update: update,
    readAll: readAll,
    getUnreadNotifications: getUnreadNotifications,
    changeUnreadNotifications: changeUnreadNotifications
  };

  function all(pageSize, timestamp, showOnlyUnread) {
    const params: any = {
      pagesize: pageSize || 10
    };
    if (timestamp) {
      params.timestamp = timestamp;
    }
    if (showOnlyUnread) {
      params.unread = true;
    }

    return $http.get(url, { params, data: { ignoreFailed: true} }).then(function(results) {
      return results.data;
    });
  }

  function update(notification) {
    const body = {
      value: !notification.unread
    };
    return $http.put(`${url}/${notification.notificationId}`, body).then(() => {
      changeUnreadNotifications(unreadNotifications + Number(notification.unread ? '+1' : '-1'));
    });
  }

  function readAll() {
    return $http.put(`${url}/readAll`).then(() => {
      changeUnreadNotifications(0);
    });
  }

  function getUnreadNotifications() {
    return unreadNotifications;
  }

  function changeUnreadNotifications(unread) {
    if (unread < 0) {
      return;
    }
    unreadNotifications = unread;
    $rootScope.$broadcast('unreadNotificationsChanged', unreadNotifications);
  }
}
