import {barnManagerModule} from '../index.module';
import {merge} from 'lodash';

barnManagerModule.factory('recordRepository', RecordRepository);

RecordRepository.$inject = ['$http', 'barnStorage'];
function RecordRepository($http, barnStorage) {
  const url = '/api/records';
  const v2url = url + '/v2/';

  return {
    search: search,
    find: find,
    update: update,
    archive: archive,
    restore: restore,
    create: create,
    remove: remove,
    togglePin: togglePin,
    markAsRead: markAsRead,
    detailsReport: detailsReport
  };

  function search(params) {
    return $http.get(url, {
      params: mergeParams(params)
    }).then(transformResult);
  }

  function find(id) {
    return $http.get(v2url + id).then(transformResult);
  }

  function togglePin(id) {
    return $http.put(v2url + id + '/pin');
  }

  function update(record) {
    return $http.put(v2url + record.id, record).then(transformResult);
  }

  function archive(id) {
    return $http.put(`${v2url}${id}/archive`).then(transformResult);
  }

  function restore(id) {
    return $http.put(`${v2url}${id}/restore`).then(transformResult);
  }

  function create(record) {
    return $http.post(v2url, record).then(transformResult);
  }

  function remove(id) {
    return $http.delete(v2url + id);
  }

  function markAsRead(id) {
    return $http.put(v2url + id + '/read');
  }

  function mergeParams(params) {
    const defaultParams = {
      tenantEnvironmentId: barnStorage.getEnvId(),
      direction: 'DESC'
    };
    return merge(defaultParams, params || {});
  }

  function transformResult(result) {
    return result.data;
  }

  function detailsReport(id) {
    return $http.get(v2url + id + '/details-report', null, { transformResponse: [ (data: any) => data] }).then((result) => result.data);
  }
}
