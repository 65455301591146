import {barnManagerModule} from '../../index.module';

barnManagerModule.directive('bmPreviewContactDetailsCard', previewContactDetailsCard);

function previewContactDetailsCard() {

  PreviewContactDetailsCardController.$inject = ['utils', 'titlesService', 'DefaultAvatars'];

  return {
    restricted: 'E',
    templateUrl: 'preview-contact-details-card.html',
    controller: PreviewContactDetailsCardController,
    controllerAs: 'vm',
    bindToController: true,
    transclude: true,
    scope: {
      item: '=',
      url: '<'
    }
  };

  function PreviewContactDetailsCardController(utils, titlesService, DefaultAvatars) {
    const vm = this;

    vm.$onInit = function() {
      vm.timezone = utils.timezone;
      vm.getInitials = titlesService.getInitialsLabel;
      vm.DefaultAvatars = DefaultAvatars;
    };
  }
}
