import {barnManagerModule} from '../../../index.module';
import {each, get, trim} from 'lodash';

barnManagerModule.controller('UsefImportController', UsefImportController);

UsefImportController.$inject = [
  '$q',
  'backLinkHistory',
  'rUsefImportRequests',
  'messageBus',
  'responseHandler',
  'MessageFrames',
  'usefUtils',
  'usefMessages',
  'subscriptionStorage'
];
function UsefImportController(
  $q,
  backLinkHistory,
  rUsefImportRequests,
  messageBus,
  responseHandler,
  MessageFrames,
  usefUtils,
  usefMessages,
  subscriptionStorage
) {
  const vm = this;
  backLinkHistory.pushLink('USEF import');

  vm.searching = false;
  vm.requests = [];
  vm.currentType = 'PENDING';
  vm.usef = {};

  vm.setCurrentType = setCurrentType;
  vm.requestImport = requestImport;

  vm.typeList = {
    PENDING: 'Pending',
    IMPORTED: 'Imported',
    REVOKED: 'Revoked'
  };

  init();

  function loadRequests() {
    rUsefImportRequests.requests().then(function(response) {
      vm.requests = response.records;
    });
  }

  function setCurrentType(type) {
    vm.currentType = type;
    vm.navOpen = false;
  }

  function requestImport() {
    if (vm.usefNumber.trim().length === 0) {
      return;
    }
    messageBus.clear(MessageFrames.DEFAULT);
    vm.searching = false;

    let hasInvalid = false;
    const horseIds = [];
    each(vm.usefNumber.split(','), function(id) {
      id = parseInt(trim(id));
      if (isNaN(id) || id > 999999999) {
        hasInvalid = true;
        return;
      }
      horseIds.push(id);
    });

    if (hasInvalid && horseIds.length === 0) {
      messageBus.push(usefMessages.noVailidUsefNumbers);
      return;
    }
    if (hasInvalid) {
      messageBus.push(usefMessages.hasInvalidUsefNumbers);
    }

    vm.searching = true;

    const promises = horseIds.map(function(id) {
      rUsefImportRequests.requestImport({ id: id }).then(function(response) {
        messageBus.push(usefUtils.mapStatusToMessage(response));
        loadRequests();
      }).catch(responseHandler.processError);
    });
    $q.all(promises).finally(function() {
      vm.usefNumber = '';
      vm.searching = false;
    });
  }

  function init() {
    loadRequests();
    subscriptionStorage.getSubscriptionPromise().then(function(subscription) {
      vm.usef = get(subscription, 'usef');
    });
  }

}
