import {barnManagerModule} from './index.module';

const VendorTypes = {
  DENTIST: { name: 'Dentist', value: 'DENTIST', title: 'Dentist' },
  VET: { name: 'Vet', value: 'VET', title: 'Vet' },
  FARRIER: { name: 'Farrier', value: 'FARRIER', title: 'Farrier' },
  OTHER: { name: 'Other', value: 'OTHER', title: 'Vendor' }
};

const TreatmentTypes = {
  COMPOUND_PRODUCT: 'Compound / Product name',
  MEDICATION: 'Medication',
  VACCINE: 'Vaccine',
  NONE: 'None'
};

const RepeatingFrequencies = {
  DAILY: { internal: 'DAILY', how: 'Daily', every: 'Days', singular: 'Day' },
  WEEKLY: { internal: 'WEEKLY', how: 'Weekly', every: 'Weeks', singular: 'Week' },
  MONTHLY: { internal: 'MONTHLY', how: 'Monthly', every: 'Months', singular: 'Month' },
  YEARLY: { internal: 'YEARLY', how: 'Yearly', every: 'Years', singular: 'Year' }
};

const ListTypes = {
  CHECKBOX_LIST: 'check',
  TABLE_LIST: 'table'
};

barnManagerModule
  .constant('BM_VERSION', '4.3.0')
  .constant('API_VERSION', '4.0.1')
  .constant('isFirefox', navigator.userAgent.toLowerCase().indexOf('firefox') > -1)
  .constant('SERVER_HOURS_DIFF', 6)
  .constant('FREE_PLAN', 'free')
  .constant('TRIALING_STATUS', 'trialing')
  .constant('USER_FACING_DATE_FORMAT', 'MM/DD/YYYY')
  .constant('ANGULAR_DATE_FORMAT', 'MM/dd/yyyy')
  .constant('DATE_FORMAT', 'MM/DD/YYYY')
  .constant('DATE_TIME_FORMAT', 'MM/DD/YYYY h:mm A')
  .constant('DATE_ISO_FORMAT', 'YYYY-MM-DD')
  .constant('DATE_PICKER_FORMAT', 'MM/dd/yyyy')
  .constant('DATE_INPUT_MIN_VALUE', '2000-01-01')
  .constant('DefaultAvatars', {
    horse: './assets/images/placeholder.jpg',
    person: './assets/images/person_placeholder.png'
  })
  .constant('OWNER_ROLE_ID', 1)
  .constant('NOTE_LENGTH_LIMIT', 2000) //need to be changed manualy for maxlength on textarea's
  .constant('PAGE_SIZE', 10)
  .constant('ListTypes', ListTypes)
  .constant('VendorTypes', VendorTypes)
  .constant('TreatmentTypes', TreatmentTypes)
  .constant('DENTIST', { name: 'Dentist', value: 'DENTIST' })
  .constant('VET', { name: 'Vet', value: 'VET' })
  .constant('FARRIER', { name: 'Farrier', value: 'FARRIER' })
  .constant('VACCINATION', { name: 'Vaccination', value: 'VACCINATION', treatmentDisplayName: 'Vaccine' })
  .constant('DEWORMING', { name: 'Deworming ', value: 'DEWORMING', treatmentDisplayName: 'Compound' })
  .constant('MEDICATION', { name: 'Medication', value: 'MEDICATION', treatmentDisplayName: 'Medication' })
  .constant('SHOW', { name: 'Show', value: 'SHOW' })
  .constant('LESSON', { name: 'Lesson', value: 'LESSON' })
  .constant('OTHER', { name: 'Other', value: 'OTHER' })
  .constant('DENTIST_APPOINTMENT', { name: 'Dentist Appointment', value: 'DENTIST_APPOINTMENT' })
  .constant('FARRIER_APPOINTMENT', { name: 'Farrier Appointment', value: 'FARRIER_APPOINTMENT' })
  .constant('VET_APPOINTMENT', { name: 'Vet Appointment', value: 'VET_APPOINTMENT' })
  .constant('RepeatingFrequencies', RepeatingFrequencies)
  .constant('WEEKDAYS', {
    'SU': { label: 'Sunday', value: 'SU', index: 0 },
    'MO': { label: 'Monday', value: 'MO', index: 1 },
    'TU': { label: 'Tuesday', value: 'TU', index: 2 },
    'WE': { label: 'Wednesday', value: 'WE', index: 3 },
    'TH': { label: 'Thursday', value: 'TH', index: 4 },
    'FR': { label: 'Friday', value: 'FR', index: 5 },
    'SA': { label: 'Saturday', value: 'SA', index: 6 }
  })
  .constant('PATTERNS', {
    email: new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/), // eslint-disable-line
    fax: new RegExp('^(\\d{3}-?\\s?\\d{3}-?\\s?\\d{4}\\s?)'),
    alphanumeric: new RegExp('^\\w+$'),
    password: new RegExp('^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$'),
    number: new RegExp('^[0-9]*$'),
    monthDay: new RegExp('^(3[01]|[12][0-9]|[1-9])$'),
    fullDate: new RegExp('^(\\d{2}/?\\s?\\d{2}/?\\s?\\d{4}\\s?)')
  })
  .constant('CREDENTIALS', {
    clientId: 'barnmanager-trusted-client',
    clientSecret: 'secret'
  })
  .constant('ContactRoles', {
    EMPLOYEE: 'Employee',
    LESSON_STUDENT: 'Lesson student',
    OWNER: 'Owner',
    RIDER: 'Rider',
    VENDOR: 'Vendor',
    VOLUNTEER: 'Volunteer',
    OTHER: 'Other'
  });
