import {barnManagerModule} from '../index.module';
import {cloneDeep, forEach} from 'lodash';

barnManagerModule.factory('errorNotificator', ErrorNotificator);

ErrorNotificator.$inject = ['sessionStorageService'];
function ErrorNotificator(sessionStorageService) {

  return {
    captureException: captureException
  };

  function captureException(message, data, asObjectParams) {
    const user = sessionStorageService.getUser();
    const userWithData = cloneDeep(user) || {};

    if (data) {
      if (asObjectParams) {
        forEach(data, function(value, key) {
          userWithData['__' + key] = value;
        });
      } else {
        userWithData.__additionalData = data;
      }
    }
  }
}
