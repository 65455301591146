import {barnManagerModule} from '../index.module';
import {includes} from 'lodash';

barnManagerModule
  .factory('VendorRestrictions', ['VendorTypes', (VendorTypes) => {
    const All = function() {
    };
    All.prototype.name = 'All';
    All.prototype.filter = function(_vendor) {
      return true;
    };

    const None = function() {
      this.isNone = true;
    };
    None.prototype.name = 'None';
    None.prototype.filter = function(_vendor) {
      return false;
    };

    const MultipleTypes = function(types, label) {
      this.types = types;
      this.label = label;
    };
    MultipleTypes.prototype.name = 'MultipleTypes';
    MultipleTypes.prototype.filter = function(vendor) {
      if (!vendor) {
        return false;
      }
      return includes(this.types.map(function(type) {
        return type.value;
      }), vendor.vendorType);
    };

    const TypeWithOther = function(type, label) {
      this.type = type;
      this.label = label;
    };
    TypeWithOther.prototype.name = 'TypeWithOther';
    TypeWithOther.prototype.filter = function(vendor) {
      if (!vendor) {
        return false;
      }
      return includes([this.type.value, VendorTypes.OTHER.value], vendor.vendorType);
    };

    const Type = function(type, label) {
      this.type = type;
      this.label = label;
    };
    Type.prototype.name = 'Type';
    Type.prototype.vendorType = function() {
      return this.type.value;
    };
    Type.prototype.filter = function(vendor) {
      if (!vendor) {
        return false;
      }
      return vendor.vendorType === this.type.value;
    };

    return {
      All: All,
      None: None,
      Type: Type,
      TypeWithOther: TypeWithOther,
      MultipleTypes: MultipleTypes
    };
  }]);
