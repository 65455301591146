import {barnManagerModule} from '../../index.module';

barnManagerModule
  .component('bmRichTextEditor', {
    templateUrl: 'rich-text-editor.html',
    controller: RichTextEditorController,
    controllerAs: 'vm',
    bindings: {
      content: '=',
      placeholder: '@'
    }
  });

function RichTextEditorController() {
  const vm = this;

  vm.onCreated = onCreated;

  function onCreated(quill) {
    let toolbar = quill.getModule('toolbar');

    // Custom matcher for "li" tag,  it removes extra empty lines in lists
    quill.clipboard.addMatcher('li', (node, delta) => {
      delta.ops.forEach(op => {
        op.insert = op.insert.replace('\n\n', '\n');
      });
      return delta;
    });

    // prepends "https://" to a link href in tooltip, if doesn't start with neither "http://", nor "https://"
    // "function" is needed to preserve "this"
    toolbar.addHandler('link', function(value) {
      if (value) {
        let range = this.quill.getSelection();
        if (range == null || range.length == 0) return;
        let preview = this.quill.getText(range);
        if (!(preview.startsWith("http://") || preview.startsWith("https://"))) {
          preview = 'https://' + preview;
        }
        let tooltip = this.quill.theme.tooltip;
        tooltip.edit('link', preview);
      } else {
        this.quill.format('link', false);
      }
    });
  }
}
