import {barnManagerModule} from '../index.module';
import {merge} from 'lodash';

barnManagerModule
  .factory('productRepository', ['$http', 'barnStorage', ($http, barnStorage) => {

    const url = '/api/feeding/products/';

    return {
      add: add,
      search: search
    };

    function search(params) {
      const config = {
        params: mergeParams(params)
      };
      return $http.get(url, config).then(transformResult);
    }

    function add(body) {
      return $http.post(url, body).then(transformResult);
    }

    function transformResult(result) {
      return result.data;
    }

    function mergeParams(params) {
      const defaultParams = {
        tenantEnvId: barnStorage.getEnvId()
      };
      return merge(defaultParams, params || {});
    }
  }]);
