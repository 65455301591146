import {barnManagerModule} from '../index.module';
import {set} from 'lodash';

barnManagerModule.factory('moduleAccessService', ModuleAccessService);

ModuleAccessService.$inject = [
  '$q',
  '$state',
  '$location',
  '$rootScope',
  '$timeout',
  'userStorage',
  'subscriptionStorage',
  'newFeaturesPolicy',
  'ModuleAccessList',
  'responseHandler',
  'MessageFrames'
];
function ModuleAccessService(
  $q,
  $state,
  $location,
  $rootScope,
  $timeout,
  userStorage,
  subscriptionStorage,
  newFeaturesPolicy,
  ModuleAccessList,
  responseHandler,
  MessageFrames
) {
  let moduleAccess = {};

  return {
    hasModuleAccess: hasModuleAccess,
    getAccessPolicy: getAccessPolicy,
    getModuleList: getModuleList,
    resolveModuleAccess: resolveModuleAccess
  };

  function resolveModuleAccess(moduleName) {
    return getModuleList().then(function(moduleList) {
      const deferred = $q.defer();
      const hasAccess = hasModuleAccess(moduleName);

      if (hasAccess) {
        return $q.resolve(moduleName);
      }

      let rejectPath = moduleList[0];
      if (rejectPath !== 'reactivate' && moduleName !== 'reactivate') {
        rejectPath = 'noaccess';
      }

      // not working without small timeout
      $timeout(function() {
        $state.go(rejectPath);
        deferred.reject(rejectPath);
      }, 10);

      return deferred.promise;
    });
  }

  function hasModuleAccess(moduleName) {
    return moduleAccess[moduleName] &&
           newFeaturesPolicy.canShowModule(moduleName);
  }

  function getModuleList() {
    return subscriptionStorage.getSubscriptionPromise().then(createModuleList).catch(function(error) {
      responseHandler.processError(error, null, MessageFrames.TOP);
    });
  }

  function getAccessPolicy(subscription) {
    let accessPolicy;

    if (subscription.isCanceled()) {
      accessPolicy = 'canceled';
    } else if (subscription.isTrialing()) {
      accessPolicy = subscription.getAccessPolicy();
    } else {
      accessPolicy = subscription.getAccessPolicy();
    }

    if (ModuleAccessList[accessPolicy] == null) { // some unknown plan, maybe legacy, or some new one
      accessPolicy = 'essential';
    }
    return accessPolicy;
  }

  function createModuleList(subscription) {
    let moduleList = ModuleAccessList[getAccessPolicy(subscription)];

    moduleAccess = moduleList.reduce(function(acc, moduleName) {
      return set(acc, moduleName, true);
    }, {});

    return moduleList;
  }
}
