import {barnManagerModule} from '../../index.module';
import {RRule} from 'rrule';
import {get, isEmpty, isNumber, isString, pick, pickBy} from 'lodash';
import moment from 'moment';

barnManagerModule.factory('RRuleHelper', RRuleHelper);

RRuleHelper.$inject = ['DATE_ISO_FORMAT'];
function RRuleHelper(DATE_ISO_FORMAT) {
  return {
    parseDate: parseDate,
    filterRRule: filterRRule,
    normalizeSchedule: normalizeSchedule,
    createRRuleObject: createRRuleObject
  };

  function parseDate(date) {
    return date ? moment(date, DATE_ISO_FORMAT).toDate() : null;
  }

  function filterRRule(rrule) {
    let filteredRRule = pickBy(rrule, function(val) {
      return !isEmpty(val) || isNumber(val);
    });
    if (isEmpty(filteredRRule)) {
      filteredRRule = null;
    }
    return filteredRRule;
  }

  function normalizeSchedule(schedule) {
    if (isString(get(schedule, 'rrule.byweekday'))) {
      schedule.rrule.byweekday = schedule.rrule.byweekday.split(',');
    }
    if (isString(get(schedule, 'rrule.bymonthday'))) {
      schedule.rrule.bymonthday = schedule.rrule.bymonthday.split(',');
    }
    return schedule;
  }

  function convertRRule(schedule) {
    const rrule = pick(schedule.rrule, ['interval', 'bymonth', 'bymonthday']);
    rrule['freq'] = RRule[schedule.rrule.freq];
    rrule['wkst'] = RRule[schedule.rrule.wkst] || null;
    rrule['byweekday'] = (schedule.rrule.byweekday || []).map(function(day) {
      return RRule[day];
    });
    rrule['dtstart'] = parseDate(schedule.dtstart);
    rrule['until'] = parseDate(schedule.rrule.until);
    return rrule;
  }

  function createRRuleObject(schedule) {
    if (isEmpty(schedule.rrule) || isEmpty(filterRRule(schedule.rrule))) {
      // single day
      return moment(schedule.dtstart, DATE_ISO_FORMAT);
    } else {
      // repeat
      const rrule = convertRRule(schedule);
      return new RRule(rrule);
    }
  }
}
