import {Inject, Injectable} from '@angular/core';
import {AuthorizedStorage} from './authorized.storage';
import {Subject} from 'rxjs';
import {ActivatedRouteSnapshot, Params, Router} from '@angular/router';
import {ENVIRONMENT} from '@shared-models/environment-type';
import {BmEnvironmentType} from '../../../environments/bm-environment-type';
import {Intercom} from '@shared/lib/intercom';

@Injectable({ providedIn: 'root' })
export class AuthorizedFacade {
  onLoggedOut$: Subject<void> = new Subject();

  constructor(
    private authorizedStorage: AuthorizedStorage,
    private intercom: Intercom,
    private router: Router,
    @Inject(ENVIRONMENT) public environment: BmEnvironmentType
  ) {
  }

  ensureLoggedIn(route: ActivatedRouteSnapshot): boolean {
    if (!!this.authorizedStorage.accessToken) {
      return true;
    } else {
      const returnUrl = this.getReturnUrl(route);
      const expiryFlag = this.getExpiryFlag(route);
      this.logout(returnUrl, expiryFlag);
      return false;
    }
  }

  ensureLoggedOut(route: ActivatedRouteSnapshot): boolean {
    if (!this.authorizedStorage.accessToken) {
      return true;
    } else {
      const returnUrl = this.getReturnUrl(route);
      this.router.navigateByUrl(returnUrl || this.environment.authorizedLink);
      return false;
    }
  }

  private getReturnUrl(route: ActivatedRouteSnapshot): string {
    let returnUrl = route.queryParamMap.get('returnUrl');
    if (!returnUrl && !window.location.pathname.startsWith('/auth')) {
      returnUrl = route.url.join('/') + window.location.search;
    }
    return returnUrl;
  }

  private getExpiryFlag(route: ActivatedRouteSnapshot): boolean {
    let expiryFlag = route?.queryParamMap.get('expiryFlag') === 'true';
    if (!expiryFlag) {
      expiryFlag = !!this.authorizedStorage.loginInfo;
    }
    return expiryFlag;
  }

  logout(returnUrl?: string, expiryFlag?: boolean): void {
    this.onLoggedOut$.next();
    this.authorizedStorage.loggedOut();
    if (this.environment.intercomSettings.enabled) {
      this.intercom.shutdown();
      this.intercom.boot();
    }
    this.navigateOnLogout(returnUrl, expiryFlag);
  }

  private navigateOnLogout(returnUrl: string, expiryFlag: boolean): void {
    const queryParams: Params = {};
    if (returnUrl) {
      queryParams['returnUrl'] = returnUrl;
    }
    if (expiryFlag) {
      queryParams['expiryFlag'] = expiryFlag;
    }
    this.router.navigate(['/auth/login'], { queryParams });
  }
}
