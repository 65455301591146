import {barnManagerModule} from '../../index.module';

barnManagerModule.directive('bmSearchBar', searchBar);

searchBar.$inject = ['pathTo', 'moduleAccessService'];
function searchBar(pathTo, moduleAccessService) {

  SearchBarController.$inject = ['$rootScope', '$state', 'authenticationService', 'utils'];

  return {
    restricted: 'E',
    templateUrl: 'search-bar.html',
    controller: SearchBarController,
    controllerAs: 'search',
    bindToController: true,
    scope: {
      state: '@',
      queryVarName: '@'
    }
  };

  function SearchBarController($rootScope, $state, authenticationService, utils) {
    const vm = this;

    vm.$onInit = function() {
      vm.isLoggedIn = false;
      vm.isReactivate = ($state.current.name === 'reactivate');
      vm.hasModuleAccess = hasModuleAccess;
      vm.search = search;

      function hasModuleAccess(moduleName) {
        return moduleAccessService.hasModuleAccess(moduleName);
      }

      function search(event) {
        event.preventDefault();
        if (event.currentTarget && event.currentTarget[0]) {
          event.currentTarget[0].blur();
        }
        const query = {};
        query[vm.queryVarName] = vm.term;

        if (vm.term) {
          if ($state.current.name !== 'search') {
            utils.setSearchPreviousState({name: $state.current.name, params: $state.params});
          }
          $state.current.reloadOnSearch = true;
          $state.go(vm.state, query);
          vm.term = ''; // clear input
        }
      }

      const stateChangeSuccess = $rootScope.$on('$stateChangeSuccess', function() {
        vm.isLoggedIn = authenticationService.isLoggedIn();
      });
      $rootScope.$on('$destroy', stateChangeSuccess);
    };
  }
}
