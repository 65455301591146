import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatRippleModule} from '@angular/material/core';
import {merge, Subject} from 'rxjs';
import {MatIconModule} from '@angular/material/icon';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {getEntryTypesArray} from '../../../bm-ng1/domain/feeding/EntryTypes';
import {take, takeUntil} from 'rxjs/operators';
import {FeedFileType, FeedPrintOrientation, FeedReportModalData, FeedReportParams} from './feed-report-models';
import {BmNgSelectModule} from '@shared-ui/bm-ng-select/bm-ng-select.module';
import {Horse, HorseAdvancedSelectComponent, HorseAdvancedSelectModalData, HorseCategoryItem} from '../../horse';

interface FeedReportFormGroup {
  fileType: FormControl<FeedFileType>,
  selectedEntryTypes: FormArray<FormControl<boolean>>;
  fontSize: FormControl<number>;
  orientation: FormControl<FeedPrintOrientation>;
  selectedHorses?: FormControl<Array<number>>;
  allHorsesAccess?: FormControl<boolean>;
  horsePerPage?: FormControl<boolean>;
}

@Component({
  selector: 'bm-feed-report-modal',
  templateUrl: './feed-report-modal.component.html',
  styleUrls: ['./feed-report-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatRippleModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    BmNgSelectModule
  ]
})
export class FeedReportModalComponent implements OnInit, AfterViewInit, OnDestroy {

  @HostBinding('class') cssClass = 'bm-feed-report-modal';

  formGroup: FormGroup<FeedReportFormGroup>;
  entryTypes: Array<any> = getEntryTypesArray();
  fontSizes: Array<{ name: string, value: number }> = [
    { name: 'Small', value: 10 },
    { name: 'Normal', value: 14 },
    { name: 'Large', value: 18 },
    { name: 'Huge', value: 24 }
  ];
  FeedFileType: typeof FeedFileType = FeedFileType;
  FeedPrintOrientation: typeof FeedPrintOrientation = FeedPrintOrientation;
  private destroy$: Subject<void> = new Subject<void>();
  selectedByUserCategories: HorseCategoryItem[] = [];

  get title(): string {
    return this.data.title;
  }

  get feedType(): boolean {
    return this.data.feedType;
  }

  get isSingleHorse(): boolean {
    return this.data.isSingleHorse;
  }

  get horses(): Array<any> {
    return this.data.horses;
  }

  get selectedHorses(): FormControl<Array<any>> {
    return <FormControl<Array<any>>>this.formGroup.get('selectedHorses');
  }

  get allHorsesAccess(): AbstractControl<boolean> {
    return this.formGroup.get('allHorsesAccess');
  }

  get horsePerPage(): AbstractControl<boolean> {
    return this.formGroup.get('horsePerPage');
  }

  get fontSize(): AbstractControl<number> {
    return this.formGroup.get('fontSize');
  }

  get orientation(): AbstractControl<FeedPrintOrientation> {
    return this.formGroup.get('orientation');
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: FeedReportModalData,
    private dialogRef: MatDialogRef<FeedReportModalComponent>,
    private fb: FormBuilder,
    private matDialog: MatDialog
  ) { }

  ngOnInit() {
    this.initForm();
    this.subscribeOnFileTypeChanges();
    this.subscribeOnAllHorsesAccessChanges();
    this.subscribeOnFormValuesChanges();
  }

  ngAfterViewInit() {
    const overlayPaneElement = this.dialogRef._containerInstance['_overlayRef']._pane;
    if (overlayPaneElement) {
      overlayPaneElement.scrollIntoView();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initForm(): void {
    this.formGroup = this.fb.group<FeedReportFormGroup>({
      fileType: this.fb.control(FeedFileType.PDF_BE),
      selectedEntryTypes: this.fb.array(this.entryTypes.map(x => this.fb.control(true))),
      fontSize: this.fb.control(this.fontSizes[1].value),
      orientation: this.fb.control(FeedPrintOrientation.PORTRAIT)
    });

    if (!this.isSingleHorse) {
      this.formGroup.addControl('selectedHorses', new FormControl([], Validators.required));
      this.formGroup.addControl('allHorsesAccess', new FormControl(false));
      this.formGroup.addControl('horsePerPage', new FormControl(false));
    }
  }

  private subscribeOnFileTypeChanges(): void {
    this.formGroup.get('fileType').valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        switch (value) {
          // case FeedFileType.PDF: {
          //   this.fontSize.enable();
          //   this.orientation.enable();
          //   break;
          // }
          case FeedFileType.PDF_BE: {
            this.fontSize.enable();
            this.orientation.enable();
            this.horsePerPage.enable();
            break;
          }
          case FeedFileType.CSV: {
            this.fontSize.disable();
            this.orientation.disable();
            this.horsePerPage.disable();
            break;
          }
        }
      });
  }

  private subscribeOnAllHorsesAccessChanges(): void {
    if (this.isSingleHorse) {
      return;
    }
    this.allHorsesAccess.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (!!this.allHorsesAccess.value) {
          this.selectedHorses.disable({ emitEvent: false });
        } else {
          this.selectedHorses.enable({ emitEvent: false });
          this.selectedHorses.markAsUntouched();
        }
      });
  }

  private subscribeOnFormValuesChanges(): void {
    if (this.isSingleHorse) {
      return;
    }
    merge(this.selectedHorses.valueChanges, this.allHorsesAccess.valueChanges)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.selectedHorses.value.length < 2 && !this.allHorsesAccess.value) {
          this.horsePerPage.setValue(false);
          this.horsePerPage.disable();
        } else {
          this.horsePerPage.enable();
        }
      });
  }

  private convertSelectedEntryTypesArrayToValues(): Array<string> {
    return this.formGroup.value['selectedEntryTypes'].map((x, i) => x && this.entryTypes[i]).filter(x => !!x);
  }

  download(): void {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    let params: FeedReportParams = <FeedReportParams>{ ...this.formGroup.value };
    if (this.feedType) {
      params = { ...params, selectedEntryTypes: this.convertSelectedEntryTypesArrayToValues() };
    } else {
      delete params.selectedEntryTypes;
    }

    if (!this.isSingleHorse) {
      const selectedHorses = [];
      if (!!this.allHorsesAccess.value) {
        // if (params.fileType === this.FeedFileType.PDF) {
        //   selectedHorses.push(...this.horses);
        // }
        if (params.fileType === this.FeedFileType.PDF_BE) {
          selectedHorses.push(...this.horses);
        }
      } else {
        selectedHorses.push(...this.selectedHorses.value);
      }
      params.selectedHorses = selectedHorses;
    }

    this.dialogRef.close(params);
  }

  openHorseAdvancedSelect(): void {
    const dialogRef: MatDialogRef<HorseAdvancedSelectComponent> = this.matDialog.open(HorseAdvancedSelectComponent, {
      data: <HorseAdvancedSelectModalData>{
        selected: [...this.selectedHorses.value],
        horses: this.horses,
        selectedByUserCategories: this.selectedByUserCategories
      }
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(([selectedHorses, selectedByUserCategories]: [Horse[], HorseCategoryItem[]]) => {
      if (selectedHorses) {
        this.selectedHorses.setValue(selectedHorses);
        this.selectedHorses.markAsTouched();
        this.selectedHorses.updateValueAndValidity();
      }
      if (selectedByUserCategories) {
        this.selectedByUserCategories = selectedByUserCategories
      }
    });
  }
}

