<div class="header">
  <div class="header__wrapper">
    <div>
      <a *ngIf="!externalBaseLink" [routerLink]="baseLink" class="header__logo__link">
        <ng-container *ngTemplateOutlet="logoTpl"></ng-container>
      </a>
      <a *ngIf="externalBaseLink" [href]="baseLink" class="header__logo__link">
        <ng-container *ngTemplateOutlet="logoTpl"></ng-container>
      </a>
      <a *ngIf="!hideTitle" [routerLink]="'/'" class="header__invoicing__link">Invoicing</a>
    </div>

    <div class="dropdown" *ngIf="!hideDropdown">
      <button class="btn dropdown-btn  text-white text-uppercase" type="button" id="header-options-dropdown"
              data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
        Account
        <span class="dropdown-btn-icon">
          <svg width="14px" height="14px" viewBox="51 598 22 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <path d="M72.5401786,602.25 L62.6026786,612.174107 C62.4330349,612.343751 62.232144,612.428571 62,612.428571 C61.767856,612.428571 61.5669651,612.343751 61.3973214,612.174107 L51.4598214,602.25 C51.2901777,602.080356 51.2053571,601.877233 51.2053571,601.640625 C51.2053571,601.404017 51.2901777,601.200894 51.4598214,601.03125 L53.6830357,598.821429 C53.8526794,598.651785 54.0535703,598.566964 54.2857143,598.566964 C54.5178583,598.566964 54.7187492,598.651785 54.8883929,598.821429 L62,605.933036 L69.1116071,598.821429 C69.2812508,598.651785 69.4821417,598.566964 69.7142857,598.566964 C69.9464297,598.566964 70.1473206,598.651785 70.3169643,598.821429 L72.5401786,601.03125 C72.7098223,601.200894 72.7946429,601.404017 72.7946429,601.640625 C72.7946429,601.877233 72.7098223,602.080356 72.5401786,602.25 Z" id="chev_down" stroke="none" fill="#fff" fill-rule="evenodd"></path>
          </svg>
        </span>
      </button>
      <div class="dropdown-menu text-uppercase p-0 header__bg" aria-labelledby="header-options-dropdown">
        <a class="dropdown-item text-white" href="/n/profile">My Profile</a>
        <a class="dropdown-item text-white" href="/n/admin/barn-profile">Admin</a>
        <a id="logoutBtn" class="dropdown-item text-white" (click)="onLogoutClick($event)">Log out</a>
      </div>
    </div>

  </div>
</div>

<ng-template #logoTpl>
  <svg class="header__logo" aria-hidden="true" role="img" viewBox="-1 -1 339 81"
       xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M45.93 51.93h-6v7.22h6c2.6 0 4-1.45 4-3.61s-1.4-3.61-4-3.61zm.4 12.72h-6.39v7.7h6.38c2.77 0 4.09-1.76 4.09-3.87 0-2.11-1.31-3.83-4.08-3.83zm49.81 13.16h10.45l-6.04-12.35h-4.41v12.35zm28.34 0h12.32l-12.32-19.09v19.09z"></path>
      <path
        d="M59.01 77.81l11.34-31.19h4.8l11.39 31.19h3.48V46.62h12.28c6.38 0 10.17 4.36 10.17 9.6a8.44 8.44 0 0 1-5.72 8.23l6.92 13.37h4.69v-31.2h5.46l12.41 19.19V46.62c-9.29-2.64-17.6-4.28-23.1-7.76-14.88-9.44-46.67-28.1-77.08-26.29 0 0-8.86-13.2-10.6-12.55-.8.3.62 4.84 0 7.52-.62 2.68 3.15 7.2 3.15 7.2-7.53 3.92-16.92 19.75-16.92 19.75a26.64 26.64 0 0 1-.32 9.7c-1.16 5.37-10 18.39-11.19 21-1.19 2.61 3.81 6.05 3.58 9.32-.23 3.27 3.55 5.55 8.48 4.83 1.9-.28 2.63.8 4.5.13 3-1.07 4.76-1.21 5-3.95-.93-4.32 3.86-11.87 3.86-11.87a21.23 21.23 0 0 0 12.23-17.17h9.1s6.61 0 8.49 5.09c1.72 4.81-.2 8.46-3.19 10.18 7.7 3.62 3.62 16.06 3.62 16.06h3.17z"></path>
      <path
        d="M106.35 56.21c0-2.42-1.76-4.14-4.45-4.14h-5.76v8.27h5.77c2.68.01 4.44-1.7 4.44-4.13zm-37.4 11.05h7.79l-3.83-11.4-3.96 11.4zm-3.56 10.55h14.78l-1.8-5.4H67.23l-1.84 5.4zm99.2.15V59.69l-6 11.88h-4.13l-6-11.88v18.27h-6.12V46.62h6l8.19 16.95 8.14-16.95h6v31.34h-6.12.04zm30.14 0l-1.85-5.55h-11.13l-1.89 5.55h-6.4l11.4-31.34h4.8l11.46 31.34h-6.39zm-7.27-22.1l-4 11.4h7.79l-3.79-11.4zm35 22.1L210 58.72v19.24h-6.12V46.62h5.46l12.41 19.19V46.62h6.12v31.34h-5.41zm29.44 0l-1.85-5.55h-11.14l-1.89 5.55h-6.38l11.4-31.34h4.8l11.44 31.34h-6.38zm-7.31-22.1l-4 11.4h7.87l-3.87-11.4zm35.34 18.8c-4.788 4.703-12.442 4.76-17.3.13-3.17-3.17-3.08-7.09-3.08-12.5s-.09-9.33 3.08-12.5a11.41 11.41 0 0 1 8.45-3.43c7.26 0 11 4.75 11.8 10h-6.16c-.7-2.95-2.51-4.58-5.63-4.58a5.08 5.08 0 0 0-3.92 1.67c-1.19 1.32-1.5 2.77-1.5 8.8 0 6.03.31 7.53 1.5 8.85a5.06 5.06 0 0 0 3.92 1.63 5.75 5.75 0 0 0 4.37-1.73 6 6 0 0 0 1.41-4.18v-1.19h-5.77v-5.1h11.84v4.53c-.02 4.58-.81 7.31-3.01 9.6zm7.87 3.3V46.62h20.66v5.44h-14.54v7.35h12.37v5.46h-12.37v7.62h14.54v5.47H287.8zm42.17 0l-6.12-12.5h-4.39v12.5h-6.12V46.62h12.28c6.38 0 10.17 4.36 10.17 9.6a8.44 8.44 0 0 1-5.72 8.23l7 13.51h-7.1zm-4.76-25.9h-5.75v8.27h5.77c2.68 0 4.45-1.72 4.45-4.14s-1.78-4.13-4.47-4.13z"></path>
    </g>
  </svg>
</ng-template>
