import {barnManagerModule} from '../index.module';
import {isFunction} from 'lodash';
import {MessageModel} from '../../app/core/response-handler/response-handler.service';

barnManagerModule.factory('responseHandler', ['$q', 'messageBus', 'MessageFrames', 'ng2ResponseHandlerService', 'errorNotificator', ($q, messageBus, MessageFrames, ng2ResponseHandlerService, errorNotificator) => {

  subscribeOnNg2SuccessMessage();
  subscribeOnNg2ErrorMessage();
  subscribeOnNg2HttpError();

  return {
    serverError: serverError,
    processError: processError,
    errorMessage: errorMessage,
    alertMessage: alertMessage,
    successMessage: successMessage,
    successOnSave: successOnSave,
    handleNotFoundOrAccess: handleNotFoundOrAccess,
    isNotFoundOrNotAccess: isNotFoundOrNotAccess
  };

  function subscribeOnNg2SuccessMessage() {
    ng2ResponseHandlerService.successMessage$.subscribe((message: MessageModel) => successMessage(message.text, message.timeout));
  }

  function subscribeOnNg2ErrorMessage() {
    ng2ResponseHandlerService.errorMessage$.subscribe((message: MessageModel) => errorMessage(message.text, MessageFrames.TOP, message.timeout));
  }

  function subscribeOnNg2HttpError() {
    ng2ResponseHandlerService.httpError$.subscribe(error => {
      let message = '';
      if (error.data && error.data.code === 'BUS_0071') {
        errorMessage('We have released a new version of the app. In order to continue, please reload the page. If you\'re on mobile, swipe down from the top of the screen. Close and reopen the app if this message doesn\'t disappear.', MessageFrames.TOP);
      } else {
        if (error.data && error.data.message) {
          message = error.data.message;
        }
        if (error.status >= 400 && error.status < 600) {
          errorNotificator.captureException('HTTP response error: ' + message, error, true);
        }

        handleServerErrorFromNg2(error);
      }
    });
  }

  function isNotFoundOrNotAccess(error) {
    return error.status === 404 || error.status === 403;
  }

  function handleNotFoundOrAccess(error, entityName, handledCallback) {
    if (isNotFoundOrNotAccess(error)) {
      handledCallback('This ' + entityName + ' either doesn\'t exist or you don\'t have access to it.');
    } else {
      handledCallback(error?.data?.message || error?.statusText);
      return $q.reject(error);
    }
  }

  function handleServerErrorFromNg2(error) {
    let text = 'Unknown error';
    if (error.status === 504 || error.status === -1) {
      if (!!error.config.data.ignoreFailed && error.status === -1) {
        return;
      }
      text = 'Server is offline. Please try again later';
      messageBus.push({ frame: MessageFrames.TOP, text: text, type: 'error' });
    } else if (error.status === 502) {
      text = 'We are doing maintenance. Should be back in a few minutes.';
      messageBus.push({ frame: MessageFrames.TOP, text: text, type: 'alert' });
    } else if (error.status >= 500) {
      text = 'Sorry, we are experiencing internal issues. We have been notified. Please clear your cache and reload the app. If the issue persists - please send us a message.';
      messageBus.push({ frame: MessageFrames.TOP, text: text, type: 'error' });
    } else if (error.status >= 400 && error.status < 500) {
      if (error.error?.message) {
        text = error.error.message;
      } else {
        text = error.statusText || 'Unknown error';
      }
      messageBus.push({ frame: MessageFrames.TOP, text: text, type: 'error' });
    }
  }

  function serverError(error) {
    let text = 'Unknown error';
    if (error.status === 504 || error.status === -1) {
      if (!!error.config.data.ignoreFailed && error.status === -1) {
        return $q.reject(error);
      }
      text = 'Server is offline. Please try again later';
      messageBus.push({ frame: MessageFrames.TOP, text: text, type: 'error' });
    } else if (error.status === 502) {
      text = 'We are doing maintenance. Should be back in a few minutes.';
      messageBus.push({ frame: MessageFrames.TOP, text: text, type: 'alert' });
    } else if (error.status >= 500) {
      text = 'Sorry, we are experiencing internal issues. We have been notified. Please clear your cache and reload the app. If the issue persists - please send us a message.';
      messageBus.push({ frame: MessageFrames.TOP, text: text, type: 'error' });
    }
    return $q.reject(error);
  }

  function processError(error, textTransform, frame) {
    // Prevent obsessive error messages on login page.
    if (error.status === 401) {
      return $q.reject(error);
    }
    if (error.status >= 400 && error.status < 500) {
      let text = error.statusText || 'Unknown error';
      if (error.data) {
        if (isFunction(textTransform)) {
          text = textTransform(error.data);
        } else {
          if (error.data.error) {
            text = error.data.error;
          }
          if (error.data.error_description) {
            text = error.data.error_description;
          }
          if (error.data.message) {
            text = error.data.message;
          }
        }
      }
      errorMessage(text, frame);
    }
    return $q.reject(error);
  }

  function errorMessage(text, frame, timeout = undefined) {
    messageBus.push({ text: text, type: 'error', frame: frame || MessageFrames.DEFAULT, timeout: timeout });
  }

  function alertMessage(text, timeout) {
    if (typeof timeout === 'undefined') {
      timeout = 5000;
    }
    messageBus.push({ text: text, type: 'alert', frame: MessageFrames.TOP, timeout: timeout });
  }

  function successMessage(text, timeout?) {
    if (typeof timeout === 'undefined') {
      timeout = 5000;
    }
    messageBus.push({ text: text, frame: MessageFrames.TOP, timeout: timeout });
  }

  function successOnSave(name, isUpdated) {
    const text = name + (isUpdated ? ' successfully updated.' : ' successfully created.');
    successMessage(text);
  }
}]);
