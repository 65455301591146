import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {RestService} from '@shared/lib/rest/rest.service';
import {catchError} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {ResponseHandlerService} from '../../core/response-handler/response-handler.service';
import {HorseDetails} from '../models';

export class HorseImportRow extends HorseDetails {
  position: number;
  errors: HorseImportError[];
}

interface HorseImportError {
  csvField: string;
  errorCode: string;
  errorText: string;
  resourceField: string;
}

interface ImportHorsesResponse {
  horses: HorseImportRow[];
  status: number;
}

export interface DoImportHorsesResponse {
  importedHorses: HorseImportRow[];
  couldNotImportHorses: HorseImportRow[];
}

@Injectable()
export class CsvImportService {
  constructor(
    private rest: RestService,
    private responseHandlerService: ResponseHandlerService
  ) { }

  getHorseImportRows(file: any): Observable<ImportHorsesResponse> {
    const payload: FormData = new FormData();
    payload.append('file', file);
    return this.rest.post({
      path: 'horses/import-horses', body: payload
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.responseHandlerService.emitHttpError(error);
        return throwError(error);
      })
    )
  }

  importHorses(horses: HorseImportRow[]): Observable<DoImportHorsesResponse> {
    return this.rest.post({
      path: 'horses/do-import', body: horses
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.responseHandlerService.emitHttpError(error);
        return throwError(error);
      })
    )
  }
}
