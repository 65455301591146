import {Directive, ElementRef, Injector, Input} from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'bm-message-frame'
})
export class MessageFrameNg2Directive extends UpgradeComponent {
  @Input() frame: string;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('bmMessageFrame', elementRef, injector);
  }
}
