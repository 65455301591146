import {barnManagerModule} from '../../index.module';

barnManagerModule.directive('bmButtonLoader', buttonLoader);

function buttonLoader() {
  return {
    restricted: 'E',
    templateUrl: 'button-loader.html',
    controller: ButtonLoader,
    controllerAs: 'vm',
    bindToController: true,
    transclude: true,
    scope: {
      'cssClass': '@',
      'buttonType': '@',
      'loading': '=',
      'buttonDisabled': '=',
      'clickAction': '&'
    }
  };

  function ButtonLoader() {
    const vm = this;

    vm.$onInit = function() {

    };
  }
}
