import {barnManagerModule} from '../../../index.module';
import {fromJson} from 'angular';
import {find, values} from 'lodash';

barnManagerModule.controller('RecordDetailsController', RecordDetailsController);

RecordDetailsController.$inject = [
  '$stateParams',
  '$rootScope',
  '$state',
  'backLinkHistory',
  'barnStorage',
  'recordRepository',
  'rAttachments',
  'vendorRepository',
  'listsService',
  'RecordTypes',
  'Record',
  'Vendor',
  'rContacts',
  'responseHandler',
  'titleService',
  'hasPermission',
  'FileOpener'
];
function RecordDetailsController(
  $stateParams,
  $rootScope,
  $state,
  backLinkHistory,
  barnStorage,
  recordRepository,
  rAttachments,
  vendorRepository,
  listsService,
  RecordTypes,
  Record,
  Vendor,
  rContacts,
  responseHandler,
  titleService,
  hasPermission,
  FileOpener
) {
  const vm = this;

  vm.hasAdminFullPermission = hasPermission('admin:full');
  vm.hasRecordsFullPermission = hasPermission('records:full');
  vm.error = null;
  vm.attachments = [];
  vm.recordMembers = [];
  vm.assignedHorses = [];
  vm.viewModeClass = 'preview-mode';
  vm.allHorses = false;
  vm.viewExtra = false;
  vm.loadingDownload = false;

  vm.recordTypes = values(RecordTypes);

  vm.recordId = -1;
  vm.curHorseId = -1;

  vm.togglePin = togglePin;
  vm.getRecord = getRecord;
  vm.showExtra = showExtra;
  vm.onSuccessArchive = onSuccessArchive;
  vm.onSuccessRemove = onSuccessRemove;
  vm.downloadAsPDF = downloadAsPDF;

  vm.prettify = function(label) {
    return (label.charAt(0).toUpperCase() + label.slice(1)).replace('_', ' ');
  };
  vm.onHorsesSet = onHorsesSet;

  init();

  function onHorsesSet(horses) {
    vm.assignedHorses = horses;
  }

  function togglePin() {
    vm.record.pinned = !vm.record.pinned;
    recordRepository.togglePin(vm.record.id);
  }

  function showExtra() {
    vm.viewExtra = true;
  }

  function loadContacts() {
    const queryParams = { tenantEnvironmentId: barnStorage.getEnvId(), pagesize: 999, archived: 0 };
    return rContacts.query(queryParams).$promise.then(function(response) {
      vm.contacts = response.records;
      return response.records;
    });
  }

  function loadVendor() {
    if (vm.record.vendorId) {
      vendorRepository.find(vm.record.vendorId).then(function(vendor) {
        vm.record.vendor = new Vendor(vendor);
      });
    }
  }

  function loadAttachments() {
    return rAttachments.query({ modelType: 'Record', modelId: vm.record.id }, function(attachments) {
        vm.attachments = attachments;
      });
  }

  function getUsers() {
    return listsService.getUsers(vm.record, function(allUsers, members) {
        vm.recordMembers = members;
      }, false);
  }

  function getRecord() {
    vm.loading = true;
    listsService.resetTo('records', null);

    recordRepository.find(vm.recordId).then(function(record) {
      vm.loading = false;
      backLinkHistory.pushLink(record.title + ' details');
      vm.record = record;
      vm.record.read = 1;
      recordRepository.markAsRead(vm.record.id);

      if (vm.record.legacyData !== '') {
        vm.record.legacyData = fromJson(vm.record.legacyData);
      }

      vm.recordType = find(vm.recordTypes, {
        value: vm.record.recordType
      });

      loadAttachments();
      getUsers();

      loadVendor();
      if (vm.record.lease) {
        loadContacts().then(function() {
          vm.lessee = find(vm.contacts, { id: vm.record.lease.lesseeId });
          vm.lessor = find(vm.contacts, { id: vm.record.lease.lessorId });
        });
      }

      vm.record = new Record(vm.record);
    }).catch(function(error) {
      vm.loading = false;
      return responseHandler.handleNotFoundOrAccess(error, 'record', function(errorMessage) {
        vm.error = errorMessage;
      });
    }).catch(function(error) {
      responseHandler.processError(error);
    });
  }

  function init() {
    titleService.setTitle('Record Details');
    $rootScope.pageConf.class = 'page--interior';

    let currentStateName = $state.current.name;

    if (currentStateName === 'recordDetails' && (isNaN($stateParams['id']) || !$stateParams['id'])) {
      $state.go('records');
      return;
    }

    if (currentStateName === 'recordHorseDetails' && (isNaN($stateParams['recordId']) || !$stateParams['recordId'])) {
      $state.go('recordsHorse');
      return;
    }

    vm.recordId = currentStateName === 'recordDetails' ? $stateParams.id : $stateParams.recordId;

    if (currentStateName === 'recordHorseDetails') {
      vm.curHorseId = $stateParams.id;
    }

    getRecord();
  }

  function downloadAsPDF() {
    // const resource: any = {
    //   resource: vm.record,
    //   recordType: vm.record.recordType,
    //   assignedHorses: vm.assignedHorses,
    //   attachments: vm.attachments
    // };
    // if (vm.lessee) {
    //   resource.lessee = vm.lessee;
    // }
    // if (vm.lessor) {
    //   resource.lessor = vm.lessor;
    // }
    //
    // vm.loadingDownload = true;
    //
    // return reportGenerator.printRecordReport(resource).finally(function() {
    //   vm.loadingDownload = false;
    // });
    if (vm.loadingDownload) {
      return;
    }
    vm.loadingDownload = true;

    recordRepository.detailsReport(vm.record.id)
      .then((data: Blob) => {
        const fileName = 'recordReportDetails';
        FileOpener(data, `${fileName}.pdf`);
      })
      .finally(() => vm.loadingDownload = false);
  }

  function onSuccessArchive() {
    if (vm.curHorseId > 0) {
      $state.go('recordsHorse', { id: vm.curHorseId });
    } else {
      $state.go('records');
    }
  }

  function onSuccessRemove() {
    backLinkHistory.goBack();
  }
}
