import {barnManagerModule} from '../../index.module';
import moment from 'moment';

barnManagerModule
  .component('bmDischargeReportModal', {
    templateUrl: 'discharge-report-modal.html',
    controller: DischargeReportModalController,
    controllerAs: 'vm',
    bindings: {
      //'horse': '=',
      'visible': '=',
      'downloadnew': '<',
    }
  });

DischargeReportModalController.$inject = ['DATE_ISO_FORMAT'];
function DischargeReportModalController(DATE_ISO_FORMAT) {
  const vm = this;

  vm.$onInit = function() {

    vm.visible = false;

    vm.includeVetRecords = false;
    vm.notes = null;

    vm.showDatePicker = {
      startDate: false,
      endDate: false
    };

    vm.dates = {
      startDate: null,
      endDate: null
    };

    vm.close = close;
    vm.openDischargeReport = openDischargeReport;
    vm.confirmActionNew = confirmActionNew;
    vm.openDatePicker = openDatePicker;
    vm.onIncludeVetChange = onIncludeVetChange;

    function close() {
      vm.includeVetRecords = false;
      vm.visible = false;
      vm.notes = null;
    }

    function openDischargeReport() {
      vm.showDischargeReportModal = true;
    }

    function confirmActionNew() {
      let formattedPeriod = {};
      if (vm.includeVetRecords) {
        formattedPeriod = {
          startDate: formatDate(vm.dates.startDate),
          endDate: formatDate(vm.dates.endDate)
        };
      }
      vm.downloadnew(vm.includeVetRecords, formattedPeriod, vm.notes);
      close();
    }

    function openDatePicker(type) {
      vm.showDatePicker[type] = true;
    }

    function formatDate(date) {
      if (!date) {
        return null;
      }
      return moment(date).utcOffset(0, true).format(DATE_ISO_FORMAT);
    }

    function onIncludeVetChange() {
      if (vm.includeVetRecords) {
        vm.dates = {
          startDate: moment().subtract(1, 'years').toDate(),
          endDate: new Date()
        };
      }
    }
  };
}
