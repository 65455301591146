import {barnManagerModule} from '../../index.module';

barnManagerModule.directive('bmReminders', reminders);

function reminders() {

  return {
    restricted: 'E',
    templateUrl: 'reminders.html',
    controller: ReminderController,
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      type: '@',
      additionalTitle: '@',
      actionText: '@',
      reminders: '=',
      allUsers: '='
    }
  };

  function ReminderController() {
    const vm = this;

    vm.add = add;
    vm.update = update;
    vm.remove = remove;
    vm.onChangeSelected = onChangeSelected;

    function remove(index) {
      vm.reminders.splice(index, 1);
    }

    function update(reminder) {
      if (reminder.frequency === 'minutes') {
        reminder.frequencyUnit = 5;
        reminder.min = 5;
      } else {
        reminder.frequencyUnit = 1;
        reminder.min = 1;
      }
    }

    function add() {
      vm.reminders.push({ frequency: 'days', frequencyUnit: 1, min: 1 });
    }

    function onChangeSelected(selected, reminder) {
      reminder.recipientIds = selected.map(i => i.id);
    }

    vm.$onInit = function() {
      if (!vm.reminders) {
        vm.reminders = [];
      }
    };
  }
}
