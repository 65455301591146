import {barnManagerModule} from '../index.module';

barnManagerModule.factory('ScrollToInvalidRequired', ScrollToInvalidRequired);

ScrollToInvalidRequired.$inject = ['$window'];
function ScrollToInvalidRequired($window) {
  return function(path) {
    const elementPath = path || 'form [required].ng-invalid';
    const topPosition = offsetTop(elementPath);
    $window.scrollTo(0, topPosition - 30);
  };

  function offsetTop(selector) {
    const element = $window.document.querySelector(selector);
    const rect = element.getBoundingClientRect();
    const scrollTop = $window.pageYOffset || $window.document.documentElement.scrollTop;
    return rect.top + scrollTop;
  }
}
