import {barnManagerModule} from '../../index.module';
import {BehaviorSubject} from 'rxjs';
import {MatDialogRef} from '@angular/material/dialog';
import {ImagePreviewModalComponent} from '../../../app/attach-file/image-preview-modal/image-preview-modal.component';
import angular from 'angular';

barnManagerModule
  .component('bmAvatar', {
    templateUrl: 'avatar.html',
    controller: AvatarController,
    controllerAs: 'vm',
    bindings: {
      thumbnailId: '=',
      imageId: '=',
      name: '=',
      defaultAvatar: '='
    }
  });

AvatarController.$inject = ['$element', 'imageService', 'ng2MatDialog', 'imageTransformer', '$log', 'responseHandler', 'MessageFrames', 'DefaultAvatars'];
function AvatarController($element, imageService, ng2MatDialog, imageTransformer, $log, responseHandler, MessageFrames, DefaultAvatars) {
  const vm = this;
  vm.handleClick = handleClick;

  vm.$onInit = () => {
    vm.default = vm.defaultAvatar || DefaultAvatars.horse;
    const avatarElement = angular.element($element.find('.avatar')[0]);
    avatarElement.bind('load', handleImageLoad);
    avatarElement.bind('error', handleImageLoad);

    imageService.getThumbnailBlob(vm.imageId, vm.thumbnailId).then((thumbnail: Blob) => {
      vm.thumbnail = thumbnail;
    }).catch(() => {
      vm.thumbnail = vm.default;
    });
  };

  function handleImageLoad(event: Event) {
    vm.loaded = true;
    const img: any = event.target;
    if (img.complete && img.naturalHeight === 0) {
      vm.thumbnail = vm.default;
    }
  }

  async function handleClick(event: Event) {
    if (vm.thumbnailId && vm.imageId && vm.thumbnail !== vm.default) {
      event.preventDefault();
      event.stopPropagation();
      await showImage();
    }
  }

  async function showImage() {
    const img = new BehaviorSubject<string>(vm.image);
    const dialogRef = openModal(img, vm.name);
    if (!vm.image) {
      try {
        const horseImageBlob = await imageService.getImageBlob(vm.imageId);
        vm.image = await imageTransformer.fixOrientation(horseImageBlob, 2000, 2000, false);
        img.next(vm.image);
      } catch (error) {
        dialogRef.close();
        $log.log(error);
        responseHandler.errorMessage('Error processing image', MessageFrames.TOP);
      }
    }
  }

  function openModal(image: BehaviorSubject<string>, name: string): MatDialogRef<ImagePreviewModalComponent> {
    return ng2MatDialog.open(ImagePreviewModalComponent, {
      panelClass: 'bm-image-preview-modal-pane',
      backdropClass: 'bm-image-preview-backdrop',
      data: { image, name }
    });
  }
}
