import {barnManagerModule} from '../../index.module';

barnManagerModule.controller('AdminController', AdminController);

AdminController.$inject = [
  '$state',
  '$scope',
  '$timeout',
  'userStorage',
  'subscriptionStorage',
  'moduleAccessService',
  'hasPermission'
];
function AdminController(
  $state,
  $scope,
  $timeout,
  userStorage,
  subscriptionStorage,
  moduleAccessService,
  hasPermission
) {
  const vm = this;

  vm.hasAdminFullPermission = hasPermission('admin:full');
  vm.hasVendorsAccess = moduleAccessService.hasModuleAccess('vendors') && hasPermission('vendors:read');

  vm.status = {
    error: false,
    errorMessage: ''
  };

  vm.user = userStorage.getUser();
  vm.subscription = subscriptionStorage.getSubscription();

  vm.navOpen = false;
  vm.isActive = isActive;
  vm.closeMenu = closeMenu;

  if ($state.current.name === 'admin') {
    $state.go('barnProfile');
  }

  function isActive(stateName) {
    return stateName == $state.current.name || stateName == $state.current.parent ? 'page' : '';
  }

  function closeMenu() {
    vm.navOpen = false;
  }
}
