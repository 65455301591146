import {barnManagerModule} from '../index.module';

barnManagerModule.factory('passwordRepository', PasswordRepository);

PasswordRepository.$inject = ['$http'];
function PasswordRepository($http) {
  const url = '/api/authorization/';

  return {
    forgot: forgot,
    reset: reset,
    invitationPasswordSet: invitationPasswordSet
  };

  function forgot(userData) {
    return $http.post(url + 'forgotpassword', userData);
  }

  function reset(userData) {
    return $http.post(url + 'resetpassword', userData);
  }

  function invitationPasswordSet(userData) {
    return $http.post(url + 'invitation-password-set', userData);
  }
}
