import {barnManagerModule} from '../../index.module';
import {isFunction} from 'lodash';
import {BehaviorSubject} from 'rxjs';
import {MatDialogRef} from '@angular/material/dialog';
import {ImagePreviewModalComponent} from '../../../app/attach-file/image-preview-modal/image-preview-modal.component';

barnManagerModule.directive('bmImageAttachment', imageAttachment);

function imageAttachment() {

  ImageAttachment.$inject = ['$scope', 'imageTransformer', 'ng2MatDialog'];

  return {
    restricted: 'E',
    templateUrl: 'image-attachment.html',
    controller: ImageAttachment,
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      // does not watch for changes, a loaded image file should be passed
      imageFile: '<',
      text: '@',
      onChange: '<',
      onClear: '='
    }
  };

  function ImageAttachment($scope, imageTransformer, ng2MatDialog) {
    const vm = this;

    vm.imageUrl = null;

    vm.state = 'Loading';
    vm.error = null;

    vm.onClearInternal = onClearInternal;
    vm.showFullImage = showFullImage;

    function onClearInternal() {
      vm.state = 'NoFile';
      if (isFunction(vm.onClear)) {
        vm.onClear();
      }
    }

    function doLoadImage(imageBlob, isInitializing) {
      vm.state = 'Loading';
      vm.error = null;

      imageTransformer.fixOrientation(imageBlob, 2000, 2000, false, imageBlob.type).then(function(imageUrl) {
        vm.imageUrl = imageUrl;
        vm.imageName = imageBlob.name;
        vm.state = 'Loaded';
      }).catch(function() {
        vm.state = 'ImageBroken';
        vm.error = 'Image format or size is not supported. Allowed formats: JPEG, PNG, GIF. 50MB max.';
      }).finally(function() {
        if (isFunction(vm.onChange) && !isInitializing) {
          vm.onChange(imageBlob, vm.error);
        }
      });
    }

    async function showFullImage() {
      openModal(new BehaviorSubject<string>(vm.imageUrl), vm.imageName);
    }

    function openModal(image: BehaviorSubject<string>, name: string): MatDialogRef<ImagePreviewModalComponent> {
      return ng2MatDialog.open(ImagePreviewModalComponent, {
        panelClass: 'bm-image-preview-modal-pane',
        backdropClass: 'bm-image-preview-backdrop',
        data: { image, name }
      });
    }

    vm.$onInit = function() {
      if (vm.imageFile && vm.imageFile.name || vm.imageFile instanceof ArrayBuffer) { // eslint-disable-line
        doLoadImage(new Blob([vm.imageFile]), true);
      } else {
        vm.state = 'NoFile';
      }

      $scope.$watch('vm.attachedFile', function(newVal) {
        if (typeof newVal !== 'undefined') {
          doLoadImage(newVal, false);
        }
      });
    };
  }
}
