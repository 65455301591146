import {NgModule} from '@angular/core';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from '@angular/material/dialog';
import {BmNgSelectModule} from '@shared-ui/bm-ng-select/bm-ng-select.module';
import {Ng1UpgradesModule} from './ng1-upgrades/ng1-upgrades.module';
import {GoNativeExternalLinkComponent} from '@shared/lib/go-native/go-native-external-link/go-native-external-link.component';
import {ColorPickerSelectComponent} from '../../event/color-picker-select/color-picker-select.component';
import {ProductSelectComponent} from '../../feed/product-select/product-select.component';
import {CsvImportComponent} from '../../horse/csv-import/csv-import.component';
import {ReportsModule} from '../../reports/reports.module';

@NgModule({
  imports: [
    // Angular Modules for AngularJS
    Ng1UpgradesModule,
    MatDialogModule,
    BmNgSelectModule,
    GoNativeExternalLinkComponent,
    ColorPickerSelectComponent,
    ProductSelectComponent,
    CsvImportComponent,
    ReportsModule
  ],
  exports: [
    Ng1UpgradesModule
  ],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        panelClass: 'bm-mat-dialog',
        autoFocus: false,
        restoreFocus: false
      }
    }
  ]
})
export class Ng1Module {
}
