import {barnManagerModule} from '../../../index.module';
import {values} from 'lodash';

barnManagerModule.controller('VendorFormController', UserFormController);

UserFormController.$inject = [
  'backLinkHistory',
  'htmlGenerator',
  'ScrollToMessageFrame',
  'responseHandler',
  'vendorRepository',
  'barnStorage',
  '$timeout',
  '$state',
  'States',
  'Countries',
  'TerritorialUnits',
  'PATTERNS',
  'VendorTypes',
  'confirmDialog',
  'hasPermission'
];
function UserFormController(
  backLinkHistory,
  htmlGenerator,
  ScrollToMessageFrame,
  responseHandler,
  vendorRepository,
  barnStorage,
  $timeout,
  $state,
  States,
  Countries,
  TerritorialUnits,
  PATTERNS,
  VendorTypes,
  confirmDialog,
  hasPermission
) {
  let vm = this, barn, timeout;
  const vendorId = $state.params.id;

  vm.hasVendorsFullPermission = hasPermission('vendors:full');
  vm.vendor = {};
  vm.submitAttempt = false;
  vm.editMode = isEditPage();
  vm.states = States;
  vm.countries = Countries;
  vm.TerritorialUnits = TerritorialUnits;
  vm.patterns = PATTERNS;
  vm.vendorTypes = values(VendorTypes);
  vm.status = {
    updating: false,
    message : ''
  };

  vm.save = save;
  vm.toggleArchive = toggleArchive;
  vm.onCountrySelected = onCountrySelected;

  init(vm.editMode);

  function onCountrySelected() {
    vm.vendor.state = '';
  }

  function isEditPage() {
    return typeof vendorId !== 'undefined' && !isNaN(vendorId);
  }

  function doToggleArchive() {
    const vendorName =  vm.vendor.firstName + ' ' + vm.vendor.lastName;

    vm.status.updating = true;
    vm.vendor.archived = vm.vendor.archived ? 0 : 1;
    vendorRepository.update(vendorId, vm.vendor).then(function() {
      if (vm.vendor.archived) {
        const undoLink = htmlGenerator.link('vendorEdit', { id: vm.vendor.id }, 'Undo');
        responseHandler.successMessage('You successfully archived ' + vendorName + '. ' + undoLink + '.');
        $state.go('vendors');
      } else {
        responseHandler.successMessage('You successfully unarchived ' + vendorName + '.');
      }
    }).catch(errorHandler).finally(finalHandler);
  }

  function toggleArchive() {
    if (vm.vendor.archived) {
      doToggleArchive();
    } else {
      confirmDialog.open('Are you sure you want to archive this vendor?').then(function() {
        doToggleArchive();
      }).catch(function() {});
    }
  }

  function save(form) {
    vm.submitAttempt = true;

    if (!form.$valid) {
      return;
    }
    let vendorPromise;

    vm.status.updating = true;
    vm.vendor.vendorType = vm.vendorType.value;
    if (vm.vendor.vendorType !== 'OTHER') {
      vm.vendor.customType = null;
    }
    vm.vendor.archived = 0;

    if (vm.editMode) {
      vendorPromise = vendorRepository.update(vendorId, vm.vendor);
    } else {
      vendorPromise = vendorRepository.add(vm.vendor);
    }
    vendorPromise.then(done).catch(errorHandler).finally(finalHandler);
  }

  function done() {
    responseHandler.successOnSave('Vendor', vm.editMode);
    backLinkHistory.goBack();
  }

  function errorHandler(error) {
    ScrollToMessageFrame();
    return responseHandler.processError(error);
  }

  function finalHandler() {
    vm.status.updating = false;
  }

  function init(editMode) {
    timer();

    barn = barnStorage.getEnv();
    vm.vendor.tenantEnvironmentId = barn.id;
    vm.vendor.country = 'USA';

    if (editMode) {
      vm.loading = true;
      vendorRepository.find(vendorId).then(function(vendor) {
        vm.loading = false;
        backLinkHistory.pushLink(vendor.firstName + ' ' + vendor.lastName + '\'s');
        vm.vendor = vendor;
        vm.vendorType = vm.vendorTypes.filter(function(obj) {
          return obj.value === vendor.vendorType;
        })[0];
      }).catch(function(error) {
        vm.loading = false;
        vm.error = true;
        responseHandler.processError(error);
      });
    } else {
      backLinkHistory.pushLink('New Vendor Page');
    }
  }

  function timer() {
    $timeout.cancel(timeout);
    timeout = $timeout(function() {
      vm.status.message = 'Oops! It is taking more time than expected.';
    }, 4000);
  }
}
