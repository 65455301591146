import { ModuleWithProviders, NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { Intercom } from './intercom';
import { IntercomConfig } from './intercom-config';

@NgModule({
  imports: [
    RouterModule
  ],
  providers: [
    Intercom,
    IntercomConfig
  ]
})
export class IntercomModule {
  static forRoot(config: IntercomConfig): ModuleWithProviders<any> {
    return {
      ngModule: IntercomModule,
      providers: [
        Intercom,
        { provide: IntercomConfig, useValue: config },
      ]
    }
  }
}
