import {barnManagerModule} from '../index.module';

barnManagerModule.config(otherRoutes);

otherRoutes.$inject = ['$stateProvider', '$urlMatcherFactoryProvider', 'StripeCheckoutProvider'];
function otherRoutes($stateProvider, $urlMatcherFactoryProvider, StripeCheckoutProvider) {

  horsePromise.$inject = ['$stateParams', '$q', 'horseRepository', 'horseCache', '_authenticated'];
  function horsePromise($stateParams, $q, horseRepository, horseCache, _authenticated) {
    if ($stateParams.id) {
      horseCache.clean();
      return horseRepository.find($stateParams.id).then(function(horse) {
        horseCache.setHorse(horse);
        return horse;
      }).catch(function(_error) {
      });
    } else {
      return $q.when({});
    }
  }

  getClosestEvent.$inject = ['$stateParams', '$state', '$q', 'MessageFrames', 'responseHandler', 'rEvents', '_authenticated', '_module', '_permissions'];
  function getClosestEvent($stateParams, $state, $q, MessageFrames, responseHandler, rEvents, _authenticated, _module, _permissions) {
    if ($stateParams.instance) {
      return $q.resolve($stateParams.closestEventInstance);
    }
    if ($stateParams.id) {
      return rEvents.closestInstance({ eventId: $stateParams.id }).$promise.then(function(event) {
        const params = {
          id: $stateParams.id,
          author: event.authorId,
          instance: event.dateDate,
          closestEventInstance: event
        };
        $state.go('eventDetails', params);
      }).catch(error => {
        $state.go('calendar');
        return responseHandler.processError(error, null, MessageFrames.TOP);
      });
    } else {
      $state.go('calendar');
      return $q.when({});
    }
  }

  setGoNativeBarnMenu.$inject = ['$q', '$timeout', 'goNative', '_authenticated'];
  function setGoNativeBarnMenu($q, $timeout, goNative, _authenticated) {
    const deferred = $q.defer();
    $timeout(function() {
      goNative.setGoNativeBarnMenu();
      deferred.resolve();
    }, 10);
    return deferred.promise;
  }

  setGoNativeReactivateMenu.$inject = ['$q', '$timeout', 'goNative', '_authenticated'];
  function setGoNativeReactivateMenu($q, $timeout, goNative, _authenticated) {
    const deferred = $q.defer();
    $timeout(function() {
      goNative.setGoNativeReactivateMenu();
      deferred.resolve();
    }, 10);
    return deferred.promise;
  }

  setGoNativeHorseMenu.$inject = ['$stateParams', '$q', '$timeout', 'goNative', '_authenticated', '_horsePromise'];
  function setGoNativeHorseMenu($stateParams, $q, $timeout, goNative, _authenticated, _horsePromise) {
    const deferred = $q.defer();
    $timeout(function() {
      goNative.setGoNativeHorseMenu($stateParams.id);
      deferred.resolve();
    }, 10);
    return deferred.promise;
  }

  const authenticated = ['authenticationService', function(authenticationService) {
    return authenticationService.withUser();
  }];

  const interior = {
    templateUrl: 'header-interior.html',
    controller: 'HeaderInteriorController',
    controllerAs: 'vm'
  };

  $urlMatcherFactoryProvider.strictMode(false);

  $stateProvider
    /**
     * GENERAL VIEWS
     */
    .state('profile', {
      url: '/n/profile',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('profile');
        }],
        setGoNativeBarnMenu: setGoNativeBarnMenu
      },
      views: {
        content: {
          templateUrl: 'user-profile.html',
          controller: 'UserProfileController',
          controllerAs: 'vm'
        },
        header: interior
      }
    })
    .state('no-horse', {
      url: '/n/no-horse',
      resolve: {
        _authenticated: authenticated,
        setGoNativeBarnMenu: setGoNativeBarnMenu
      },
      views: {
        content: {
          templateUrl: 'no-horse.html',
          controller: 'GlueController',
          controllerAs: 'vm'
        }
      }
    })
    .state('no-permissions', {
      url: '/n/no-permissions',
      resolve: {
        _authenticated: authenticated,
        setGoNativeBarnMenu: setGoNativeBarnMenu
      },
      views: {
        content: {
          templateUrl: 'no-permissions.html',
          controller: 'GlueController',
          controllerAs: 'vm'
        }
      }
    })
    .state('noaccess', {
      url: '/n/noaccess',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('noaccess');
        }],
        setGoNativeBarnMenu: setGoNativeBarnMenu
      },
      views: {
        content: {
          templateUrl: 'noaccess.html',
          controller: 'NoaccessController',
          controllerAs: 'vm'
        }
      }
    })
    .state('reactivate', {
      url: '/n/reactivate?reactivating',
      resolve: {
        stripe: StripeCheckoutProvider.load,
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('reactivate');
        }],
        setGoNativeReactivateMenu: setGoNativeReactivateMenu
      },
      views: {
        content: {
          templateUrl: 'reactivate.html',
          controller: 'ReactivateController',
          controllerAs: 'vm'
        }
      }
    })
    .state('search', {
      url: '/n/search?term',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('search');
        }],
        setGoNativeBarnMenu: setGoNativeBarnMenu
      },
      views: {
        content: {
          templateUrl: 'search-results.html',
          controller: 'SearchResultsController',
          controllerAs: 'vm'
        },
        header: interior
      }
    })

    /**
     * HORSES CRUD VIEWS
     */
    .state('horseDetails', {
      url: '/n/horses/details/:id',
      resolve: {
        _authenticated: authenticated,
        _horsePromise: horsePromise,
        module: ['moduleAccessService', '_authenticated', '_horsePromise', function(moduleAccessService, _authenticated, _horsePromise) {
          return moduleAccessService.resolveModuleAccess('horses');
        }],
        setGoNativeHorseMenu: setGoNativeHorseMenu
      },
      views: {
        content: {
          templateUrl: 'horse-details.html',
          controller: 'HorseDetailsController',
          controllerAs: 'vm'
        },
        header: interior
      },
      data: {
        horseHeader: true
      }
    })
    .state('horseEdit', {
      url: '/n/horses/edit/:id',
      resolve: {
        _authenticated: authenticated,
        _horsePromise: horsePromise,
        module: ['moduleAccessService', '_authenticated', '_horsePromise', function(moduleAccessService, _authenticated, _horsePromise) {
          return moduleAccessService.resolveModuleAccess('horses');
        }],
        permissions: ['permissionEnforcer', '_authenticated', '_horsePromise', function(permissionEnforcer, _authenticated, _horsePromise) {
          return permissionEnforcer.enforcePermissions('horses:full');
        }],
        setGoNativeHorseMenu: setGoNativeHorseMenu
      },
      views: {
        content: {
          templateUrl: 'horse-form.html',
          controller: 'HorseFormController',
          controllerAs: 'vm'
        },
        data: {
          horseHeader: true
        },
        header: interior
      }
    })
    .state('horseNew', {
      url: '/n/horses/new',
      resolve: {
        _authenticated: authenticated,
        _horsePromise: horsePromise,
        module: ['moduleAccessService', '_authenticated', '_horsePromise', function(moduleAccessService, _authenticated, _horsePromise) {
          return moduleAccessService.resolveModuleAccess('horses');
        }],
        permissions: ['permissionEnforcer', '_authenticated', '_horsePromise', function(permissionEnforcer, _authenticated, _horsePromise) {
          return permissionEnforcer.enforcePermissions('horses:full');
        }],
        setGoNativeBarnMenu: setGoNativeBarnMenu
      },
      views: {
        content: {
          templateUrl: 'horse-form.html',
          controller: 'HorseFormController',
          controllerAs: 'vm'
        },
        header: interior
      }
    })
    .state('horseImportUsef', {
      url: '/n/horses/import-usef',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('horses');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('horses:full');
        }],
        setGoNativeBarnMenu: setGoNativeBarnMenu
      },
      views: {
        content: {
          templateUrl: 'usef-import.html',
          controller: 'UsefImportController',
          controllerAs: 'vm'
        },
        header: interior
      }
    })
    .state('horseImportCsv', {
      url: '/n/horses/import-csv',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('horses');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('horses:full');
        }],
        setGoNativeBarnMenu: setGoNativeBarnMenu
      },
      views: {
        content: {
          templateUrl: 'csv-import.html',
          controller: 'CsvImportController',
          controllerAs: 'vm'
        },
        header: interior
      }
    })

    /**
     * RECORDS CRUD VIEWS
     */
    .state('recordDetails', {
      url: '/n/records/details/:id?author',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('records');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('records:read');
        }],
        setGoNativeBarnMenu: setGoNativeBarnMenu
      },
      views: {
        content: {
          templateUrl: 'record-details.html',
          controller: 'RecordDetailsController',
          controllerAs: 'vm'
        },
        header: interior
      }
    })
    .state('recordEdit', {
      url: '/n/records/edit/:id?author',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('records');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('records:full');
        }],
        setGoNativeBarnMenu: setGoNativeBarnMenu
      },
      views: {
        content: {
          templateUrl: 'record-form.html',
          controller: 'RecordFormController',
          controllerAs: 'vm'
        },
        header: interior
      }
    })
    .state('recordNew', {
      url: '/n/records/new?leaseHorseId&horseId',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('records');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('records:full');
        }],
        setGoNativeBarnMenu: setGoNativeBarnMenu
      },
      views: {
        content: {
          templateUrl: 'record-form.html',
          controller: 'RecordFormController',
          controllerAs: 'vm'
        },
        header: interior
      }
    })

    /**
     * LISTS CRUD VIEWS
     */
    .state('listDetails', {
      url: '/n/lists/details/:id?author',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('lists');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('lists:read');
        }],
        setGoNativeBarnMenu: setGoNativeBarnMenu
      },
      views: {
        content: {
          templateUrl: 'list-details.html',
          controller: 'ListDetailsController',
          controllerAs: 'vm'
        },
        header: interior
      }
    })
    .state('listEdit', {
      url: '/n/lists/edit/:id?author',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('lists');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('lists:full');
        }],
        setGoNativeBarnMenu: setGoNativeBarnMenu
      },
      views: {
        content: {
          templateUrl: 'list-form.html',
          controller: 'ListFormController',
          controllerAs: 'vm'
        },
        header: interior
      }
    })
    .state('listNew', {
      url: '/n/lists/new?horseId',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('lists');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('lists:full');
        }],
        setGoNativeBarnMenu: setGoNativeBarnMenu
      },
      views: {
        content: {
          templateUrl: 'list-form.html',
          controller: 'ListFormController',
          controllerAs: 'vm'
        },
        header: interior
      }
    })

    /**
     * CONVERSATIONS CRUD VIEWS
     */
    .state('conversationDetails', {
      url: '/n/conversations/details/:id?author',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('conversations');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('conversations:read');
        }],
        setGoNativeBarnMenu: setGoNativeBarnMenu
      },
      views: {
        content: {
          templateUrl: 'conversation-details.html',
          controller: 'ConversationDetailsController',
          controllerAs: 'vm'
        },
        header: interior
      }
    })
    .state('conversationEdit', {
      url: '/n/conversations/edit/:id?author',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('conversations');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('conversations:full');
        }],
        setGoNativeBarnMenu: setGoNativeBarnMenu
      },
      views: {
        content: {
          templateUrl: 'conversation-form.html',
          controller: 'ConversationFormController',
          controllerAs: 'vm'
        },
        header: interior
      }
    })
    .state('conversationNew', {
      url: '/n/conversations/new?horseId',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('conversations');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('conversations:full');
        }],
        setGoNativeBarnMenu: setGoNativeBarnMenu
      },
      views: {
        content: {
          templateUrl: 'conversation-form.html',
          controller: 'ConversationFormController',
          controllerAs: 'vm'
        },
        header: interior
      }
    })

    /**
     * EVENTS CRUD VIEWS
     */
    .state('eventDetails', {
      url: '/n/events/details/:id?instance&?author',
      params: {
        closestEventInstance: null
      },
      resolve: {
        _authenticated: authenticated,
        _module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('events');
        }],
        _permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissionsList(['events:read', 'lessons:read']);
        }],
        getClosestEvent: getClosestEvent,
        setGoNativeBarnMenu: setGoNativeBarnMenu
      },
      views: {
        content: {
          templateUrl: 'event-details.html',
          controller: 'EventDetailsController',
          controllerAs: 'vm'
        },
        header: interior
      }
    })
    .state('eventEdit', {
      url: '/n/events/edit/:id?instance&?author?thisAndFollowing',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('events');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissionsList(['events:full', 'lessons:full']);
        }],
        setGoNativeBarnMenu: setGoNativeBarnMenu
      },
      views: {
        content: {
          templateUrl: 'event-form.html',
          controller: 'EventFormController',
          controllerAs: 'vm'
        },
        header: interior
      }
    })
    .state('eventNew', {
      url: '/n/events/new?horseId',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('events');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissionsList(['events:full', 'lessons:full']);
        }],
        setGoNativeBarnMenu: setGoNativeBarnMenu
      },
      views: {
        content: {
          templateUrl: 'event-form.html',
          controller: 'EventFormController',
          controllerAs: 'vm'
        },
        header: interior
      }
    })
    /**
     * CONTACTS CRUD VIEWS
     */
    .state('contactDetails', {
      url: '/n/contacts/details/:id',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('contacts');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('contacts:read');
        }],
        setGoNativeBarnMenu: setGoNativeBarnMenu
      },
      views: {
        content: {
          templateUrl: 'contact-details.html',
          controller: 'ContactDetailsController',
          controllerAs: 'vm'
        },
        header: interior
      }
    })
    .state('contactEdit', {
      url: '/n/contacts/edit/:id',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('contacts');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('contacts:full');
        }],
        setGoNativeBarnMenu: setGoNativeBarnMenu
      },
      views: {
        content: {
          templateUrl: 'contact-form.html',
          controller: 'ContactFormController',
          controllerAs: 'vm'
        },
        header: interior
      }
    })
    .state('contactNew', {
      url: '/n/contacts/new',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('contacts');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('contacts:full');
        }],
        setGoNativeBarnMenu: setGoNativeBarnMenu
      },
      views: {
        content: {
          templateUrl: 'contact-form.html',
          controller: 'ContactFormController',
          controllerAs: 'vm'
        },
        header: interior
      }
    })

    /**
     * ENTRIES CRUD VIEWS
     */
    .state('entryNew', {
      url: '/n/entries/new?type',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('feed');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('feed:full');
        }],
        setGoNativeBarnMenu: setGoNativeBarnMenu
      },
      views: {
        content: {
          templateUrl: 'entry-form.html',
          controller: 'EntryFormController',
          controllerAs: 'vm'
        },
        header: interior
      }
    });
}
