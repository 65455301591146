import {barnManagerModule} from '../index.module';

barnManagerModule.config(adminRoutes);

adminRoutes.$inject = ['$stateProvider', '$urlMatcherFactoryProvider', 'StripeCheckoutProvider'];
function adminRoutes($stateProvider, $urlMatcherFactoryProvider, StripeCheckoutProvider) {

  const interior = {
    templateUrl: 'header-interior.html',
    controller: 'HeaderInteriorController',
    controllerAs: 'vm'
  };

  redirectOnPushNotification.$inject = ['$q', '$timeout', '$stateParams', '$state', 'NotificationTypes'];
  function redirectOnPushNotification($q, $timeout, $stateParams, $state, NotificationTypes) {
    const deferred = $q.defer();
    const notificationTypesValues = Object.keys(NotificationTypes);
    const type = $stateParams.type;
    if (type && notificationTypesValues.includes(type)) {
      const routeData = NotificationTypes[type].routeData($stateParams);
      if (!routeData.name) {
        $timeout(function() {
          $state.go('activity');
          deferred.reject();
        }, 10);
      }
      $timeout(function() {
        $state.go(routeData.name, routeData.params);
        deferred.reject();
      }, 10);
    } else {
      $timeout(function() {
        $state.go('activity');
        deferred.reject();
      }, 10);
    }
    return deferred.promise;
  }

  setGoNativeBarnMenu.$inject = ['$q', '$timeout', 'goNative', '_authenticated'];
  function setGoNativeBarnMenu($q, $timeout, goNative, _authenticated) {
    const deferred = $q.defer();
    $timeout(function() {
      goNative.setGoNativeBarnMenu();
      deferred.resolve();
    }, 10);
    return deferred.promise;
  }

  $urlMatcherFactoryProvider.strictMode(false);

  $stateProvider
    .state('admin', {
      url: '/n/admin',
      resolve: {
        _authenticated: ['sessionService', 'authenticationService', function(sessionService, authenticationService) {
          return authenticationService.withUser();
        }],
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('admin');
        }],
        setGoNativeBarnMenu: setGoNativeBarnMenu
      },
      views: {
        content: {
          templateUrl: 'admin.html',
          controller: 'AdminController',
          controllerAs: 'vm'
        },
        header: interior
      }
    })
    .state('barnProfile', {
      url: '/barn-profile',
      parent: 'admin',
      views: {
        'content@admin': {
          templateUrl: 'barn-profile.html',
          controller: 'BarnProfileController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('admin:full');
        }]
      }
    })
    .state('users', {
      url: '/users',
      parent: 'admin',
      views: {
        'content@admin': {
          templateUrl: 'users.html',
          controller: 'UsersController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('admin:full');
        }]
      }
    })
    .state('userEdit', {
      url: '/:id',
      parent: 'users',
      views: {
        'content@admin': {
          templateUrl: 'user-form.html',
          controller: 'UserFormController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('admin:full');
        }]
      }
    })
    .state('userNew', {
      url: '/new',
      parent: 'users',
      views: {
        'content@admin': {
          templateUrl: 'user-form.html',
          controller: 'UserFormController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('admin:full');
        }]
      }
    })
    .state('payment', {
      url: '/barn-plan',
      parent: 'admin',
      views: {
        'content@admin': {
          templateUrl: 'payment.html',
          controller: 'PaymentController',
          controllerAs: 'vm',
          resolve: {
            stripe: StripeCheckoutProvider.load
          }
        }
      },
      resolve: {
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('admin:full');
        }]
      }
    })
    .state('contactSupport', {
      url: '/contact-support',
      parent: 'admin',
      views: {
        'content@admin': {
          templateUrl: 'contact-support.html',
          controller: 'ContactSupportController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('admin:full');
        }]
      }
    })
    .state('vendors', {
      url: '/vendors',
      parent: 'admin',
      resolve: {
        _authenticated: ['sessionService', 'authenticationService', function(sessionService, authenticationService) {
          return authenticationService.withUser();
        }],
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('vendors');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('vendors:read');
        }]
      },
      views: {
        'content@admin': {
          templateUrl: 'vendors.html',
          controller: 'VendorsController',
          controllerAs: 'vm'
        }
      }
    })
    .state('vendorEdit', {
      url: '/:id',
      parent: 'vendors',
      views: {
        'content@admin': {
          templateUrl: 'vendor-form.html',
          controller: 'VendorFormController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('vendors:read');
        }]
      }
    })
    .state('vendorNew', {
      url: '/new',
      parent: 'vendors',
      views: {
        'content@admin': {
          templateUrl: 'vendor-form.html',
          controller: 'VendorFormController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('vendors:full');
        }]
      }
    })
    .state('push-notification', {
      url: '/n/push-notification?type&?recordId&?listId&?conversationId&?eventId&?instance&?horseId',
      resolve: {
        _redirectOnPushNotification: redirectOnPushNotification
      }
    });
}
