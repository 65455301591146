import {barnManagerModule} from '../index.module';
import * as angular from 'angular';

barnManagerModule
  .factory('rContacts', ['$resource', ($resource) => {

    return $resource('/api/contacts', {
    }, {
      'delete': {
        method: 'DELETE',
        url: '/api/contacts/:id',
        params:{
          id: '@id'
        }
      },
      'reactivate': {
        method: 'PUT',
        url: '/api/contacts/:id/reactivate',
        params:{
          id: '@id'
        }
      },
      'toggleContactPin': {
        url: '/api/contacts/:id/pin',
        method: 'PUT',
        params:{
          id: '@id'
        }
      },
      'get': {
        method: 'GET',
        url: '/api/contacts/:contactId',
        params:{
          contactId: '@contactId'
        }
      },
      'query':  {
        method: 'GET'
      },
      'save': {
        method: 'POST',
        url:'/api/contacts/'
      },
      'update': {
        method: 'PUT',
        url:'/api/contacts/:id',
        params:{
          id:'@id'
        }
      },
      'removeImage' : {
        url: '/api/contacts/:id/image',
        method: 'DELETE',
        params:{
          id: '@id'
        }
      },
      'saveImage':{
        url: '/api/contacts/:id/image',
        method: 'POST',
        headers : {'Content-Type': undefined},
        transformRequest: angular.identity,
        params:{
          id: '@id'
        }
      },
      'togglePin': {
        url: '/api/contacts/:id/pin',
        method: 'GET',
        params:{
          id: '@id'
        }
      },
      'idWithName': {
        url: '/api/contacts/idWithName',
        method: 'GET'
      }
    });
  }]);
