import { NgModule } from '@angular/core';
import { NgOptionHighlightDirective } from './ng-option-highlight.directive';

@NgModule({
  declarations: [
    NgOptionHighlightDirective
  ],
  exports: [
    NgOptionHighlightDirective
  ]
})
export class NgOptionHighlightModule {}
