import {barnManagerModule} from '../../index.module';

barnManagerModule.directive('bmFooter', footer);

footer.$inject = ['BM_VERSION'];
function footer(BM_VERSION) {
  return {
    restricted: 'E',
    templateUrl: 'footer.html',
    controller: FooterController,
    controllerAs: 'vm',
    bindToController: true
  };

  function FooterController() {
    const vm = this;

    vm.barnManagerVersion = BM_VERSION;
    vm.year = (new Date()).getFullYear();
  }
}
