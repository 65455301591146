import {barnManagerModule} from '../../../index.module';
import angular, {copy} from 'angular';
import {includes, isEqual} from 'lodash';
import {User} from '../../../../app/user';

barnManagerModule.controller('UserFormController', UserFormController);

UserFormController.$inject = [
  'htmlGenerator',
  'backLinkHistory',
  'rUser',
  'userRepository',
  'listsService',
  'userStorage',
  'barnStorage',
  '$scope',
  '$timeout',
  '$state',
  '$stateParams',
  'PATTERNS',
  'ScrollToMessageFrame',
  'responseHandler',
  'PermissionCollection',
  'confirmDialog'
];
function UserFormController(
  htmlGenerator,
  backLinkHistory,
  rUser,
  userRepository,
  listsService,
  userStorage,
  barnStorage,
  $scope,
  $timeout,
  $state,
  $stateParams,
  PATTERNS,
  ScrollToMessageFrame,
  responseHandler,
  PermissionCollection,
  confirmDialog
) {
  let vm = this, timeout, barn;
  const currentUser = userStorage.getUser();

  vm.user = {};
  vm.submitAttempt = false;
  vm.editMode = isEditPage();
  vm.patterns = PATTERNS;
  vm.roles = ['Super Admin', 'Admin', 'Employee', 'Owner'];
  vm.updatePassword = false;
  vm.userInvitation = null;
  vm.status = {
    updating: false,
    message : '',
    error: false
  };
  vm.canChangePermissions = true;

  if (!currentUser.sysAdmin) {
    vm.roles = vm.roles.filter(function(role) {
      return role !== 'Super Admin';
    });
  }

  vm.save = save;
  vm.updatePermissions = updatePermissions;
  vm.toggleArchive = toggleArchive;
  vm.sendInvitation = sendInvitation;
  vm.onHorseSelection = onHorseSelection;
  vm.isAdmin = isAdmin;

  init(vm.editMode);

  function onHorseSelection(horseIds: number[]) {
    vm.user.horseIds = horseIds;
  }

  function isAdmin(): boolean {
    return includes(vm.user.permissions, 'admin:full');
  }

  function isEditPage() {
    return typeof $stateParams.id !== 'undefined' && !isNaN($stateParams.id);
  }

  function watch() {
    $scope.$watch('vm.user.email', (function(scope) {
      if (!scope.userForm?.loginEmail?.$valid) {
        scope.userForm?.loginEmail?.$setValidity('uniqueEmail', true);
      }
    }).bind(vm, $scope));
  }

  function doToggleArchive() {
    let promise;

    vm.status.updating = true;
    vm.user.archived = !vm.user.archived;
    if (vm.user.archived) {
      promise = rUser.delete({ id: vm.user.id, soft: true }).$promise;
    } else {
      promise = rUser.update(vm.user).$promise;
    }
    promise.then(function() {
      if (vm.user.archived) {
        const undoLink = htmlGenerator.link('userEdit', { id: vm.user.id }, 'Undo');
        responseHandler.successMessage('You successfully archived ' + vm.user.displayName + '. ' + undoLink + '.');
        $state.go('users');
      } else {
        responseHandler.successMessage('You successfully unarchived ' + vm.user.displayName + '.');
      }
    }).catch(function(error) {
      ScrollToMessageFrame();
      return responseHandler.processError(error);
    }).finally(function() {
      vm.status.updating = false;
    });
  }

  function toggleArchive() {
    if (vm.user.archived) {
      doToggleArchive();
    } else {
      confirmDialog.open('Are you sure you want to archive this user?').then(function() {
        doToggleArchive();
      }).catch(function() {});
    }
  }

  function save(form) {
    if (currentUser.id === vm.user.id && vm.permissionsTouched) {
      confirmDialog.open('You\'re updating your own user. You will be logged out after the save. Continue?').then(function() {
        doSave(form);
      }).catch(function() {});
    } else {
      doSave(form);
    }
  }

  function doSave(form) {
    vm.submitAttempt = true;
    form.loginEmail.$setValidity('uniqueEmail', true);

    if (!form.$valid) {
      return;
    }

    if (!!vm.confirmPassword && vm.confirmPassword !== '') {
      vm.user.password = vm.confirmPassword;
    }

    vm.status.updating = true;

    if (vm.editMode) {
      if (isEqual(vm.unchangedUser, vm.user)) {
        vm.status.updating = false;
        responseHandler.successOnSave('User', true);
        backLinkHistory.goBack();
        return;
      }
      rUser.update(vm.user).$promise.then((user: User) => {
        form.loginEmail.$setValidity('uniqueEmail', true);
        if (!vm.permissionsTouched) {
          onSuccessUpdate(user);
          return;
        }
        const body = { permissions: copy(vm.user.permissions) };
        listsService.ready(() => {
          userRepository.updatePermissions(vm.user.id, body).then(() => onSuccessUpdate(user)).catch(errorHandler.bind(vm, form));
        });
      }).catch(errorHandler.bind(vm, form));
    } else {
      const userInvite = {
        tenantId: barn.tenant.id,
        allHorsesAccess: vm.user.allHorsesAccess,
        roleName: 'Admin',
        sysAdmin: false,
        firstName: vm.user.firstName,
        lastName: vm.user.lastName,
        email: vm.user.email,
        permissions: vm.user.permissions,
        horseIds: vm.user.horseIds
      };

      rUser.invite(userInvite).$promise.then(() => {
        form.loginEmail.$setValidity('uniqueEmail', true);
        listsService.ready(function() {
          vm.status.updating = false;
          responseHandler.successMessage('User has been invited.');
          backLinkHistory.goBack();
        });
      }).catch(errorHandler.bind(vm, form));
    }
  }

  function onSuccessUpdate(user: User) {
    vm.status.updating = false;
    responseHandler.successOnSave('User', true);
    if (currentUser.id === vm.user.id) {
      userStorage.setUser(user);
    }
    backLinkHistory.goBack();
  }

  function updatePermissions(permissions) {
    vm.user.permissions = copy(permissions);
    if (isAdmin()) {
      vm.user.allHorsesAccess = true;
    }
    vm.permissionsTouched = !isEqual(vm.unchangedUser.permissions, vm.user.permissions) || vm.unchangedUser.allHorsesAccess !== vm.user.allHorsesAccess;
  }

  function errorHandler(form, error) {
    vm.status.updating = false;
    const isUniqueEmailError = error.data.message.indexOf('Duplicated username') > -1;
    ScrollToMessageFrame();
    responseHandler.processError(error, textTransform);

    if (isUniqueEmailError) {
      form.loginEmail.$setValidity('uniqueEmail', false);
    }
  }

  function textTransform(data) {
    const text = data.message.replace('Duplicated username', 'This email address is already in use.');
    return 'User was not saved. Error: ' + text;
  }

  function sendInvitation() {
    rUser.reinvite({ id: vm.user.id }).$promise.then((userInvitation: any) => {
      vm.userInvitation = userInvitation;
      responseHandler.successMessage('Invitation is sent.');
    }).catch(responseHandler.processError);
  }

  function loadInvitation(user: User) {
    if (user.state === 'INVITED') {
      rUser.lastInvitation({ id: vm.user.id }).$promise
        .then((userInvitation: any) => vm.userInvitation = userInvitation)
        .catch(responseHandler.processError);
    }
  }

  function init(editMode: boolean) {
    timer();
    watch();

    barn = barnStorage.getEnv();
    vm.user = { ...vm.user, allHorsesAccess: false, roleNames: ['Admin'], sysAdmin: false };
    listsService.resetTo('environments', null);

    if (editMode) {
      vm.loading = true;
      rUser.query({ tenantEnvironmentId: barn.id, id: $stateParams.id }).$promise.then((user: User) => {
        vm.loading = false;
        backLinkHistory.pushLink(user.displayName + '\'s');
        vm.user = JSON.parse(angular.toJson(user));
        vm.unchangedUser = JSON.parse(angular.toJson(user));
        loadInvitation(user);
      }).catch((error: any) => {
        vm.loading = false;
        vm.error = true;
        responseHandler.processError(error);
      });
    } else {
      backLinkHistory.pushLink('New User Page');
      vm.user = { ...vm.user, permissions: PermissionCollection.regularUserPermissions() };
    }
  }

  function timer() {
    $timeout.cancel(timeout);
    timeout = $timeout(() => vm.status.message = 'Oops! It is taking more time than expected.', 4000);
  }
}
