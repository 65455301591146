import {barnManagerModule} from '../../index.module';

barnManagerModule.directive('bmProductsArea', ProductsArea);

function ProductsArea() {
  return {
    restricted: 'E',
    templateUrl: 'products-area.html',
    controller: ProductsAreaController,
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      treatments: '=',
      eventType: '='
    }
  };

  function ProductsAreaController() {
  }
}
