import {barnManagerModule} from '../../../index.module';
import {copy, extend} from 'angular';
import {find, get} from 'lodash';
import {entityTimes, getEntryTypesArray} from '../../../domain/feeding/EntryTypes';

barnManagerModule.controller('EntryFormController', EntryFormController);

EntryFormController.$inject = [
  '$q',
  '$state',
  'backLinkHistory',
  'responseHandler',
  'ScrollToMessageFrame',
  'barnStorage',
  'EntryFormHelpers',
  'horseRepository',
  'productRepository',
  'userRepository',
  'listsService',
  'entryRepository'
];
function EntryFormController(
  $q,
  $state,
  backLinkHistory,
  responseHandler,
  ScrollToMessageFrame,
  barnStorage,
  EntryFormHelpers,
  horseRepository,
  productRepository,
  userRepository,
  listsService,
  entryRepository
) {
  let vm = this, barn;

  vm.editMode = !!$state.params.entryId;
  vm.horseLevel = !!$state.params.id;
  vm.saving = false;
  vm.errors = {};

  vm.allUsers = [];
  vm.recordMembers = [];

  vm.form = {
    wasSaved: false,
    ongoing: false,
    endDate: false
  };
  vm.form.item = {
    measure: {},
    dateRange: {},
    administrationTimes: {},
    schedule: {}
  };
  vm.form.horse = null;
  vm.form.product = null;
  vm.form.brand = null;

  vm.barnProducts = [];
  vm.barnHorses = [];

  vm.form.allHorsesAccess = false;

  vm.save = save;
  vm.onFormChange = onFormChange;
  vm.onSelectHorse = onSelectHorse;
  vm.entryTypes = getEntryTypesArray();
  vm.onSelectType = onSelectType;
  vm.onSelectProduct = onSelectProduct;
  vm.newProduct = newProduct;
  vm.onChangeBrand = onChangeBrand;
  vm.getUnits = getUnits;
  vm.onSelectUnit = onSelectUnit;
  vm.getTimeList = getTimeList;
  vm.onScheduleChange = onScheduleChange;

  init();

  function save() {
    if (!checkFrom()) {
      return;
    }
    vm.saving = true;

    const body = EntryFormHelpers.prepareFormForApi(vm.form);

    if (!vm.form.item.productId) {
      addProduct().then(function(result) {
        vm.form.item.productId = body.productId = result.id;
        saveAction(body).then(savingSuccess).catch(savingFailed);
      }).catch(savingFailed);
    } else {
      saveAction(body).then(savingSuccess).catch(savingFailed);
    }
  }

  function saveAction(body) {
    if (vm.editMode) {
      return entryRepository.update(body);
    } else if (vm.form.allHorsesAccess) {
      return entryRepository.addToAllHorses(body);
    } else {
      return entryRepository.add(body);
    }
  }

  function savingSuccess(response) {
    listsService.updateMembers(response);
    return listsService.ready(function() {}).then(function() {
      vm.saving = false;
      responseHandler.successOnSave('Entry', vm.editMode);
      backLinkHistory.goBack();
      return response;
    });
  }

  function savingFailed(error) {
    vm.saving = false;
    return errorHandler(error);
  }

  function errorHandler(error) {
    ScrollToMessageFrame();
    return responseHandler.processError(error);
  }

  function checkFrom() {
    vm.form.wasSaved = true;
    const result = EntryFormHelpers.validate(vm.form);
    vm.errors = result.errors;
    return result.isFormValid;
  }

  function onFormChange() {
    vm.form.wasSaved && checkFrom();
  }

  function onScheduleChange(schedule) {
    vm.form.item.schedule = copy(schedule);
    onFormChange();
  }

  function addProduct() {
    const product = vm.form.product;
    if (product.brand == null) {
      product.brand = '';
    }
    return productRepository.add(product);
  }

  function onSelectHorse(item) {
    vm.form.horse = item;
    vm.form.item.horseId = item ? item.id : null;
  }

  function onSelectType(type) {
    vm.form.item.type = type;
    vm.form.item.productId = null;
    vm.form.item.measure.unit = null;
    vm.form.product = null;
    vm.form.brand = null;
    vm.products = getProducts();
    vm.onFormChange();
  }

  function onSelectProduct(product) {
    if (!product.id) {
      product = newProduct(product.name);
    }
    vm.form.item.productId = product.id;
    vm.form.product = product;
    vm.form.brand = product.brand;
    vm.onFormChange();
  }

  function newProduct(name) {
    return {
      id: null,
      tenantEnvId: barn.id,
      type: vm.form.item.type.productType,
      name: name,
      brand: null
    };
  }

  function getProducts() {
    if (!vm.form.item?.type) {
      return [];
    }
    return vm.barnProducts.filter(product => product.type === vm.form.item.type.productType);
  }

  function onChangeBrand() {
    if (vm.form.product) {
      if (vm.form.product.id) {
        vm.form.item.productId = null;
        vm.form.product = vm.newProduct(vm.form.product.name);
      }
      vm.form.product.brand = vm.form.brand;
    }
    vm.onFormChange();
  }

  function getUnits() {
    return get(vm.form.item.type, 'units');
  }

  function onSelectUnit(unit: string) {
    vm.form.item.measure.unit = unit;
    vm.onFormChange();
  }

  function getTimeList() {
    return get(vm.form.item.type, 'time');
  }

  function loadEntry() {
    if (vm.editMode) {
      return entryRepository.find($state.params.entryId).then(function(result) {
        backLinkHistory.pushLink(result.product.name + ' ' + result.product.brand);
        extend(vm.form, EntryFormHelpers.prepareFormForUi(result));
        return result;
      }).catch(errorHandler);
    } else {
      backLinkHistory.pushLink('New entry');
      const defaultType = $state.params.type || 'Feed';
      vm.form.item = {
        horseId: Number($state.params.id),
        type: vm.entryTypes.find(type => type.value === defaultType),
        productId: 0,
        measure: { quantity: 1, unit: null },
        administrationTimes: {
          [entityTimes.am.value]: false,
          [entityTimes.pm.value]: false,
          [entityTimes.noon.value]: false,
          [entityTimes.nightCheck.value]: false
        },
        schedule: { dtstart: null, rrule: { interval: 1, freq: 'DAILY', wkst: 'SU' } }
      };
      return $q.when(vm.form.item);
    }
  }

  function loadHorses() {
    return horseRepository.all().then(function(horses) {
      vm.barnHorses = horses;
      if (vm.form.item.horseId) {
        vm.form.horse = find(vm.barnHorses, { id: vm.form.item.horseId });
      }
    }).catch(errorHandler);
  }

  function loadProducts() {
    return productRepository.search().then(function(result) {
      vm.barnProducts = result;
      if (vm.form.item.productId) {
        vm.form.product = find(vm.barnProducts, { id: vm.form.item.productId });
        vm.form.brand = vm.form.product.brand;
      }
    }).catch(errorHandler);
  }

  function loadAllUsers() {
    return userRepository.all().then(function(users) {
      vm.allUsers = users;
      return users;
    });
  }

  function init() {
    listsService.resetTo('entries', 'ENTRY');

    barn = barnStorage.getEnv();
    vm.loading = true;
    $q.all({
      allUsers: loadAllUsers(),
      horses: loadHorses(),
      products: loadProducts(),
      entry: loadEntry()
    }).then(() => {
      vm.products = getProducts();
      vm.loading = false;
    }).catch((error) => {
      vm.loading = false;
      vm.error = true;
      errorHandler(error);
    });
  }
}
