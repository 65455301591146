import {barnManagerModule} from '../index.module';
import {unescape} from 'lodash';

barnManagerModule.directive('contenteditable', contentEditable);

function contentEditable() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, element, attrs, ngModel) {

      function read() {
        ngModel.$setViewValue(unescape(element.html()));
      }

      ngModel.$render = function() {
        element.html(unescape(ngModel.$viewValue || ''));
      };

      element.bind('blur keyup change', function() {
        scope.$apply(read);
      });
    }
  };
}
