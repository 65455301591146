import {barnManagerModule} from '../../index.module';
import {includes, keys} from 'lodash';

barnManagerModule.directive('bmVendorIcon', vendorIcon);

function vendorIcon() {
  return {
    restricted: 'E',
    templateUrl: 'vendor-icon.html',
    controller: VendorIconController,
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      vendorType: '='
    }
  };

  function VendorIconController() {
    const vm = this;

    // Vendors helpers
    const VET_TYPES = ['VET', 'DEWORMING', 'MEDICATION', 'VACCINATION'];
    const VENDOR_SVG_VIEWBOX = {
      VET: '-8 -6 32 32',
      DENTIST: '-7 -7 33 35',
      FARRIER: '-7 -7 33 35'
    };

    vm.svgViewBox = svgViewBox;
    vm.getIconName = getIconName;

    function svgViewBox() {
      return VENDOR_SVG_VIEWBOX[getIconName()];
    }

    function getIconName() {
      if (includes(VET_TYPES, vm.vendorType)) {
        return 'VET';
      }
      if (includes(keys(VENDOR_SVG_VIEWBOX), vm.vendorType)) {
        return vm.vendorType;
      }
      return '';
    }

  }
}
