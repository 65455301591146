import {barnManagerModule} from '../index.module';
import * as angular from 'angular';

barnManagerModule
  .factory('rHorses', ['$resource', ($resource) => {

    return  $resource('/api/horses/:id', {
      id: '@id'
    }, {
      'save': {
        method: 'POST',
        url:'/api/horses/'
      },
      'update': {
        method: 'PUT',
        url:'/api/horses/:id',
        params: {
          id: '@id'
        }
      },
      'removeImage' : {
        url: '/api/horses/:id/image',
        method: 'DELETE',
        params:{
          id: '@id'
        }
      },
      'saveImage':{
        url: '/api/horses/:id/image',
        method: 'POST',
        headers : {'Content-Type': undefined},
        transformRequest: angular.identity,
        params:{
          id: '@id'
        }
      },
      'dischargeReport': {
        url: '/api/horses/:id/discharge-report',
        method: 'GET',
        params: {
          id: '@id'
        }
      },
      'downloadDischargeReportPdf': {
        url: '/api/horses/v2/:id/discharge-report',
        method: 'POST',
        params: {
          id: '@id'
        },
        transformResponse: function(data) {
          return { data: data };
        },
        body: {
          htmlInput: '@htmlInput'
        }
      },
      'downloadHorseDetailsPdf': {
        url: '/api/horses/v2/:id/pdf',
        method: 'POST',
        params: {
          id: '@id'
        },
        transformResponse: function(data) {
          return { data: data };
        },
        body: {
          htmlInput: '@htmlInput'
        }
      }
    });
  }]);
