import {barnManagerModule} from '../../index.module';
import {NameValueType} from '../../../app/core/utils/name-value-type';

export const entityTimes: Record<string, { label: string } & NameValueType> = {
  am: { label: 'AM', name: 'am', value: 'am' },
  noon: { label: 'Noon', name: '12', value: 'noon' },
  pm: { label: 'PM', name: 'pm', value: 'pm' },
  nightCheck: { label: 'Night Check', name: 'nc', value: 'nightCheck' }
};

const EntryTypes = {
  Feed: {
    name: 'Feed',
    units: ['Scoop', 'Pound', 'Ounces', 'Kilogram', 'Flake'].sort(),
    time: [entityTimes.am, entityTimes.noon, entityTimes.pm, entityTimes.nightCheck],
    productType: 'Feed',
    dateBinding: true,
    alwaysShowInFeedTable: false
  },
  Supplement: {
    name: 'Supplements',
    units: ['Scoop', 'Packet', 'Tablet', 'Grams', 'Ounces'].sort(),
    time: [entityTimes.am, entityTimes.noon, entityTimes.pm, entityTimes.nightCheck],
    productType: 'Supplement',
    dateBinding: true,
    alwaysShowInFeedTable: false
  },
  Medication: {
    name: 'Medications',
    units: ['Scoop', 'Packet', 'Tablet', 'Grams', 'Ounces', 'Ccs'].sort(),
    time: [entityTimes.am, entityTimes.noon, entityTimes.pm, entityTimes.nightCheck],
    productType: 'Medication',
    dateBinding: true,
    alwaysShowInFeedTable: false
  },
  ShowMedication: {
    name: 'Show Medications',
    units: ['Scoop', 'Packet', 'Tablet', 'Grams', 'Ounces', 'Ccs'].sort(),
    time: [entityTimes.am, entityTimes.noon, entityTimes.pm, entityTimes.nightCheck],
    productType: 'Medication',
    dateBinding: false,
    alwaysShowInFeedTable: false
  }
};

export function getEntryTypesArray(): Array<any> {
  return [...Object.entries(EntryTypes).map(([key, value]: [string, Object]) => ({
    value: key,
    ...value
  }))]
}

export function getEntryTypeOrder() {
  return getEntryTypesArray().map(type => type.value);
}

barnManagerModule.constant('EntryTypes', EntryTypes);
