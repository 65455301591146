import {barnManagerModule} from '../index.module';

barnManagerModule.config(horseRoutes);

horseRoutes.$inject = ['$stateProvider', '$urlMatcherFactoryProvider'];
function horseRoutes($stateProvider, $urlMatcherFactoryProvider) {

  let horseId = null;

  const authenticated = ['authenticationService', function(authenticationService) {
    return authenticationService.withUser();
  }];

  horsePromise.$inject = ['$stateParams', '$state', '$q', 'horseRepository', 'horseCache', 'responseHandler', '_authenticated'];
  function horsePromise($stateParams, $state, $q, horseRepository, horseCache, responseHandler, _authenticated) {
    if ($stateParams.id === horseId || $state.params.id === horseId) {
      return $q.resolve();
    }
    horseId = $stateParams.id || $state.params.id;
    if (horseId) {
      horseCache.clean();
      return horseRepository.find(horseId).then(function(horse) {
        horseCache.setHorse(horse);
        return horse;
      }).catch(function(error) {
        if (responseHandler.isNotFoundOrNotAccess(error)) {
          $state.go('no-horse');
        }
      });
    } else {
      $state.go('no-horse');
      return $q.when({});
    }
  }

  getClosestEvent.$inject = ['$stateParams', '$state', '$q', 'MessageFrames', 'responseHandler', 'rEvents', '_authenticated', '_module', '_permissions'];
  function getClosestEvent($stateParams, $state, $q, MessageFrames, responseHandler, rEvents, _authenticated, _module, _permissions) {
    if ($stateParams.instance) {
      return $q.resolve($stateParams.closestEventInstance);
    }
    if ($stateParams.eventId) {
      return rEvents.closestInstance({ eventId: $stateParams.eventId }).$promise.then(function(event) {
        const params = {
          id: $stateParams.id || $state.params.id,
          eventId: $stateParams.eventId,
          author: event.authorId,
          instance: event.dateDate,
          closestEventInstance: event
        };
        $state.go('eventHorseDetails', params);
      }).catch(error => {
        $state.go('eventsHorse', { id: $stateParams.id || $state.params.id });
        return responseHandler.processError(error, null, MessageFrames.TOP);
      });
    } else {
      $state.go('eventsHorse', { id: $stateParams.id || $state.params.id });
      return $q.when({});
    }
  }

  setGoNativeHorseMenu.$inject = ['$stateParams', '$state', '$q', '$timeout', 'goNative', '_authenticated', '_horsePromise'];
  function setGoNativeHorseMenu($stateParams, $state, $q, $timeout, goNative, _authenticated, _horsePromise) {
    const deferred = $q.defer();
    $timeout(function() {
      goNative.setGoNativeHorseMenu($stateParams.id || $state.params.id);
      deferred.resolve();
    }, 10);
    return deferred.promise;
  }

  const interior = {
      templateUrl: 'header-interior.html',
      controller: 'HeaderInteriorController',
      controllerAs: 'vm'
    },

    glueView = {
      templateUrl: 'glue.html',
      controller: 'GlueController',
      controllerAs: 'vm'
    };

  $urlMatcherFactoryProvider.strictMode(false);

  $stateProvider
    .state('horseMain', {
      url: '/n/horse/:id',
      resolve: {
        _authenticated: authenticated,
        _horsePromise: horsePromise,
        module: ['moduleAccessService', '_authenticated', '_horsePromise', function(moduleAccessService, _authenticated, _horsePromise) {
          return moduleAccessService.resolveModuleAccess('horses');
        }],
        setGoNativeHorseMenu: setGoNativeHorseMenu
      },
      views: {
        content: glueView,
        header: {
          templateUrl: 'header-horse.html',
          controller: 'HeaderHorseController',
          controllerAs: 'vm'
        }
      }
    })
    .state('activityHorse', {
      url: '/activity',
      parent: 'horseMain',
      views: {
        content: {
          templateUrl: 'horse-activity.html',
          controller: 'HorseActivityController',
          controllerAs: 'vm'
        }
      }
    })
    /**
     * EVENTS VIEWS
     */
    .state('eventsHorse', {
      url: '/events',
      parent: 'horseMain',
      resolve: {
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('events');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          permissionEnforcer.enforcePermissionsList(['events:read', 'lessons:read']);
        }]
      },
      views: {
        content: {
          templateUrl: 'events.html',
          controller: 'EventsController',
          controllerAs: 'vm'
        }
      }
    })
    .state('eventHorseDetails', {
      url: '/n/horse/:id/events/:eventId?instance&?author',
      params: {
        closestEventInstance: null
      },
      resolve: {
        _authenticated: authenticated,
        _module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('events');
        }],
        _permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('events:read');
        }],
        _horsePromise: horsePromise,
        getClosestEvent: getClosestEvent,
        setGoNativeHorseMenu: setGoNativeHorseMenu
      },
      views: {
        content: {
          templateUrl: 'event-details.html',
          controller: 'EventDetailsController',
          controllerAs: 'vm'
        },
        header: interior
      },
      data: {
        horseHeader: true
      }
    })
    .state('eventHorseEdit', {
      url: '/n/horse/:id/events/:eventId/edit?instance&?author?thisAndFollowing',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('events');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('events:full');
        }],
        _horsePromise: horsePromise,
        setGoNativeHorseMenu: setGoNativeHorseMenu
      },
      views: {
        content: {
          templateUrl: 'event-form.html',
          controller: 'EventFormController',
          controllerAs: 'vm'
        },
        header: interior
      },
      data: {
        horseHeader: true
      }
    })
    /**
     * CONVERSATIONS VIEWS
     */
    .state('conversationsHorse', {
      url: '/conversations',
      parent: 'horseMain',
      resolve: {
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('conversations');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('conversations:read');
        }]
      },
      views: {
        content: {
          templateUrl: 'conversations.html',
          controller: 'ConversationsController',
          controllerAs: 'vm'
        }
      }
    })
    .state('conversationHorseDetails', {
      url: '/n/horse/:id/conversations/:conversationId?author',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('conversations');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('conversations:read');
        }],
        _horsePromise: horsePromise,
        setGoNativeHorseMenu: setGoNativeHorseMenu
      },
      views: {
        content: {
          templateUrl: 'conversation-details.html',
          controller: 'ConversationDetailsController',
          controllerAs: 'vm'
        },
        header: interior
      },
      data: {
        horseHeader: true
      }
    })
    .state('conversationHorseEdit', {
      url: '/n/horse/:id/conversations/:conversationId/edit?author',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('conversations');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('conversations:full');
        }],
        _horsePromise: horsePromise,
        setGoNativeHorseMenu: setGoNativeHorseMenu
      },
      views: {
        content: {
          templateUrl: 'conversation-form.html',
          controller: 'ConversationFormController',
          controllerAs: 'vm'
        },
        header: interior
      },
      data: {
        horseHeader: true
      }
    })
    /**
     * RECORDS VIEWS
     */
    .state('recordsHorse', {
      url: '/records',
      parent: 'horseMain',
      resolve: {
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('records');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('records:read');
        }]
      },
      views: {
        content: {
          templateUrl: 'records.html',
          controller: 'RecordsController',
          controllerAs: 'vm'
        }
      }
    })
    .state('recordHorseDetails', {
      url: '/n/horse/:id/records/:recordId?author',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('records');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('records:read');
        }],
        _horsePromise: horsePromise,
        setGoNativeHorseMenu: setGoNativeHorseMenu
      },
      views: {
        content: {
          templateUrl: 'record-details.html',
          controller: 'RecordDetailsController',
          controllerAs: 'vm'
        },
        header: interior
      },
      data: {
        horseHeader: true
      }
    })
    .state('recordHorseEdit', {
      url: '/n/horse/:id/records/:recordId/edit?author',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('records');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('records:full');
        }],
        _horsePromise: horsePromise,
        setGoNativeHorseMenu: setGoNativeHorseMenu
      },
      views: {
        content: {
          templateUrl: 'record-form.html',
          controller: 'RecordFormController',
          controllerAs: 'vm'
        },
        header: interior
      },
      data: {
        horseHeader: true
      }
    })
    /**
     * LISTS VIEWS
     */
    .state('listsHorse', {
      url: '/lists',
      parent: 'horseMain',
      resolve: {
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('lists');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('lists:read');
        }]
      },
      views: {
        content: {
          templateUrl: 'lists.html',
          controller: 'ListsController',
          controllerAs: 'vm'
        }
      }
    })
    .state('listHorseDetails', {
      url: '/n/horse/:id/lists/:listId?author',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('lists');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('lists:read');
        }],
        _horsePromise: horsePromise,
        setGoNativeHorseMenu: setGoNativeHorseMenu
      },
      views: {
        content: {
          templateUrl: 'list-details.html',
          controller: 'ListDetailsController',
          controllerAs: 'vm'
        },
        header: interior
      },
      data: {
        horseHeader: true
      }
    })
    .state('listHorseEdit', {
      url: '/n/horse/:id/lists/:listId/edit?author',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('lists');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('lists:full');
        }],
        _horsePromise: horsePromise,
        setGoNativeHorseMenu: setGoNativeHorseMenu
      },
      views: {
        content: {
          templateUrl: 'list-form.html',
          controller: 'ListFormController',
          controllerAs: 'vm'
        },
        header: interior
      },
      data: {
        horseHeader: true
      }
    })
    /**
     * ENTRIES VIEWS
     */
    .state('entriesHorse', {
      url: '/entries?weekStart',
      parent: 'horseMain',
      resolve: {
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('feed');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('feed:read');
        }]
      },
      views: {
        content: {
          templateUrl: 'entries.html',
          controller: 'EntriesController',
          controllerAs: 'vm'
        }
      }
    })
    .state('entryHorseNew', {
      url: '/n/horse/:id/entries/new?type',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('feed');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('feed:full');
        }],
        _horsePromise: horsePromise,
        setGoNativeHorseMenu: setGoNativeHorseMenu
      },
      views: {
        content: {
          templateUrl: 'entry-form.html',
          controller: 'EntryFormController',
          controllerAs: 'vm'
        },
        header: interior
      }
    })
    .state('entryHorseEdit', {
      url: '/n/horse/:id/entries/:entryId/edit',
      resolve: {
        _authenticated: authenticated,
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('feed');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('feed:full');
        }],
        _horsePromise: horsePromise,
        setGoNativeHorseMenu: setGoNativeHorseMenu
      },
      views: {
        content: {
          templateUrl: 'entry-form.html',
          controller: 'EntryFormController',
          controllerAs: 'vm'
        },
        header: interior
      }
    })
    .state('entryChanges', {
      url: '/entry-changes',
      parent: 'horseMain',
      resolve: {
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('feed');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('feed:read');
        }]
      },
      views: {
        content: {
          templateUrl: 'entry-changes.html',
          controller: 'EntryChangesController',
          controllerAs: 'vm'
        }
      }
    })
    /**
     * REPORTS VIEWS
     */
    .state('reportsHorse', {
      url: '/reports',
      parent: 'horseMain',
      resolve: {
        module: ['moduleAccessService', '_authenticated', function(moduleAccessService, _authenticated) {
          return moduleAccessService.resolveModuleAccess('feed');
        }],
        permissions: ['permissionEnforcer', '_authenticated', function(permissionEnforcer, _authenticated) {
          return permissionEnforcer.enforcePermissions('feed:read');
        }]
      },
      views: {
        content: {
          templateUrl: 'reports.html',
          controller: 'ReportsController',
          controllerAs: 'vm'
        }
      }
    })
}
