import {barnManagerModule} from '../index.module';

barnManagerModule
  .constant('ModuleAccessList', {
    canceled: [
      'reactivate',
      'profile'
    ],
    starter: [
      'admin',
      'activity',
      'noaccess',
      'profile',
      'search',
      'horses',
      'conversations',
      'lists',
      'contacts'
    ],
    essential: [
      'admin',
      'activity',
      'noaccess',
      'profile',
      'search',
      'horses',
      'conversations',
      'lists',
      'contacts',
      'feed',
      'records',
      'events',
      'vendors',
      'reports',
      'invoicing'
    ]
  });
