import {barnManagerModule} from '../index.module';
import {merge, sortBy} from 'lodash';

barnManagerModule.factory('userRepository', UserRepository);

UserRepository.$inject = ['$http', 'barnStorage'];
function UserRepository($http, barnStorage) {
  const url = '/api/users/';

  return {
    all: all,
    allIncludingArchived: allIncludingArchived,
    find: find,
    updatePermissions: updatePermissions,
    feedingMembersOfHorse: feedingMembersOfHorse,
    saveFeedingMembersOfHorse: saveFeedingMembersOfHorse,
    proTrialEndedShown: proTrialEndedShown
  };

  function all() {
    return $http.get(url, {
      params: mergeParams({
        archived: 0,
        pagesize: 999
      })
    }).then(function(results) {
      return sortBy(results.data.records, 'name');
    });
  }

  function allIncludingArchived() {
    return $http.get(url, {
      params: mergeParams({
        pagesize: 999
      })
    }).then(function(results) {
      return sortBy(results.data.records, 'name');
    });
  }

  function updatePermissions(id, permissions) {
    return $http.put(url + id + '/permissions', permissions).then(function(results) {
      return results.data;
    });
  }

  function find(id) {
    return $http.get(url + id, {
      params: mergeParams({})
    }).then(function(results) {
      return results.data;
    });
  }

  function feedingMembersOfHorse(horse) {
    return $http.get('/api/horses/' + horse.id + '/feedingMembers', {
      params: mergeParams({})
    }).then(function(results) {
      return results.data;
    });
  }

  function proTrialEndedShown(id) {
    return $http.put('/api/users/' + id + '/pro-trial-ended-shown', {
      params: mergeParams({})
    });
  }

  function saveFeedingMembersOfHorse(horse, members) {
    return $http.put('/api/horses/' + horse.id + '/feedingMembers', members, {
      params: mergeParams({})
    });
  }

  function mergeParams(params) {
    const defaultParams = {
      tenantEnvironmentId: barnStorage.getEnvId()
    };
    return merge(defaultParams, params || {});
  }
}
