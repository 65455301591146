import {barnManagerModule} from '../index.module';

barnManagerModule
  .factory('RecordTypes', ['VendorTypes', 'TreatmentTypes', 'VendorRestrictions', (VendorTypes, TreatmentTypes, VendorRestrictions) => {
    return {
      VET: {
        name: 'Vet',
        value: 'VET',
        asTitle: 'Vet Record',
        vendorRestriction: new VendorRestrictions.TypeWithOther(VendorTypes.VET, 'Vet'),
        treatment: TreatmentTypes.NONE
      },
      DENTIST: {
        name: 'Dentist',
        value: 'DENTIST',
        asTitle: 'Dentist Record',
        vendorRestriction: new VendorRestrictions.MultipleTypes([VendorTypes.DENTIST, VendorTypes.VET, VendorTypes.OTHER], 'Dentist'),
        treatment: TreatmentTypes.NONE
      },
      FARRIER: {
        name: 'Farrier',
        value: 'FARRIER',
        asTitle: 'Farrier Record',
        vendorRestriction: new VendorRestrictions.TypeWithOther(VendorTypes.FARRIER, 'Farrier'),
        treatment: TreatmentTypes.NONE
      },
      DEWORMING: {
        name: 'Deworming',
        value: 'DEWORMING',
        asTitle: 'Deworming',
        vendorRestriction: new VendorRestrictions.Type(VendorTypes.OTHER, 'Vendor'),
        treatment: TreatmentTypes.COMPOUND_PRODUCT
      },
      MEDICATION: {
        name: 'Medication',
        value: 'MEDICATION',
        asTitle: 'Medication',
        vendorRestriction: new VendorRestrictions.TypeWithOther(VendorTypes.VET, 'Vet'),
        treatment: TreatmentTypes.MEDICATION
      },
      VACCINATION: {
        name: 'Vaccination',
        value: 'VACCINATION',
        asTitle: 'Vaccination',
        vendorRestriction: new VendorRestrictions.TypeWithOther(VendorTypes.VET, 'Vet'),
        treatment: TreatmentTypes.VACCINE
      },
      LEASE: {
        name: 'Lease',
        value: 'LEASE',
        asTitle: 'Lease',
        vendorRestriction: new VendorRestrictions.None(),
        treatment: TreatmentTypes.NONE
      },
      OTHER: {
        name: 'Other',
        value: 'OTHER',
        asTitle: 'Other',
        vendorRestriction: new VendorRestrictions.Type(VendorTypes.OTHER, 'Vendor'),
        treatment: TreatmentTypes.NONE
      }
    };
  }]);
