import {barnManagerModule} from '../index.module';
import * as angular from 'angular';
import {get} from 'lodash';

barnManagerModule.factory('userStorage', UserStorage);
UserStorage.$inject = ['sessionStorageService', 'rUser', 'User'];
function UserStorage(sessionStorageService, rUser, User) {
  let user = null;

  return {
    getUser: getUser,
    setUser: setUser,
    hasUser: hasUser,
    clean: clean,
    loadUser: loadUser,
    refreshUser: refreshUser
  };

  function getUser() {
    if (hasUser()) {
      return new User(angular.copy(user));
    } else if (sessionStorageService.getUser()) {
      return new User(sessionStorageService.getUser());
    }
    return null;
  }

  function setUser(_user) {
    user = new User(angular.copy(_user));
    user.userRoles[0].tenantEnvironment.timeZone = user.getBarn().tenant.timeZone;
    sessionStorageService.setUser(user);
  }

  function hasUser() {
    return !!get(user, 'id');
  }

  function loadUser(id) {
    return rUser.query({ id: id }).$promise.then(function(_user) {
      setUser(new User(_user));
      return new User(user);
    });
  }

  function refreshUser() {
    return loadUser(user.id);
  }

  function clean() {
    user = null;
  }
}
