import {barnManagerModule} from '../../../index.module';
import {forEach} from 'angular';

barnManagerModule.controller('UsersController', UsersController);

UsersController.$inject = [
  'backLinkHistory',
  'rUser',
  'responseHandler',
  'barnStorage',
  'listsService'
];
function UsersController(
  backLinkHistory,
  rUser,
  responseHandler,
  barnStorage,
  listsService
) {
  let vm = this, barn;
  backLinkHistory.pushLink('Users & Permissions');

  vm.users = [];
  vm.filteredUsers = [];
  vm.dropdownOptions = [
    { name: 'Active' },
    { name: 'Invited' },
    { name: 'Archived' }
  ];
  vm.filters = {
    by: vm.dropdownOptions[0]
  };

  vm.status = {
    error: false,
    loaded: false,
    busy: false,
    message : '',
    errorMessage: ''
  };

  vm.keywords = ['displayName'];

  vm.horsesNumberFilter = horsesNumberFilter;
  vm.statusFilter = statusFilter;

  init();

  function horsesNumberFilter(value) {
    return function(item) {
      if (value === 'allHorses') {
        return item.allHorsesAccess;
      }
      if (parseInt(value) === 10) {
        return item.horseIds.length >= value && !item.allHorsesAccess;
      }
      if (parseInt(value) >= 1 && parseInt(value) < 10) {
        return item.horseIds.length === parseInt(value) && !item.allHorsesAccess;
      }
      return true;
    };
  }

  function statusFilter(value) {
    return function(user) {
      switch (value.name) {
        case 'Active': return user.state === 'ACTIVE';
        case 'Invited': return user.state === 'INVITED';
        case 'Archived': return user.state === 'ARCHIVED';
        default: return true;
      }
    };
  }

  function init() {
    barn = barnStorage.getEnv();
    listsService.resetTo('environments', null);
    rUser.search({
      tenantEnvironmentId: barn.id,
      pagesize: 999
    }).$promise.then(function(users) {
      vm.users = users.records;
      vm.status.loaded = true;
    }).catch(function(error) {
      vm.status.loaded = true;
      vm.status.error = true;
      responseHandler.processError(error);
    });
  }
}
