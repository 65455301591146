import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {AuthorizedStorage} from './authorized.storage';
import {ResponseHandlerService} from '../response-handler/response-handler.service';
import {InterceptorSkipHeader} from '@shared/lib/rest/api-base-parameters';

@Injectable()
export class AuthorizedInterceptor implements HttpInterceptor {
  constructor(
    private authorizedStorage: AuthorizedStorage,
    private responseHandlerService: ResponseHandlerService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has(InterceptorSkipHeader)) {
      const headers = request.headers.delete(InterceptorSkipHeader);
      return next.handle(request.clone({ headers }));
    }

    try {
      const tenantEnvironmentId = this.authorizedStorage.tenantEnvironmentId;
      const accessToken = this.authorizedStorage.accessToken ?? '';

      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
          TenantEnvironmentId: `${tenantEnvironmentId}`
        }
      });
    } catch (e) {
      console.log('AuthorizedInterceptor: ', e);
    }

    return next.handle(request).pipe(
      tap((response: HttpResponse<any>) => this.handleResponse(response)),
      catchError((error: HttpErrorResponse) => this.handleError(error))
    );
  }

  private handleResponse(response: HttpResponse<any>): void {
    if (response.status === 401) {
      this.responseHandlerService.emitUnauthorized401Error();
    }
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    this.responseHandlerService.emitHttpError(error);
    return throwError(error);
  }
}
