import {barnManagerModule} from '../index.module';
import {saveAs} from 'file-saver';

barnManagerModule.service('FileOpener', FileOpener);

FileOpener.$inject = ['$q', 'goNative', 'responseHandler'];
function FileOpener($q, goNative, responseHandler)  {
  return function(data, fileName, _mimeType, isImage) {
    if (goNative.isAndroid()) {
      if (isImage) {
        goNative.downloadImage(data);
      } else {
        goNative.downloadFile(data);
      }
    } else {
      if (typeof data === 'string') {
        fetch(data)
          .then(async res => {
            const blob = await res.blob();
            const serverFilename = getServerFilenameFromHeader(res.headers.get('Content-Disposition'))
            return { blob, serverFilename };
          })
          .then(({ blob, serverFilename }) => saveAs(blob, serverFilename || fileName))
          .catch(() => responseHandler.errorMessage('File processing error'));
      } else {
        saveAs(data, fileName);
      }
    }
    return $q.resolve(true);
  };

  function getServerFilenameFromHeader(header: string): string {
    // Content-Disposition header should be in format 'attachment; filename="${fileName}.${fileExtension}"'
    try {
      return header && header
        .split(';')
        .find((n: string) => n.includes('filename='))
        .replace('filename=', '')
        .replace(/['"]/g, '')
        .trim();
    } catch (e) {
      console.error(e);
      return null;
    }
  }
}
