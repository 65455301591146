import {barnManagerModule} from '../../index.module';
import * as angular from 'angular';
import {groupBy} from 'lodash';
import {takeWhile} from 'rxjs/operators';
import {environment} from '@environment';

barnManagerModule
  .component('bmNotifications', {
    templateUrl: 'notifications.html',
    controller: NotificationsSidebar,
    controllerAs: 'vm',
    bindings: {
      'showOnlyUnread': '='
    }
  });

NotificationsSidebar.$inject = [
  '$scope',
  '$rootScope',
  '$interval',
  'responseHandler',
  'notificationRepository',
  'dateTimeUpgraded',
  'MessageFrames',
  'Notification',
  'NotificationTypes',
  'ng2ConnectionService'
];
function NotificationsSidebar(
  $scope,
  $rootScope,
  $interval,
  responseHandler,
  notificationRepository,
  dateTimeUpgraded,
  MessageFrames,
  Notification,
  NotificationTypes,
  ng2ConnectionService
) {
  let vm = this, pageSize = 20, intervalLoadNotifications, alive = true;

  vm.notifications = [];
  vm.fromUTCDateToLocalDateTimeFormatted = dateTimeUpgraded.fromUTCDateToLocalDateTimeFormatted;
  vm.loading = true;
  vm.notificationTypes = NotificationTypes;
  vm.notificationTypesValues = Object.keys(vm.notificationTypes);

  vm.loadMore = loadMore;
  vm.hasMore = hasMore;
  vm.toggleNotification = toggleNotification;

  init();

  function init() {
    const onUserAuthenticated = $rootScope.$on('userAuthenticated', function(event, user) {
      if (user) {
        watchShowOnlyUnreadChanges();
      } else {
        stopInterval();
      }
    });
    $rootScope.$on('$destroy', onUserAuthenticated);

    const unreadNotificationsChanged = $rootScope.$on('unreadNotificationsChanged', function(event, unreadNotifications) {
      if (Number(unreadNotifications) === 0) {
        if (vm.showOnlyUnread) {
          vm.notifications = [];
        } else {
          vm.notifications.forEach(notification => {
            if (notification.unread) {
              notification.unread = false;
            }
          });
        }
      }
      groupNotifications();
    });
    $rootScope.$on('$destroy', unreadNotificationsChanged);

    ng2ConnectionService.isOnline$().pipe(takeWhile(() => !!alive)).subscribe(online => {
      vm.isOnline = online;
    })
    $rootScope.$on('$destroy', () => alive = false);
  }

  function watchShowOnlyUnreadChanges() {
    $scope.$watch('vm.showOnlyUnread', () => loadNotifications(true, false));
  }

  function loadNotifications(reset, updateMode, timestamp?) {
    vm.loading = !updateMode;
    notificationRepository.all(pageSize, timestamp, vm.showOnlyUnread).then(data => {
      if (reset) {
        vm.notifications = [];
      }
      const newNotifications = [];
      const currentNotifications = angular.copy(vm.notifications);
      data.notifications.forEach(notification => {
        const notificationIndex = vm.notifications.findIndex(n => n.notificationId === notification.notificationId);
        if (notificationIndex === -1) {
          newNotifications.push(new Notification(notification));
        } else {
          currentNotifications.splice(notificationIndex, 1, new Notification(notification));
        }
      });
      if (updateMode) {
        vm.notifications = [...newNotifications, ...currentNotifications];
      } else {
        vm.notifications = [...currentNotifications, ...newNotifications];
        vm.showloadMore = data.notifications.length >= pageSize;
      }
      groupNotifications();
      notificationRepository.changeUnreadNotifications(data.unread);
      startInterval();
    }).catch(error => {
      responseHandler.processError(error, null, MessageFrames.TOP);
      if (error.status !== 401) {
        startInterval();
      }
    }).finally(() => {
      vm.loading = false;
    });
  }

  function groupNotifications() {
    vm.groupedNotifications = groupBy(vm.notifications, 'shortDate');
  }

  function loadMore() {
    if (hasMore()) {
      stopInterval();
      const timestamp = vm.notifications[vm.notifications.length - 1].created;
      loadNotifications(false, false, timestamp);
    }
  }

  function hasMore() {
    return vm.showloadMore && vm.notifications.length >= pageSize;
  }

  function toggleNotification(notification) {
    if (notification.updating) {
      return;
    }
    notification.unread = !notification.unread;
    notification.updating = true;
    notificationRepository.update(notification).then(() => {
    }).catch(error => {
      notification.unread = !notification.unread;
      responseHandler.processError(error, null, MessageFrames.TOP);
    }).finally(function() {
      notification.updating = false;
    });
  }

  function startInterval() {
    if (environment.hasOwnProperty('notificationsRequestIntervalEnabled') && !environment.notificationsRequestIntervalEnabled) {
      return;
    }
    stopInterval();
    intervalLoadNotifications = $interval(() => {
      if (vm.isOnline) {
        loadNotifications(false, true);
      } else {
        startInterval();
      }
    }, 30000);
  }

  function stopInterval() {
    $interval.cancel(intervalLoadNotifications);
  }
}
