import {barnManagerModule} from '../index.module';

barnManagerModule.service('permissionEnforcer', PermissionEnforcer);

PermissionEnforcer.$inject = ['$q', '$timeout', '$state', 'hasPermission'];
function PermissionEnforcer($q, $timeout, $state, hasPermission) {
  return {
    enforcePermissions: enforcePermissions,
    enforcePermissionsList: enforcePermissionsList,
  };

  function enforcePermissionsList(permissionKeys) {
    let allowedPermissionKey = null;
    for (let i = 0; i < permissionKeys.length; i++) {
      if (hasPermission(permissionKeys[i])) {
        allowedPermissionKey = permissionKeys[i];
        break;
      }
    }
    return enforcePermissions(allowedPermissionKey)
  }
  function enforcePermissions(permissionKey) {
    const deferred = $q.defer();
    if (hasPermission(permissionKey)) {
      deferred.resolve();
    } else {
      $timeout(function() {
        $state.go('no-permissions');
        deferred.reject();
      }, 10);
    }
    return deferred.promise;
  }
}
