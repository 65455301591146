import {barnManagerModule} from '../../../index.module';
import {fromJson, toJson} from 'angular';
import moment from 'moment';
import {cloneDeep, unescape} from 'lodash';

barnManagerModule.controller('ListFormController', ListFormController);

ListFormController.$inject = [
  'backLinkHistory',
  '$stateParams',
  '$rootScope',
  '$scope',
  '$state',
  'userStorage',
  'barnStorage',
  'ScrollToMessageFrame',
  'responseHandler',
  'listsService',
  'dateTimeUtils',
  'listRepository',
  'rUser',
  'rAttachments',
  'ListTypes',
  'titleService'
];
function ListFormController(
  backLinkHistory,
  $stateParams,
  $rootScope,
  $scope,
  $state,
  userStorage,
  barnStorage,
  ScrollToMessageFrame,
  responseHandler,
  listsService,
  dateTimeUtils,
  listRepository,
  rUser,
  rAttachments,
  ListTypes,
  titleService
) {
  let vm = this, removedFiles = [], barn;

  titleService.setTitle('List Form');
  $rootScope.pageConf.class = 'page--interior';

  vm.currentUser = userStorage.getUser();
  vm.listTypes = [
    { name: 'Check List', value: ListTypes.CHECKBOX_LIST },
    { name: 'Table List', value: ListTypes.TABLE_LIST }
  ];
  vm.assignedHorses = [];

  vm.calendarOpen = false;
  vm.attachmentsReady = false;
  vm.saving = false;
  vm.submitAttempt = false;
  vm.editMode = $state.current.name === 'listEdit' || $state.current.name === 'listHorseEdit';
  vm.listId = $state.current.name === 'listHorseEdit' ? $stateParams.listId : $stateParams.id;
  vm.error = false;
  vm.horseId = $state.params.horseId;

  vm.queueDelete = queueDelete;
  vm.save = save;
  vm.openCalendar = openCalendar;
  vm.typeChanged = typeChanged;
  vm.assigneeChanged = assigneeChanged;
  vm.onHorsesChange = onHorsesChange;
  vm.onHorsesSet = onHorsesSet;
  vm.onTagsChange = onTagsChange;
  vm.onMembersChange = onMembersChange;

  initializeList(vm.editMode);

  function onHorsesChange(horseIds) {
    vm.list.horseIds = horseIds;
  }

  function onHorsesSet(horses) {
    vm.assignedHorses = horses;
  }

  function onTagsChange(tags) {
    vm.list.tags = tags;
  }

  function onMembersChange(membersModel) {
    vm.list.memberIds = membersModel.memberIds;
    vm.list.allTeamMembersNotifications = membersModel.allTeamMembersNotifications;
  }

  function updateRelatedData(list, attachments) {
    listsService.updateAttachments(attachments.new, attachments.removed, list);
  }

  function goToList() {
    return listsService.ready(function() {
      vm.saving = false;
      responseHandler.successOnSave('List', vm.editMode);
      backLinkHistory.goBack();
    });
  }

  function errorHandler(error) {
    vm.saving = false;
    ScrollToMessageFrame();
    return responseHandler.processError(error);
  }

  function queueDelete(file) {
    removedFiles.push(file);
  }

  function openCalendar() {
    vm.calendarOpen = true;
  }

  function setAssignee(assignee) {
    const watchAssignee = $scope.$watch('vm.users', function(newVal) {
      if (typeof newVal !== 'undefined') {
        watchAssignee();
        vm.assignee = vm.users.filter(function(obj) {
          return obj.id === assignee; // set default value
        })[0];
      }
    });
  }

  function typeChanged(newType) {
    if (newType.value === 'check') {
      delete vm.list.table;
      vm.list.items = [];
    } else {
      vm.list.table = {
        table: [['', ''], ['', ''], ['', '']]
      };
      delete vm.list.items;
    }
  }

  function assigneeChanged(assignee) {
    if (typeof assignee !== 'undefined' && assignee != null) {
      vm.list.assignee = assignee.id;
    } else {
      vm.list.assignee = null;
    }
  }

  function initializeList(editMode) {
    barn = barnStorage.getEnv();
    vm.list = {
      id: 0,
      tenantEnvironmentId: barn.id,
      pinned: 0,
      read: 0,
      type: 'LIST', // TODO Drop this when API is ready for this sh*t
      allHorsesAccess: 0,
      tags: [],
      allTeamMembersNotifications: false
    };

    loadUsers();
    listsService.resetTo('lists', 'LIST_SIMPLE');

    if (editMode) {
      vm.loading = true;
      listRepository.get(vm.listId).then(function(list) {
        vm.loading = false;
        backLinkHistory.pushLink(list.title);
        vm.list = list;
        vm.list.read = 1;

        if (vm.list.dueOn) {
          vm.list.dueOn = moment(list.dueOn).toDate();
        }

        titleService.setTitle(list.title + ' :: Lists');
        if (list.assignee) {
          setAssignee(list.assignee);
        }
        if (list.items?.length > 0) {
          vm.list.items = vm.list.items.sort((a, b) => a.pos - b.pos);
          vm.rType = vm.listTypes[0];
        } else if (vm.list.table) {
          vm.list.table = fromJson(unescape(list.table.replace(/&quot;/g, '\\\"'))); // eslint-disable-line
          vm.rType = vm.listTypes[1];
        } else {
          vm.rType = vm.listTypes[0];
        }

        rAttachments.query({ modelType: 'LIST_SIMPLE', modelId: list.id }).$promise.then(function(attachments) {
          vm.attachments = attachments;
          vm.attachmentsReady = true;
        }).catch(function(error) {
          vm.attachmentsReady = true;
          responseHandler.processError(error);
        });
      }).catch(function(error) {
        vm.loading = false;
        vm.error = true;
        responseHandler.processError(error);
      });
    } else {
      backLinkHistory.pushLink('New list');
      vm.attachmentsReady = true;
      if (vm.horseId) {
        vm.list.horseIds = [Number(vm.horseId)];
      }
    }
  }

  function save(form) {
    vm.submitAttempt = true;

    if (!form.$valid) {
      return;
    }

    const clonedList = cloneDeep(vm.list);

    if (vm.rType.value === ListTypes.TABLE_LIST) {
      clonedList.table.cols = vm.list.table.table[0].length;
      clonedList.table.rows = vm.list.table.table.length;
      clonedList.table.type = vm.rType.value;
      clonedList.table = toJson(vm.list.table);
    } else {
      clonedList.items.forEach((item, index) => item.pos = index);
    }

    vm.error = false;
    vm.saving = true;

    if (clonedList.dueOn) {
      clonedList.dueOn = dateTimeUtils.resetTimezone(clonedList.dueOn);
    }

    if (vm.editMode) {
      updateRelatedData(clonedList, {
        new: vm.attachments,
        removed: removedFiles
      });
      listRepository.update(clonedList.id, clonedList)
        .then(goToList)
        .catch(errorHandler);
    } else {
      listRepository.add(clonedList).then(function(list) {
        clonedList.id = list.id;
        clonedList.authorId = list.authorId;
        updateRelatedData(clonedList, {
          new: vm.attachments,
          removedFiles: removedFiles
        });
      }).then(goToList).catch(errorHandler);
    }
  }

  function loadUsers() {
    rUser.search({
      tenantEnvironmentId: barn.id,
      archived: false,
      pagesize: 999
    }).$promise.then(function(response) {
      vm.users = response.records;
    }).catch(responseHandler.processError);
  }

}
