import {barnManagerModule} from '../index.module';

barnManagerModule.directive('bmAccessibleForm', accessibleForm);

accessibleForm.$inject = ['$timeout'];
function accessibleForm($timeout) {
  /**
    Use bm-focusable-error attribute for custom errors (non-angular)
      <span bm-focusable-error="vm.error">Text</span>
    WARNING!
      uib-datepicker-popup conflicts with bm-focusable-error directive
  */
  return {
    restrict: 'A',
    link: function(scope, elem) {
      elem.on('submit', function() {
        $timeout(function() {
          const firstInvalid = elem[0].querySelector('.ng-invalid,.bm-custom-invalid');
          if (firstInvalid) {
            firstInvalid.focus();
          }
        }, 100);
      });
    }
  };
}
