export class StorageKeys {
  public static get LOGIN_INFO(): string {
    return 'bmLoginInfo';
  }

  public static get USER(): string {
    return 'bmUser';
  }

  public static get TEST_API_ENDPOINT(): string {
    return 'bmTestApiEndpoint';
  }
}
