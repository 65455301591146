import {Injectable} from '@angular/core';
import {RestService} from '@shared/lib/rest/rest.service';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {HorseCategoryType} from '../../../bm-ng1/resources/horseCategoryRepository';
import {AllHorseCategories} from './horse-category';

@Injectable({ providedIn: 'root' })
export class HorseCategoryHttpService {
  constructor(
    public rest: RestService
  ) {
  }

  all(): Observable<AllHorseCategories> {
    return this.rest.get({
      path: 'horses/categories'
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    )
  }

  byType(type: HorseCategoryType): Observable<any> {
    return this.rest.get({
      path: `horses/categories/by-type/${type}`
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    )
  }

  archive(id: number): Observable<any> {
    return this.rest.put({
      path: `horses/categories/${id}/archive`
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    )
  }

  restore(id: number): Observable<any> {
    return this.rest.put({
      path: `horses/categories/${id}/restore`
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    )
  }
}
