import {barnManagerModule} from './index.module';
import {takeWhile} from 'rxjs/operators';

barnManagerModule.run(runBlock);

runBlock.$inject = [
  '$rootScope',
  '$window',
  'authenticationService',
  'DATE_FORMAT',
  'ANGULAR_DATE_FORMAT',
  'USER_FACING_DATE_FORMAT',
  'DATE_TIME_FORMAT',
  'DATE_PICKER_FORMAT',
  'DATE_ISO_FORMAT',
  'backLinkHistory',
  '$templateCache',
  'ng2ConnectionService',
  'messageBus',
  'MessageFrames'
];
function runBlock(
  $rootScope,
  $window,
  authenticationService,
  DATE_FORMAT,
  ANGULAR_DATE_FORMAT,
  USER_FACING_DATE_FORMAT,
  DATE_TIME_FORMAT,
  DATE_PICKER_FORMAT,
  DATE_ISO_FORMAT,
  backLinkHistory,
  $templateCache,
  ng2ConnectionService,
  messageBus,
  MessageFrames
) {
  initTemplateCache();

  trackConnection();

  backLinkHistory.applicationStarted();

  $rootScope.dateFormat = DATE_FORMAT;
  $rootScope.userFacingDateFormat = USER_FACING_DATE_FORMAT;
  $rootScope.angularDateFormat = ANGULAR_DATE_FORMAT;
  $rootScope.dateTimeFormat = DATE_TIME_FORMAT;
  $rootScope.datePickerFormat = DATE_PICKER_FORMAT;
  $rootScope.dateIsoFormat = DATE_ISO_FORMAT;

  const APIAuthError = $rootScope.$on('401UnauthorizedError', function() {
    authenticationService.logoutOnNotAuthenticated(true);
  });
  $rootScope.$on('$destroy', APIAuthError);

  const stateChange = $rootScope.$on('$stateChangeStart', function() {
    $rootScope.pageConf.class = '';
    $rootScope.pageConf.htmlTagClass = '';
    $rootScope.pageConf.horseName = '';
  });
  $rootScope.$on('$destroy', stateChange);

  const stateSuccess = $rootScope.$on('$stateChangeSuccess', function() {
    $window.scrollTo(0, 0);
  });
  $rootScope.$on('$destroy', stateSuccess);

  const showLoader = $rootScope.$on('$showLoader', function(event, showLoader) {
    const loaderElement = document.getElementById('loading-icon');
    const mainContentElement = document.getElementById('main-content');
    if (loaderElement && mainContentElement) {
      if (showLoader) {
        loaderElement.removeAttribute('style');
        mainContentElement.setAttribute('style', 'display: none');
      } else {
        loaderElement.setAttribute('style', 'display: none');
        mainContentElement.removeAttribute('style');
      }
    }
  });
  $rootScope.$on('$destroy', showLoader);

  function initTemplateCache(): void {
    const modules = requireAll((<any>require).context("/", true, /\.html$/));
    modules.map(val => $templateCache.put(val.name, val.tpl));

    function requireAll(requireContext) {
      return requireContext.keys().map(function(val){
        return {
          tpl: requireContext(val),
          name: val.split('/').pop()
        }
      });
    }
  }

  function trackConnection() {
    let alive = true;
    ng2ConnectionService.isOnline$().pipe(takeWhile(() => !!alive)).subscribe(online => {
      if (online) {
        messageBus.clear(MessageFrames.TOP);
      } else {
        const text = 'Connection Lost. Please check your Internet connection';
        messageBus.push({ frame: MessageFrames.TOP, text, type: 'alert' });
      }
    })
    $rootScope.$on('$destroy', () => alive = false);
  }

  $rootScope.pageConf = {};
}
