import {barnManagerModule} from '../index.module';

barnManagerModule.factory('httpErrorInterceptor', httpErrorInterceptor);

httpErrorInterceptor.$inject = ['$q', 'errorNotificator', 'responseHandler', 'MessageFrames'];
function httpErrorInterceptor($q, errorNotificator, responseHandler, MessageFrames) {
  return {
    responseError: function(response) {
      let message = '';
      if (response.data && response.data.code === 'BUS_0071') {
        responseHandler.errorMessage('We have released a new version of the app. In order to continue, please reload the page. If you\'re on mobile, swipe down from the top of the screen. Close and reopen the app if this message doesn\'t disappear.', MessageFrames.TOP);
        return $q.reject();
      } else {
        if (response.data && response.data.message) {
          message = response.data.message;
        }
        if (response.status >= 400 && response.status < 600) {
          errorNotificator.captureException('HTTP response error: ' + message, response, true);
        }

        return responseHandler.serverError(response);
      }
    }
  };
}
