import {barnManagerModule} from '../index.module';
import moment from 'moment';

barnManagerModule.directive('dateFormatter', dateFormatter);

dateFormatter.$inject = ['DATE_ISO_FORMAT'];
function dateFormatter(DATE_ISO_FORMAT) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function postLink(scope, element, attrs, ngModel) {

      ngModel.$formatters.push(function(date) {
        return moment(date, DATE_ISO_FORMAT).toDate();
      });
      ngModel.$parsers.push(function(date) {
        date.setMinutes(date.getTimezoneOffset() * -1);
        return date;
      });
    }
  };
}
