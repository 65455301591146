import {barnManagerModule} from '../index.module';
import moment from 'moment';
import {get, reduce, merge} from 'lodash';
import {extend} from 'angular';

enum ReportType {
  FEED = 'FEED',
  HORSE_DETAILS = 'HORSE_DETAILS',
  RECORD = 'RECORD',
}

barnManagerModule.service('reportGenerator', ReportGenerator);

ReportGenerator.$inject = [
  '$rootScope',
  '$compile',
  '$http',
  '$templateCache',
  '$timeout',
  '$q',
  'imageTransformer',
  'rHorses',
  'rAttachments',
  'horseImageService',
  'utils',
  'DATE_FORMAT',
  'DATE_PICKER_FORMAT',
  'PrintReports',
  'FileOpener'
];
function ReportGenerator(
  $rootScope,
  $compile,
  $http,
  $templateCache,
  $timeout,
  $q,
  imageTransformer,
  rHorses,
  rAttachments,
  horseImageService,
  utils,
  DATE_FORMAT,
  DATE_PICKER_FORMAT,
  PrintReports,
  FileOpener
) {

  const additionalFormats = {
    datePickerFormat: DATE_PICKER_FORMAT
  };

  return  {
    printRecordReport: printRecordReport,
    printDischargeReport: printDischargeReport,
    printGeneralReport: printGeneralReport,
    printOneHorseFeedReport: printOneHorseFeedReport,
    printMultiHorsesFeedReport: printMultiHorsesFeedReport
  };

  function getBarnLogo(barn) {
    const deferred = $q.defer();

    rAttachments.query({
      modelType: 'Barn',
      modelId: barn.id
    }, function(attachments) {
      if (attachments.length) {
        rAttachments.get({id: attachments[0].id}).$promise.then(function(image) {
          horseImageService.generateInlineImage(image).then(function(img) {
            deferred.resolve(img);
          });
        });
      } else {
        deferred.resolve('UNRECOGNIZED_IMAGE');
      }
    }, function() {
      deferred.resolve('UNRECOGNIZED_IMAGE');
    });

    return deferred.promise;
  }

  function getHorseImage(horse) {
    const deferred = $q.defer();

    if (horse.horseImageThumbnailId) {
      horseImageService.getInlineImage(horse.horseImageThumbnailId).then(function(image) {
        imageTransformer.resizeBase64ToBase64(image).then(function(resized) {
          deferred.resolve(resized);
        });
      }).catch(function() {
        deferred.resolve('UNRECOGNIZED_IMAGE');
      });
    } else {
      deferred.resolve('UNRECOGNIZED_IMAGE');
    }

    return deferred.promise;
  }

  function loadExtendCss(cssPaths) {
    const promises = cssPaths.map(function(cssPath) {
      return $http.get(cssPath, { cache: $templateCache });
    });

    return $q.all(promises).then(function(allCss) {
      return allCss.map(function(res) {
        return res.data;
      }).join('/* --- */');
    });
  }

  function generatePostParams(compiledTemplates, css) {
    return reduce(compiledTemplates, function(acc, dom, key) {
      acc[key] = `
        <!DOCTYPE html>
        <html>
          <head>
          </head>
          <body onload="loaded && loaded()">${dom[0].outerHTML}${css ? ('<style>' + css + '</style>') : ''}</body>
        </html>`;
      return acc;
    }, {});
  }

  function getDate(params, path) {
    if (params.includeVetRecords) {
      return get(params, path);
    }
  }

  function downloadPdf(params, reportOptions, downloadReportMethod) {
    const deferred = $q.defer();

    const templatesPromises = reduce(reportOptions.templates, function(acc, path, key) {
      acc[key] = $http.get(path, { cache: $templateCache });
      return acc;
    }, {});

    $q.all(templatesPromises).then(function(response) {
      const scope = extend($rootScope.$new(), params, {
        currentDate: moment().format(DATE_FORMAT)
      });
      scope.hasOneOf = utils.checkExistSomeParamsFunc(scope);

      const compiledTemplates = reduce(response, function(acc, res, key) {
        acc[key] = $compile(res.data)(scope);
        return acc;
      }, {});

      $timeout(function() {
        downloadReportMethod({
          id: params.horse.id,
          recordId: params.resource?.id,
          type: params.type,
          tenantEnvironmentId: params.tenantEnvironmentId,
          includeVetRecords: params.includeVetRecords,
          vetRecordsStartDate: getDate(params, 'vetRecordsPeriod.startDate'),
          vetRecordsEndDate: getDate(params, 'vetRecordsPeriod.endDate'),
          orientation: params.orientation
        }, generatePostParams(compiledTemplates, params.css)).$promise.then(function(result) {
          deferred.resolve(FileOpener(result.data, reportOptions.getFileName(params), 'application/pdf'));
        }).catch(function(error) {
          deferred.reject(error);
        });
      }, 1000);
    });

    return deferred.promise;
  }

  function printGeneralReport(params) {
    const reportOptions = PrintReports.details;
    const detailsPromises = {
      horseImage: getHorseImage(params.horse)
    };
    return $q.all(detailsPromises).then(function(data) {
      merge(params, data, additionalFormats);
      return downloadPdf({...params, type: ReportType.HORSE_DETAILS}, reportOptions, rHorses.downloadHorseDetailsPdf);
    });
  }

  function printRecordReport(params) {
    const reportOptions = PrintReports.recordDetails;
    const attachments = [];
    if (params.attachments && params.attachments.length) {
      params.attachments.forEach(function(attachment) {
        if (attachment.data) {
          attachments.push(horseImageService.generateInlineImage(attachment));
        }
      });
    }
    const detailsPromises: any = {
      css: loadExtendCss(reportOptions.cssPaths)
    };
    if (attachments.length) {
      detailsPromises.images = $q.all(attachments);
    }

    return $q.all(detailsPromises).then(function(data) {
      merge(params, data, additionalFormats, { horse: { id: 0 } });
      return downloadPdf({...params, type: ReportType.RECORD}, reportOptions, rHorses.downloadHorseDetailsPdf);
    });
  }

  function printDischargeReport(params) {
    const reportOptions = PrintReports.discharge;
    const detailsPromises = {
      horseImage: getHorseImage(params.horse),
      barnLogo: getBarnLogo(params.barn),
      css: loadExtendCss(reportOptions.cssPaths),
      discharge: rHorses.dischargeReport({
        id: params.horse.id,
        tenantEnvironmentId: params.tenantEnvironmentId,
        includeVetRecords: params.includeVetRecords,
        vetRecordsStartDate: getDate(params, 'vetRecordsPeriod.startDate'),
        vetRecordsEndDate: getDate(params, 'vetRecordsPeriod.endDate')
      }).$promise
    };
    return $q.all(detailsPromises).then(function(data) {
      merge(params, data, additionalFormats);
      return downloadPdf(params, reportOptions, rHorses.downloadDischargeReportPdf);
    });
  }

  function printOneHorseFeedReport(params) {
    const reportOptions = PrintReports.oneHorseFeed;
    const detailsPromises = {
      horseImage: getHorseImage(params.horse),
      barnLogo: getBarnLogo(params.barn),
      css: loadExtendCss(reportOptions.cssPaths),
      entryTypes: reportOptions.entryTypes,
      fontSize: params.fontSize,
      pageOrientation: params.orientation
    };
    return $q.all(detailsPromises).then(function(data) {
      merge(params, data, additionalFormats);
      return downloadPdf({...params, type: ReportType.FEED}, reportOptions, rHorses.downloadHorseDetailsPdf);
    });
  }

  function printMultiHorsesFeedReport(params) {
    const reportOptions = PrintReports.multiHorsesFeed;

    if (params.horses.length === 1) {
      reportOptions.getFileName = PrintReports.oneHorseFeed.getFileName;
      params.horse = params.horses[0];
    }

    const horsesImages = params.horses.reduce(function(acc, horse) {
      acc[horse.id] = getHorseImage(horse);
      return acc;
    }, {});

    const detailsPromises = {
      horseImages: $q.all(horsesImages),
      barnLogo: getBarnLogo(params.barn),
      css: loadExtendCss(reportOptions.cssPaths),
      entryTypes: reportOptions.entryTypes,
      fontSize: params.fontSize,
      pageOrientation: params.orientation
    };

    return $q.all(detailsPromises).then(function(data) {
      merge(params, data, additionalFormats, {
        horse: params.horse ?? { id: 0 }  //FIXME: API needs a horse ID since it uses the same print endpoint as a Print Horse Details Report
      });
      return downloadPdf({...params, type: ReportType.FEED}, reportOptions, rHorses.downloadHorseDetailsPdf);
    });
  }
}
