import {barnManagerModule} from '../../index.module';

barnManagerModule.service('confirmDialog', ConfirmDialog);

ConfirmDialog.$inject = ['$q'];
function ConfirmDialog($q) {
  const vm = this;
  let listener = null;
  let deferred = null;

  vm.closed = function() {
    deferred.reject('closed');
  };

  vm.confirmed = function() {
    deferred.resolve('confirmed');
  };

  vm.open = function(text, type, confirmText, cancelText, headerText) {
    deferred = $q.defer();

    if (!listener) {
      throw 'No listener is registered for ConfirmDialog';
    }
    listener.open(text, type, confirmText, cancelText, headerText);

    return deferred.promise;
  };

  vm.subscribe = function(aListener) {
    listener = aListener;
  };
}
