import {barnManagerModule} from '../../index.module';
import {extend} from 'angular';

barnManagerModule.directive('bmFiltersArea', filtersArea);

function filtersArea() {
  return {
    restricted: 'E',
    templateUrl: 'filters-area.html',
    controller: FiltersAreaController,
    controllerAs: 'filters',
    bindToController: true,
    transclude: {
      title: '?bmFiltersAreaTitle',
      action: '?bmFiltersAreaAction',
      extra: '?bmFiltersAreaExtra',
      showing: '?bmFiltersAreaShowing'
    },
    scope: {
      defaultDropdownOption: '@',
      title: '@',
      dropdownOptions: '=',
      currentView: '=',
      totalItemsSize: '=',
      horsesPerPage: '<',
      maxSize: '=',
      ngModel: '=',
      sortByFirst: '@',
      sortBySecond: '@',
      sortByLastUpdated: '=',
      onFiltersChanged: '<?',
      hideShowBy: '<'
    }
  };

  function FiltersAreaController() {
    const vm = this;
    vm.$onInit = function() {

      if (typeof(vm.hideShowBy) === 'undefined') {
        vm.hideShowBy = false;
      }

      if (typeof(vm.onFiltersChanged) === 'undefined') {
        vm.onFiltersChanged = function() {};
      }

      vm.setView = function(viewType) {
        vm.currentView = viewType;
      };

      vm.setMaxSize = function() {
        vm.maxSize = 999;
        vm.onFiltersChanged(vm);
      };

      vm.ngModel = extend({}, vm.ngModel, {by : vm.dropdownOptions[vm.defaultDropdownOption || 0]});
    };
  }
}
