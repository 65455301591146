import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter, HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  ReportTypeFilterField,
  ReportTypeFilterFieldConfigType,
  ReportTypeFilterValues,
  ReportTypeSpecification
} from '../ReportTypeSpecification';
import {Moment} from 'moment';
import * as moment from 'moment';
import {today} from '@shared/lib/utils/date-range';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {merge, Observable, of, Subject} from 'rxjs';
import {Horse, HorseAdvancedSelectComponent, HorseAdvancedSelectModalData, HorseCategoryItem} from '../../horse';
import {DateRange, PrimitiveDateRangeMap} from '@shared/lib/utils/date-range';
import {DaterangepickerDirective} from 'ngx-daterangepicker-material';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, debounceTime, filter, map, shareReplay, switchMap, take, takeUntil} from 'rxjs/operators';
import {NameValueType} from '../../core/utils/name-value-type';
import {EventTypes} from '../../event/models/event-types';
import {RecordTypes} from '../../record/models/record-types';
import {DynamicKeyType} from '../../core/utils/dynamic-key-type';
import {HorseService} from '../../horse/horse.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FeedReportModalComponent, FeedReportModalData, FeedReportParams} from '../../feed';

interface ReportForm {
  reportType: FormControl<ReportTypeSpecification>;
  [key: string]: FormControl<any>;
}

interface ReportFormValues {
  reportType: ReportTypeSpecification;
  [key: string]: any;
}

@Component({
  selector: 'bm-report-filters',
  templateUrl: './report-filters.component.html',
  styleUrls: ['./report-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ReportFiltersComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'bm-report-filters';
  @Input() horseId: number;
  @Output() filtersChanged: EventEmitter<ReportTypeFilterValues & { valid: boolean }> = new EventEmitter<ReportTypeFilterValues & { valid: boolean }>();
  @Output() downloadFeedReport: EventEmitter<FeedReportParams> = new EventEmitter<FeedReportParams>();
  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;
  reportForm: FormGroup<ReportForm>;
  readonly reportTypes: ReportTypeSpecification[] = ReportTypeSpecification.ALL;
  readonly horses$: Observable<Horse[]> = this.getHorses$();
  private selectedByUserCategories: HorseCategoryItem[] = [];
  readonly periodDateRanges: PrimitiveDateRangeMap = {
    'Today': DateRange.today().toArrayDateRangeMoment(),
    'This Month': DateRange.currentMonth().toArrayDateRangeMoment(),
    'Next 30 days': DateRange.in30Days().toArrayDateRangeMoment(),
    'Last Month': DateRange.lastMonth().toArrayDateRangeMoment(),
    'This Year': DateRange.currentYear().toArrayDateRangeMoment(),
    'Last Year': DateRange.lastYear().toArrayDateRangeMoment()
  };
  readonly eventTypes: Array<NameValueType & { type: string }> = [...EventTypes.All].map(eventType => ({ ...eventType, type: 'eventType' }));
  readonly recordTypes: Array<NameValueType & { type: string }> = [...RecordTypes.All].map(recordType => ({ ...recordType, type: 'recordType' }));
  readonly ReportTypeFilterField = ReportTypeFilterField;
  readonly EventTypes = EventTypes;
  readonly RecordTypes = RecordTypes;
  private destroy$: Subject<void> = new Subject<void>();


  get reportTypeControl(): FormControl<ReportTypeSpecification> {
    return <FormControl<ReportTypeSpecification>>this.reportForm?.get('reportType');
  }

  get currentReportType(): ReportTypeSpecification {
    return this.reportTypeControl.value;
  }

  get filtersConfig(): ReportTypeFilterFieldConfigType {
    return this.currentReportType?.getFilterConfigs(this.horseId);
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private horseServices: HorseService,
    private matDialog: MatDialog
  ) { }

  grayOutPast() : Moment {
    if (this.currentReportType.value == 'EXPIRATIONS') {
      return moment(today);
    }
    return undefined;
  }

  ngOnInit() {
    this.initFilterForm();
    this.subscribeOnReportTypeControlValuesChanges();
    this.subscribeOnFilterFormGroupValuesChanges();
    this.populateFiltersFormGroupValuesFromQueryParams();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getHorses$(): Observable<Horse[]> {
    if (this.horseId) {
      return of([]);
    } else {
      return this.horseServices.all().pipe(
        map(response => response.records),
        catchError(() => ([])),
        shareReplay(1)
      );
    }
  }

  private initFilterForm(): void {
    const filtersControls = {};
    Object.values(ReportTypeFilterField).forEach(type => {
      filtersControls[type] = this.fb.control({ value: null, disabled: true }, Validators.required);
    });
    this.reportForm = this.fb.group<ReportForm>({
      reportType: this.fb.control(null, Validators.required),
      ...filtersControls
    });
  }

  private subscribeOnReportTypeControlValuesChanges(): void {
    this.reportTypeControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.removeReportFiltersControls();
      this.addReportFiltersControls();
    });
  }

  private subscribeOnFilterFormGroupValuesChanges(): void {
    const valueChanges = Object.values(ReportTypeFilterField).map(type => {
      return this.reportForm.get(type).valueChanges;
    });
    merge(...valueChanges).pipe(
      takeUntil(this.destroy$),
      filter(() => !!this.currentReportType),
      debounceTime(0),
      map(() => this.getFilterValues(this.horseId, this.reportForm.value))
    ).subscribe((value: ReportTypeFilterValues) => {
      this.setQueryParams(value);
      switch (this.currentReportType.value) {
        case ReportTypeSpecification.FeedReport.value: {
          this.filtersChanged.next({ ...value, valid: false });
          this.openReportFeedModal();
          break;
        }
        default: {
          this.filtersChanged.next({ ...value, valid: this.reportForm.valid });
          break;
        }
      }
    });
  }

  private populateFiltersFormGroupValuesFromQueryParams(): void {
    this.activatedRoute.queryParamMap.pipe(
      take(1),
      map(queryParamMap => {
        const reportType = this.reportTypes.find(reportType => reportType.name === queryParamMap.get('type'));
        let filters = {};
        reportType?.getFilterConfigs(this.horseId).forEach(config => {
          const value = config.getValuesFromQueryParams(queryParamMap);
          if (value) {
            filters = { ...filters, [config.type]: value };
          }
        });
        return { reportType, ...filters };
      })
    ).subscribe((values: ReportFormValues) => {
      if (values.reportType) {
        this.reportForm.patchValue(values);
      }
    });
  }

  private getFilterValues(horseId: number, formValues: DynamicKeyType): ReportTypeFilterValues {
    let values: ReportTypeFilterValues = { type: this.currentReportType };
    if (!!horseId) {
      values = { ...values, horseIds: [horseId] };
    }
    this.filtersConfig?.forEach(config => {
      if (formValues[config.type]) {
        values = { ...values, ...config.getValueFromFormValues(formValues) }
      }
    });

    return values;
  }

  private removeReportFiltersControls(): void {
    Object.values(ReportTypeFilterField).forEach(type => {
      this.reportForm.get(type).disable();
    });
  }

  private addReportFiltersControls(): void {
    this.filtersConfig?.forEach(config => {
      const control = this.reportForm.get(config.type);
      control.enable();
      if (config.value) {
        control.setValue(config.value);
      }
    });
  }

  private setQueryParams(filterValues: ReportTypeFilterValues): void {
    const queryParams = {};
    for (const key in filterValues) {
      const value = filterValues[key];
      if (!value || (Array.isArray(value) && value.length === 0)) {
        continue;
      }
      if (key === 'type') {
        queryParams[key] = (<ReportTypeSpecification>value).name;
        continue;
      }
      if (Array.isArray(value)) {
        queryParams[key] = value.join(',');
      } else {
        queryParams[key] = value;
      }
    }
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams, replaceUrl: true });
  }

  private openReportFeedModal(): void {
    this.horses$.pipe(switchMap(horses => {
      const dialogRef: MatDialogRef<FeedReportModalComponent> = this.matDialog.open(FeedReportModalComponent, {
        data: <FeedReportModalData>{
          title: `Download Feed Report`,
          feedType: true,
          horses: horses,
          isSingleHorse: !!this.horseId
        }
      });
      return dialogRef.afterClosed().pipe(take(1));
    })).subscribe((params: FeedReportParams) => {
      this.reportTypeControl.reset();
      this.reportForm.reset();
      this.setQueryParams(null);
      if (params && typeof params === 'object') {
        this.downloadFeedReport.next(params);
      }
    });
  }

  closeDatePicker(): void {
    setTimeout(() => this.pickerDirective?.hide(), 0);
  }

  openHorseAdvancedSelect(horses: Horse[], horsesControl: AbstractControl): void {
    const dialogRef: MatDialogRef<HorseAdvancedSelectComponent> = this.matDialog.open(HorseAdvancedSelectComponent, {
      data: <HorseAdvancedSelectModalData>{
        selected: horsesControl.value ? [...horsesControl.value] : [],
        horses: horses,
        selectedByUserCategories: this.selectedByUserCategories
      }
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(([selectedHorses, selectedByUserCategories]: [Horse[], HorseCategoryItem[]]) => {
      if (selectedHorses) {
        horsesControl.setValue(selectedHorses.map(horse => horse.id));
        horsesControl.markAsTouched();
        horsesControl.updateValueAndValidity();
      }
      if (selectedByUserCategories) {
        this.selectedByUserCategories = selectedByUserCategories
      }
    });
  }
}
