<form class="flex-area" [formGroup]="reportForm">
  <!-- Report type -->
  <div class="flex-area__percent-col">
    <label class="display-block" for="report-type-select">
      <span class="label__text">Report type</span>
      <shared-bm-ng-select id="report-type-select"
                           [sortable]="false"
                           [items]="reportTypes"
                           [placeholder]="'Select report type'"
                           [formControl]="reportTypeControl"
                           [multiple]="false"
                           [clearable]="false"
                           [virtualScroll]="false"
                           [dropdownPosition]="'bottom'">
      </shared-bm-ng-select>
    </label>
  </div>
  <ng-container *ngFor="let config of filtersConfig">
    <ng-container [ngSwitch]="config.type">
      <!-- Horses -->
      <div *ngSwitchCase="ReportTypeFilterField.Horse" class="flex-area__percent-col">
        <label class="display-block" for="horses-select">
          <span class="label__text">
            Horses
            <button *ngIf="horses$ | async as horses" type="button" class="bm-label-btn" title="Advanced Horse Select" (click)="openHorseAdvancedSelect(horses, reportForm.get(config.type))">
              <i class="fa fa-check"></i>Advanced select
            </button>
          </span>
          <shared-bm-ng-select id="horses-select"
                               [idsMode]="true"
                               [checkSelectedInItems]="true"
                               [items]="horses$ | async"
                               [placeholder]="'Select horse(s)'"
                               [formControlName]="config.type"
                               [bindValue]="'id'"
                               [bindLabel]="'name'"
                               [dropdownPosition]="'bottom'"
                               [ngMultiLabelTmp]="horsesMultiLabelTmp">
          </shared-bm-ng-select>
          <ng-template #horsesMultiLabelTmp let-items let-clear="clear">
            <ng-container *ngIf="(horses$ | async).length === items.length; else someHorsesSelected">
              <div class="ng-value">
                <span class="ng-value-icon left" (click)="reportForm.get(config.type).reset(); $event.preventDefault();" aria-hidden="true">×</span>
                <span class="ng-value-label">All Horses</span>
              </div>
            </ng-container>
            <ng-template #someHorsesSelected>
              <ng-container *ngTemplateOutlet="someItemsSelected; context: { $implicit: items, clear: clear }"></ng-container>
            </ng-template>
          </ng-template>
        </label>
      </div>
      <!-- Date range -->
      <div *ngSwitchCase="ReportTypeFilterField.DateRange" class="flex-area__percent-col">
        <label class="display-block invoice-period" for="date-range-select">
          <span class="label__text">Date range</span>
          <input
            class="text-input"
            type="text"
            id="date-range-select"
            ngxDaterangepickerMd
            readonly
            placeholder="Choose a date range"
            startKey="start"
            endKey="end"
            [minDate]="grayOutPast()"
            [formControlName]="config.type"
            (datesUpdated)="closeDatePicker()"
            [opens]="'center'"
            [showClearButton]="true"
            [autoApply]="true"
            [locale]="{format: 'MM/DD/YYYY'}"
            [ranges]="periodDateRanges"
            [showCustomRangeLabel]="true"
            [alwaysShowCalendars]="true"
            [showWeekNumbers]="true"
            [linkedCalendars]="false"/>
        </label>
      </div>
      <!-- Event type(s) -->
      <div *ngSwitchCase="ReportTypeFilterField.EventType" class="flex-area__percent-col">
        <label class="display-block" for="event-type-select">
          <span class="label__text">Event type(s)</span>
          <shared-bm-ng-select id="event-type-select"
                               [idsMode]="true"
                               [groupBy]="'type'"
                               [sortable]="false"
                               [items]="eventTypes"
                               [placeholder]="'Select event type(s)'"
                               [formControlName]="config.type"
                               [bindValue]="'value'"
                               [bindLabel]="'name'"
                               [dropdownPosition]="'bottom'"
                               [ngMultiLabelTmp]="eventTypesMultiLabelTmp"
                               [ngOptgroupTmp]="eventTypesOptgroupTmp">
          </shared-bm-ng-select>
          <ng-template #eventTypesMultiLabelTmp let-items let-clear="clear">
            <ng-container *ngIf="eventTypes.length === items.length; else someEventTypesSelected">
              <div class="ng-value">
                <span class="ng-value-icon left" (click)="reportForm.get(config.type).reset(); $event.preventDefault();" aria-hidden="true">×</span>
                <span class="ng-value-label">{{ EventTypes.AllEventTypes.name }}</span>
              </div>
            </ng-container>
            <ng-template #someEventTypesSelected>
              <ng-container *ngTemplateOutlet="someItemsSelected; context: { $implicit: items, clear: clear }"></ng-container>
            </ng-template>
          </ng-template>
          <ng-template #eventTypesOptgroupTmp let-item>
            <mat-checkbox color="primary" class="checkbox" [(ngModel)]="item.selected" [ngModelOptions]="{standalone: true}" (click)="$event.preventDefault()" [title]="EventTypes.AllEventTypes.name">
              <span>{{ EventTypes.AllEventTypes.name }}</span>
            </mat-checkbox>
          </ng-template>
        </label>
      </div>
      <!-- Record type(s) -->
      <div *ngSwitchCase="ReportTypeFilterField.RecordType" class="flex-area__percent-col">
        <label class="display-block" for="record-type-select">
          <span class="label__text">Record type(s)</span>
          <shared-bm-ng-select id="record-type-select"
                               [idsMode]="true"
                               [groupBy]="'type'"
                               [sortable]="false"
                               [items]="recordTypes"
                               [placeholder]="'Select record type(s)'"
                               [formControlName]="config.type"
                               [bindValue]="'value'"
                               [bindLabel]="'name'"
                               [dropdownPosition]="'bottom'"
                               [ngMultiLabelTmp]="recordTypesMultiLabelTmp"
                               [ngOptgroupTmp]="recordTypesOptgroupTmp">
          </shared-bm-ng-select>
          <ng-template #recordTypesMultiLabelTmp let-items let-clear="clear">
            <ng-container *ngIf="recordTypes.length === items.length; else someRecordTypesSelected">
              <div class="ng-value">
                <span class="ng-value-icon left" (click)="reportForm.get(config.type).reset(); $event.preventDefault();" aria-hidden="true">×</span>
                <span class="ng-value-label">{{ RecordTypes.AllRecordTypes.name }}</span>
              </div>
            </ng-container>
            <ng-template #someRecordTypesSelected>
              <ng-container *ngTemplateOutlet="someItemsSelected; context: { $implicit: items, clear: clear }"></ng-container>
            </ng-template>
          </ng-template>
          <ng-template #recordTypesOptgroupTmp let-item>
            <mat-checkbox color="primary" class="checkbox" [(ngModel)]="item.selected" [ngModelOptions]="{standalone: true}" (click)="$event.preventDefault()" [title]="RecordTypes.AllRecordTypes.name">
              <span>{{ RecordTypes.AllRecordTypes.name }}</span>
            </mat-checkbox>
          </ng-template>
        </label>
      </div>
    </ng-container>
  </ng-container>
</form>

<ng-template #someItemsSelected let-items let-clear="clear">
  <div class="ng-value" *ngFor="let item of items | slice:0:1">
    <span class="ng-value-icon left" (click)="clear(item); $event.preventDefault();" aria-hidden="true">×</span>
    <span class="ng-value-label">{{ item['name'] }}</span>
  </div>
  <div class="ng-value more" *ngIf="items.length > 1">
    <span class="ng-value-label">{{items.length - 1}} more</span>
  </div>
</ng-template>
