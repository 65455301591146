import {barnManagerModule} from '../../index.module';

barnManagerModule.controller('ReactivateController', ReactivateController);

ReactivateController.$inject = ['$state', '$rootScope', 'goNative', 'hasPermission'];
function ReactivateController($state, $rootScope, goNative, hasPermission) {
  const vm = this;
  $rootScope.$broadcast('$showLoader', false);
  vm.hasAdminFullPermission = hasPermission('admin:full');
  vm.isReactivating = $state.params.reactivating === 'true';
  vm.startReactivating = startReactivating;
  vm.cancelReactivating = cancelReactivating;
  vm.isGoNative = goNative.isGoNative();
  vm.reactivationUrl = `${window.location.origin}/n/reactivate`;

  function startReactivating() {
    vm.isReactivating = true;
  }

  function cancelReactivating() {
    vm.isReactivating = false;
  }
}
