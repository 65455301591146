import {barnManagerModule} from '../../index.module';

barnManagerModule.controller('HeaderHorseController', HeaderHorseController);

HeaderHorseController.$inject = ['$rootScope', 'horseCache', 'hasPermission'];
function HeaderHorseController($rootScope, horseCache, hasPermission) {
  const vm = this;

  vm.hasEventsReadPermission = hasPermission('events:read') || hasPermission('lessons:full');
  vm.hasConversationsReadPermission = hasPermission('conversations:read');
  vm.hasRecordsReadPermission = hasPermission('records:read');
  vm.hasListsReadPermission = hasPermission('lists:read');
  vm.hasFeedReadPermission = hasPermission('feed:read');
  vm.horse = null;

  init();

  function loadHorse() {
    const horse = horseCache.horse();
    if (horse) {
      $rootScope.pageConf.horseName = horse.name;
      vm.horse = horse;
    }
  }

  function init() {
    loadHorse();
  }
}
