import {barnManagerModule} from '../../index.module';

barnManagerModule
  .constant('States', {
    'USA': [
      { key: 'AL', value: 'Alabama' },
      { key: 'AK', value: 'Alaska' },
      { key: 'AZ', value: 'Arizona' },
      { key: 'AR', value: 'Arkansas' },
      { key: 'CA', value: 'California' },
      { key: 'CO', value: 'Colorado' },
      { key: 'CT', value: 'Connecticut' },
      { key: 'DE', value: 'Delaware' },
      { key: 'DC', value: 'District Of Columbia' },
      { key: 'FL', value: 'Florida' },
      { key: 'GA', value: 'Georgia' },
      { key: 'HI', value: 'Hawaii' },
      { key: 'ID', value: 'Idaho' },
      { key: 'IL', value: 'Illinois' },
      { key: 'IN', value: 'Indiana' },
      { key: 'IA', value: 'Iowa' },
      { key: 'KS', value: 'Kansas' },
      { key: 'KY', value: 'Kentucky' },
      { key: 'LA', value: 'Louisiana' },
      { key: 'ME', value: 'Maine' },
      { key: 'MD', value: 'Maryland' },
      { key: 'MA', value: 'Massachusetts' },
      { key: 'MI', value: 'Michigan' },
      { key: 'MN', value: 'Minnesota' },
      { key: 'MS', value: 'Mississippi' },
      { key: 'MO', value: 'Missouri' },
      { key: 'MT', value: 'Montana' },
      { key: 'NE', value: 'Nebraska' },
      { key: 'NV', value: 'Nevada' },
      { key: 'NH', value: 'New Hampshire' },
      { key: 'NJ', value: 'New Jersey' },
      { key: 'NM', value: 'New Mexico' },
      { key: 'NY', value: 'New York' },
      { key: 'NC', value: 'North Carolina' },
      { key: 'ND', value: 'North Dakota' },
      { key: 'OH', value: 'Ohio' },
      { key: 'OK', value: 'Oklahoma' },
      { key: 'OR', value: 'Oregon' },
      { key: 'PA', value: 'Pennsylvania' },
      { key: 'RI', value: 'Rhode Island' },
      { key: 'SC', value: 'South Carolina' },
      { key: 'SD', value: 'South Dakota' },
      { key: 'TN', value: 'Tennessee' },
      { key: 'TX', value: 'Texas' },
      { key: 'UT', value: 'Utah' },
      { key: 'VT', value: 'Vermont' },
      { key: 'VA', value: 'Virginia' },
      { key: 'WA', value: 'Washington' },
      { key: 'WV', value: 'West Virginia' },
      { key: 'WI', value: 'Wisconsin' },
      { key: 'WY', value: 'Wyoming' }
    ],
    'Canada': [
      { key: 'AB', value: 'Alberta' },
      { key: 'BC', value: 'British Columbia' },
      { key: 'MB', value: 'Manitoba' },
      { key: 'NB', value: 'New Brunswick' },
      { key: 'NL', value: 'Newfoundland and Labrador' },
      { key: 'NS', value: 'Nova Scotia' },
      { key: 'NT', value: 'Northwest Territories' },
      { key: 'NU', value: 'Nunavut' },
      { key: 'ON', value: 'Ontario' },
      { key: 'PE', value: 'Prince Edward Island' },
      { key: 'QC', value: 'Quebec' },
      { key: 'SK', value: 'Saskatchewan' },
      { key: 'YT', value: 'Yukon' }
    ],
    'Australia': [
      { key: 'ACT', value: 'Australian Capital Territory' },
      { key: 'NSW', value: 'New South Wales' },
      { key: 'NT',  value: 'Northern Territory' },
      { key: 'QLD', value: 'Queensland' },
      { key: 'SA',  value: 'South Australia' },
      { key: 'TAS', value: 'Tasmania' },
      { key: 'VIC', value: 'Victoria' },
      { key: 'WA',  value: 'Western Australia' }
    ],
    'Dominican Republic': [
      { key: 'Azua', value: 'Azua' },
      { key: 'Baoruco', value: 'Baoruco' },
      { key: 'Barahona', value: 'Barahona' },
      { key: 'Dajabón', value: 'Dajabón' },
      { key: 'Distrito Nacional', value: 'Distrito Nacional' },
      { key: 'Duarte', value: 'Duarte' },
      { key: 'Elías Piña', value: 'Elías Piña' },
      { key: 'El Seibo', value: 'El Seibo' },
      { key: 'Espaillat', value: 'Espaillat' },
      { key: 'Hato Mayor', value: 'Hato Mayor' },
      { key: 'Hermanas Mirabal', value: 'Hermanas Mirabal' },
      { key: 'Independencia', value: 'Independencia' },
      { key: 'La Altagracia', value: 'La Altagracia' },
      { key: 'La Romana', value: 'La Romana' },
      { key: 'La Vega', value: 'La Vega' },
      { key: 'María Trinidad Sánchez', value: 'María Trinidad Sánchez' },
      { key: 'Monseñor Nouel', value: 'Monseñor Nouel' },
      { key: 'Monte Cristi', value: 'Monte Cristi' },
      { key: 'Monte Plata', value: 'Monte Plata' },
      { key: 'Pedernales', value: 'Pedernales' },
      { key: 'Peravia', value: 'Peravia' },
      { key: 'Puerto Plata', value: 'Puerto Plata' },
      { key: 'Samaná', value: 'Samaná' },
      { key: 'San Cristóbal', value: 'San Cristóbal' },
      { key: 'San José de Ocoa', value: 'San José de Ocoa' },
      { key: 'San Juan', value: 'San Juan' },
      { key: 'San Pedro de Macorís', value: 'San Pedro de Macorís' },
      { key: 'Sánchez Ramírez', value: 'Sánchez Ramírez' },
      { key: 'Santiago', value: 'Santiago' },
      { key: 'Santiago Rodríguez', value: 'Santiago Rodríguez' },
      { key: 'Santo Domingo', value: 'Santo Domingo' },
      { key: 'Valverde', value: 'Valverde' }
    ],
    'New Zealand': [
      { key: 'Northland', value: 'Northland' },
      { key: 'Auckland', value: 'Auckland' },
      { key: 'Waikato', value: 'Waikato' },
      { key: 'Bay of Plenty', value: 'Bay of Plenty' },
      { key: 'Gisborne', value: 'Gisborne' },
      { key: 'Hawke\'s Bay', value: 'Hawke\'s Bay' },
      { key: 'Taranaki', value: 'Taranaki' },
      { key: 'Manawatū-Whanganui', value: 'Manawatū-Whanganui' },
      { key: 'Wellington', value: 'Wellington' },
      { key: 'Tasman', value: 'Tasman' },
      { key: 'Nelson', value: 'Nelson' },
      { key: 'Marlborough', value: 'Marlborough' },
      { key: 'West Coast', value: 'West Coast' },
      { key: 'Canterbury', value: 'Canterbury' },
      { key: 'Otago', value: 'Otago' },
      { key: 'Southland', value: 'Southland' }
    ],
    'South Africa': [
      { key: 'Eastern Cape', value: 'Eastern Cape' },
      { key: 'Free State', value: 'Free State' },
      { key: 'Gauteng', value: 'Gauteng' },
      { key: 'KwaZulu-Natal', value: 'KwaZulu-Natal' },
      { key: 'Limpopo', value: 'Limpopo' },
      { key: 'Mpumalanga', value: 'Mpumalanga' },
      { key: 'Northern Cape', value: 'Northern Cape' },
      { key: 'North West', value: 'North West' },
      { key: 'Western Cape', value: 'Western Cape' }
    ]
  });
