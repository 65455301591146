import {barnManagerModule} from '../../index.module';

barnManagerModule.controller('SearchResultsController', SearchResultsController);

SearchResultsController.$inject = [
  'responseHandler',
  '$stateParams',
  'backLinkHistory',
  'rConversations',
  'recordRepository',
  'listRepository',
  'horseRepository',
  'barnStorage',
  '$q',
  '$state',
  '$timeout',
  '$scope',
  '$location',
  'utils',
  'rEvents'
];
function SearchResultsController(
  responseHandler,
  $stateParams,
  backLinkHistory,
  rConversations,
  recordRepository,
  listRepository,
  horseRepository,
  barnStorage,
  $q,
  $state,
  $timeout,
  $scope,
  $location,
  utils,
  rEvents
) {
  let vm = this,
      barn = barnStorage.getEnv(),
      PAGE_SIZE = 100,
      timeout;
  backLinkHistory.pushLink('Search results');

  vm.models = {
    conversations: [],
    records: [],
    lists: [],
    events: [],
    horses: []
  };

  vm.status = {
    error: false,
    loaded: false,
    query: false,
    message: '',
    errorMessage: ''
  };

  vm.sortBy = [
    {
      name: 'All results',
      value: 'all'
    },
    {
      name: 'Conversations',
      value: 'conversations'
    },
    {
      name: 'Horses',
      value: 'horses'
    },
    {
      name: 'Lists',
      value: 'lists'
    },
    {
      name: 'Records',
      value: 'records'
    },
    {
      name: 'Events',
      value: 'events'
    }
  ];

  vm.filters = {
    keyword: $stateParams['term']
  };

  vm.routeUrl = routeUrl;

  init();

  function routeUrl(state, stateParams) {
    return $state.href(state, stateParams);
  }

  function searchConversations(payload) {
    return rConversations.query(payload).$promise.then(function(response) {
      vm.models.conversations = response.records;
      return response;
    });
  }

  function searchRecords(payload) {
    return recordRepository.search(payload).then(function(response) {
      vm.models.records = response.records;
      return response;
    });
  }

  function searchLists(payload) {
    return listRepository.search(payload).then(function(response) {
      vm.models.lists = response.records;
      return response;
    });
  }

  function searchHorses(payload) {
    return horseRepository.search(payload).then(function(response) {
      vm.models.horses = utils.preprocessHorses(response.records);
      return response;
    });
  }

  function searchEvents(payload) {
    // delete payload.pagesize;
    return rEvents.query(payload).$promise.then(function(response) {
      vm.models.events = response;
      return response;
    });
  }

  function hideLoader() {
    if (!vm.status.loaded) {
      vm.status.loaded = true;
    }
  }

  function init() {
    let timeoutPromise,
      UPDATE_DELAY = 600;

    $scope.$watch('vm.filters.keyword', function() {

      $state.current.reloadOnSearch = false;
      $timeout.cancel(timeoutPromise);

      timeoutPromise = $timeout(function() {
        search({
          tenantEnvironmentId: barn.id,
          pagesize: PAGE_SIZE,
          text: vm.filters.keyword
        });
      }, UPDATE_DELAY);
    }, true);
  }

  function search(payload) {
    vm.status.loaded = false;
    timer();

    $location.search('term', payload.text);
    backLinkHistory.pushLink('Search results');

    return $q.all([
      searchConversations(payload),
      searchRecords(payload),
      searchLists(payload),
      searchHorses(payload),
      searchEvents(payload)
    ]).catch(responseHandler.processError).finally(hideLoader);
  }

  function timer() {
    const REQUEST_DELAY = 4000;

    vm.status.message = '';
    $timeout.cancel(timeout);
    timeout = $timeout(function() {
      vm.status.message = 'Oops! It is taking more time than expected.';
    }, REQUEST_DELAY);
  }

}
