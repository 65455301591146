export interface FeedReportModalData {
  title: string;
  feedType: boolean;
  isSingleHorse: boolean;
  horses: any[];
}

export enum FeedFileType {
  PDF_BE = 'PDF_BE',
  CSV = 'CSV'
}

export enum FeedPrintOrientation {
  PORTRAIT = 'portrait',
  LANDSCAPE = 'landscape'
}

export interface FeedReportParams {
  fileType: FeedFileType,
  selectedEntryTypes: Array<any>;
  fontSize: number;
  orientation: FeedPrintOrientation;
  selectedHorses?: Array<any>;
  allHorsesAccess?: boolean;
  horsePerPage?: boolean;
}
