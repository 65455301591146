import {barnManagerModule} from '../../../index.module';
import {cloneDeep} from 'lodash';

barnManagerModule.controller('ConversationFormController', ConversationFormController);

ConversationFormController.$inject = [
  'backLinkHistory',
  'responseHandler',
  'userStorage',
  'barnStorage',
  'rUserConversations',
  '$stateParams',
  '$rootScope',
  '$scope',
  '$state',
  '$log',
  'rAttachments',
  'listsService',
  'rUser',
  'NOTE_LENGTH_LIMIT',
  'titleService'
];
function ConversationFormController(
  backLinkHistory,
  responseHandler,
  userStorage,
  barnStorage,
  rUserConversations,
  $stateParams,
  $rootScope,
  $scope,
  $state,
  $log,
  rAttachments,
  listsService,
  rUser,
  NOTE_LENGTH_LIMIT,
  titleService
) {
  let vm = this,
    barn,
    user,
    removedFiles = [];

  titleService.setTitle('Conversation Form');
  $rootScope.pageConf.class = 'page--interior';

  vm.currentUser = userStorage.getUser();
  vm.conversationTypes = [
    { name: 'Dentist', value: 'DENTIST' },
    { name: 'Farrier', value: 'FARRIER' },
    { name: 'Vet', value: 'VET' }
  ];
  vm.horseId = $state.params.horseId;

  vm.noteLengthLimit = NOTE_LENGTH_LIMIT;
  vm.error = false;
  vm.updating = false;
  vm.submitAttempt = false;
  vm.attachmentsReady = false;
  vm.editMode =
    $state.current.name === 'conversationEdit' ||
    $state.current.name === 'conversationHorseEdit';

  vm.queueDelete = queueDelete;
  vm.save = save;
  vm.onHorsesChange = onHorsesChange;
  vm.onTagsChange = onTagsChange;

  initializeConversation(vm.editMode);

  function onHorsesChange(horseIds) {
    vm.conversation.horseIds = horseIds;
  }

  function onTagsChange(tags) {
    vm.conversation.tags = tags;
  }

  function loadAllUsers() {
    rUser.search({
      tenantEnvironmentId: barn.id,
      archived: false,
      pagesize: 999
    }).$promise.then(function(response) {
      vm.allUsers = response.records;
    });
  }

  function updateRelatedData(conversation, attachments) {
    listsService.updateAttachments(attachments.new, attachments.removed, conversation);
    listsService.updateMembers(conversation);
  }

  function save(form) {
    let params;
    if (!form.$valid) {
      return;
    }

    vm.error = false;
    vm.updating = true;
    vm.submitAttempt = true;
    const clonedConversation = cloneDeep(vm.conversation);

    if (vm.editMode) {

      updateRelatedData(clonedConversation, {
        new: vm.attachments,
        removed: removedFiles
      });

      params = {
        userId: vm.conversation.authorId,
        conversationId: vm.conversation.id
      };
      rUserConversations.update(params, vm.conversation).$promise.then(goToConversation).catch(errorHandler);
    } else {
      params = { userId: vm.currentUser.id };
      rUserConversations.save(params, vm.conversation).$promise.then(function(conversation) {
        clonedConversation.id = conversation.id;
        updateRelatedData(clonedConversation, {
          new: vm.attachments,
          removed: removedFiles
        });
      }).then(goToConversation).catch(errorHandler);
    }
  }

  function errorHandler(error) {
    vm.updating = false;
    vm.error = true;
    return responseHandler.processError(error);
  }

  function queueDelete(file) {
    removedFiles.push(file);
  }

  function goToConversation() {
    return listsService.ready(function() {}).then(function() {
      vm.updating = false;
      responseHandler.successOnSave('Conversation', vm.editMode);
      backLinkHistory.goBack();
    });
  }

  function watch() {
    $scope.$watch('vm.rType', function(newVal) {
      if (typeof newVal !== 'undefined') {
        vm.conversation.vendorType = newVal.value;
      }
    });
  }

  function initializeConversation(editMode) {
    user = userStorage.getUser();
    barn = barnStorage.getEnv();

    let currentConversationId = $stateParams.id;

    vm.conversationMembers = [];

    vm.conversation = {};
    vm.conversation.id = 0;
    vm.conversation.tenantEnvironmentId = barn.id;
    vm.conversation.authorId = user.id;
    vm.conversation.pinned = 0;
    vm.conversation.archived = 0;
    vm.conversation.read = 0; // should be .read
    vm.conversation.message = '';
    vm.conversation.allHorsesAccess = 0;
    vm.conversation.allTeamMembersNotifications = false;
    vm.conversation.tags = [];

    if ($state.current.name === 'conversationHorseEdit') {
      currentConversationId = $stateParams.conversationId;
    }

    watch();
    listsService.resetTo('conversations', 'CONVERSATION');

    if (editMode) {
      vm.loading = true;
      rUserConversations.get(
        {
          conversationId: currentConversationId,
          userId: $stateParams.author
        },
        function(conversation) {
          vm.loading = false;
          backLinkHistory.pushLink(conversation.title);
          vm.conversation = conversation;
          vm.conversation.read = 1;
          vm.rType = vm.conversationTypes.filter(function(obj) {
            return obj.value == conversation.vendorType; // set default value
          })[0];
          listsService.getUsers(vm.conversation, function(allUsers, members) {
            vm.conversationMembers = members;
          });
          rAttachments.query(
            { modelType: 'Conversation', modelId: vm.conversation.id },
            function(attachments) {
              vm.attachments = attachments;
              vm.attachmentsReady = true;
            },
            function() {
              vm.attachmentsReady = true;
            }
          );
        },
        function (error) {
          vm.loading = false;
          vm.error = error?.data?.message || error?.statusText;
        }
      );
    } else {
      backLinkHistory.pushLink('New conversation');
      vm.attachmentsReady = true;
      if (vm.horseId) {
        vm.conversation.horseIds = [Number(vm.horseId)];
      }
    }

    loadAllUsers();
  }

}
