import {barnManagerModule} from '../../index.module';
import * as angular from 'angular';

barnManagerModule
  .component('bmNotificationsSidebar', {
    templateUrl: 'notifications-sidebar.html',
    controller: NotificationsSidebarController,
    controllerAs: 'vm'
  });

NotificationsSidebarController.$inject = ['$rootScope', 'SidebarJS', 'notificationRepository', 'responseHandler', 'MessageFrames'];
function NotificationsSidebarController($rootScope, SidebarJS, notificationRepository, responseHandler, MessageFrames) {
  const vm = this;

  vm.sidebarName = 'notifications-sidebar';
  vm.sidebarConfig = {
    nativeSwipe: false,
    nativeSwipeOpen: false,
    position: 'right'
  };
  vm.scrollBarWidth = getScrollBarWidth();

  vm.markAllAsRead = markAllAsRead;
  vm.onSideBarClose = onSideBarClose;

  init();

  function init() {
    const stateChangeSuccess = $rootScope.$on('$stateChangeSuccess', function() {
      if (SidebarJS.isVisible(vm.sidebarName)) {
        SidebarJS.close(vm.sidebarName);
      }
    });
    $rootScope.$on('$destroy', stateChangeSuccess);
    const notificationsSidebarToggle = $rootScope.$on('notificationsSidebarToggle', function(event, sidebarVisible) {
      SidebarJS.toggle(vm.sidebarName);
      vm.scrollBarWidth = getScrollBarWidth();
      if (sidebarVisible) {
        setDocumentStyles(sidebarVisible);
        setIntercomLauncherStyles(sidebarVisible);
      }
      setSidebarStyles();
    });
    $rootScope.$on('$destroy', notificationsSidebarToggle);

    const unreadNotificationsChanged = $rootScope.$on('unreadNotificationsChanged', function(event, unreadNotifications) {
      vm.unreadNotifications = unreadNotifications;
    });
    $rootScope.$on('$destroy', unreadNotificationsChanged);

    setIntercomLauncherStyles(false);
    setSidebarStyles();
    setHeaderStyles();
  }

  function setHeaderStyles() {
    const bannerElement = document.getElementById('app_banner');
    const bannerContentElement = document.getElementById('app_banner_content');
    const appNavElement = document.getElementById('app_nav');
    if (bannerElement && bannerContentElement && appNavElement && vm.scrollBarWidth && vm.scrollBarWidth > 0) {
      bannerElement.style.setProperty('width', `calc(100% + ${vm.scrollBarWidth}px)`);
      bannerContentElement.style.setProperty('right', `${vm.scrollBarWidth}px`);
      appNavElement.style.setProperty('margin-left', `${vm.scrollBarWidth}px`);
    }
  }

  function setSidebarStyles() {
    const sidebarElement = document.getElementById('notifications-sidebar');
    const appNavElement = document.getElementById('app_nav');
    if (sidebarElement && appNavElement) {
      const rect = appNavElement.getBoundingClientRect();
      const value = rect.bottom >= 0 ? rect.bottom : 0;
      sidebarElement.style.top = `${value}px`;
      sidebarElement.style.setProperty('height', `calc(100% - ${value}px)`);
    }
  }

  function setDocumentStyles(sidebarVisible: boolean) {
    const docEl = document.documentElement;
    if (sidebarVisible) {
      docEl.classList.add('non-scrollable');
      docEl.classList.remove('scrollable');
      if (vm.scrollBarWidth && vm.scrollBarWidth > 0) {
        docEl.style.setProperty('margin-right', `${vm.scrollBarWidth}px`);
      }
    } else {
      docEl.classList.add('scrollable');
      docEl.classList.remove('non-scrollable');
      docEl.style.setProperty('margin-right', '0');
    }
  }

  function setIntercomLauncherStyles(sidebarVisible: boolean) {
    if (!vm.scrollBarWidth || vm.scrollBarWidth === 0) {
      return;
    }
    const intercomLauncherElement = document.getElementsByClassName('intercom-lightweight-app-launcher intercom-launcher')
      ||  document.getElementsByClassName('intercom-dfosxs');
    if (intercomLauncherElement?.length > 0) {
      const value = sidebarVisible ? 20 + vm.scrollBarWidth : 20;
      (<HTMLElement>intercomLauncherElement.item(0))?.style.setProperty('right', `${value}px`);
    }
  }

  function onSideBarClose() {
    setDocumentStyles(false);
    setIntercomLauncherStyles(false);
  }

  function getScrollBarWidth() {
    let el = document.createElement('div');
    el.style.cssText = 'overflow:scroll; visibility:hidden; position:absolute;';
    document.body.appendChild(el);
    let width = el.offsetWidth - el.clientWidth;
    el.remove();

    return width;
  }

  function markAllAsRead() {
    notificationRepository.readAll().then(() => {
    }).catch(error => {
      responseHandler.processError(error, null, MessageFrames.TOP);
    });
  }
}
