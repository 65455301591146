import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from '@environment';
import {setAngularJSGlobal} from '@angular/upgrade/static';
import * as angular from 'angular';

function hideMainLoader(): void {
  const mainLoaderElement = document.getElementById('main-loading-icon');
  if (mainLoaderElement) {
    mainLoaderElement.style.setProperty('display', 'none');
  }
}


if (environment.production) {
  window.console.log = function() {};
  enableProdMode();
}

setAngularJSGlobal(angular);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => hideMainLoader())
  .catch(err => console.error(err));
