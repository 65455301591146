import {barnManagerModule} from '../index.module';

barnManagerModule
  .factory('titlesService', TitlesService);

function TitlesService() {
  return {
    getVendorLabel: getVendorLabel,
    getInitialsLabel: getInitialsLabel
  };

  function getVendorLabel(vendor) {
    return vendor.firstName + ' ' + vendor.lastName;
  }

  function getInitialsLabel(name) {
    if (!name) {
      return '';
    }

    name = name.trim();
    return (name.split(' ')[0][0] + name.split(' ')[1][0]).toUpperCase();
  }
}
