import {barnManagerModule} from '../../index.module';
import * as angular from 'angular';

barnManagerModule.directive('bmWhiteBoardPost', whiteBoardPost);

function whiteBoardPost() {

  WhiteBoardPostController.$inject = [
    'rUser',
    'userStorage',
    'rAttachments',
    '$scope',
    'utils',
    '$window',
    'dateTimeUpgraded',
    'hasPermission'
  ];

  return {
    restricted: 'E',
    templateUrl: 'white-board-post.html',
    controller: WhiteBoardPostController,
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      item: '=',
      editAction: '=',
      removeAction: '=',
      scrollTo: '='
    },
    link: function (scope, element, _attrs) {
      // hide "More" button if content is fully visible
      scope.$watch('item', function (_) {
        const trimmedContent = element[0].getElementsByClassName('trimmed-content')[0];
        if (trimmedContent.scrollHeight <= trimmedContent.clientHeight) {
          const toggleBtn = element[0].getElementsByClassName('white-board-posts__toggle-btn')[0];
          toggleBtn.classList.add('hidden');
        }
      });
    }
  };

  function WhiteBoardPostController(
    rUser,
    userStorage,
    rAttachments,
    $scope,
    utils,
    $window,
    dateTimeUpgraded,
    hasPermission
  ) {
    const vm = this;
    vm.$onInit = function() {
      vm.hasWhiteboardFullPermission = hasPermission('whiteboard:full');
      vm.showContentClass = 'trimmed-content';
      vm.contentTrimmed = true;
      vm.currentUser = {};
      vm.user = {};
      vm.serverTimeStampToLocalDateTimeFormatted = dateTimeUpgraded.serverTimeStampToLocalDateTimeFormatted;

      vm.edit = edit;
      vm.toggleContent = toggleContent;
      vm.remove = remove;
      vm.timezone = utils.timezone;

      init();
      reloadOnPush();

      function reloadOnPush() {
        $scope.$on('whiteboard:push', function() {
          init();
        });
      }

        function remove(id) {
        vm.removeAction(id);
      }

      function edit(id) {
        vm.editAction(id);
        const pos = (<any>angular.element(vm.scrollTo)).position().top + (<any>angular.element(vm.scrollTo)).parent().scrollTop();
        $window.scrollTo(0, pos);
      }

      function init() {
        vm.currentUser = userStorage.getUser();
      }

      function toggleContent() {
        if (vm.contentTrimmed) {
          vm.showContentClass = '';
          vm.contentTrimmed = false;
        } else {
          vm.showContentClass = 'trimmed-content';
          vm.contentTrimmed = true;
        }
      }

    };
  }
}
