import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatRippleModule} from '@angular/material/core';

declare type MatBtnType = 'mat-raised-button' | 'mat-stroked-button' | 'mat-flat-button' | 'mat-icon-button' | 'mat-fab' | 'mat-mini-fab';

@Component({
  selector: 'shared-button-loader',
  templateUrl: './button-loader.component.html',
  styleUrls: ['./button-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatRippleModule],
  encapsulation: ViewEncapsulation.None
})
export class ButtonLoaderComponent {
  @HostBinding('class') cssClass: string = 'shared-button-loader';
  @Input() loading: boolean = false;
  @Input() btnClass: string;
  @Input() matBtnType: MatBtnType = 'mat-flat-button';
  @Input() color: any = 'primary';
  @Input() type: string = 'button';
  @Input() disabled: boolean = false;
  @Output() btnClick: EventEmitter<any> = new EventEmitter<any>();

  get buttonClass(): string {
    return `${this.btnClass} ${this.matBtnType}`;
  }

  handleClick(event: any) {
    event.preventDefault();
    event.stopPropagation();
    if (this.loading) {
      return;
    }
    this.btnClick.emit();
  }
}
