import {barnManagerModule} from '../../../index.module';
import {fromJson} from 'angular';
import {filter, includes, unescape} from 'lodash';

barnManagerModule.controller('ListDetailsController', ListDetailsController);

ListDetailsController.$inject = [
  'backLinkHistory',
  '$rootScope',
  '$state',
  '$stateParams',
  'responseHandler',
  'listsService',
  'listRepository',
  'rAttachments',
  'titleService',
  'hasPermission',
  'rUser',
  'barnStorage'
]
function ListDetailsController(
  backLinkHistory,
  $rootScope,
  $state,
  $stateParams,
  responseHandler,
  listsService,
  listRepository,
  rAttachments,
  titleService,
  hasPermission,
  rUser,
  barnStorage
) {
  const vm = this;

  vm.hasListsFullPermission = hasPermission('lists:full');

  vm.list = {};
  vm.attachments = [];
  vm.listMembers = [];

  vm.listId = -1;
  vm.curHorseId = -1;

  vm.error = null;

  vm.listTypes = [
    { name: 'Dentist', value: 'dentist' },
    { name: 'Farrier', value: 'farrier' },
    { name: 'Vet', value: 'vet' }
  ];

  vm.togglePin = togglePin;
  vm.toggleCheckbox = toggleCheckbox;
  vm.onSuccessArchive = onSuccessArchive;
  vm.onSuccessRemove = onSuccessRemove;

  init();

  function togglePin() {
    const method = vm.list.pinned ? listRepository.unpin : listRepository.pin;
    method(vm.list.id).then(function() {
      vm.list.pinned = !vm.list.pinned;
    }).catch(responseHandler.processError);
  }

  function toggleCheckbox(item) {
    listRepository.updateCheckItemState(vm.listId, item).catch(responseHandler.processError);
  }

  function onSuccessArchive() {
    if (vm.curHorseId > 0) {
      $state.go('listsHorse', {id: vm.curHorseId});
    } else {
      $state.go('lists');
    }
  }

  function onSuccessRemove() {
    backLinkHistory.goBack();
  }

  function init() {
    titleService.setTitle('List Details');
    $rootScope.pageConf.class = 'page--interior';

    let currentStateName = $state.current.name;

    if (currentStateName === 'listDetails' && (isNaN($stateParams['id']) || !$stateParams['id'])) {
      $state.go('lists');
      return;
    }

    if (currentStateName === 'listHorseDetails' && (isNaN($stateParams['listId']) || !$stateParams['listId'])) {
      $state.go('listsHorse');
      return;
    }

    vm.listId = currentStateName === 'listDetails' ? $stateParams.id : $stateParams.listId;

    if (currentStateName === 'listHorseDetails') {
      vm.curHorseId = $stateParams.id;
    }

    listsService.resetTo('lists', null);
    vm.loading = true;

    listRepository.get(vm.listId).then(function(response) {
      vm.loading = false;
      backLinkHistory.pushLink(response.title + '\'s details');
      saveList(response);
    }).catch(function(error) {
      vm.loading = false;
      return responseHandler.handleNotFoundOrAccess(error, 'list', function(errorMessage) {
        vm.error = errorMessage;
      });
    }).catch(responseHandler.processError);
  }

  function saveList(list) {
    vm.list = list;

    titleService.setTitle(list.title + ' :: Lists');

    if (!vm.list.read) {
      listRepository.markAsRead(vm.list.id).then(function() {
        vm.list.read = true;
      }).catch(responseHandler.processError);
    }

    if (list.items?.length > 0) {
      vm.list.items = vm.list.items.sort((a, b) => a.pos - b.pos);
      if (vm.list.table) {
        delete vm.list.table;
      }
    } else if (vm.list.table) {
      vm.list.table = fromJson(unescape(list.table.replace(/&quot;/g, '\\\"')));// eslint-disable-line
    }

    rAttachments.query({ modelType: 'LIST_SIMPLE', modelId: list.id }).$promise.then(function(attachments) {
      vm.attachments = attachments;
    });

    loadAllUsers().then(function(allUsers) {
      vm.allUsers = allUsers;
      vm.listMembers = filter(allUsers, function(user) {
        return includes(vm.list.memberIds, user.id);
      });
    });

    if (typeof vm.list.comments == 'undefined') {
      vm.list.comments = [];
    }
  }

  function loadAllUsers() {
    const barn = barnStorage.getEnv();
    return rUser.search({ tenantEnvironmentId: barn.id, archived: false, pagesize: 999 }).$promise.then(response => response.records);
  }
}
