import {barnManagerModule} from '../index.module';

barnManagerModule
  .factory('rHorseOwners', ['$resource', ($resource) => {
    return $resource('/api/horse-owners', {}, {
      'get': {
        method: 'GET',
        url: '/api/horse-owners',
        params: {}
      }
    });
  }]);
