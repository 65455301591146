import {barnManagerModule} from '../../../index.module';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';
import {merge} from 'angular';
import {isEqual} from 'lodash';

barnManagerModule.controller('EventsController', EventsController);

EventsController.$inject = [
  '$log',
  '$scope',
  '$state',
  '$timeout',
  'backLinkHistory',
  'rEvents',
  'barnStorage',
  'eventsService',
  'titleService',
  'hasPermission',
  'selectDialog'
];
function EventsController(
  $log,
  $scope,
  $state,
  $timeout,
  backLinkHistory,
  rEvents,
  barnStorage,
  eventsService,
  titleService,
  hasPermission,
  selectDialog
) {
  let vm = this, barn, timeout;
  if ($state.params.id) {
    backLinkHistory.pushHorseLink('events', $state.params.id);
  } else {
    backLinkHistory.pushLink('Events');
  }

  vm.hasEventsFullPermission = hasPermission('events:full') || hasPermission('lessons:full');
  vm.navigateToEventInstanceDetails = navigateToEventInstanceDetails;
  vm.update = update;
  vm.openCalendar = openCalendar;
  vm.onSuccessRemove = onSuccessRemove;

  vm.events = [];
  vm.horseId = $state.params.id;
  vm.zoneCode = undefined;
  vm.startDateCalendarOpen = false;
  vm.endDateCalendarOpen = false;

  vm.status = {
    empty: false,
    error: false,
    loaded: false,
    busy: false,
    query: false,
    message: '',
    errorMessage: ''
  };

  vm.orderBy = [
    {name: 'Newest',  value: {'reverse' : true} },
    {name: 'Oldest',  value: {'reverse' : false} }
  ];

  vm.order = vm.orderBy[0];

  vm.sortBy = [
    { name: 'All Events', value: {} },
    { name: 'Vet Appointment', section: 'Event Type', value: { type: 'VET_APPOINTMENT' } },
    { name: 'Dentist Appointment', section: 'Event Type', value: { type: 'DENTIST_APPOINTMENT' } },
    { name: 'Farrier Appointment', section: 'Event Type', value: { type: 'FARRIER_APPOINTMENT' } },
    { name: 'Vaccination', section: 'Event Type', value: { type: 'VACCINATION' } },
    { name: 'Deworming ', section: 'Event Type', value: { type: 'DEWORMING' } },
    { name: 'Medication', section: 'Event Type', value: { type: 'MEDICATION' } },
    { name: 'Show', section: 'Event Type', value: { type: 'SHOW' } },
    { name: 'Lesson', section: 'Event Type', value: { type: 'LESSON' } },
    { name: 'Other', section: 'Event Type', value: { type: 'OTHER' } }
  ];

  vm.filters = {
    by: vm.sortBy[0],
    startDate: moment().toDate(),
    endDate: moment().add(2, 'week').toDate()
  };
  vm.sortByLastUpdated = false;

  init();

  function update(event) {
    event.pinned = !event.pinned;
    eventsService.togglePin(event);
  }

  function navigateToEventInstanceDetails(event) {
    const stateParams: any = {
      id: vm.horseId,
      eventId: event.id,
      author: event.authorId
    };

    if (event.repeat) {
      stateParams.instance = event.dateDate;
    }

    if ($state.current.name === 'eventsHorse') {
      return $state.href('eventHorseDetails', stateParams);
    }
    return $state.href('eventDetails', stateParams);
  }

  function init() {
    titleService.setTitle('Events');
    timer();
    barn = barnStorage.getEnv();

    // Applying Timezone code
    vm.zoneCode = moment.tz(barn.timeZone).zoneAbbr();

    const queryParams = getQueryParams();

    rEvents.query(queryParams, function(response) {
      vm.events = response;
      vm.status.loaded = true;
      vm.status.empty = !response.length;
      watchFilters();
    }, function(error) {
      $timeout.cancel(timeout);
      vm.status.loaded = true;
      vm.status.error = true;
      vm.status.empty = true;
      vm.status.errorMessage = 'Can\'t load events. Error: ' + error.status + ' ' + error.statusText;
    });
  }

  function watchFilters() {
    let timeoutPromise, delay = 600;
    $scope.$watch('vm.filters', function(newFilters, oldFilters) {
      if (!isEqual(newFilters, oldFilters) && $scope.datepickerForm.$valid) {
        if (newFilters.keyword !== (oldFilters || {}).keyword) {
          $timeout.cancel(timeoutPromise);
          timeoutPromise = $timeout(search, delay);
        } else {
          search();
        }
      }
    }, true);
    $scope.$watch('vm.sortByLastUpdated', function(newValue, oldValue) {
      if (newValue !== oldValue) {
        search();
      }
    }, true);
  }

  function search() {
    timer();
    vm.status.busy = true;
    const queryParams = getQueryParams();

    rEvents.query(merge({}, queryParams, vm.filters.by.value), function(response) {
      vm.status.busy = false;
      vm.events = response;
      vm.status.empty = !response.length;
    }, function(error) {
      $log.error(error);
      vm.status.error = true;
      vm.status.errorMessage = 'Can\'t load events. Error: ' + error.status + ' ' + error.statusText;
    });
  }

  function getQueryParams() {
    const startDate = moment(vm.filters.startDate).startOf('day').format('YYYY-MM-DDTHH:mm');
    const endDate = moment(vm.filters.endDate).endOf('day').format('YYYY-MM-DDTHH:mm');
    const queryParams: any = {
      horseId: $state.params.id,
      allHorses: true,
      sort: vm.sortByLastUpdated ? 'updated' : 'startsAt',
      direction: 'DESC',
      tenantEnvironmentId: barn.id,
      startDate,
      endDate
    };

    if (vm.filters.keyword && vm.filters.keyword.length > 0) {
      queryParams.text = vm.filters.keyword;
    }

    return queryParams;
  }

  function timer() {
    vm.status.message = '';
    $timeout.cancel(timeout);
    timeout = $timeout(function() {
      vm.status.message = 'Oops! It is taking more time than expected.';
    }, 4000);
  }

  function openCalendar(calendar) {
    if (calendar === 'startDate') {
      vm.startDateCalendarOpen = true;
    }
    if (calendar === 'endDate') {
      vm.endDateCalendarOpen = true;
    }
  }

  function onSuccessRemove() {
    search();
  }
}
