import {NgModule} from '@angular/core';
import {ConfirmDialogNg2Directive} from './confirm-dialog-ng2.directive';
import {HeaderNg2Directive} from './header-ng2.directive';
import {FooterNg2Directive} from './footer-ng2.directive';
import {MessageFrameNg2Directive} from './message-frame-ng2.directive';
import {NotificationsSidebarNg2Directive} from './notifications-sidebar-ng2.directive';
import {SearchBarNg2Directive} from './search-bar-ng2.directive';
import {TestApiEndpointNotificationNg2Directive} from './test-api-endpoint-notification-ng2.directive';
import {ClearCacheNg2Directive} from './clear-cache-ng2.directive';

@NgModule({
  declarations: [
    ConfirmDialogNg2Directive,
    HeaderNg2Directive,
    FooterNg2Directive,
    MessageFrameNg2Directive,
    NotificationsSidebarNg2Directive,
    SearchBarNg2Directive,
    TestApiEndpointNotificationNg2Directive,
    ClearCacheNg2Directive
  ],
  exports: [
    ConfirmDialogNg2Directive,
    HeaderNg2Directive,
    FooterNg2Directive,
    MessageFrameNg2Directive,
    NotificationsSidebarNg2Directive,
    SearchBarNg2Directive,
    TestApiEndpointNotificationNg2Directive,
    ClearCacheNg2Directive
  ]
})
export class Ng1UpgradesModule {
}
