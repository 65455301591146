import {ChangeDetectionStrategy, Component, HostBinding, Inject, ViewEncapsulation} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {ClickOutsideDirective} from '@shared-ui/click-outside/click-outside.directive';
import {SharedPipesModule} from '@shared/lib/pipes/shared-pipes.module';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {BehaviorSubject} from 'rxjs';

interface ImagePreviewModalData {
  image: BehaviorSubject<string>;
  name: string;
}

@Component({
  selector: 'bm-image-preview-modal',
  templateUrl: './image-preview-modal.component.html',
  styleUrls: ['./image-preview-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    NgOptimizedImage,
    ClickOutsideDirective,
    SharedPipesModule,
    MatButtonModule,
    MatIconModule
  ]
})
export class ImagePreviewModalComponent {
  @HostBinding('class') cssClass = 'bm-image-preview-modal';
  loaded: boolean = false;

  get image$(): BehaviorSubject<string> {
    return this.data.image;
  }

  get name(): string {
    return this.data.name;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ImagePreviewModalData,
    private matDialogRef: MatDialogRef<ImagePreviewModalComponent>
  ) { }

  close(): void {
    this.matDialogRef.close();
  };
}
