import {barnManagerModule} from '../../../index.module';

barnManagerModule
  .component('bmNotificationTypes', {
    templateUrl: 'notifications-types.html',
    controller: NotificationTypes,
    controllerAs: 'vm',
    bindings: {
      notification: '='
    }
  });

NotificationTypes.$inject = ['$rootScope', 'NotificationTypes', 'hasPermission'];
function NotificationTypes($rootScope, NotificationTypes, hasPermission) {
  const vm = this;

  vm.notificationTypes = NotificationTypes;

  const onUserAuthenticated = $rootScope.$on('userAuthenticated', function() {
    vm.hasVendorsFullPermission = hasPermission('vendors:full');
    vm.hasVendorsReadPermission = hasPermission('vendors:read');
    vm.hasAdminFullPermission = hasPermission('admin:full');
  });
  $rootScope.$on('$destroy', onUserAuthenticated);
}
