import {barnManagerModule} from '../../index.module';

barnManagerModule
  .constant('TerritorialUnits', {
    'USA': 'State',
    'Canada': 'State',
    'Australia': 'State',
    'Dominican Republic': 'State',
    'New Zealand': 'State',
    'South Africa': 'Province'
  });
