import {barnManagerModule} from '../index.module';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';
import * as angular from 'angular';
import {at, get, includes, isArray, some, sortBy, xorBy} from 'lodash';

barnManagerModule.factory('utils', utils);

utils.$inject = [
  '$log',
  '$timeout',
  '$state',
  '$q',
  '$rootScope',
  '$window',
  'SERVER_HOURS_DIFF',
  'DATE_TIME_FORMAT',
  'OWNER_ROLE_ID',
  'barnStorage',
  '$httpParamSerializer'
];
function utils(
  $log,
  $timeout,
  $state,
  $q,
  $rootScope,
  $window,
  SERVER_HOURS_DIFF,
  DATE_TIME_FORMAT,
  OWNER_ROLE_ID,
  barnStorage,
  $httpParamSerializer
) {
  let timeout, previousState = false;

  return {
    arraysHaveDifference: arraysHaveDifference,
    sortByCaseInsensitive: sortByCaseInsensitive,
    localizedDateTime: localizedDateTime,
    addHours: addHours,
    setSearchPreviousState: setSearchPreviousState,
    getSearchPreviousState: getSearchPreviousState,
    timezone: timezone,
    timer: timer,
    capitalizeString: capitalizeString,
    toUnderlineUpperCase: toUnderlineUpperCase,
    preprocessHorses: preprocessHorses,
    checkExistSomeParamsFunc: checkExistSomeParamsFunc,
    buildUrl: buildUrl
  };

  function arraysHaveDifference(previousArray, currentArray, field) {
    if (!field) {
      field = null;
    }
    if (isArray(currentArray)) {
      if (isArray(previousArray) && xorBy(previousArray, currentArray, field).length === 0) {
        return false;
      }
      return true;
    }
    return false;
  }

  function sortByCaseInsensitive(collection, field) {
    return sortBy(collection, function(item) {
      return get(item, field, '').toUpperCase();
    });
  }

  function localizedDateTime(date, format, hoursDiff) {
    const barn = barnStorage.getEnv(),
      currentTimeZone =
        barn.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;

    return moment(date || new Date())
      .tz(currentTimeZone)
      .add(SERVER_HOURS_DIFF + (hoursDiff || 0), 'hours')
      .format(format || DATE_TIME_FORMAT);
  }

  function timezone(date, format, unix, timezone) {
    const barn = barnStorage.getEnv();
    const currentTimeZone = timezone ? timezone : (barn.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone);

    if (date && !unix) {
      date = date.replace('Z', '');
    }

    if (unix) {
      return moment
        .unix(date, currentTimeZone)
        .tz(currentTimeZone)
        .format(format || DATE_TIME_FORMAT);
    } else {
      return moment
        .tz(date + (format === 'l' ? '' : 'Z'), currentTimeZone)
        .format(format || DATE_TIME_FORMAT);
    }
  }

  function addHours(date, format, hoursDiff) {
    return moment(new Date(date))
      .add(hoursDiff || SERVER_HOURS_DIFF, 'hours')
      .format(format || DATE_TIME_FORMAT);
  }

  function timer() {
    $timeout.cancel(timeout);
    timeout = $timeout(function() {
      return 'Oops! It is taking more time than expected.';
    }, 4000);
  }

  function getSearchPreviousState() {
    return previousState;
  }

  function setSearchPreviousState(state) {
    previousState = state;
  }

  function capitalizeString(str) {
    if (!str) {
      return '';
    }

    return str
      .split('_')
      .join(' ')
      .toLowerCase()
      .replace(/\b\w/g, function(l) {
        return l.toUpperCase();
      });
  }

  function toUnderlineUpperCase(str) {
    const tmp = angular.copy(str);
    if (!str) {
      return null;
    }

    return tmp
      .split(' ')
      .join('_')
      .toUpperCase();
  }

  function preprocessHorses(records) {
    const horses = angular.copy(records),
      categorizationKeys = ['type', 'status', 'health', 'function'];

    horses.map(function(horse) {
      Object.keys(horse).map(function(key) {
        if (includes(categorizationKeys, key)) {
          horse[key] = capitalizeString(horse[key]);
        }
      });

      if (!horse.horseOwner) {
        horse.previewName = '';
      } else {
        horse.previewName = horse.horseOwner.name
          .split(' ')
          .map(function(el) {
            return el.charAt(0);
          })
          .join('')
          .substr(0, 2)
          .toUpperCase();
      }
    });

    return horses;
  }

  /*
    Check if exists params in obj
    `obj` - root object
    `keyMap` - array of searched keys. Example of keys ['key1','key2.length','key3.key4','key5.key6.key7']
    It can be used with scope
    >>> *.js
      scope.ifExist = utils.checkExistParamsFunc(scope);
    >>> *.html
      <div ng-if="ifExist(['horse.barn.id', 'horse.image.id'])"></div>
  */
  function checkExistSomeParamsFunc(obj) {
    return function(keyMap) {
      return some(at(obj, keyMap));
    };
  }

  function buildUrl(url, params) {
    const serializedParams = $httpParamSerializer(params);

    if (serializedParams.length > 0) {
      url += ((url.indexOf('?') === -1) ? '?' : '&') + serializedParams;
    }

    return url;
  }
}
