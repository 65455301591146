import {barnManagerModule} from '../../index.module';
import {PdfPreviewModalComponent} from '../../../app/attach-file/pdf-preview-modal/pdf-preview-modal.component';
import {ImagePreviewModalComponent} from '../../../app/attach-file/image-preview-modal/image-preview-modal.component';
import {BehaviorSubject} from 'rxjs';
import {MatDialogRef} from '@angular/material/dialog';

barnManagerModule.directive('bmAttachedFilePreview', attachedFilePreview);

function attachedFilePreview() {

  AttachedFilePreview.$inject = [
    '$scope',
    'FileOpener',
    'imageTransformer',
    '$log',
    'responseHandler',
    'ng2MatDialog',
    'MessageFrames'
  ];

  return {
    restricted: 'E',
    templateUrl: 'attached-file-preview.html',
    controller: AttachedFilePreview,
    controllerAs: 'attached',
    bindToController: true,
    scope: {
      noDelete: '=',
      delete: '=',
      files: '=',
      editable: '=',
      previewMode: '@',
      customClass: '@'
    }
  };
  function AttachedFilePreview(
    $scope,
    FileOpener,
    imageTransformer,
    $log,
    responseHandler,
    ng2MatDialog,
    MessageFrames
  ) {
    const vm = this;

    vm.$onInit = function() {

      $scope.$watchCollection('attached.files', files => {
        if (files?.length > 0) {
          vm.files = mapFiles(vm.files);
        }
      });

      vm.remove = remove;
      vm.download = download;
      vm.showModal = showModal;
      vm.previewPDF = previewPDF;
      vm.view = view;
      vm.isImage = isImage;
      vm.isPDF = isPDF;
      vm.onImageLoad = onImageLoad;

      function mapFiles(files) {
        return files.map(file => {
          if (!file.imageSrc && file.preSignedUrl) {
            file.imageSrc = file.preSignedUrl;
            delete file['preSignedUrl'];
          }
          file.availableForView = isAvailableForView(file.contentType);

          return file;
        });
      }

      function remove(index) {
        // TODO: fix bug when removing and adding the same file
        if (typeof vm.delete !== 'undefined') {
          vm.delete(vm.files[index]);
        }
        vm.files.splice(index, 1);
      }

      function download(file, event) {
        event.preventDefault();
        FileOpener(file.data || file.imageSrc, file.name, file.contentType, isImage(file.contentType));
      }

      function previewPDF(file, event) {
        event.preventDefault();
        ng2MatDialog.open(PdfPreviewModalComponent, {
          panelClass: 'bm-pdf-preview-modal-pane',
          data: {
            file: file,
          }
        });
      }

      async function showModal(file) {
        const img = new BehaviorSubject<string>(file.fullViewUrl);
        const dialogRef = openModal(img, file.name);
        if (!file.fullViewUrl) {
          try {
            file.fullViewUrl = await imageTransformer.fixOrientation(file.data || file.imageSrc, 2000, 2000, false, file.contentType);
            img.next(file.fullViewUrl);
          } catch (error) {
            dialogRef.close();
            $log.log(error);
            responseHandler.errorMessage('Error processing image', MessageFrames.TOP);
          }
        }
      }

      function openModal(image: BehaviorSubject<string>, name: string): MatDialogRef<ImagePreviewModalComponent> {
        return ng2MatDialog.open(ImagePreviewModalComponent, {
          panelClass: 'bm-image-preview-modal-pane',
          backdropClass: 'bm-image-preview-backdrop',
          data: { image, name }
        });
      }

      async function view(file, event) {
        if (isImage(file.contentType)) {
          await showModal(file);
        } else if (isPDF(file.contentType)) {
          previewPDF(file, event);
        }
      }

      function isImage(contentType) {
        const imageContentTypes = ['image/jpg', 'image/png', 'image/jpeg', 'image/webp', 'image/gif'];
        return imageContentTypes.includes(contentType);
      }

      function isPDF(contentType) {
        return contentType === 'application/pdf';
      }

      function isAvailableForView(contentType) {
        return isImage(contentType) || isPDF(contentType);
      }

      function onImageLoad(file) {
        file.ready = true;
      }
    };
  }
}
