import {barnManagerModule} from '../index.module';

barnManagerModule.factory('barnListService', BarnListService);

BarnListService.$inject = ['listRepository', 'responseHandler', '$state', 'MessageFrames'];
function BarnListService(listRepository, responseHandler, $state, MessageFrames) {
  function duplicate(listId) {
    return listRepository.duplicate(listId).then(response => {
      $state.go('listDetails', { id: response.id });
      responseHandler.successMessage('List successfully duplicated');
    }).catch((error) => responseHandler.processError(error, null, MessageFrames.TOP));
  }

  return {
    duplicate: duplicate
  };
}
