import {barnManagerModule} from '../index.module';
import * as angular from 'angular';

barnManagerModule
  .factory('Vendor', ['VendorTypes', (VendorTypes) => {
    function Vendor(data) {
      angular.extend(this, angular.copy(data));
      this.name = this.fullName();
    }

    Vendor.prototype = {
      fullName: function() {
        return this.firstName + ' ' + this.lastName;
      },

      displayType: function() {
        if (this.vendorType === VendorTypes.OTHER.value) {
          if (this.customType) {
            return this.customType;
          } else {
            return VendorTypes.OTHER.name;
          }
        }
        return VendorTypes[this.vendorType].name;
      },

      status: function() {
        if (this.archived) {
          return 'Archived';
        }
        return 'Active';
      }
    };

    return Vendor;
  }]);
