import {barnManagerModule} from '../../index.module';

barnManagerModule
  .component('bmEntryChangeSnapshot', {
    templateUrl: 'entry-change-snapshot.html',
    controller: function() {},
    controllerAs: 'vm',
    bindings: {
      snapshot: '<'
    }
  });
