import {barnManagerModule} from '../../index.module';
import {template} from 'lodash';

barnManagerModule.factory('backLinkHistory', BackLinkHistory);

BackLinkHistory.$inject = ['$rootScope', '$state', '$location', '$timeout', '$window', '$q', 'sessionStorageService', 'horseRepository', 'horseCache'];
function BackLinkHistory($rootScope, $state, $location, $timeout, $window, $q, sessionStorageService, horseRepository, horseCache) {
  const links = [];
  const pathName = $window.location.pathname;
  const defaultRouteName = 'activity';

  const titles = {
    activity: 'Activity',
    barnProfile: 'Barn profile',
    calendar: 'Calendar',
    contacts: 'Contacts',
    conversations: 'Conversations',
    entries: 'Feed',
    horses: 'Horses',
    lists: 'Lists',
    records: 'Records',
    users: 'Users & Permissions',
    vendors: 'Vendors',
    reports: 'Reports'
  };

  const parentMap = {
    barnProfile: { name: defaultRouteName },
    users: { name: 'barnProfile' },
    userEdit: { name: 'users' },
    userNew: { name: 'users' },
    payment: { name: 'barnProfile' },
    contactSupport: { name: 'barnProfile' },
    vendors: { name: 'barnProfile' },
    vendorEdit: { name: 'vendors' },
    vendorNew: { name: 'vendors' },
    'no-horse': { name: 'horses' },
    'no-permissions': { name: defaultRouteName },
    noaccess: { name: defaultRouteName },
    search: { name: defaultRouteName },
    horseDetails: { name: 'horses' },
    horseNew: { name: 'horses' },
    horseImportUsef: { name: 'horses' },
    horseImportCsv: { name: 'horses' },
    recordDetails: { name: 'records' },
    recordNew: { name: 'records' },
    listDetails: { name: 'lists' },
    listNew: { name: 'lists' },
    conversationDetails: { name: 'conversations' },
    conversationNew: { name: 'conversations' },
    eventDetails: { name: 'calendar' },
    eventNew: { name: 'calendar' },
    contactDetails: { name: 'contacts' },
    contactNew: { name: 'contacts' },
    activityHorse: { name: 'horses' },
    eventsHorse: { name: 'horses' },
    conversationsHorse: { name: 'horses' },
    recordsHorse: { name: 'horses' },
    listsHorse: { name: 'horses' },
    entriesHorse: { name: 'horses' },
    entryNew: { name: 'entries' },
    entryHorseNew: { name: 'entriesHorse', params: true, template: '${ name }\'s feed' },
    eventHorseDetails: { name: 'eventsHorse', params: true, template: '${ name }\'s events' },
    conversationHorseDetails: { name: 'conversationsHorse', params: true, template: '${ name }\'s conversations' },
    recordHorseDetails: { name: 'recordsHorse', params: true, template: '${ name }\'s records' },
    listHorseDetails: { name: 'listsHorse', params: true, template: '${ name }\'s lists' },
    reportsHorse: { name: 'horses' },
    horseEdit: { name: 'horseDetails', params: true, details: true },
    recordEdit: { name: 'recordDetails', params: true, details: true },
    listEdit: { name: 'listDetails', params: true, details: true },
    conversationEdit: { name: 'conversationDetails', params: true, details: true },
    eventEdit: { name: 'eventDetails', params: true, details: true },
    contactEdit: { name: 'contactDetails', params: true, details: true },
    eventHorseEdit: { name: 'eventHorseDetails', params: true, details: true },
    conversationHorseEdit: { name: 'conversationHorseDetails', params: true, details: true },
    recordHorseEdit: { name: 'recordHorseDetails', params: true, details: true },
    listHorseEdit: { name:  'listHorseDetails', params: true, details: true },
    entryHorseEdit: { name: 'entriesHorse', params: true, template: '${ name }\'s feed' }
  };

  return {
    applicationStarted: applicationStarted,
    pushLink: pushLink,
    pushHorseLink: pushHorseLink,
    getBackLink: getBackLink,
    popLink: popLink,
    goBack: goBack,
    getLinks: getLinks,
    setHorsesLink: setHorsesLink
  };

  function applicationStarted() {
    if (!sessionStorageService.getMainPageLoaded()) {
      sessionStorageService.clearProBackLink();
    }
    sessionStorageService.setMainPageLoaded(true);
    sessionStorageService.clearProPageLoaded();
  }

  function goBack() {
    getBackLink().then(function(result) {
      if (result.external) {
        sessionStorageService.setMainBackUrl(null);
      } else {
        popLink();
      }
      if (result.external) {
        $window.location.href = '/invoicing' + result.url;
      } else {
        $location.url(result.url);
      }
    });
  }

  function currentUrl() {
    return $location.url();
  }

  function routeUrl(routeName, routeParams?) {
    return $state.href(routeName, routeParams || {});
  }

  function pushLink(title) {
    const url = currentUrl();
    sessionStorageService.setMainBackUrl({
      url: url,
      title: title,
      external: true
    });
    const last = (links[links.length - 1] || { url: '' });
    if (url !== last.url) {
      // FIXME: workaround for navigation between different history results breaks application (hangs)
      if ($state.current.name === 'search' && last.name === 'search') {
        popLink();
      }
      links.push({
        url: currentUrl(),
        name: $state.current.name,
        title: title
      });
    }
    getBackLink().then(function(result) {
      $timeout(function() {
        $rootScope.$broadcast('backLinkChanged', result);
      }, 0);
    });
  }

  function pushHorseLink(title, id) {
    const horse = horseCache.horse();
    if (horse && Number(horse.id) === Number(id)) {
      pushLink(horse.name + '\'s ' + title);
    } else {
      horseRepository.find(id).then(function(data) {
        pushLink(data.name + '\'s ' + title);
      });
    }
  }

  function getBackLink() {
    let parent, result;
    const deferred = $q.defer();

    if (links.length > 1) {
      return $q.when(links[links.length - 2]);
    }
    const externalLink = sessionStorageService.getProBackUrl();
    if (externalLink) {
      return $q.when(externalLink);
    }

    result = {
      url: routeUrl(defaultRouteName),
      name: defaultRouteName,
      title: 'Activity'
    };

    parent = parentMap[$state.current.name];

    if (parent && links.length > 0) {
      result.name = parent.name;
      if (parent.params) {
        result.url = routeUrl(parent.name, $state.params);
      } else if (parent.horseId) {
        result.url = routeUrl(parent.name, { id: $state.params.horseId });
      } else {
        result.url = routeUrl(parent.name);
      }

      if (parent.details) {
        result.title = links[0].title.split('\'s')[0] + '\'s details';
      } else if (parent.template) {
        const horseId = parent.horseId ? $state.params.horseId : $state.params.id;
        const horse = horseCache.horse();
        if (horse && Number(horse.id) === Number(horseId)) {
          result.title = template(parent.template)({ name: horse.name });
          deferred.resolve(result);
        } else {
          horseRepository.find(horseId).then(function(data) {
            result.title = template(parent.template)({ name: data.name });
            deferred.resolve(result);
          });
        }

        return deferred.promise;
      } else {
        result.title = titles[parent.name] || 'Oops!';
      }
    }
    deferred.resolve(result);
    return deferred.promise;
  }

  function popLink() {
    links.pop();
  }

  function getLinks() {
    return links;
  }

  function setHorsesLink() {
    links.push({
      url: '/n/horses',
      name: 'horses',
      title: 'Horses'
    });
  }
}
