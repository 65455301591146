import {barnManagerModule} from '../index.module';

barnManagerModule
  .config(['$httpProvider', 'API_VERSION', 'BM_VERSION', ($httpProvider, API_VERSION, BM_VERSION) => {
    // may be used to pin-point issues, does not change any server responses
    $httpProvider.defaults.headers.common.BarnManagerClientVersion = BM_VERSION;
    // different API versions may result in changes of server behavior, with backwards incompatible changes
    // like different fields in json
    $httpProvider.defaults.headers.common.BarnManagerApiVersion = API_VERSION;

    // $httpProvider.defaults.headers.common.BarnManagerClientBuildDate = buildDate;
  }]);
