import {barnManagerModule} from '../index.module';
import {sortBy} from 'lodash';

barnManagerModule
  .factory('billingPlanRepository', ['$http', ($http) => {
    return {
      all: all
    };

    function all() {
      return $http.get('/api/tenants/stripe/plans-v2').then(function(result) {
        return result.data;
      }).then(function(results) {
        return sortBy(results.map(function(result) {
          let term = 'monthly';
          if (result.name.includes('Yearly')) {
            term = 'yearly';
          }

          let type = 'starter';
          if (result.name.includes('Pro')) {
            type = 'pro';
          } else if (result.name.includes('Essential')) {
            type = 'essential';
          }

          return {
            id: result.id,
            name: result.name,
            amount: result.amount,
            term: term,
            type: type,
          };
        }), 'amount');
      });
    }
  }]);
