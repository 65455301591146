import {barnManagerModule} from '../index.module';

barnManagerModule.factory('horseImageService', HorseImageService);

HorseImageService.$inject = ['$window', '$q', 'imageRepository'];
function HorseImageService($window, $q, imageRepository) {
  const horseImages = {};
  const url = $window.URL || $window.webkitURL;
  // disable storing images in IE
  const fetch = $window.fetch || function() {
    return $q.reject();
  };

  return {
    getInlineImage: getInlineImage,
    generateInlineImage: generateInlineImage
  };

  function loadImage(userId, returnBlobUrl) {
    return imageRepository.find(userId).then(function(image) {
      horseImages[userId] = url.createObjectURL(new Blob([image.data]));
      return returnBlobUrl ? horseImages[userId] : image;
    });
  }

  function getInlineImage(userId) {
    if (horseImages[userId]) {
      return fetch(horseImages[userId]).then(function(response) {
        return response.blob();
      }).then(function(blob) {
        return generateInlineImage(blob, true);
      }).catch(function() {
        return loadImage(userId, false).then(generateInlineImage);
      });
    }
    return loadImage(userId, false).then(generateInlineImage);
  }

  function generateInlineImage(image, isBlob) {
    const deferred = $q.defer();
    const blob = isBlob ? image : new Blob([image.data]);

    const reader = new $window.FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function() {
      let img = reader.result.replace(/^data:.*;base64,/, '');
      const binaryImg = atob(img).toLowerCase();
      let format = '';

      if (binaryImg.indexOf('png') !== -1) {
        format = 'image/png';
      } else if (binaryImg.indexOf('jpeg') !== -1 || binaryImg.indexOf('jpg') !== -1) {
        format = 'image/jpeg';
      } else if (binaryImg.indexOf('bmp') !== -1) {
        format = 'image/bmp';
      } else {
        format = 'image/jpeg';
      }

      img = 'data:' + format + ';base64,' + img;
      deferred.resolve(img);
    };

    return deferred.promise;
  }

}
