import {barnManagerModule} from '../../index.module';

barnManagerModule
  .constant('HorseColors',
    [
      'Albino',
      'Appaloosa',
      'Bay Roan',
      'Bay and White Paint',
      'Bay',
      'Black and White Tobiano',
      'Black',
      'Blagdon',
      'Blue Roan',
      'Bright Bay',
      'Brown',
      'Buckskin and White Tobiano',
      'Buckskin',
      'Chestnut and White Paint',
      'Chestnut',
      'Cremello',
      'Dark Bay',
      'Dun',
      'Flea-Bitten Gray',
      'Gray',
      'Grey and White Paint',
      'Grey',
      'Grullo',
      'Liver Chestnut',
      'Other',
      'Paint',
      'Palomino and White Tobiano',
      'Palomino',
      'Perlino',
      'Piebald',
      'Pinto',
      'Red Dun',
      'Red Roan',
      'Roan',
      'Seal Brown',
      'Silver Dapple',
      'Skewbald',
      'Sorrel',
      'Tricolor Paint',
      'White'
    ]
  );
