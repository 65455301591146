import {barnManagerModule} from '../../../index.module';

barnManagerModule
  .component('bmLeaseDescription', {
    templateUrl: 'lease-description.html',
    controller: LeaseDescription,
    controllerAs: 'vm',
    bindings: {
      notification: '='
    }
  });

function LeaseDescription() {
  const vm = this;
}
