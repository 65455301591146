import {barnManagerModule} from '../../index.module';
import {includes, keys, values} from 'lodash';

barnManagerModule
  .component('bmCheckboxSelect', {
    templateUrl: 'checkbox-select.html',
    controller: checkboxSelectController,
    controllerAs: 'vm',
    bindings: {
      items: '<',
      selected: '<',
      onChange: '<'
    }
  });

function checkboxSelectController() {
  const vm = this;

  vm.$onInit = function() {
    vm.model = {};

    vm.onItemChange = onItemChange;

    init();

    function onItemChange() {
      const selected = keys(vm.model).reduce(function(acc, key) {
        if (vm.model[key]) {
          acc.push(vm.items[key].value);
        }
        return acc;
      }, []);
      vm.onChange(selected);
    }

    function init() {
      vm.model = values(vm.items).reduce(function(acc, item) {
        acc[item.value] = includes(vm.selected, item.value);
        return acc;
      }, {});
    }

  };
}
