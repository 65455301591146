import {barnManagerModule} from '../index.module';

barnManagerModule.factory('commentRepository', ['$http', 'userStorage', ($http, userStorage) => {
  return {
    add: add,
    update: update,
    remove: remove,
    all: all
  };

  function url(modelType: string, model: any) {
    switch (modelType) {
      case 'events':
        if (model.instance) {
          return `/api/events/v2/${model.id}/instances/${model.instance}/comments/`;
        } else {
          return `/api/events/v2/${model.id}/comments/`;
        }
      case 'lists':
        return `/api/lists/v2/${model.id}/comments/`;
      default:
        const currentUser = userStorage.getUser();
        return `/api/users/${currentUser.id}/${modelType}/${model.id}/comments/`;
    }
  }

  function recordUrl(modelType: string, model: any, commentId: number) {
    return `${url(modelType, model)}${commentId}`;
  }

  function remove(modelType: string, model: any, commentId: number) {
    return $http.delete(recordUrl(modelType, model, commentId)).then(transformResult);
  }

  function add(modelType: string, model: any, body: any, isNotified: boolean) {
    return $http.post(url(modelType, model) + '?isNotified=' + isNotified, body).then(transformResult);
  }

  function update(modelType: string, model: any, commentId: number, body: any, isNotified: boolean) {
    return $http.put(recordUrl(modelType, model, commentId) + '?isNotified=' + isNotified, body).then(transformResult);
  }

  function all(modelType: string, model: any) {
    return $http.get(url(modelType, model)).then(transformResult);
  }

  function transformResult(result) {
    return result.data;
  }
}]);
