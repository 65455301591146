import {barnManagerModule} from '../../index.module';
import {clone, every, includes, isFunction, map, sortBy} from 'lodash';
import {MatDialogRef} from '@angular/material/dialog';
import {take} from 'rxjs/operators';
import {Horses} from '../../../../../barn-manager-pro/src/app/shared/domain/Horses';
import {HorseAdvancedSelectComponent, HorseAdvancedSelectModalData, HorseCategoryItem} from '../../../app/horse';

barnManagerModule
  .component('bmHorseArea', {
    templateUrl: 'horse-area.html',
    controller: horseAreaController,
    controllerAs: 'vm',
    bindings: {
      selectedIds: '<',
      items: '<',
      onChange: '<',
      onSet: '<',
      multiple: '<',
      isRequired: '<',
      previewMode: '<',
      hideLabel: '<',
      hideActionButtons: '<'
    }
  });

horseAreaController.$inject = ['horseRepository', 'utils', 'ng2MatDialog'];

function horseAreaController(horseRepository, utils, ng2MatDialog) {
  let vm = this;

  vm.horses = [];

  vm.allHorsesAccess = false;

  vm.onHorseChange = onHorseChange;
  vm.setAllHorsesAccess = setAllHorsesAccess;
  vm.clearAllHorsesAccess = clearAllHorsesAccess;
  vm.openHorseAdvancedSelect = openHorseAdvancedSelect;

  function onHorseChange(selectedHorses) {
    if (typeof selectedHorses !== 'undefined') {
      vm.selected = selectedHorses;
    } else {
      vm.selected = [];
    }
    updateHorses();
  }

  function setAllHorsesAccess() {
    onHorseChange(clone(vm.horses));
  }

  function clearAllHorsesAccess() {
    onHorseChange([]);
  }

  function updateHorses() {
    checkAllHorses();
    let ids = [], items = [];
    if (vm.multiple) {
      ids = vm.selected ? map(vm.selected, 'id') : [];
      items = vm.selected ? vm.selected : [];
    } else {
      ids = vm.selected && vm.selected.id ? [vm.selected.id] : [];
      items = vm.selected ? [vm.selected] : [];
    }
    isFunction(vm.onChange) && vm.onChange(ids, items);
  }

  function checkAllHorses() {
    let assignedIds = sortBy(map(vm.selected, 'id'));
    let allIds = sortBy(map(vm.horses, 'id').sort());
    vm.allHorsesAccess = every(allIds.map(function(id) {
      return includes(assignedIds, id);
    }));
  }

  function setHorsesById() {
    let selected = vm.horses.filter(function(horse) {
      return includes(vm.horsesIds, horse.id);
    });
    if (vm.multiple) {
      vm.selected = selected;
    } else {
      vm.selected = selected[0] ? selected[0] : null;
    }
    isFunction(vm.onSet) && vm.onSet(selected);
  }

  function openHorseAdvancedSelect() {
    const dialogRef: MatDialogRef<HorseAdvancedSelectComponent> = ng2MatDialog.open(HorseAdvancedSelectComponent, {
      data: <HorseAdvancedSelectModalData>{
        selected: [...vm.selected],
        horses: vm.horses,
        selectedByUserCategories: vm.selectedByUserCategories
      }
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(([selectedHorses, selectedByUserCategories]: [Horses[], HorseCategoryItem[]]) => {
      if (selectedHorses) {
        onHorseChange(selectedHorses)
      }
      if (selectedByUserCategories) {
        vm.selectedByUserCategories = selectedByUserCategories
      }
    });
  }

  vm.$onInit = function() {
    horseRepository.all().then(function(horses) {
      vm.horses = horses;
      vm.horsesLoaded = true;
      setHorsesById();
      vm.advancedSelectBtnAvailable = vm.horses && vm.horses.length > 0;
    });
  };

  vm.$onChanges = function(changes) {
    let items = changes.items;
    let selectedIds = changes.selectedIds;
    if (items && utils.arraysHaveDifference(items.previousValue, items.currentValue, 'id')) {
      vm.horses = changes.items.currentValue;
    }
    if (selectedIds && utils.arraysHaveDifference(selectedIds.previousValue, selectedIds.currentValue)) {
      vm.horsesIds = changes.selectedIds.currentValue;
      setHorsesById();
    }
  };
}
