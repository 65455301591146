import {barnManagerModule} from '../index.module';
import {map} from 'lodash';

barnManagerModule.filter('capitalize', capitalize);

function capitalize() {
  function process(input) {
    if (!input) {
      return '';
    }

    return map(input.split(' '), function(el) {
        return el[0].toUpperCase() + el.substr(1).toLowerCase();
      })
      .join(' ');
  }

  return process;
}
