import {barnManagerModule} from '../index.module';
import * as angular from 'angular';
import {environment} from '@environment';

barnManagerModule.factory('intercomService', intercomService);

intercomService.$inject = [
  'userStorage',
  'rIntercom',
  'rUser',
  '$log',
  'hasPermission',
  'goNative',
  'ng2Intercom'
];
function intercomService(
  userStorage,
  rIntercom,
  rUser,
  $log,
  hasPermission,
  goNative,
  ng2Intercom
) {

  function bootIntercom(user) {
    if (!environment.intercomSettings.enabled || !ng2Intercom.loaded) {
      return;
    }

    if (user.intercomId) {
      authenticateIntercom(user, user.intercomId);
      return;
    }

    rIntercom.getUser({ email: user.email }, function(response) {
      authenticateIntercom(user, response.id);
      saveIntercomId(user, response.id);
    }, function(error) {
      if (error.status === 404) {
        const visitorId = ng2Intercom.getVisitorId();
        authenticateIntercom(user, visitorId);
        saveIntercomId(user, visitorId);
      } else {
        $log.error('Internal server error for user: ', user.email);
        return;
      }
    });
  }

  function resetIntercom() {
    if (!environment.intercomSettings.enabled || !ng2Intercom.loaded) {
      return;
    }
    ng2Intercom.shutdown();
    ng2Intercom.boot();
  }

  function authenticateIntercom(_user, intercomId) {
    const user = angular.copy(_user);
    const tenant = user.userTenants[0].tenant;
    rIntercom.hash({ message: intercomId }, function(response) {
      ng2Intercom.update({
        'user_id': intercomId,
        'name': user.firstName + ' ' + user.lastName,
        'email': user.email,
        'user_hash': response.hash,
        'barn': tenant.name,
        'is_admin': !!hasPermission('admin:full'),
        'Subscription_Plan': tenant.accessPolicy,
        'Trialing': !user.isInvoicingEnabled,
        'Platform': getUserPlatform()
      });
    });
  }

  function getUserPlatform() {
    if (goNative.isIOS()) {
      return 'iOS';
    } else if (goNative.isAndroid()) {
      return 'Android';
    } else {
      return 'Other';
    }
  }

  function saveIntercomId(_user, intercomId) {
    const user = angular.copy(_user);
    user.intercomId = intercomId;
    $log.info('intercom user', user);
    userStorage.setUser(user);

    rUser.updateIntercomId({
      id: user.id,
      intercomId: intercomId
    });
  }

  return {
    bootIntercom: bootIntercom,
    resetIntercom: resetIntercom
  };
}
