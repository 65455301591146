import {Injectable} from '@angular/core';
import {RestService} from '@shared/lib/rest/rest.service';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {ResponseHandlerService} from '../core/response-handler/response-handler.service';
import {ReportTypeFilterValues} from './ReportTypeSpecification';
import {EventTypes} from '../event/models/event-types';
import {RecordTypes} from '../record/models/record-types';
import {AuthorizedStorage} from "../core/authorized";
import {FileOpenerService} from "../core/utils/file-opener.service";

export interface BuildReportRequestBody {
  type: string,
  params: {
    horseIds?: number[],
    recordTypes?: string[],
    eventTypes?: string[],
    dateRange?: { start: string, end: string }
  }
}
export interface BuildReportResponse {
  headers: BuildReportResponseHeader[];
  rows: BuildReportResponseRow[];
}

export interface BuildReportResponseHeader {
  key: string;
  name: string;
  type: BuildReportResponseHeaderType;
}

export enum BuildReportResponseHeaderType {
  Details = "details",
  Url = 'url',
  TooltipString = 'tooltipString',
  String = 'string',
  Date = 'date',
  Time = 'time',
  Boolean = 'boolean',
  Color = 'color'
}

export interface BuildReportResponseRow {
  title: string;
  eventType: string;
  date: string;
  startTime: string;
  endTime: string;
  allDay: boolean;
  horses: string;
}

@Injectable()
export class ReportsService {

  constructor(
    private rest: RestService,
    private authorizedStorage: AuthorizedStorage,
    private responseHandlerService: ResponseHandlerService,
    private fileOpenerService: FileOpenerService
) { }

  exportToCsv(filters: ReportTypeFilterValues): Observable<string> {
    return this.rest.post({
      path: 'report-builder/exportToCsv', body: this.getBuildReportRequestBody(filters), responseType: 'text'
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.responseHandlerService.emitHttpError(error);
        return throwError(error);
      })
    )
  }

  buildReport(filters: ReportTypeFilterValues): Observable<BuildReportResponse> {
    return this.rest.post({
      path: 'report-builder/preview', body: this.getBuildReportRequestBody(filters)
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.responseHandlerService.emitHttpError(error);
        return throwError(error);
      })
    )
  }

  private getBuildReportRequestBody(filters: ReportTypeFilterValues): BuildReportRequestBody {
    const body = {
      type: filters.type.value,
      params: {}
    };
    if (filters.horseIds?.length > 0) {
      body.params = { ...body.params, horseIds: filters.horseIds };
    }
    if (filters.eventTypes?.length > 0) {
      if (filters.eventTypes.includes(EventTypes.AllEventTypes.value)) {
        body.params = { ...body.params, eventTypes: [] };
      } else {
        body.params = { ...body.params, eventTypes: filters.eventTypes };
      }
    }
    if (filters.recordTypes?.length > 0) {
      if (filters.recordTypes.includes(RecordTypes.AllRecordTypes.value)) {
        body.params = { ...body.params, recordTypes: [] };
      } else {
        body.params = {...body.params, recordTypes: filters.recordTypes};
      }
    }
    if (filters.start && filters.end) {
      body.params = { ...body.params, dateRange: { start: filters.start, end: filters.end } };
    }

    return body;
  }
}
