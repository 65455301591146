import {barnManagerModule} from '../index.module';

barnManagerModule.factory('unreadService', unreadService);

unreadService.$inject = ['tenantRepository'];
function unreadService(tenantRepository) {
  let unreadData = {}; // unreadLists: 10, unreadconversations: 3, unreadRecords: 5

  function get(model) {
    return unreadData[model];
  }

  function query(tenantId, callback) {
    tenantRepository.unreads(tenantId).then(function(response) {
      unreadData = response;
      if (callback) {
        callback(response);
      }
    });
  }

  return {
    get: get,
    query: query
  };
}
