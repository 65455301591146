import {barnManagerModule} from '../../index.module';

barnManagerModule
  .factory('NotificationTypes', () => {
    function EventCanceled() {
      this.value = 'EVENT_CANCELED';
      this.entity = (params) => {
        return params.event;
      };
      this.entityUrl = (entity) => {
        return `n/events/details/${entity.id}/`;
      };
      this.routeData = (stateParams) => {
        return { name: 'eventDetails', params: { id: stateParams.eventId, instance: stateParams.instance } };
      };
    }

    function CommentedOnConversation() {
      this.value = 'COMMENTED_ON_CONVERSATION';
      this.entity = (params) => {
        return params.conversation;
      };
      this.entityUrl = (entity) => {
        return `n/conversations/details/${entity.id}/`;
      };
      this.routeData = (stateParams) => {
        if (stateParams['conversationId']) {
          return { name: 'conversationDetails', params: { id: stateParams.conversationId } };
        }
      };
    }

    function CommentedOnEvent() {
      this.value = 'COMMENTED_ON_EVENT';
      this.entity = (params) => {
        return params.event;
      };
      this.entityUrl = (entity, params) => {
        const queryString = params.instanceId ? `?instance=${params.instanceId}` : '';
        return `n/events/details/${entity.id}${queryString}`;
      };
      this.routeData = (stateParams) => {
        if (stateParams['eventId']) {
          return { name: 'eventDetails', params: { id: stateParams.eventId, instance: stateParams.instance } };
        }
      };
    }

    function CommentedOnList() {
      this.value = 'COMMENTED_ON_LIST';
      this.entity = (params) => {
        return params.list;
      };
      this.entityUrl = (entity) => {
        return `n/lists/details/${entity.id}/`;
      };
      this.routeData = (stateParams) => {
        if (stateParams['listId']) {
          return { name: 'listDetails', params: { id: stateParams.listId } };
        }
      };
    }

    function CommentedOnRecord() {
      this.value = 'COMMENTED_ON_RECORD';
      this.entity = (params) => {
        return params.record;
      };
      this.entityUrl = (entity) => {
        return `n/records/details/${entity.id}/`;
      };
      this.routeData = (stateParams) => {
        if (stateParams['recordId']) {
          return { name: 'recordDetails', params: { id: stateParams.recordId } };
        }
      };
    }

    function ConversationAddedOrUpdated() {
      this.value = 'CONVERSATION_ADDED_OR_UPDATED';
      this.entity = (params) => {
        return params.conversation;
      };
      this.entityUrl = (entity) => {
        return `n/conversations/details/${entity.id}/`;
      };
      this.routeData = (stateParams) => {
        return { name: 'conversationDetails', params: { id: stateParams.conversationId } };
      };
    }

    function ConversationArchivedOrRestored() {
      this.value = 'CONVERSATION_ARCHIVED_OR_RESTORED';
      this.entity = (params) => {
        return params.conversation;
      };
      this.entityUrl = (entity) => {
        return `n/conversations/details/${entity.id}/`;
      };
      this.routeData = (stateParams) => {
        return { name: 'conversationDetails', params: { id: stateParams.conversationId } };
      };
    }

    function EventAddedOrUpdated() {
      this.value = 'EVENT_ADDED_OR_UPDATED';
      this.entity = (params) => {
        return params.event;
      };
      this.entityUrl = (entity, params) => {
        const queryString = params.instanceId ? `?instance=${params.instanceId}` : '';
        return `n/events/details/${entity.id}${queryString}`;
      };
      this.routeData = (stateParams) => {
        return { name: 'eventDetails', params: { id: stateParams.eventId, instance: stateParams.instance } };
      };
    }

    function ExpirationReminder() {
      this.value = 'EXPIRATION_REMINDER';
      this.entity = (params) => {
        return params.horse;
      };
      this.entityUrl = (entity) => {
        return `n/horses/details/${entity.id}/`;
      };
      this.routeData = (stateParams) => {
        return { name: 'horseDetails', params: { id: stateParams.horseId } };
      };
    }

    function FeedingEntriesAddedOrUpdated() {
      this.value = 'FEEDING_ENTRIES_ADDED_OR_UPDATED';
      this.entity = (params) => {
        return params.feedingEntry;
      };
      this.entityUrl = (entity, params) => {
        if (params.horse && params.horse.id) {
          return `n/horse/${params.horse.id}/entry-changes`;
        } else {
          return 'n/entries';
        }
      };
      this.routeData = (stateParams) => {
        if (stateParams['horseId']) {
          return {name: 'entryChanges', params: {id: stateParams.horseId}};
        } else {
          return { name: 'entries', params: {} };
        }
      };
    }

    function HorsesImported() {
      this.value = 'HORSES_IMPORTED';
      this.entity = (params) => {
        return params.horses;
      };
      this.entityUrl = () => {
        return `n/horses`;
      };
      this.routeData = () => {
        return { name: 'horses' };
      };
    }

    function HorseAdded() {
      this.value = 'HORSE_ADDED';
      this.entity = (params) => {
        return params.horse;
      };
      this.entityUrl = (entity) => {
        return `n/horses/details/${entity.id}/`;
      };
      this.routeData = (stateParams) => {
        return { name: 'horseDetails', params: { id: stateParams.horseId } };
      };
    }

    function LeaseBuyoutDateReminder() {
      this.value = 'LEASE_BUYOUT_DATE_REMINDER';
      this.entity = (params) => {
        return params.record;
      };
      this.entityUrl = (entity) => {
        return `n/records/details/${entity.id}/`;
      };
      this.routeData = (stateParams) => {
        return { name: 'recordDetails', params: { id: stateParams.recordId } };
      };
    }

    function LeaseEndDateReminder() {
      this.value = 'LEASE_END_DATE_REMINDER';
      this.entity = (params) => {
        return params.record;
      };
      this.entityUrl = (entity) => {
        return `n/records/details/${entity.id}/`;
      };
      this.routeData = (stateParams) => {
        return { name: 'recordDetails', params: { id: stateParams.recordId } };
      };
    }

    function LeaseExtensionDateReminder() {
      this.value = 'LEASE_EXTENSION_DATE_REMINDER';
      this.entity = (params) => {
        return params.record;
      };
      this.entityUrl = (entity) => {
        return `n/records/details/${entity.id}/`;
      };
      this.routeData = (stateParams) => {
        return { name: 'recordDetails', params: { id: stateParams.recordId } };
      };
    }

    function ListAddedOrUpdated() {
      this.value = 'LIST_ADDED_OR_UPDATED';
      this.entity = (params) => {
        return params.list;
      };
      this.entityUrl = (entity) => {
        return `n/lists/details/${entity.id}/`;
      };
      this.routeData = (stateParams) => {
        return { name: 'listDetails', params: { id: stateParams.listId } };
      };
    }

    function ListArchivedOrRestored() {
      this.value = 'LIST_ARCHIVED_OR_RESTORED';
      this.entity = (params) => {
        return params.list;
      };
      this.entityUrl = (entity) => {
        return `n/lists/details/${entity.id}/`;
      };
      this.routeData = (stateParams) => {
        return { name: 'listDetails', params: { id: stateParams.listId } };
      };
    }

    function ListOrListItemAssigned() {
      this.value = 'LIST_OR_LIST_ITEM_ASSIGNED';
      this.entity = (params) => {
        return {...params.list, item: params.item};
      };
      this.entityUrl = (entity) => {
        return `n/lists/details/${entity.id}/`;
      };
      this.routeData = (stateParams) => {
        return { name: 'listDetails', params: { id: stateParams.listId } };
      };
    }

    function LisItemCheckedOrUnchecked() {
      this.value = 'LIST_ITEM_CHECKED_OR_UNCHECKED';
      this.entity = (params) => {
        return params.list;
      };
      this.entityUrl = (entity) => {
        return `n/lists/details/${entity.id}/`;
      };
      this.routeData = (stateParams) => {
        return { name: 'listDetails', params: { id: stateParams.listId } };
      };
    }

    function PostedToWhiteboard() {
      this.value = 'POSTED_TO_WHITEBOARD';
      this.entity = (params) => {
        return params.whiteboardPost;
      };
      this.entityUrl = () => {
        return '/n/activity?section=whiteboard';
      };
      this.routeData = () => {
        return { name: 'activity', params: { section: 'whiteboard' } };
      };
    }

    function RecordAddedOrUpdated() {
      this.value = 'RECORD_ADDED_OR_UPDATED';
      this.entity = (params) => {
        return params.record;
      };
      this.entityUrl = (entity) => {
        return `n/records/details/${entity.id}/`;
      };
      this.routeData = (stateParams) => {
        return { name: 'recordDetails', params: { id: stateParams.recordId } };
      };
    }

    function RecordArchivedOrRestored() {
      this.value = 'RECORD_ARCHIVED_OR_RESTORED';
      this.entity = (params) => {
        return params.record;
      };
      this.entityUrl = (entity) => {
        return `n/records/details/${entity.id}/`;
      };
      this.routeData = (stateParams) => {
        return { name: 'recordDetails', params: { id: stateParams.recordId } };
      };
    }

    function UpcomingEvent() {
      this.value = 'UPCOMING_EVENT';
      this.entity = (params) => {
        return params.event;
      };
      this.entityUrl = (entity) => {
        return `n/events/details/${entity.id}/`;
      };
      this.routeData = (stateParams) => {
        return { name: 'eventDetails', params: { id: stateParams.eventId, instance: stateParams.instance } };
      };
    }

    return {
      EVENT_CANCELED: new EventCanceled(),
      COMMENTED_ON_CONVERSATION: new CommentedOnConversation(),
      COMMENTED_ON_EVENT: new CommentedOnEvent(),
      COMMENTED_ON_LIST: new CommentedOnList(),
      COMMENTED_ON_RECORD: new CommentedOnRecord(),
      CONVERSATION_ADDED_OR_UPDATED: new ConversationAddedOrUpdated(),
      CONVERSATION_ARCHIVED_OR_RESTORED: new ConversationArchivedOrRestored(),
      EVENT_ADDED_OR_UPDATED: new EventAddedOrUpdated(),
      EXPIRATION_REMINDER: new ExpirationReminder(),
      FEEDING_ENTRIES_ADDED_OR_UPDATED: new FeedingEntriesAddedOrUpdated(),
      HORSES_IMPORTED: new HorsesImported(),
      HORSE_ADDED: new HorseAdded(),
      LEASE_BUYOUT_DATE_REMINDER: new LeaseBuyoutDateReminder(),
      LEASE_END_DATE_REMINDER: new LeaseEndDateReminder(),
      LEASE_EXTENSION_DATE_REMINDER: new LeaseExtensionDateReminder(),
      LIST_ADDED_OR_UPDATED: new ListAddedOrUpdated(),
      LIST_ARCHIVED_OR_RESTORED: new ListArchivedOrRestored(),
      LIST_OR_LIST_ITEM_ASSIGNED: new ListOrListItemAssigned(),
      LIST_ITEM_CHECKED_OR_UNCHECKED: new LisItemCheckedOrUnchecked(),
      POSTED_TO_WHITEBOARD: new PostedToWhiteboard(),
      RECORD_ADDED_OR_UPDATED: new RecordAddedOrUpdated(),
      RECORD_ARCHIVED_OR_RESTORED: new RecordArchivedOrRestored(),
      UPCOMING_EVENT: new UpcomingEvent()
    };
  });
