import {barnManagerModule} from '../index.module';
import {find, get} from 'lodash';

barnManagerModule.factory('plansStorage', PlansStorage);

PlansStorage.$inject = ['$q', 'billingPlanRepository', 'subscriptionStorage'];
function PlansStorage($q, billingPlanRepository, subscriptionStorage) {
  let allPlans = [];

  return {
    getPlanById: getPlanById,
    getSelectedPlanTerm: getSelectedPlanTerm,
    getAllPlans: getAllPlans,
    filterByTerm: filterByTerm
  };

  function getSelectedPlanTerm() {
    let subscription = subscriptionStorage.getSubscription();
    if (!subscription || subscription.planName.indexOf('Monthly') > -1) {
      return 'monthly';
    } else {
      return 'yearly';
    }
  }

  function getPlanById(planId) {
    return find(allPlans, function(plan) {
      return planId === plan.id;
    });
  }

  function getAllPlans() {
    if (get(allPlans, 'length')) {
      return $q.when(allPlans);
    } else {
      return billingPlanRepository.all().then(function(responses) {
        allPlans = responses;
        return responses;
      });
    }
  }

  function filterByTerm(term) {
    return allPlans.filter(function(plan) {
      return plan.term === term;
    });
  }
}
