import {barnManagerModule} from '../../index.module';

barnManagerModule.directive('bmHorseCard', horseCard);

function horseCard() {

  return {
    restricted: 'E',
    templateUrl: 'horse-card.html',
    controller: HorseCardController,
    controllerAs: 'horsecard',
    bindToController: true,
    transclude: {
      pinButton: '?bmHorseCardPinButton',
      archiveButton: '?bmHorseCardArchiveButton'
    },
    scope: {
      item: '='
    }
  };

  function HorseCardController() {
    const vm = this;
    vm.$onInit = function() {

      vm.getHorseCategoriesArray = getHorseCategoriesArray();

      function getHorseCategoriesArray() {
        const categories = [];
        if (vm.item?.categories?.statuses) {
          categories.push(...vm.item.categories.statuses)
        }
        if (vm.item?.categories?.healths) {
          categories.push(...vm.item.categories.healths)
        }
        if (vm.item?.categories?.functions) {
          categories.push(...vm.item.categories.functions)
        }
        return categories;
      }

    };
  }
}
