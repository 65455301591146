import {barnManagerModule} from './index.module';
import {environment} from '@environment';

barnManagerModule.config(config).factory('middleware', middleware);

config.$inject = ['$logProvider', '$httpProvider', '$compileProvider', 'StripeCheckoutProvider', 'uibDatepickerConfig', 'DATE_INPUT_MIN_VALUE'];
function config(
  $logProvider,
  $httpProvider,
  $compileProvider,
  StripeCheckoutProvider,
  uibDatepickerConfig,
  DATE_INPUT_MIN_VALUE
) {
  // Enable log
  $logProvider.debugEnabled(true);

  // Enable speedups
  if (environment.environmentName === 'production') {
    $compileProvider.debugInfoEnabled(false);
  }

  // Allow images base64 format for Print Details to PDF feature.
  $compileProvider.imgSrcSanitizationWhitelist(/^\s*(https?|ftp|file|blob):|data:image\//);

  // HTTP Interceptor
  $httpProvider.interceptors.push('authInterceptor', 'httpErrorInterceptor', 'tenantEnvironmentIdEnricherInterceptor', 'middleware');

  // Stripe settings
  StripeCheckoutProvider.defaults({
    key: environment.stripeCredentials.key
  });

  //Allow webcal urls
  $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|webcal):/);

  uibDatepickerConfig.minDate = DATE_INPUT_MIN_VALUE;
}

middleware.$inject = ['sessionStorageService'];
function middleware(sessionStorageService) {
  return {
    request: function(config) {
      if (config.url.substring(0, 5) === '/api/') {
        const testApiEndpoint = sessionStorageService.getTestApiEndpoint();
        if (testApiEndpoint) {
          config.url = testApiEndpoint + config.url;
        }
      }
      return config;
    }
  };
}
