import {barnManagerModule} from '../index.module';

barnManagerModule
  .factory('rUser', ['$resource', ($resource) => {
    return $resource('/api/users/:id', {
      id: '@id'
    },
    {
      'delete': {
        method: 'DELETE',
        url: '/api/users/:id',
        params: {
          id: '@id'
        }
      },
      'query': {
        method: 'GET',
        url: '/api/users/:id'
      },
      'search': {
        method: 'GET',
        url: '/api/users/'
      },
      'simple': {
        method: 'GET',
        url: '/api/users/:id/simple',
        params:{
          id: '@id'
        }
      },
      'lastInvitation': {
        url: '/api/users/:id/last-invitation',
        method: 'GET'
      },
      'invite': {
        url: '/api/users/invite',
        method: 'POST'
      },
      'reinvite': {
        url: '/api/users/:id/reinvite',
        method: 'POST'
      },
      'update': {
        method: 'PUT',
        url:'/api/users/:id',
        params:{}
      },
      'updateIntercomId': {
        method: 'PUT',
        url: '/api/users/:id/intercomId',
        transformResponse: (data) => {
          return { data };
        }
      }
    });
  }]);
