import {barnManagerModule} from '../../index.module';

barnManagerModule
  .component('bmSubmitCancelButtons', {
    templateUrl: 'submit-cancel-buttons.html',
    controller: SubmitCancelButtonController,
    controllerAs: 'vm',
    transclude: true,
    bindings: {
      divider: '<',
      editMode: '<',
      savingDisabled: '<',
      saving: '<',
      addLabel: '@',
      addingLabel: '@'
    }
  });
function SubmitCancelButtonController() {
  const vm = this;

  vm.$onInit = function() {
    vm.addLabel = vm.addLabel || 'Add';
    vm.addingLabel = vm.addingLabel || 'Adding';
  };
}
