import {barnManagerModule} from '../../index.module';
import {get, set} from 'lodash';

barnManagerModule.directive('bmShowHideWrapper', showHideWrapper);

function showHideWrapper() {

  ShowHideWrapper.$inject = ['userStorage', 'userSettingsStorage', 'rModelLists'];

  return {
    restricted: 'E',
    templateUrl: 'show-hide-wrapper.html',
    controller: ShowHideWrapper,
    controllerAs: 'vm',
    bindToController: true,
    transclude: true,
    scope: {
      title: '@',
      property: '@'
    }
  };

  function ShowHideWrapper(userStorage, userSettingsStorage, rModelLists) {
    const vm = this;

    vm.$onInit = function() {
      vm.user = userStorage.getUser();
      vm.userSettings = userSettingsStorage.getUserSettings();
      vm.show = get(vm.userSettings, vm.property) !== undefined ? get(vm.userSettings, vm.property) : true;
      vm.toggle = toggle;

      function toggle() {
        vm.show = !vm.show;
        changeProperty(vm.show);
      }

      function changeProperty(show) {
        if (!vm.property) {
          return;
        }

        const preferences = {userId: vm.user.id};
        set(preferences, vm.property, show);

        rModelLists.patchUserPreferences(preferences).$promise
          .then(() => {
            vm.userSettings = set(vm.userSettings, vm.property, show);
            userSettingsStorage.setUserSettings(vm.userSettings);
          });
      }
    };
  }
}
