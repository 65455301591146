import {barnManagerModule} from '../../index.module';
import {get} from 'lodash';
import moment from 'moment';

barnManagerModule
  .component('bmDateRange', {
    templateUrl: 'date-range.html',
    controller: DateRangeController,
    controllerAs: 'vm',
    bindings: {
      schedule: '<'
    }
  });

DateRangeController.$inject = ['RRuleHelper', 'DATE_FORMAT', 'DATE_ISO_FORMAT'];
function DateRangeController(RRuleHelper, DATE_FORMAT, DATE_ISO_FORMAT) {
  const vm = this;

  vm.$onInit = function() {

    vm.getEndDate = getEndDate;
    vm.isRepeated = isRepeated;

    function getEndDate() {
      return get(vm.schedule, 'rrule.until') ? moment(vm.schedule.rrule.until, DATE_ISO_FORMAT).format(DATE_FORMAT) : '';
    }

    function isRepeated() {
      return !!get(vm.schedule, 'rrule.freq');
    }

  };
}
