import {barnManagerModule} from '../../index.module';

barnManagerModule.directive('bmClearCache', clearCache);

function clearCache() {

  ClearCacheController.$inject = ['$rootScope', 'authenticationService'];

  return {
    restricted: 'E',
    templateUrl: 'clear-cache.html',
    controller: ClearCacheController,
    controllerAs: 'clear_cache',
    bindToController: true
  };

  function ClearCacheController($rootScope, authenticationService) {
    const vm = this;

    vm.$onInit = function() {
      vm.initLoadServerError = false;
      vm.clearCacheAndReload = clearCacheAndReload;

      const initLoadServerError = $rootScope.$on('initLoadServerError', () => vm.initLoadServerError = true);
      $rootScope.$on('$destroy', initLoadServerError);

      const onUserAuthenticated = $rootScope.$on('userAuthenticated', () => vm.initLoadServerError = false);
      $rootScope.$on('$destroy', onUserAuthenticated);

      function clearCacheAndReload($event: any) {
        $event.preventDefault();
        authenticationService.logout();
      }
    };
  }
}
