import {barnManagerModule} from '../index.module';

barnManagerModule.config(publicRoutes);

publicRoutes.$inject = ['$stateProvider', '$urlMatcherFactoryProvider', 'StripeCheckoutProvider'];
function publicRoutes($stateProvider, $urlMatcherFactoryProvider, StripeCheckoutProvider) {
  $urlMatcherFactoryProvider.strictMode(false);

  $stateProvider
    .state('signup', {
      url: '/n/signup',
      data: { isPublic: true },
      views: {
        content: {
          templateUrl: 'signup.html',
          controller: 'SignupController',
          controllerAs: 'vm',
          resolve: {
            stripe: StripeCheckoutProvider.load
          }
        }
      }
    });

  $stateProvider.state('sink', {
    url: '/*path',
    template: ''
  });
}
