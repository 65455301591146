import {barnManagerModule} from '../../../index.module';
import {DateTimeFormatter, ZonedDateTime, ZoneId} from '@js-joda/core';
import {Locale} from '@js-joda/locale_en-us';
import {each, filter, find, includes, map} from 'lodash';

barnManagerModule.controller('EventDetailsController', EventDetailsController);

EventDetailsController.$inject = [
  'backLinkHistory',
  'rUser',
  'rEvents',
  'vendorRepository',
  '$stateParams',
  '$rootScope',
  '$state',
  'barnStorage',
  'eventMapper',
  'responseHandler',
  'eventsService',
  'goNative',
  'horseRepository',
  'titleService',
  'hasPermission'
];
function EventDetailsController(
  backLinkHistory,
  rUser,
  rEvents,
  vendorRepository,
  $stateParams,
  $rootScope,
  $state,
  barnStorage,
  eventMapper,
  responseHandler,
  eventsService,
  goNative,
  horseRepository,
  titleService,
  hasPermission
) {
  let vm = this, barn;

  vm.event = {};
  vm.excludedDays = [];
  vm.eventId = -1;
  vm.curHorseId = -1;
  vm.instance = $stateParams.instance;
  vm.horses = [];
  vm.allHorsesList = [];
  vm.tags = [];
  vm.eventMembers = [];
  vm.viewModeClass = 'preview-mode';
  vm.allHorses = false;
  vm.viewExtra = false;
  vm.zoneCode = undefined;
  vm.isGoNative = goNative.isGoNative();

  vm.loading = false;
  vm.error = null;

  vm.togglePin = togglePin;
  vm.getRecord = getRecord;
  vm.hasEventsFullPermission = hasPermission('events:full') && vm.event.eventType === 'LESSON';
  vm.hasLessonsFullPermission = hasPermission('lessons:full') && vm.event.eventType === 'LESSON';
  vm.showExtra = showExtra;
  vm.edit = edit;
  vm.eventInstanceStartsOn = null;
  vm.onToggleAttendance = onToggleAttendance;
  vm.onSuccessRemove = onSuccessRemove;

  init();

  function edit() {
    eventsService.navigateToEdit(vm.event,  vm.curHorseId, vm.instance);
  }

  function togglePin() {
    vm.event.pinned = !vm.event.pinned;
    eventsService.togglePin(vm.event);
  }

  function showExtra() {
    vm.viewExtra = true;
  }

  function eventPromise() {
    if ($stateParams.closestEventInstance) {
      return Promise.resolve($stateParams.closestEventInstance);
    }
    if (vm.instance) {
      return rEvents.getInstance({
        eventId: vm.eventId,
        instance: vm.instance
      }).$promise;
    } else {
      return rEvents.get({
        eventId: vm.eventId,
      }).$promise;
    }
  }

  function getRecord() {
    vm.loading = true;

    loadVendors().then(function(vendors) {
      const promise = eventPromise();
      promise.then(function(event) {
        vm.loading = false;
        event.instance = vm.instance;
        vm.event = eventMapper.eventFromPayload(event, barnStorage.getEnv().timeZone, vendors, false);
        vm.excludedDays = vm.event.excludedDays;
        vm.excludedDays.sort();
        if (vm.event.eventType === 'LESSON') {
          vm.hasLessonsFullPermission = hasPermission('lessons:full')
        } else {
          vm.hasEventsFullPermission = hasPermission('events:full')
        }
        backLinkHistory.pushLink(vm.event.title + '\'s details');
        loadAllUsers().then(function(allUsers) {
          vm.eventMembers = filter(allUsers, function(user) {
            return includes(vm.event.memberIds, user.id);
          });
          prepareReminders(vm.event.reminders, allUsers);
        });
      }).catch(function(error) {
        vm.loading = false;
        return responseHandler.handleNotFoundOrAccess(error, 'event', function(errorMessage) {
          vm.error = errorMessage;
        });
      });
    });
  }

  function loadAllHorses() {
    horseRepository.all().then(function(horses) {
      vm.allHorsesList = horses;
    });
  }

  function prepareReminders(reminders, allUsers) {
    vm.reminders = map(reminders, function(reminder) {
      reminder.recipientNames = map(reminder.recipientIds, (id: string) => find(allUsers, { id: id })?.displayName).filter(e => e != null)

      return reminder;
    });
  }

  function onToggleAttendance(attendance) {
    rEvents.updateAttendance({ eventId: vm.eventId }, {
      horseId: attendance.horse.id,
      contactId: attendance.contact.id,
      attended: attendance.attended,
      date: vm.instance
    });
  }

  function loadVendors() {
    return vendorRepository.all().then(function(vendors) {
      return vendors.records;
    });
  }

  function loadAllUsers() {
    return rUser.search({ tenantEnvironmentId: barn.id, archived: false, pagesize: 999 }).$promise.then(function(response) {
      return response.records;
    });
  }

  function init() {
    titleService.setTitle('Event Details');
    $rootScope.pageConf.class = 'page--interior';
    barn = barnStorage.getEnv();

    const currentStateName = $state.current.name;

    // Applying Timezone code
    vm.zoneCode = ZonedDateTime.now(ZoneId.of(barn.timeZone))
      .format(DateTimeFormatter.ofPattern('zzz').withLocale(Locale.ENGLISH));

    if (currentStateName === 'eventDetails' && (isNaN($stateParams['id']) || !$stateParams['id'])) {
      $state.go('events');
      return;
    }

    if (currentStateName === 'eventHorseDetails' && (isNaN($stateParams['eventId']) || !$stateParams['eventId'])) {
      $state.go('eventsHorse');
      return;
    }

    vm.eventId = currentStateName === 'eventDetails' ? $stateParams.id : $stateParams.eventId;

    if (currentStateName === 'eventHorseDetails') {
      vm.curHorseId = $stateParams.id;
    }

    vm.getRecord();
    loadAllHorses();
  }

  function onSuccessRemove() {
    backLinkHistory.goBack();
  }
}
