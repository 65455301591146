import {barnManagerModule} from '../index.module';
import moment from 'moment';
import {RRule} from 'rrule'
import {compact, groupBy, isEmpty, map, mapValues, sortBy, merge} from 'lodash';

barnManagerModule.factory('EntryHelper', EntryHelper);

EntryHelper.$inject = ['WEEKDAYS', 'DATE_ISO_FORMAT', 'DATE_FORMAT', 'EntryTypes', 'RRuleHelper'];
function EntryHelper(
  WEEKDAYS,
  DATE_ISO_FORMAT,
  DATE_FORMAT,
  EntryTypes,
  RRuleHelper
) {
  return {
    fullSchedule: fullSchedule,
    viewSchedule: viewSchedule,
    viewTime: viewTime,
    groupHorseEntries: groupHorseEntries,
    getEntryTitle: getEntryTitle
  };

  function isMomentBetween(mdate, week) {
    return mdate.isSame(week.dateRangeStart)
      || mdate.isSame(week.dateRangeEnd)
      || mdate.isAfter(week.dateRangeStart) && mdate.isBefore(week.dateRangeEnd);
  }

  function RRuleBetween(rrule, week) {
    const start = moment(week.dateRangeStart, DATE_ISO_FORMAT)
      .startOf('day')
      // fixed rrule.between bug
      .add(-1, 'seconds')
      .toDate();
    const end = moment(week.dateRangeEnd, DATE_ISO_FORMAT).endOf('day').toDate();
    return rrule.between(start, end);
  }

  function ordinalNumberEnding(number) {
    switch(number) {
      case 1: case 21: case 31:
        return 'st';
      case 2: case 22:
        return 'nd';
      case 3: case 23:
        return 'rd';
      default:
        return 'th';
    }
  }

  function fullSchedule(entry) {
    const schedule = RRuleHelper.normalizeSchedule(entry.schedule);
    const rule = RRuleHelper.createRRuleObject(schedule);
    if (rule._isAMomentObject) {
      return rule.format('ddd') + ' ' + rule.format(DATE_FORMAT);
    }

    // Every 2 Months On The 1st, 14th, 18th, 27th And 9th
    const rrule = entry.schedule.rrule;
    const freqName = {
      MONTHLY: 'month',
      WEEKLY: 'week',
      DAILY: 'day'
    };
    let text = 'Every ';
    if (rrule.interval > 1) {
      text += rrule.interval + ' ' + freqName[rrule.freq] + 's ';
    } else {
      text += freqName[rrule.freq] + ' ';
    }

    if (!isEmpty(rrule.byweekday)) {
      const byweekday = rrule.byweekday.map(function(day) {
        return WEEKDAYS[day].label.substr(0, 3);
      });
      text += 'on ' + byweekday.join(', ') + ' ';
    }
    if (!isEmpty(rrule.bymonthday)) {
      const bymonthday = rrule.bymonthday.map(function(day) {
        return '' + day + ordinalNumberEnding(day);
      });
      text += 'on the ' + bymonthday.join(', ') + ' ';
    }

    return text;
  }

  function viewSchedule(entry, week) {
    const rule = RRuleHelper.createRRuleObject(entry.schedule);
    if (rule._isAMomentObject) {
      if (isEmpty(week) || isMomentBetween(rule, week)) {
        // has no week limits or in current week
        return rule.format('dd') + ' ' + rule.format(DATE_FORMAT);
      }
      return null;
    }

    const weekDays = RRuleBetween(rule, week);
    if (rule.options.freq === RRule.DAILY && rule.options.interval === 1 && rule.options.dtstart < moment(week.dateRangeStart, DATE_ISO_FORMAT).startOf('day').toDate()) {
      if (rule.options.until == null || rule.options.until > moment(week.dateRangeEnd, DATE_ISO_FORMAT).endOf('day').toDate()) {
        return 'Every day';
      }
    }

    if (weekDays.length === 7) {
      return 'Every day';
    } else if (weekDays.length === 0) {
      return null;
    }

    return weekDays.map(function(date) {
      return moment(date).format('dd');
    }).join(', ');
  }

  function viewTime(entry) {
    const timeList = map(entry.administrationTimes, function(val, key) {
      const entityTime = EntryTypes[entry.type].time.find(t => t.value === key);
      if (val && entityTime) {
        return entityTime.label;
      } else {
        return null;
      }
    });
    return compact(timeList).join(', ');
  }

  function groupEntries(entries) {
    return merge(
      mapValues(EntryTypes, function() {
        return [];
      }),
      groupBy(sortBy(entries, 'schedule.dtstart'), 'type')
    );
  }

  function groupHorseEntries(entries) {
    const entriesGroupedForSchedule = groupEntries(entries.filter(e => e.viewSchedule || !e.schedule));
    const entriesScheduled = mapValues(entriesGroupedForSchedule, function(items, type) {
      return EntryTypes[type].time.reduce(function (acc, entityTime) {
        acc[entityTime.value] = items.filter(item => item.administrationTimes[entityTime.value]);
        return acc;
      }, {});
    });
    return {
      entriesGrouped: groupEntries(entries),
      entriesScheduled: entriesScheduled
    };
  }

  function getEntryTitle(key) {
    return EntryTypes[key].name;
  }
}
