import {barnManagerModule} from '../index.module';
import {cloneDeep, find} from 'lodash';

barnManagerModule.factory('recordMapper', RecordMapper);

RecordMapper.$inject = ['dateTimeUtils', 'Vendor', 'Record', 'RecordTypes'];
function RecordMapper(dateTimeUtils, Vendor, Record, RecordTypes) {

  return {
    recordToPayload: recordToPayload,
    recordFromPayload: recordFromPayload
  };

  function recordFromPayload(recordData, vendorDataCollection) {

    const result = new Record(recordData);
    result.date = dateTimeUtils.serverMixedDateToLocal(recordData.date);
    result.type = RecordTypes[recordData.recordType];

    const vendorData = find(vendorDataCollection, { id: recordData.vendorId });
    if (vendorData) {
      result.vendor = new Vendor(vendorData);
    }

    return result;
  }

  function recordToPayload(record) {
    const result = cloneDeep(record);
    if (result.recordType !== RecordTypes.LEASE.value) {
      result.date = dateTimeUtils.resetTimezone(result.date);
      result.lease = null;
    } else {
      result.date = null;
      if (!result.lease.endDate) {
        result.lease.endDateReminders = [];
      }
      if (!result.lease.buyoutDate) {
        result.lease.buyoutDateReminders = [];
      }
    }
    result.notes = result.notes ? result.notes : '';
    result.tags = result.tags ? result.tags : [];

    delete result.type;
    delete result.vendor;

    return result;
  }
}
