import {barnManagerModule} from '../index.module';
import * as angular from 'angular';

barnManagerModule.factory('globalNotificationService', globalNotificationService);

globalNotificationService.$inject = ['$stomp', '$log', '$timeout', '$rootScope', 'utils', 'messageBus', 'EntityUrl'];
function globalNotificationService($stomp, $log, $timeout, $rootScope, utils, messageBus, EntityUrl) {
  let connected = false,
      subscriptions = [],
      prevConf = null,
      attempts = 0,
      messages = ['Connection Attempts: ', 'Reached Max Attempts: '];

  function reconnect(conf) {
    if (attempts < 3) {
      attempts = attempts + 1;
      $log.info(messages[0] + attempts);
      $timeout(function() {
        connect(conf);
      }, 4000);
    } else {
      $log.info(messages[1] + attempts);
    }
  }

  function connect(conf) {
    if (!connected) {
      prevConf = conf;
      $stomp.connect(conf.basePath, conf.headers).then(function() { // (frame)
        connected = true;
        const onMessage = function(response) {
          $rootScope.$apply(function() {
            messageBus.push({
              frame: 'top',
              timeout: 5500,
              text: generateMessage(response),
              title: response.userDisplayName,
              type: 'info'
            });
          });
        };
        angular.forEach(conf.endpoints, function(endpoint) {
          const subscription = $stomp.subscribe(endpoint.path, onMessage, endpoint.headers);
          subscriptions.push(subscription);
        });
      }, function() { // (error)
        connected = false;
        if (prevConf !== null) {
          reconnect(prevConf);
        }
      });
    }
  }

  function generateMessage(item) {
    return '<a href="' + EntityUrl.fromActivity(item) + '">' + item.message + '</a>';
  }

  function disconnect() {
    if (connected) {
      unsubscribeAll();
      $stomp.disconnect().then(function() {
        connected = false;
      });
    }
  }

  function unsubscribeAll() {
    if (connected) {
      angular.forEach(subscriptions, function(subscription) {
        subscription.unsubscribe();
      });
    }
  }

  return {
    disconnect: disconnect,
    unsubscribeAll : unsubscribeAll,
    connect : connect
  };
}
