import {barnManagerModule} from '../index.module';
import {compact, groupBy, mapValues} from 'lodash';

barnManagerModule.factory('feedService', FeedService);

FeedService.$inject = ['entryRepository', 'EntryHelper', 'DATE_FORMAT', 'DATE_ISO_FORMAT'];
function FeedService(entryRepository, EntryHelper, DATE_FORMAT, DATE_ISO_FORMAT) {
  return {
    calculateSchedule: calculateSchedule,
    calculateAllSchedules: calculateAllSchedules,
    calculateStartWeek: calculateStartWeek,
    calculateLabel: calculateLabel,
    calculateWeek: calculateWeek
  };

  function entryMapper(entry, week) {
    entry.viewTime = EntryHelper.viewTime(entry);
    if (entry.schedule) {
      entry.viewSchedule = EntryHelper.viewSchedule(entry, week);
      entry.fullSchedule = EntryHelper.fullSchedule(entry);
    }
    return entry;
  }

  function calculateSchedule(weekStart, horseId) {
    const params = {
      horseIds: [horseId]
    };
    return entryRepository.search(params).then(function(entries) {
      const mappedEntry = entries.map(function(entry) {
        return entryMapper(entry, calculateWeek(weekStart));
      });
      // _.compact()
      const result = EntryHelper.groupHorseEntries(mappedEntry);
      result.nEntries = mappedEntry.length;
      return result;
    });
  }

  function calculateAllSchedules(date, horses) {
    const params = calculateWeek(date);
    return entryRepository.search(params).then(function(entries) {
      const mappedEntry = compact(entries.map(function(entry) {
        return entryMapper(entry, params);
      }));
      const groupedByHorse = groupBy(mappedEntry, 'horseId');
      return mapValues(
        horses,
        function(horse) {
          const horseEntries = groupedByHorse[horse.id] || [];
          horse.entries = EntryHelper.groupHorseEntries(horseEntries).entriesScheduled;
          return horse;
        }
      );
    });
  }

  function calculateLabel(date) {
    return 'Week of ' + date.startOf('week').format(DATE_FORMAT) + ' - ' + date.endOf('week').format(DATE_FORMAT);
  }

  function calculateStartWeek(date) {
    return date.startOf('week').format(DATE_ISO_FORMAT);
  }

  function calculateWeek(date) {
    return {
      dateRangeStart: date.startOf('week').format(DATE_ISO_FORMAT),
      dateRangeEnd: date.endOf('week').format(DATE_ISO_FORMAT)
    };
  }
}
