import {barnManagerModule} from '../index.module';

barnManagerModule
  .factory('activityRepository', ['$http', 'userStorage', 'barnStorage', ($http, userStorage, barnStorage) => {

    return {
      search: search,
      pin: pin
    };

    function url() {
      const currentUser = userStorage.getUser();
      return '/api/users/' + currentUser.id + '/activities';
    }

    function search(pageSize, isPinned, horseId) {
      const config = {
        params: {
          pagesize: pageSize,
          pinned: isPinned ? 1 : null,
          horseId: horseId,
          tenantEnvironmentId: barnStorage.getEnvId()
        }
      };
      return $http.get(url(), config).then(function(result) {
        return result.data;
      });
    }

    function pin(id) {
      return $http.get(url() + '/' + id + '/pin');
    }
  }]);
