import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';

export interface MessageModel {
  text: string;
  timeout: number;
}

@Injectable({
  providedIn: 'root'
})
export class ResponseHandlerService {

  private httpError: Subject<HttpErrorResponse> = new Subject<HttpErrorResponse>();
  httpError$: Observable<HttpErrorResponse> = this.httpError.asObservable();

  private unauthorized401Error: Subject<void> = new Subject<void>();
  unauthorized401Error$: Observable<void> = this.unauthorized401Error.asObservable();

  private successMessage: Subject<MessageModel> = new Subject<MessageModel>();
  successMessage$: Observable<MessageModel> = this.successMessage.asObservable();

  private errorMessage: Subject<MessageModel> = new Subject<MessageModel>();
  errorMessage$: Observable<MessageModel> = this.errorMessage.asObservable();

  emitHttpError(error: HttpErrorResponse): void {
    if (error.status === 401) {
      this.emitUnauthorized401Error();
    }

    this.httpError.next(error);
  }

  emitUnauthorized401Error(): void {
    this.unauthorized401Error.next();
  }

  emitSuccessMessage(text: string, timeout?: number): void {
    this.successMessage.next({ text, timeout });
  }

  emitErrorMessage(text: string, timeout?: number): void {
    this.errorMessage.next({ text, timeout });
  }
}
