import {barnManagerModule} from '../../index.module';

barnManagerModule.directive('bmButton', button);

function button() {
  return {
    restricted: 'E',
    templateUrl: 'button.html',
    controller: ButtonController,
    controllerAs: 'vm',
    bindToController: true,
    transclude: true,
    scope: {
      action: '&',
      icon: '@',
      isLoading: '&'
    }
  };

  function ButtonController() { }
}
