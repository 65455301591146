import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot} from '@angular/router';
import {AuthorizedFacade} from './authorized.facade';

@Injectable({ providedIn: 'root' })
export class AuthorizedGuard implements CanActivate {
  constructor(
    public authorizedFacade: AuthorizedFacade
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    return this.authorizedFacade.ensureLoggedIn(route);
  }
}
