import {barnManagerModule} from '../../index.module';

barnManagerModule.controller('HorseActivityController', HorseActivityController);

HorseActivityController.$inject = [
  '$rootScope',
  '$state',
  '$stateParams',
  'backLinkHistory',
  'activityRepository',
  'barnStorage',
  'PAGE_SIZE',
  'responseHandler',
  'authenticationService',
  'horseRepository',
  'MessageFrames',
  'horseCache',
  'titleService',
  'hasPermission'
];
function HorseActivityController(
  $rootScope,
  $state,
  $stateParams,
  backLinkHistory,
  activityRepository,
  barnStorage,
  PAGE_SIZE,
  responseHandler,
  authenticationService,
  horseRepository,
  MessageFrames,
  horseCache,
  titleService,
  hasPermission
) {
  let vm = this, barn, pageSize = PAGE_SIZE;
  backLinkHistory.pushHorseLink('activity', $state.params.id);

  vm.hasHorsesFullPermission = hasPermission('horses:full');
  vm.hasHorsesReadPermission = hasPermission('horses:read');
  vm.error = null;
  vm.items = [];
  vm.totalPages = 1;
  vm.curHorseId = $stateParams.id;
  vm.queryActivities = queryActivities;
  vm.horse = null;

  vm.loadMore = loadMore;
  vm.saveNotes = saveNotes;
  vm.isNotesFormOpen = false;
  vm.notes = '';
  vm.cancelNotes = cancelNotes;
  vm.showNotesForm = showNotesForm;

  if (authenticationService.isLoggedIn()) {
    init();
  }

  function loadMore() {
    if (vm.totalPages > 1) {
      pageSize += pageSize;
      queryActivities();
    }
  }

  function queryActivities(isPinned?) {
    if (typeof barn !== 'undefined') {
      activityRepository.search(pageSize, isPinned, vm.curHorseId).then(function(activities) {
        vm.items = activities.records.map(function(item) {
          switch (item.type) {
            case 'LIST_SIMPLE': {
              item.message = item.message.replace('details for ', '');
              break;
            }
            case 'HORSES_IMPORT': {
              item.message = `${item.message}: ${item.horses}`;
              item.horses = null;
              break;
            }
          }
          return item;
        });
        vm.totalPages = activities.totalPages;
      }).catch(responseHandler.processError);
    }
  }

  function showNotesForm() {
    vm.isNotesFormOpen = true;
    vm.notes = vm.horse.notes;
  }

  function saveNotes() {
    horseRepository.saveNotes(vm.horse.id, vm.notes).then(function() {
      vm.isNotesFormOpen = false;
      responseHandler.successMessage('Notes successfully saved.');
      horseRepository.find(vm.curHorseId).then(function(horse) {
        vm.horse = horse;
      });
    }).catch(function(_error) {
      return responseHandler.processError('There was an error while saving notes. Please try again.', null, MessageFrames.RIGHT);
    });
  }

  function cancelNotes() {
    vm.isNotesFormOpen = false;
  }

  function init() {
    barn = barnStorage.getEnv();
    const horse = horseCache.horse();
    if (horse) {
      vm.horse = horse;
    }

    titleService.setTitle('Activity');
    $rootScope.pageConf.class = 'page--barn';
    $rootScope.pageConf.htmlTagClass = 'overview-page';
  }
}
