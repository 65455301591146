import {barnManagerModule} from '../../index.module';

barnManagerModule.directive('bmShowMore', bmShowMore);

function bmShowMore() {

  ShowMoreController.$inject = ['$scope', '$element', '$timeout'];

  return {
    restricted: 'E',
    templateUrl: 'show-more.html',
    controller: ShowMoreController,
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      text: '<'
    }
  };

  function ShowMoreController($scope, $element, $timeout) {
    const vm = this;

    vm.$onInit = function() {

      vm.onClick = onClick;
      vm.onBlur = onBlur;

      function onClick() {
        vm.isOpened = true;
      }

      function onBlur() {
        vm.isOpened = false;
      }

      $scope.$watch('vm.text', function() {
        $timeout(function() {
          const elem = $element[0].querySelector('.bm-show-more__text');
          vm.overflowed = elem.scrollWidth > elem.offsetWidth;
        }, 50);
      });

    };
  }
}
