import {barnManagerModule} from '../../../index.module';
import {includes, keys, omit, values} from 'lodash';

barnManagerModule
  .component('bmPermissions', {
    templateUrl: 'permissions.html',
    controller: PermissionsController,
    controllerAs: 'vm',
    transclude: true,
    bindings: {
      user: '<',
      onUpdate: '&'
    }
  });

PermissionsController.$inject = [
  'moduleAccessService',
  'subscriptionStorage',
  'ModulePermissionList',
  'AccessTypes',
  'PermissionTypes',
  'Permission',
  'userStorage',
  'confirmDialog'
];
function PermissionsController(
  moduleAccessService,
  subscriptionStorage,
  ModulePermissionList,
  AccessTypes,
  PermissionTypes,
  Permission,
  userStorage,
  confirmDialog
) {
  const vm = this;

  vm.moduleList = [];
  vm.permissions = null;
  vm.hasAdminAccess = false;
  vm.permissionTypes = omit(PermissionTypes, PermissionTypes.admin.name);

  vm.toggledAdminAccess = toggledAdminAccess;
  vm.handleChangeAccess = handleChangeAccess;

  function handleChangeAccess() {
    vm.onUpdate({ permissions: permissionsToPayload(vm.permissions) });
  }

  function toggledAdminAccess() {
    let adminAccessType;
    if (!vm.hasAdminAccess && userStorage.getUser().id === vm.user.id) {
      confirmDialog.open('You\'re removing your admin access. You will not be able to enable it again yourself. Are you sure?').then(function() {
        adminAccessType = vm.hasAdminAccess ? AccessTypes.full : AccessTypes.none;
      }).catch(function() {
        vm.hasAdminAccess = true;
      });
    } else {
      adminAccessType = vm.hasAdminAccess ? AccessTypes.full : AccessTypes.none;
    }

    vm.permissions[PermissionTypes.admin.name] = adminAccessType;
    vm.onUpdate({ permissions: permissionsToPayload(vm.permissions) });
  }

  function init() {
    vm.permissions = permissionsFromPayload(vm.user.permissions);
    vm.hasAdminAccess = includes(vm.user.permissions, new Permission(PermissionTypes.admin, AccessTypes.full).asString());

    subscriptionStorage.getSubscriptionPromise().then(function(subscription) {
      const accessPolicy = moduleAccessService.getAccessPolicy(subscription);
      vm.currentPermissionList = ModulePermissionList[accessPolicy];
      vm.disabledPermissions = keys(vm.permissions).reduce(function(acc, permission) {
        correctPermission(permission);
        acc[permission] = isPermissionDisabled(permission);
        return acc;
      }, {});
    });
  }

  function isPermissionDisabled(permissionName) {
    // use maximum plan
    if (includes(ModulePermissionList.essential, permissionName)) {
      if (includes(vm.currentPermissionList, permissionName)) {
        return false;
      }
      return true;
    }
    return false;
  }

  function correctPermission(permissionName) {
    if (includes(ModulePermissionList.essential, permissionName) && !includes(vm.currentPermissionList, permissionName)) {
      vm.permissions[permissionName] = AccessTypes.none;
    }
  }

  function defaultPermissions() {
    const result = {};
    values(PermissionTypes).forEach(function(permissionType) {
      if (vm.hasAdminAccess) {
        result[permissionType.name] = AccessTypes.full;
      } else {
        result[permissionType.name] = permissionType.default();
      }
    });
    return result;
  }

  function permissionsFromPayload(source) {
    const result = defaultPermissions();
    if (vm.hasAdminAccess) {
      return result;
    }
    source.forEach(function(item) {
      const permission = Permission.fromString(item);
      result[permission.permissionType.name] = permission.accessType;
    });
    return result;
  }

  function permissionsToPayload(source) {
    const result = [];
    keys(source).forEach(function(permissionTypeName) {
      const permission = new Permission(PermissionTypes[permissionTypeName], source[permissionTypeName]);
      if (!permission.isDefault()) {
        result.push(permission.asString());
      }
    });
    return result;
  }

  this.$onInit = init;

  this.$onChanges = function(changes) {
    vm.user = changes.user.currentValue;
    init();
  };
}
