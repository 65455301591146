import {barnManagerModule} from '../index.module';

barnManagerModule
  .factory('rUserConversations', ['$resource', ($resource) => {

    return $resource('/api/users/:userId/conversations', {
      userId: '@userId'
    }, {
      'delete': {
        method: 'DELETE',
        url: '/api/users/:userId/conversations/:conversationId',
        params:{
          userId: '@userId',
          conversationId: '@conversationId'
        }
      },
      'get': {
        method: 'GET',
        url:'/api/users/:userId/conversations/:conversationId',
        params:{
          userId:'@userId',
          conversationId: '@conversationId'
        }
      },
      'query':  {
        method: 'GET'
      },
      'save': {
        url: '/api/users/:userId/conversations',
        method: 'POST',
        params:{
          userId:'@userId'
        }
      },
      'update': {
        method: 'PUT',
        url:'/api/users/:userId/conversations/:conversationId',
        params:{
          userId:'@userId',
          conversationId: '@conversationId'
        }
      },
      'addTags':{
        method: 'POST',
        url: '/api/users/:userId/conversations/:conversationId/tags',
        params:{
          userId:'@userId',
          conversationId: '@conversationId'
        }
      },
      'removeTags':{
        method: 'DELETE',
        url: '/api/users/:userId/conversations/:conversationId/tags/?:tags',
        params:{
          userId:'@userId',
          conversationId: '@conversationId',
          tags: '@tags'
        }
      },
      'togglePin': {
        method: 'GET',
        url:'/api/users/:userId/conversations/:conversationId/pin',
        params:{
          userId:'@userId',
          conversationId: '@conversationId'
        }
      },
      'markAsRead': {
        method: 'GET',
        url:'/api/users/:userId/conversations/:conversationId/read',
        params:{
          userId:'@userId',
          conversationId: '@conversationId'
        }
      },
      'archive': {
        method: 'PUT',
        url: '/api/users/:userId/conversations/:conversationId/archive',
        params: {
          userId:'@userId',
          conversationId: '@conversationId'
        }
      },
      'restore': {
        method: 'PUT',
        url: '/api/users/:userId/conversations/:conversationId/restore',
        params: {
          userId:'@userId',
          conversationId: '@conversationId'
        }
      }
    });
  }]);
