import {barnManagerModule} from '../../../index.module';

barnManagerModule.controller('CsvImportController', CsvImportController);

CsvImportController.$inject = [
  'backLinkHistory'
];
function CsvImportController(
  backLinkHistory
) {
  backLinkHistory.pushLink('CSV import');
}
