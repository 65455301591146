import {barnManagerModule} from '../../../index.module';
import {cloneDeep, isEmpty, isString, map, sortBy, union} from 'lodash';
import moment from 'moment';
import {copy} from 'angular';
import {HorseCategoryType} from '../../../resources/horseCategoryRepository';
import {HorseLocation} from '../../../../app/horse/horse-location';
import {HorseStallLocation} from "../../../../app/horse/horse-stall-location";

barnManagerModule.controller('HorseFormController', HorseFormController);

HorseFormController.$inject = [
  '$state',
  'backLinkHistory',
  'responseHandler',
  'listsService',
  'barnStorage',
  'dateTimeUtils',
  'utils',
  'registrationsHelpers',
  'imageService',
  'rHorses',
  'reminderRepository',
  'rAttachments',
  'rUser',
  'rHorseOwners',
  'HorseBreeds',
  'HorseColors',
  'PATTERNS',
  'DATE_ISO_FORMAT',
  '$q',
  'horseBreedRepository',
  'horseCache',
  'titleService'
];
function HorseFormController(
  $state,
  backLinkHistory,
  responseHandler,
  listsService,
  barnStorage,
  dateTimeUtils,
  utils,
  registrationsHelpers,
  imageService,
  rHorses,
  reminderRepository,
  rAttachments,
  rUser,
  rHorseOwners,
  HorseBreeds,
  HorseColors,
  PATTERNS,
  DATE_ISO_FORMAT,
  $q,
  horseBreedRepository,
  horseCache,
  titleService
) {
  let vm = this, barn, removedFiles = [], removedFilesC = [], originImage: any = {};
  const horseId = $state.params.id;

  if (isEditPage()) {
    backLinkHistory.pushHorseLink('edit', horseId);
  } else {
    backLinkHistory.pushLink('New horse');
  }

  titleService.setTitle('Horse Form');
  vm.reminders = { fei: [], certificate: [] };

  vm.attachmentsReady = false;
  vm.attachments = [];
  vm.files = [];
  vm.years = [];
  vm.calendarOpened = {
    feiPassportExpirationDate: false,
    insuranceDate: false,
    organizationInfo: false,
    coggingsCertificateExpiration: false
  };
  vm.submitAttempt = false;
  vm.patterns = PATTERNS;
  vm.horseCategoryType = HorseCategoryType;
  vm.editMode = isEditPage();
  vm.horseTypes = ['Gelding', 'Mare', 'Stallion'];
  vm.horseBreeds = [];
  vm.horseColors = HorseColors.sort();
  vm.horseOwners = [];
  vm.horseOwner = {};

  vm.status = {
    message: '',
    error: false,
    updating: false,
    updatingAndAddAnother: false
  };

  vm.months = map(moment.months(), function(m, i) {
    return { value: i + 1, name: m };
  });

  vm.allUsers = [];

  vm.convertOwnerFromOriginal = convertOwnerFromOriginal;
  vm.convertOwnerToOriginal = convertOwnerToOriginal;

  vm.onRegistrationChange = onRegistrationChange;
  vm.setHorseOwner = setHorseOwner;
  vm.queueDeleteCoggings = queueDeleteCoggings;
  vm.queueDelete = queueDelete;
  vm.openCalendar = openCalendar;
  vm.save = save;
  vm.onLocationsChange = onLocationsChange;
  vm.onStallLocationsChange = onStallLocationsChange;

  vm.onHorseImageRemoved = onHorseImageRemoved;
  vm.horseImageRemoved = false;
  vm.onHorseImageChanged = onHorseImageChanged;
  vm.horseImageLoaded = false;
  vm.horseImageError = null;

  vm.setHorseOwner();

  initializeHorse(vm.editMode);

  function isEditPage() {
    return $state.current.name === 'horseEdit';
  }

  function onLocationsChange(locations: HorseLocation) {
    vm.horse.locations = locations;
  }

  function onStallLocationsChange(stallLocations: HorseStallLocation, selectedStallLocation: HorseStallLocation) {
    vm.horse.stallLocation = stallLocations;
    vm.selectedStallLocation = selectedStallLocation;
  }

  function onHorseImageChanged(file, error) {
    vm.horseImageRemoved = false;
    vm.horseImageError = error;
    vm.horseImage = file;
  }

  function onHorseImageRemoved() {
    vm.horseImageRemoved = true;
    vm.horseImageError = null;
    vm.horseImage = null;
  }

  function processHorseImage(horse, horseImage) {
    if (vm.horseImageRemoved && horse.horseImageId) {
      return rHorses.removeImage({ id: horse.id }).$promise;
    } else {
      return uploadImage(horse, horseImage);
    }
  }

  function uploadImage(horse, image) {
    const deferred = $q.defer();
    vm.horseImageError = null;

    if (!image || typeof image === 'undefined' || (originImage.name === image.name)) {
      deferred.resolve();
    } else {
      const payload = new FormData();

      payload.append('file', image);

      rHorses.saveImage({ tenantEnvironmentId: barn.id, id: horse.id }, payload).$promise.then(function(result) {
        deferred.resolve(result);
      }).catch(function(uploadError) {
        vm.horseImageError = uploadError.data.error;
        deferred.reject(uploadError.data.error);
      });
    }

    return deferred.promise;
  }

  function convertOwnerFromOriginal(original) {
    original = original || {};
    return {
      id: original.id ? original.id : null,
      tenantEnvironmentId: original.tenantEnvironmentId,
      name: original.name ? original.name : '',
      primaryContactMailingAddress: {
        street1: original.streetAddress1 ? original.streetAddress1 : '',
        street2: original.streetAddress2 ? original.streetAddress2 : '',
        city: original.city ? original.city : original.city,
        country: original.country ? original.country : null,
        state: original.state ? original.state : null,
        zip: original.zipCode ? original.zipCode : ''
      },
      primaryContactPrimaryPhone: original.phone ? original.phone : '',
      primaryContactPrimaryEmail: original.email ? original.email : ''
    };
  }

  function convertOwnerToOriginal(owner) {
    return {
      id: owner.id,
      tenantEnvironmentId: owner.tenantEnvironmentId,
      name: owner.name,
      streetAddress1: owner.primaryContactMailingAddress.street1,
      streetAddress2: owner.primaryContactMailingAddress.street2,
      country: owner.primaryContactMailingAddress.country,
      city: owner.primaryContactMailingAddress.city,
      state: owner.primaryContactMailingAddress.state,
      zipCode: owner.primaryContactMailingAddress.zip,
      phone: owner.primaryContactPrimaryPhone,
      email: owner.primaryContactPrimaryEmail
    };
  }

  function setHorseOwner(ownerInfo) {
    vm.horseOwner = isEmpty(ownerInfo) ? copy(vm.emptyHorseOwner) : ownerInfo;
  }

  function onRegistrationChange(registrations) {
    vm.horse.registrations = registrations;
  }

  function openCalendar(calendar) {
    vm.calendarOpened[calendar] = true;
  }

  function loadAllUsers() {
    rUser.search({
      tenantEnvironmentId: barn.id,
      archived: false,
      pagesize: 999
    }).$promise.then(function(response) {
      vm.allUsers = response.records;
    });
  }

  function queueDeleteCoggings(file) {
    removedFilesC.push(file);
  }

  function queueDelete(file) {
    removedFiles.push(file);
  }

  function save(form: any, addAnother?: boolean) {
    vm.submitAttempt = true;

    const horsePayload = cloneDeep(vm.horse);
    if (!form.$valid || vm.horseImageError || registrationsHelpers.hasErrors(vm.horse.registrations)) {
      return;
    }

    horsePayload.type = utils.toUnderlineUpperCase(vm.horse.type);

    if (!vm.horse.foalYear) {
      horsePayload.foalYear = null;
      horsePayload.foalMonth = null;
      horsePayload.foalDay = null;
    }

    if (vm.horse.coggingsCertificateExpiration) {
      horsePayload.coggingsCertificateExpiration = dateTimeUtils.resetTimezone(vm.horse.coggingsCertificateExpiration);
    }

    if (vm.horse.insuranceDate) {
      horsePayload.insuranceDate = dateTimeUtils.resetTimezone(vm.horse.insuranceDate);
    }

    if (vm.horse.organizationExpires) {
      horsePayload.organizationExpires = dateTimeUtils.resetTimezone(vm.horse.organizationExpires);
    }

    if (vm.horse.feiPassportExpirationDate) {
      horsePayload.feiPassportExpirationDate = moment(vm.horse.feiPassportExpirationDate).format(DATE_ISO_FORMAT);
    }

    if (vm.horse.occupancyDate) {
      horsePayload.occupancyDate = dateTimeUtils.resetTimezone(vm.horse.occupancyDate);
    } else {
      horsePayload.occupancyDate = dateTimeUtils.resetTimezone(moment().toDate());
    }

    if(!vm.horse.stallLocation) {
      horsePayload.occupancyDate = null;
    }

    horsePayload.horseOwner = vm.horseOwner && vm.horseOwner.name && isString(vm.horseOwner.name) ? vm.horseOwner : null;

    if (horsePayload.horseOwner !== null) {
      for (let key in horsePayload.horseOwner) {
        if (horsePayload.horseOwner[key] === '') {
          horsePayload.horseOwner[key] = null;
        }
      }
    }

    if (addAnother) {
      vm.status.updatingAndAddAnother = true;
    } else {
      vm.status.updating = true;
    }

    let horsePromise;

    if (vm.editMode) {
      horsePromise = rHorses.update(horsePayload).$promise;
    } else {
      horsePromise = rHorses.save(horsePayload).$promise;
    }

    horsePromise.then(function(horse) {
      vm.status.message = '';
      vm.status.error = false;

      if (!vm.horse.feiPassportExpirationDate) {
        vm.reminders.fei = [];
      }
      if (!vm.horse.coggingsCertificateExpiration) {
        vm.reminders.certificate = [];
      }
      reminderRepository.addFeiReminder(horse.id, vm.reminders.fei).catch(responseHandler.processError);
      reminderRepository.addCertificateReminder(horse.id, vm.reminders.certificate).catch(responseHandler.processError);

      return registrationsHelpers.updateAttachments(
        vm.attachments,
        removedFiles,
        horse,
        vm.horse.registrations
      ).then(function(horseUpdated) {
        return attachCoggingFile(horseUpdated).then(function() {
          return processHorseImage(horseUpdated, vm.horseImage).then(function() {
            responseHandler.successOnSave('Horse', vm.editMode);
            if (addAnother) {
              backLinkHistory.setHorsesLink();
              $state.go('horseNew').then(() => {
                $state.reload();
              });
            } else {
              backLinkHistory.goBack();
            }
          });
        });
      });
    }).catch(function(error) {
      vm.status.updating = false;
      vm.status.updatingAndAddAnother = false;
      vm.status.error = true;
      return responseHandler.processError(error);
    });
  }

  function attachCoggingFile(horse) {
    listsService.updateAttachments(vm.files, removedFilesC, horse);
    return listsService.ready(function() {});
  }

  function yearRange() {
    const currentYear = new Date().getFullYear();
    vm.years.push(currentYear.toString());
    for (let i = currentYear; i <= currentYear + 20; i++) {
      vm.years.push(i.toString());
    }
  }

  function loadHorseBreeds() {
    horseBreedRepository.all().then(breeds => {
      vm.horseBreeds = sortBy(union(HorseBreeds, map(breeds, breed => breed.breed)));
    });
  }

  function loadHorseOwners() {
    rHorseOwners.get({ tenantEnvironmentId: barn.id, pagesize: 999 }, function(response) {
      vm.horseOwners = response.records;
      vm.horseOwnersLoaded = true;
    });
  }

  function initializeHorse(editMode) {
    barn = barnStorage.getEnv();

    vm.horse = {
      archived: 0,
      pinned: 0,
      tenantEnvironmentId: barn.id,
      categories: {
        statuses: [],
        healths: [],
        functions: []
      }
    };
    vm.horseImage = null;

    loadAllUsers();
    loadHorseBreeds();
    loadHorseOwners();

    listsService.resetTo('none', 'HORSE');

    if (editMode) {
      reminderRepository.getFeiReminder(horseId).then(function(response) {
        vm.reminders.fei = response;
      }).catch(responseHandler.processError);

      reminderRepository.getCertificateReminder(horseId).then(function(response) {
        vm.reminders.certificate = response;
      }).catch(responseHandler.processError);

      const horse = horseCache.horse();
      if (horse && Number(horse.id) === Number(horseId)) {
        titleService.setTitle('Edit :: ' + horse.name);

        vm.horse = horse;
        vm.horse.type = utils.capitalizeString(horse.type);

        if (vm.horse.horseOwner) {
          vm.setHorseOwner(vm.horse.horseOwner);
        }
        if (horse.insuranceDate) {
          vm.horse.insuranceDate = moment(horse.insuranceDate.replace(/Z/, '')).toDate();
        }
        if (horse.coggingsCertificateExpiration) {
          vm.horse.coggingsCertificateExpiration = moment(horse.coggingsCertificateExpiration).toDate();
        }
        if (horse.organizationExpires) {
          vm.horse.organizationExpires = moment(horse.organizationExpires).toDate();
        }
        if (horse.feiPassportExpirationDate) {
          vm.horse.feiPassportExpirationDate = moment(horse.feiPassportExpirationDate, DATE_ISO_FORMAT).toDate();
        }
        if (horse.occupancyDate) {
          vm.horse.occupancyDate = moment(horse.occupancyDate).toDate();
        }
        if (horse.stallLocation) {
          vm.horse.existingStallLocation = horse.stallLocation;
        }

        vm.horseLoaded = true;

        imageService.getImage(horse.horseImageId).then(image =>{
          vm.horseImageLoaded = true;
          if (image) {
            vm.horseImage = image.data;
            originImage = copy(image.data);
          }
        }).finally(() => vm.horseImageLoaded = true);
        rAttachments.query({ modelType: 'HORSE_ORGANIZATION', modelId: vm.horse.id }, function(attachments) {
          vm.attachments = attachments;
          vm.attachmentsReady = true;
        }, function() {
          vm.attachmentsReady = true;
        });
        rAttachments.query({ modelType: 'HORSE', modelId: vm.horse.id }, function(attachments) {
          vm.files = attachments;
        });
      } else {
        vm.error = true;
      }
    } else {
      vm.horseLoaded = true;
      vm.horseImageLoaded = true;
    }
    yearRange();
  }
}
