import {Directive, ElementRef, Injector, Input} from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'bm-search-bar'
})
export class SearchBarNg2Directive extends UpgradeComponent {
  @Input() state: string;
  @Input() queryVarName: string;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('bmSearchBar', elementRef, injector);
  }
}
