import {barnManagerModule} from '../index.module';
import {merge} from 'lodash';

barnManagerModule
  .factory('entryRepository', ['$http', 'barnStorage', function($http, barnStorage) {

    const url = '/api/feeding/entries/';
    const urlAllHorses = '/api/feeding/entries/all-horses';

    return {
      search: search,
      add: add,
      addToAllHorses: addToAllHorses,
      find: find,
      remove: remove,
      update: update,
      exportToPdf: exportToPdf,
      exportToCsv: exportToCsv
    };

    function search(params) {
      const config = {
        params: mergeParams(params)
      };
      return $http.get(url, config).then(transformResult);
    }

    function add(body) {
      return $http.post(url, body).then(transformResult);
    }

    function addToAllHorses(body) {
      return $http.post(urlAllHorses, body).then(transformResult);
    }

    function find(id) {
      const config = {
        params: mergeParams({})
      };
      return $http.get(url + id, config).then(transformResult);
    }

    function update(body) {
      return $http.put(url + body.id, body).then(transformResult);
    }

    function remove(id) {
      return $http.delete(url + id).then(transformResult);
    }

    function transformResult(result) {
      return result.data;
    }

    function exportToPdf(params) {
      const config = {
        params: mergeParams(params)
      };

      return $http.get(url + 'exportToPdf', config, { transformResponse: [ (data: any) => data] }).then(transformResult);
    }

    function exportToCsv(params) {
      const config = {
        params: mergeParams(params)
      };

      return $http.get(url + 'exportToCsv', config, { transformResponse: [ (data: any) => data] }).then(transformResult);
    }

    function mergeParams(params) {
      const defaultParams = {
        tenantEnvironmentId: barnStorage.getEnvId()
      };
      return merge(defaultParams, params || {});
    }
  }]);
