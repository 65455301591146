import {Injectable} from '@angular/core';
import {RestService} from '@shared/lib/rest/rest.service';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Horse, HorseSearchParams} from './models';
import {AuthorizedStorage} from '../core/authorized';
import {BmBaseRecordResponse} from '../core/rest';
import {ResponseHandlerService} from '../core/response-handler/response-handler.service';

@Injectable({ providedIn: 'root' })
export class HorseService {
  constructor(
    private rest: RestService,
    private authorizedStorage: AuthorizedStorage,
    private responseHandlerService: ResponseHandlerService
  ) {
  }

  search(params: HorseSearchParams): Observable<BmBaseRecordResponse<Horse>> {
    return this.rest.get({
      path: 'horses', params: { ...params, tenantEnvironmentId: this.authorizedStorage.tenantEnvironmentId }
    }).pipe(
        catchError((error: HttpErrorResponse) => {
          this.responseHandlerService.emitHttpError(error);
          return throwError(error);
        })
    )
  }

  all(): Observable<BmBaseRecordResponse<Horse>> {
    return this.rest.get({
      path: 'horses', params: { archived: 0, page: 0, pagesize: 9999, tenantEnvironmentId: this.authorizedStorage.tenantEnvironmentId }
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.responseHandlerService.emitHttpError(error);
        return throwError(error);
      })
    )
  }
}
