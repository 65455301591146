import {barnManagerModule} from '../../index.module';

barnManagerModule
  .factory('Permission', ['PermissionTypes', 'AccessTypes', (PermissionTypes, AccessTypes) => {
    function Permission(permissionType, accessType) {
      if (!permissionType || !accessType) {
        throw new TypeError('Permission');
      }
      this.permissionType = permissionType;
      this.accessType = accessType;
    }

    Permission.prototype.asString = function() {
      return this.permissionType.name + ':' + this.accessType.name;
    };

    Permission.prototype.isDefault = function() {
      return this.accessType === this.permissionType.default();
    };

    Permission.fromString = function(value) {
      let elements = value.split(':');
      return new Permission(PermissionTypes[elements[0]], AccessTypes[elements[1]]);
    };

    return Permission;
  }]);
