import {barnManagerModule} from '../../index.module';
import {values} from 'lodash';

barnManagerModule
  .component('bmVendorSimplifiedForm', {
    templateUrl: 'vendor-simplified-form.html',
    controller: VendorSimplifiedFormController,
    controllerAs: 'vm',
    bindings: {
      predefinedVendorType: '<',
      addingVendor: '=',
      onBackLinkClick: '&',
      onAddVendorSuccess: '<'
    }
  });

VendorSimplifiedFormController.$inject = ['$scope', 'VendorTypes', 'MessageFrames', 'responseHandler', 'vendorRepository', 'barnStorage'];
function VendorSimplifiedFormController($scope, VendorTypes, MessageFrames, responseHandler, vendorRepository, barnStorage) {
  const vm = this;

  vm.vendorTypes = values(VendorTypes);
  vm.backLinkClick = backLinkClick;
  vm.save = save;

  init();

  function init() {
    $scope.$on('initiateSaveVendor', () => save());
    $scope.$watch('vm.predefinedVendorType', () => setPredefinedVendorType());
  }

  function setPredefinedVendorType() {
    if (vm.predefinedVendorType) {
      const predefinedValue = (vm.predefinedVendorType.type && vm.predefinedVendorType.type.value) || ( vm.predefinedVendorType.types && vm.predefinedVendorType.types[0].value);
      const vendorType = vm.vendorTypes.find(vendorType => vendorType.value === predefinedValue);
      if (vendorType) {
        vm.vendorType = vendorType;
      }
    }
  }

  function backLinkClick() {
    vm.onBackLinkClick();
  }

  function save() {
    if (vm.addingVendor) {
      return;
    }

    const form = $scope.vendorForm;
    if (!form.$valid) {
      form.$setDirty();
      form.$$controls.forEach(control => control.$setDirty());
      return;
    }

    vm.addingVendor = true;

    const barn = barnStorage.getEnv();
    vm.vendor.tenantEnvironmentId = barn.id;
    vm.vendor.country = 'USA';

    vm.vendor.vendorType = vm.vendorType.value;
    if (vm.vendor.vendorType !== 'OTHER') {
      vm.vendor.customType = null;
    }
    vm.vendor.archived = 0;
    vendorRepository.add(vm.vendor).then(done).catch(errorHandler).finally(() => vm.addingVendor = false);
  }

  function done(vendor) {
    vm.onAddVendorSuccess(vendor);
    responseHandler.successOnSave('Vendor', false);
  }

  function errorHandler(error) {
    return responseHandler.processError(error, null, MessageFrames.TOP);
  }
}
