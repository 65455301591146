import {barnManagerModule} from '../../../index.module';
import {flatten, map, values} from 'lodash';

barnManagerModule.controller('VendorsController', VendorsController);

VendorsController.$inject = [
  '$scope',
  'backLinkHistory',
  'responseHandler',
  'vendorRepository',
  'Vendor',
  'VendorTypes',
  'hasPermission'
];
function VendorsController(
  $scope,
  backLinkHistory,
  responseHandler,
  vendorRepository,
  Vendor,
  VendorTypes,
  hasPermission
) {
  const vm = this;
  backLinkHistory.pushLink('Vendors');

  vm.hasVendorsFullPermission = hasPermission('vendors:full');

  vm.vendors = [];
  vm.filteredUsers = [];

  vm.status = {
    loading: false,
    error: false
  };

  vm.keywords = ['vendorType', 'firstName', 'lastName', 'phonePrimary', 'email'];

  vm.sortBy = flatten(<any>[
    { name: 'All Vendors', value: {} },
    values(VendorTypes).map(vendorType => {
      return {
        name: vendorType.name,
        value: {
          vendorType: vendorType.value
        }
      };
    }),
    { name: 'Active', value: { archived: 0 } },
    { name: 'Archived', value: { archived: 1 } }
  ]);

  init();

  function init() {
    vm.status.error = false;
    vm.status.loading = true;
    vendorRepository.allIncludingArchived().then(function(vendors) {
      vm.vendors = map(vendors.records, function(vendorData) {
        return new Vendor(vendorData);
      });
    }).catch(function(error) {
      vm.status.error = true;
      return responseHandler.processError(error);
    }).finally(function() {
      vm.status.loading = false;
    });
  }

}
