import {barnManagerModule} from '../index.module';

barnManagerModule.factory('tagRepository', tenantRepository);

tenantRepository.$inject = ['$http'];
function tenantRepository($http) {
  const url = '/api/tags/v2';

  return {
    all: all,
    archive: archive,
    restore: restore
  };

  function all() {
    return $http.get(url).then(function(result) {
      return result.data;
    });
  }

  function archive(id) {
    return $http.put(`${url}/${id}/archive`).then(function(result) {
      return result.data;
    });
  }

  function restore(id) {
    return $http.put(`${url}/${id}/restore`).then(function(result) {
      return result.data;
    });
  }
}
