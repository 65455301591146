import {barnManagerModule} from '../index.module';

barnManagerModule.factory('imageService', ImageService);

ImageService.$inject = ['$q', 'imageRepository', '$window'];
function ImageService($q, imageRepository, $window) {
  const url = $window.URL || $window.webkitURL;

  return {
    getThumbnailBlob: getThumbnailBlob,
    getImage: getImage,
    getImageBlob: getImageBlob
  };

  function getThumbnailBlob(imageId, thumbnailId) {
    const deferred = $q.defer();
    if (imageId && thumbnailId) {
      imageRepository.find(thumbnailId).then(image => deferred.resolve(url.createObjectURL(new Blob([image.data])))).catch(error => deferred.reject(error));
    } else {
      deferred.reject();
    }
    return deferred.promise;
  }

  function getImage(imageId) {
    const deferred = $q.defer();
    if (imageId) {
      imageRepository.find(imageId).then(image => deferred.resolve(image)).catch(error => deferred.reject(error));
    } else {
      deferred.reject('');
    }
    return deferred.promise;
  }

  function getImageBlob(imageId) {
    return getImage(imageId).then(image => url.createObjectURL(new Blob([image.data])));
  }
}
