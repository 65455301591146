import {barnManagerModule} from '../index.module';
import {read, utils} from 'xlsx';
import {each, range} from 'lodash';

barnManagerModule.factory( 'XLSXReaderService', XLSXReader);

XLSXReader.$inject = ['$q'];
function XLSXReader($q) {
  'use strict';

  // var exports = exports || undefined;

  // Baseline setup
  // --------------

  // Establish the root object, `window` in the browser, or `exports` on the server.
  const root = this;

  // Save the previous value of the `XLSXReader` variable.
  // var previousXLSXReader = root.XLSXReader;


  // Create a safe reference to the XLSXReader object for use below.
  const XLSXReader = function(options) {
    const deferred = $q.defer();
    XLSXReader.utils.initializeFromFile(deferred, options.file, options.readCells, options.toJSON);
    return deferred.promise;
  };

  // Export the XLSXReader object for **Node.js**, with
  // backwards-compatibility for the old `require()` API. If we're in
  // the browser, add `XLSXReader` as a global object via a string identifier,
  // for Closure Compiler 'advanced' mode.
  // if (typeof exports !== 'undefined') {
  //   if (typeof module !== 'undefined' && module.exports) {
  //     exports = module.exports = XLSXReader;
  //   }
  //   exports.XLSXReader = XLSXReader;
  // } else {
  // }
  root.XLSXReader = XLSXReader;

  // Current version.
  XLSXReader.VERSION = '0.0.1';

  XLSXReader.utils = {
    initializeFromFile: function(deferred, file, readCells, toJSON) {
      const reader = new FileReader(), obj: any = {};

      reader.onload = function(e) {
        const data = e.target.result;
        const workbook = read(data, {
          type: 'binary'
        });

        obj.sheets = XLSXReader.utils.parseWorkbook(workbook, readCells, toJSON);
        deferred.resolve(obj);
      };

      reader.readAsBinaryString(file);
    },
    parseWorkbook: function(workbook, readCells, toJSON) {
      if (toJSON === true) {
        return XLSXReader.utils.toJson(workbook);
      }

      const sheets = {};

      each(workbook.SheetNames, function(sheetName) {
        const sheet = workbook.Sheets[sheetName];
        sheets[sheetName] = XLSXReader.utils.parseSheet(sheet, readCells);
      });

      return sheets;
    },
    parseSheet: function(sheet, readCells) {
      const rangeFn = utils.decode_range(sheet['!ref']);
      const sheetData = [];

      if (readCells === true) {
        each(range(rangeFn.s.r, rangeFn.e.r + 1), function(row) {
          const rowData = [];
          each(range(rangeFn.s.c, rangeFn.e.c + 1), function(column) {
            const cellIndex = utils.encode_cell({
              'c': column,
              'r': row
            });
            const cell = sheet[cellIndex];
            rowData[column] = cell ? cell.v : undefined;
          });
          sheetData[row] = rowData;
        });
      }

      return {
        'data': sheetData,
        'name': sheet.name,
        'col_size': rangeFn.e.c + 1,
        'row_size': rangeFn.e.r + 1
      };
    },
    toJson: function(workbook) {
      const result = {};
      workbook.SheetNames.forEach(function(sheetName) {
        const roa = utils.sheet_to_json(workbook.Sheets[sheetName]);
        if (roa.length > 0) {
          result[sheetName] = roa;
        }
      });
      return result;
    }
  };

  return XLSXReader;
}
