import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import {BmNgSelectModule} from '@shared-ui/bm-ng-select/bm-ng-select.module';
import {CommonModule} from '@angular/common';
import {NgOptionHighlightModule} from '@shared/lib/ng-select/ng-option-highlight/ng-option-highlight.module';

interface Color {
  name: string;
  uuid: string;
  color: string;
}

@Component({
  selector: 'bm-color-picker-select',
  templateUrl: './color-picker-select.component.html',
  styleUrls: ['./color-picker-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    BmNgSelectModule,
    NgOptionHighlightModule
  ]
})
export class ColorPickerSelectComponent {
  @Input() colors: Color[];
  @Input() selectedColor: string;
  @Input() selectedColorUuid: string;
  @Output() changeSelected: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('colorPickerLabelTmp', {static: true}) readonly colorPickerLabelTmp: TemplateRef<HTMLElement>;
  @ViewChild('colorPickerOptionTmp', {static: true}) readonly colorPickerOptionTmp: TemplateRef<HTMLElement>;
}
