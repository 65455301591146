import {barnManagerModule} from '../../index.module';
import {map} from 'lodash';

barnManagerModule
  .component('bmTimezoneSelect', {
    templateUrl: 'timezone-select.html',
    controller: timezoneSelectController,
    controllerAs: 'vm',
    bindings: {
      selected: '<',
      onChange: '<'
    }
  });

timezoneSelectController.$inject = ['ZoneToCC', 'CCToCountryName'];
function timezoneSelectController(ZoneToCC, CCToCountryName) {
  const vm = this;
  vm.$onInit = function() {
    vm.onChangeSelected = onChangeSelected;

    function onChangeSelected(item) {
      vm.onChange(item.name, item);
    }

    vm.items = map(ZoneToCC, function(cc, tz) {
      return {
        name: tz,
        country: CCToCountryName[cc],
        nameWithSpaces: tz.replace('_', ' ')
      };
    });
  };
}
