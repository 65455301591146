<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</button>

<h3 mat-dialog-title>{{ title }}</h3>

<mat-dialog-content class="mat-typography">
  <form [formGroup]="formGroup">
    <div *ngIf="!isSingleHorse" class="multiple-horses-settings">
      <label class="display-block" for="horse-select">
        <span class="label__text">
          Horses
          <button *ngIf="horses?.length > 0 && selectedHorses.enabled" type="button" class="bm-label-btn" title="Advanced Horse Select" (click)="openHorseAdvancedSelect()">
            <i class="fa fa-check"></i>Advanced select
          </button>
        </span>
        <ng-container *ngIf="selectedHorses.enabled">
          <shared-bm-ng-select id="horse-select"
                               [items]="horses"
                               [formControl]="selectedHorses"
                               [placeholder]="'Select horse(s)'"
                               [virtualScroll]="false"
                               [dropdownPosition]="'bottom'">
          </shared-bm-ng-select>
          <span *ngIf="!selectedHorses.valid && selectedHorses.touched" class="inline-error has-error">Select at least one horse</span>
        </ng-container>
      </label>

      <label for="all-horse-feed-report-modal-checkbox" class="input--cr input--cr--large all-horse-checkbox">
        <input type="checkbox" id="all-horse-feed-report-modal-checkbox" name="include-vet-records-checkbox" formControlName="allHorsesAccess">
        <span class="input--cr__ui"></span>
        <span class="input--cr__label">All Horses ({{ horses.length }})</span>
      </label>

      <label *ngIf="horsePerPage.enabled" for="horse-per-page-feed-report-modal-checkbox" class="input--cr input--cr--large all-horse-checkbox">
        <input type="checkbox" id="horse-per-page-feed-report-modal-checkbox" name="horse-per-page-feed-report-modal-checkbox" formControlName="horsePerPage">
        <span class="input--cr__ui"></span>
        <span class="input--cr__label">Single horse per page</span>
      </label>
    </div>

    <div class="flex-area">
      <div class="flex-area__percent-col--50">
        <div class="display-block label">
          <span class="label__text">File type</span>
          <div class="orientation">
<!--            <div class="form-group">-->
<!--              <label>-->
<!--                <input type="radio" [value]="FeedFileType.PDF" formControlName="fileType">-->
<!--                <span>PDF</span>-->
<!--              </label>-->
<!--            </div>-->
            <div class="form-group">
              <label>
                <input type="radio" [value]="FeedFileType.PDF_BE" formControlName="fileType">
                <span>PDF</span>
              </label>
            </div>
            <div class="form-group">
              <label>
                <input type="radio" [value]="FeedFileType.CSV" formControlName="fileType">
                <span>CSV</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-area">
      <div *ngIf="feedType" class="flex-area__percent-col--50">
        <div class="display-block label">
          <span class="label__text">Feed Types</span>
          <div class="entry-types">
            <label class="input--cr input--cr--large" *ngFor="let entryType of entryTypes;let i = index" formArrayName="selectedEntryTypes">
              <input type="checkbox" [formControlName]="i">
              <span class="input--cr__ui"></span>
              <span class="input--cr__label">{{ entryType.name }}</span>
            </label>
          </div>
        </div>
      </div>

      <div *ngIf="fontSize.enabled" class="flex-area__percent-col--50">
        <div class="display-block label">
          <span class="label__text">Font Size</span>
          <div class="font-size">
            <div class="form-group" *ngFor="let fontSize of fontSizes">
              <label>
                <input type="radio" [value]="fontSize.value" formControlName="fontSize">
                <span [ngStyle]="{'font-size': fontSize.value + 'px'}">{{ fontSize.name }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="orientation.enabled" class="flex-area">
      <div class="flex-area__percent-col--50">
        <div class="display-block label">
          <span class="label__text">Orientation</span>
          <div class="orientation">
            <div class="form-group">
              <label>
                <input type="radio" [value]="FeedPrintOrientation.PORTRAIT" formControlName="orientation">
                <span>Portrait</span>
              </label>
            </div>
            <div class="form-group">
              <label>
                <input type="radio" [value]="FeedPrintOrientation.LANDSCAPE" formControlName="orientation">
                <span>Landscape</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button type="button" mat-button mat-stroked-button color="primary" matRipple mat-dialog-close>Close</button>
  <button type="submit" mat-button mat-flat-button color="primary" matRipple matRippleColor="white" (click)="download()">Download</button>
</mat-dialog-actions>

