import {barnManagerModule} from '../../index.module';
import {copy, isUndefined} from 'angular';
import {find} from 'lodash';

barnManagerModule
  .component('bmContactSelect', {
    templateUrl: 'contact-select.html',
    controller: ContactSelectController,
    controllerAs: 'vm',
    bindings: {
      contactId: '<',
      contacts: '<',
      onChange: '<',
      baseName: '@',
      customColClass: '@',
      convertFromOriginal: '&?',
      convertToOriginal: '&?'
    }
  });

ContactSelectController.$inject = ['barnStorage', 'States', 'Countries', 'TerritorialUnits'];
function ContactSelectController(barnStorage, States, Countries, TerritorialUnits) {
  const vm = this;
  const defaultEmptyPerson  = {
    id: null,
    tenantEnvironmentId: barnStorage.getEnvId(),
    name: '',
    primaryContactMailingAddress: {
      street1: '',
      street2: '',
      city: '',
      country: null,
      state: null,
      zip: ''
    },
    primaryContactPrimaryPhone: '',
    primaryContactPrimaryEmail: ''
  };

  vm.contact = copy(defaultEmptyPerson);
  vm.originalContact = null;
  vm.states = States;
  vm.countries = Countries;
  vm.TerritorialUnits = TerritorialUnits;
  vm.setPerson = setPerson;

  vm.contacts = [];

  vm.getName = getName;
  vm.onCountrySelected = onCountrySelected;
  vm.onStateSelected = onStateSelected;
  vm.onFieldChange = onFieldChange;

  vm.$onInit = function() {
    if (isUndefined(vm.convertFromOriginal)) {
      vm.convertFromOriginal = function(args) {
        return args.original;
      };
    }
    if (isUndefined(vm.convertToOriginal)) {
      vm.convertToOriginal = function(args) {
        return args.contact;
      };
    }

    vm.colClass = vm.customColClass || 'flex-area__percent-col--50';
    vm.emptyPerson = vm.convertToOriginal({ contact: defaultEmptyPerson });
    if (vm.contactId) {
      vm.originalContact = find(vm.contacts, { id: Number(vm.contactId) });
      vm.contact = vm.convertFromOriginal({ original: vm.originalContact });
      vm.onChange(vm.originalContact);
    }
  };

  function getName(name) {
    return vm.baseName + name;
  }

  function onCountrySelected() {
    vm.contact = { ...vm.contact, primaryContactMailingAddress: {...vm.contact.primaryContactMailingAddress, state: null } };
    onFieldChange();
  }

  function onStateSelected(state: { key: string, value: string }) {
    vm.contact = { ...vm.contact, primaryContactMailingAddress: {...vm.contact.primaryContactMailingAddress, state: state.value } };
    onFieldChange();
  }

  function onFieldChange() {
    vm.originalContact = vm.convertToOriginal({ contact: vm.contact });
    vm.onChange(vm.originalContact);
  }

  function setPerson(person) {
    vm.originalContact = person ? { ...person } : null;
    vm.contact = vm.convertFromOriginal({ original: vm.originalContact });
    vm.onChange(vm.originalContact);
  }
}
