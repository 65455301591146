import {barnManagerModule} from '../../index.module';
import * as angular from 'angular';
import {sortBy} from 'lodash';

barnManagerModule.directive('barnSwitcher', barnSwitcher);

function barnSwitcher() {

  BarnSwitcherController.$inject = ['sessionService', 'userStorage', 'barnStorage', 'tenantRepository'];

  return {
    restricted: 'E',
    templateUrl: 'barn-switcher.html',
    controller: BarnSwitcherController,
    controllerAs: 'barn_switcher',
    bindToController: true
  };

  function BarnSwitcherController(
    sessionService,
    userStorage,
    barnStorage,
    tenantRepository
  ) {
    const vm = this, user = userStorage.getUser();

    vm.$onInit = function() {
      vm.showMenu = false;
      vm.barns = [];
      vm.initialBarn = vm.selectedBarn = barnStorage.getEnv();

      vm.setBarn = barn => {
        vm.selectedBarn = barn;
        if (vm.selectedBarn.tenant) {
          const barn = {...vm.selectedBarn, timeZone: vm.selectedBarn.tenant.timeZone};
          sessionService.updateEnv(barn).catch(() => vm.selectedBarn = vm.initialBarn);
        }
      };

      if (user.sysAdmin) {
        if (sessionService.isInitialized()) {
          //Get all barns
          vm.showMenu = true;
          tenantRepository.all().then(barns => {
            vm.barns = sortBy(barns, [(o) => o.name.toLocaleLowerCase()]).map(barn => {
              const te = angular.copy(barn.tenantEnvironments[0]);
              delete barn.tenantEnvironments;
              te.tenant = barn;
              return te;
            });
          });
        }
      }
    };
  }
}
