import {barnManagerModule} from '../../index.module';
import {isEmpty, size} from 'lodash';

barnManagerModule.directive('bmHorsesListBox', horsesListBox);

function horsesListBox() {
  return {
    restricted: 'E',
    templateUrl: 'horses-list-box.html',
    controller: HorsesListBoxController,
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      assignedHorses: '=',
      allHorsesAssigned: '='
    }
  };

  function HorsesListBoxController() {
    const vm = this;

    vm.$onInit = function() {

    vm.horsesString = '';

    init(vm.assignedHorses);

      function init(horses) {
        if (vm.allHorsesAssigned) {
          vm.horsesString = 'All horses';
        } else if (isEmpty(horses)) {
          vm.horsesString = 'No horses';
        } else if (size(horses) <= 3) {
          vm.horsesString = horses.join(', ');
        } else {
          vm.horsesString = horses.slice(0, 3).join(', ')
            + ' <b>and '
            + (horses.length - 3)
            + ' other horse'
            + (((horses.length - 3) > 1) ? 's' : '')
            + '</b>';
        }
      }

    };
  }
}
