import {Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'bm-clear-cache'
})
export class ClearCacheNg2Directive extends UpgradeComponent {
  constructor(elementRef: ElementRef, injector: Injector) {
    super('bmClearCache', elementRef, injector);
  }
}
