import {barnManagerModule} from '../index.module';
import {template} from 'lodash';

barnManagerModule
  .constant('PrintReports', {
      details: {
        templates: {
          htmlInput: 'horse-details-print-template.html'
        },
        getFileName: template('${horse.name} Details.pdf')
      },
      recordDetails: {
        cssPaths: [
          '/assets/styles/quill.bubble.css',
          '/assets/styles/quill.snow.css',
          '/assets/styles/quill.core.css',
          '/assets/styles/discharge-report-modal/quill-notes.css'
        ],
        templates: {
          htmlInput: 'record-details-print-template.html'
        },
        getFileName: template('${resource.title}.pdf')
      },
      discharge: {
        cssPaths: [
          '/assets/styles/quill.bubble.css',
          '/assets/styles/quill.snow.css',
          '/assets/styles/quill.core.css',
          '/assets/styles/feed-table/feed-table.css',
          '/assets/styles/discharge-report-modal/quill-notes.css'
        ],
        templates: {
          htmlInput: 'horse-discharge-report-print-template.html',
          headerInput: 'horse-discharge-report-header-template.html',
          footerInput: 'horse-discharge-report-footer-template.html'
        },
        getFileName: template('${horse.name} Discharge Report.pdf')
      },
      oneHorseFeed: {
        cssPaths: ['/assets/styles/feed-table/feed-table.css'],
        templates: {
          htmlInput: 'one-horse-entries-print-template.html'
        },
        getFileName: template('${horse.name} Feed Report.pdf')
      },
      multiHorsesFeed: {
        cssPaths: ['/assets/styles/feed-table/feed-table.css'],
        templates: {
          htmlInput: 'multi-horses-entries-print-template.html'
        },
        getFileName: template('Multiple Horses Feed Report.pdf')
      }
    });
