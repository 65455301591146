import {barnManagerModule} from '../../index.module';
import angular from 'angular';
import {find} from 'lodash';
import {nativeJs, ZonedDateTime} from '@js-joda/core';
import {CheckListItemState} from '../../resources/listRepository';

barnManagerModule.directive('bmCheckList', checkList);

function checkList() {
  let directive = {
    restricted: 'E',
    templateUrl: 'check-list.html',
    controller: CheckList,
    controllerAs: 'checkList',
    bindToController: true,
    scope : {
      items : '=',
      editMode: '=',
      disableCheckboxes: '=',
      onToggle: '&',
      allUsers: '=',
      showItemChanges: '='
    }
  };

  CheckList.$inject = ['rUser', 'barnStorage', 'hasPermission', 'dateTimeUpgraded']

  function CheckList(rUser, barnStorage, hasPermission, dateTimeUpgraded) {
    let vm = this;

    vm.$onInit = function() {

      vm.hasListsReadPermission = hasPermission('lists:read');
      vm.checkListItemState = CheckListItemState;
      vm.fromUTCDateToLocalDateTimeFormatted = dateTimeUpgraded.fromUTCDateToLocalDateTimeFormatted;

      vm.calendarOpen = false;
      vm.updateMode = false;
      vm.updateIndex = -1;
      vm.users = [];

      vm.addItem = addItem;
      vm.openCalendar = openCalendar;
      vm.getUser = getUser;
      vm.editItem = editItem;
      vm.deleteItem = deleteItem;
      vm.cancelUpdate = cancelUpdate;
      vm.updateItem = updateItem;

      loadUsers();

      function openCalendar() {
        vm.calendarOpen = true;
      }

      function addItem() {
        vm.items.push({
          state: vm.checkListItemState.INCOMPLETE,
          name: vm.itemName,
          dueOn: vm.dueOn ? ZonedDateTime.from(nativeJs(vm.dueOn)).toLocalDate().toJSON() : null,
          assignedToId: vm.assignee.value
        });
        clear();
      }

      function editItem(item, index) {
        vm.itemName = item.name;
        vm.dueOn = item.dueOn ? new Date(item.dueOn) : null;
        if (!item.assignedToId) {
          item.assignedToId = null;
        }
        vm.assignee = find(vm.users, user => user.value === item.assignedToId);

        vm.updateMode = true;
        vm.updateIndex = index;
      }

      function updateItem() {
        vm.items.splice(vm.updateIndex, 1, {
          state: vm.items[vm.updateIndex].state,
          name: vm.itemName,
          assignedToId: vm.assignee.value,
          dueOn: vm.dueOn ? ZonedDateTime.from(nativeJs(vm.dueOn)).toLocalDate().toJSON() : null
        });
        vm.updateIndex = -1;
        clear();
      }

      function cancelUpdate() {
        clear();
        vm.updateIndex = -1;
        vm.updateMode = false;
      }

      function deleteItem(index) {
        if (index === vm.updateIndex) {
          clear();
          vm.updateMode = false;
          vm.updateIndex = -1;
        }

        vm.updateIndex = vm.updateIndex < index ? vm.updateIndex : vm.updateIndex - 1;
        vm.items.splice(index, 1);
      }

      function clear() {
        vm.itemName = '';
        vm.dueOn = false;
        vm.calendarOpen = false;
        vm.assignee = vm.users[0];

        vm.updateMode = false;
        vm.updateIndex = -1;
      }

      function getUser(id) {
        return vm.users.filter(obj => obj.value == id)[0];
      }

      function loadUsers() {
        if (!vm.allUsers) {
          rUser.search({
            tenantEnvironmentId: barnStorage.getEnvId(),
            archived: false,
            pagesize: 999
          }).$promise.then(function(response) {
            initUsers(response.records);
          });
        } else {
          initUsers(vm.allUsers);
        }
      }

      function initUsers(users) {
        vm.users = [
          {label: 'Unassigned', value: null},
          ...users.map(user => ({label: user.displayName, value: user.id}))
        ];
        vm.assignee = vm.users[0];
      }
    };
  }

  return directive;
}
