<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</button>

<div mat-dialog-title>
  <h3>Horse Advanced Select</h3>

  <div *ngIf="(loaded$ | async) === true" class="field-container-row actions-buttons">
    <button type="button" class="bm-label-btn add-all-horses-link" [disabled]="horseSelection.hasValue() && allHorsesSelected()" (click)="$event.preventDefault(); selectAllHorses()">
      <i class="fa fa-check"></i>Add All Horses
    </button>
    <button type="button" class="bm-label-btn remove-all-horses-link" (click)="$event.preventDefault(); clearAllHorses()">
      <i class="fa fa-trash"></i>Clear All Horses
    </button>
  </div>
</div>

<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="(loaded$ | async) === false; else loaded">
    <div class="empty-state">
      <div class="empty-state__message">
        <p>Loading data...</p>
      </div>
    </div>
  </ng-container>
  <ng-template #loaded>
    <div class="items-container">
      <div class="category">
        <div *ngFor="let group of horseCategories$ | async | groupBy: 'type'" class="category__group">
          <h3>{{ group.key }}</h3>
          <div *ngFor="let category of group.value" class="item">
            <mat-checkbox color="primary"
                          class="checkbox"
                          [checked]="categoriesSelection.isSelected(category)"
                          [disabled]="category['horseIds'].length === 0"
                          (change)="$event ? toggleAllHorsesInCategory(category) : null"
                          [title]="category.name">
              <span class="item__label">{{ category.name }}</span><span class="badge">{{category['horseIds'].length}}</span>
            </mat-checkbox>
          </div>
        </div>
      </div>

      <div class="horse">
        <h3>Horses</h3>
        <div *ngFor="let horse of horses" class="item">
          <mat-checkbox color="primary"
                        class="checkbox"
                        [checked]="horseSelection.isSelected(horse.id)"
                        [disabled]="!!horse.archived"
                        (change)="$event ? horseSelection.toggle(horse.id) : null"
                        [title]="horse.name">
            {{ horse.name }}
          </mat-checkbox>
        </div>
      </div>
    </div>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button type="button" mat-button mat-stroked-button color="primary" matRipple mat-dialog-close>Close</button>
  <button type="submit" mat-button mat-flat-button color="primary" matRipple matRippleColor="white" [disabled]="(loaded$ | async) === false" (click)="addSelection()">Add selection</button>
</mat-dialog-actions>



