import {barnManagerModule} from '../index.module';

barnManagerModule
  .factory('colorsRepository', ['$http', ($http) => {

    return {
      all: all,
      add: add,
      update: update,
      remove: remove,
    };

    function all() {
      return $http.get('/api/colors').then(transformResult);
    }

    function add(color) {
      return $http.post('/api/colors', color).then(transformResult);
    }

    function update(color) {
      return $http.post('/api/colors', color).then(transformResult);
    }

    function remove(colorId) {
      return $http.delete(`/api/colors/${colorId}`).then(transformResult);
    }

    function transformResult(result) {
      return result.data;
    }
  }]);
