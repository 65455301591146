import {barnManagerModule} from "../index.module";
import {HorseStallLocation} from "../../app/horse/horse-stall-location";

barnManagerModule.factory('horseStallLocationRepository', HorseStallLocationRepository);

HorseStallLocationRepository.$inject = ['$http', 'barnStorage'];
function HorseStallLocationRepository($http, barnStorage) {
  const url = '/api/horse-stall-locations';

  return {
    all: all,
    archive: archive,
    restore: restore
  };

  function all(): Promise<HorseStallLocation[]> {
    return $http.get(url, {
      params: {
        tenantEnvironmentId: barnStorage.getEnvId()
      }
    }).then(results => results.data);
  }

  function archive(id: string): Promise<void> {
    return $http.put(`${url}/${id}/archive`).then(result => result.data);
  }

  function restore(id: string): Promise<void> {
    return $http.put(`${url}/${id}/restore`).then(result => result.data);
  }
}
