import {barnManagerModule} from '../index.module';
import angular from 'angular';

barnManagerModule.provider('pathTo', <angular.IServiceProviderFactory>pathToService);

function pathToService() {
  const path = {};

  this.$get = function() {
    return function(folder, component) {
      return path[folder].path + component + '/' + component + '.' + path[folder].templateName;
    };
  };

  this.folder = function(key, obj) {
    path[key] = obj;
    return this;
  };
}
