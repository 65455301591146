import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {ENVIRONMENT} from '@shared-models/environment-type';
import {RestModule} from '@shared/lib/rest/rest.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {GoNativeService, IS_GO_NATIVE} from '@shared/lib/go-native/go-native.service';
import {Router} from '@angular/router';
import {UpgradeModule} from '@angular/upgrade/static';
import {IntercomModule} from '@shared/lib/intercom';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {Ng1Module} from './ng1/ng1.module';
import {environment} from '@environment';
import {AuthorizedInterceptor} from './authorized';
import {performRedirectOnAppInitialize} from './redirects';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    NgxWebstorageModule.forRoot({
      prefix: '',
      separator: '',
      caseSensitive: true
    }),
    RestModule,
    UpgradeModule,
    IntercomModule.forRoot({
      appId: environment.intercomSettings.appId,
      updateOnRouterChange: true
    }),
    Ng1Module
  ],
  exports: [
    Ng1Module
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizedInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: performRedirectOnAppInitialize,
      deps: [Router],
      multi: true
    },
    {
      provide: '$scope',
      useExisting: '$rootScope'
    },
    {
      provide: ENVIRONMENT,
      useValue: environment
    },
    {
      provide: IS_GO_NATIVE,
      useValue: navigator.userAgent.indexOf('gonative') > -1
    },
    GoNativeService
  ]
})
export class CoreModule {
}
