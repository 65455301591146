import {barnManagerModule} from '../../index.module';

barnManagerModule.directive('bmCommentsCount', commentsCount);

function commentsCount() {

  CommentsCount.$inject = ['$scope']

  return {
    restricted: 'E',
    templateUrl: 'comments-count.html',
    controller: CommentsCount,
    controllerAs: 'commentsCount',
    scope: {
      numberComments: '='
    }
  };

  function CommentsCount($scope) {
    const vm = this;

    vm.numberComments = $scope.numberComments;
  }
}
