import {barnManagerModule} from '../index.module';

barnManagerModule.factory('htmlGenerator', HtmlGenerator);

HtmlGenerator.$inject = ['$state'];
function HtmlGenerator($state) {

  return {
    link: link
  };

  function link(linkName, linkParams, linkText) {
    const href = $state.href(linkName, linkParams, linkText);
    return '<a href="' + href + '">' + linkText + '</a>';
  }

}
