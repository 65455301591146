import {AfterViewInit, ChangeDetectionStrategy, Component, HostBinding, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AllHorseCategories, HorseCategoryHttpService} from '../horse-category';
import {Horse} from '../models';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatRippleModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject, of, zip} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {HorseLocation, HorseLocationHttpService} from '../horse-location';
import {SharedPipesModule} from '@shared/lib/pipes/shared-pipes.module';

export interface HorseAdvancedSelectModalData {
  selected: Horse[];
  horses: Horse[];
  selectedByUserCategories: HorseCategoryItem[];
}

enum HorseCategoryItemType {
  Status = 'Statuses',
  Health = 'Healths',
  Function = 'Functions',
  Location = 'Locations',
  Type = 'Types'
}

export interface HorseCategoryItem {
  name: string;
  type: HorseCategoryItemType;
  value: string;
  horseIds?: number[];
}

@Component({
  selector: 'bm-horse-advanced-select',
  templateUrl: './horse-advanced-select.component.html',
  styleUrls: ['./horse-advanced-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatRippleModule,
    MatIconModule,
    MatCheckboxModule,
    SharedPipesModule,
    MatButtonModule
  ]
})
export class HorseAdvancedSelectComponent implements OnInit, AfterViewInit {
  @HostBinding('class') cssClass = 'bm-horse-advanced-select';
  horseSelection: SelectionModel<number> = new SelectionModel<number>(true, []);
  categoriesSelection: SelectionModel<HorseCategoryItem> = new SelectionModel<HorseCategoryItem>(true, []);
  horseCategories$: BehaviorSubject<HorseCategoryItem[]> = new BehaviorSubject<HorseCategoryItem[]>([]);
  loaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get horses(): Horse[] {
    return this.data.horses;
  }

  get horseIds(): number[] {
    return this.horses.map(horse => horse.id);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: HorseAdvancedSelectModalData,
    private dialogRef: MatDialogRef<HorseAdvancedSelectComponent>,
    private horseCategoryHttpService: HorseCategoryHttpService,
    private horseLocationHttpService: HorseLocationHttpService
  ) {
  }

  ngOnInit(): void {
    if (this.data.selected?.length > 0) {
      this.horseSelection.select(...this.data.selected.map(horse => horse.id));
    }
    this.initHorseCategories();
  }

  ngAfterViewInit() {
    const overlayPaneElement = this.dialogRef._containerInstance['_overlayRef']._pane;
    if (overlayPaneElement) {
      overlayPaneElement.scrollIntoView();
    }
  }

  allHorsesSelected(): boolean {
    const numSelected = this.horseSelection.selected.length;
    const numRows = this.horseIds.length;
    return numSelected === numRows;
  }

  selectAllHorses(): void {
    this.horseSelection.select(...this.horseIds);
  }

  clearAllHorses(): void {
    if (this.horseSelection.hasValue()) {
      this.horseSelection.clear();
      this.categoriesSelection.clear();
    }
  }

  toggleAllHorsesInCategory(category: HorseCategoryItem): void {
    this.categoriesSelection.toggle(category);
    if (!this.categoriesSelection.isSelected(category)) {
      this.horseSelection.deselect(...category.horseIds);
      return;
    }
    this.horseSelection.select(...category.horseIds);
  }

  addSelection(): void {
    this.dialogRef.close([this.getSelectedHorses(), this.categoriesSelection.selected]);
  }

  private getSelectedHorses(): Horse[] {
    let selectedHorses: Horse[] = [];
    if (this.horseSelection.hasValue()) {
      selectedHorses = this.horses.filter(horse => this.horseSelection.selected.includes(horse.id));
    }
    return selectedHorses;
  }

  private initHorseCategories(): void {
    zip(
      this.horseCategoryHttpService.all().pipe(catchError(() => of([]))),
      this.horseLocationHttpService.all().pipe(catchError(() => of([])))
    ).pipe(
      map(([categories, locations]) => {
        return [ ...this.mapHorseCategories(<AllHorseCategories>categories), ...this.mapHorseLocations(<HorseLocation[]>locations), ...this.getHorseTypes() ];
      }),
      map(categories => {
        return categories.map(category => ({...category, horseIds: this.getHorsesIdsByCategoryType(this.horses, category.type, category.value)}))
      })
    ).subscribe(categories => {
      this.horseCategories$.next(categories);
      this.categoriesSelection.select(...this.mapSelectedByUserCategoriesToCurrentCategories(categories));
      this.loaded$.next(true)
    });
  }

  private mapHorseCategories(categories: AllHorseCategories): HorseCategoryItem[] {
    let categoriesForFilter = [];
    const mapCategory = (type: HorseCategoryItemType, values: string[]) => {
      categoriesForFilter = [
        ...categoriesForFilter,
        ...values.sort((a, b) => a.localeCompare(b)).map(category => {
          return { name: category, type, value: category };
        })
      ];
    };
    for (const [key, values] of Object.entries(categories)) {
      switch (key) {
        case 'statuses': {
          mapCategory(HorseCategoryItemType.Status, values);
          break;
        }
        case 'healths': {
          mapCategory(HorseCategoryItemType.Health, values);
          break;
        }
        case 'functions': {
          mapCategory(HorseCategoryItemType.Function, values);
          break;
        }
      }
    }

    return categoriesForFilter;
  }

  private mapHorseLocations(locations: HorseLocation[]): HorseCategoryItem[] {
    return locations.map(location => ({name: location.location, type: HorseCategoryItemType.Location, value: location.location}));
  }

  private getHorseTypes(): HorseCategoryItem[] {
    return [
      { name: 'Gelding', type: HorseCategoryItemType.Type, value: 'GELDING' },
      { name: 'Mare', type: HorseCategoryItemType.Type, value: 'MARE' },
      { name: 'Stallion', type: HorseCategoryItemType.Type, value: 'STALLION' }
    ];
  }

  private getHorsesIdsByCategoryType(horses: Horse[], categoryType: HorseCategoryItemType, value: string): number[] {
    return horses.filter(horse => {
      switch (categoryType) {
        case HorseCategoryItemType.Status: {
          return horse.categories.statuses.includes(<string>value);
        }
        case HorseCategoryItemType.Health: {
          return horse.categories.healths.includes(<string>value);
        }
        case HorseCategoryItemType.Function: {
          return horse.categories.functions.includes(<string>value);
        }
        case HorseCategoryItemType.Location: {
          return horse.locations.includes(<string>value);
        }
        case HorseCategoryItemType.Type: {
          return horse.type === <string>value;
        }
      }
    }).map(horse => horse.id);
  }

  private mapSelectedByUserCategoriesToCurrentCategories(currentCategories: HorseCategoryItem[]): HorseCategoryItem[] {
    let selectedCategoriesByUser = [];
    if (!this.data.selectedByUserCategories) {
      return selectedCategoriesByUser;
    }
    this.data.selectedByUserCategories.forEach(selectedCategoryByUser => {
      const category = currentCategories.find(c => c.type === selectedCategoryByUser.type && c.value === selectedCategoryByUser.value);
      if (category) {
        selectedCategoriesByUser.push(category);
      }
    });

    return selectedCategoriesByUser;
  }
}
