import {barnManagerModule} from '../../../index.module';
import {get} from 'lodash';

barnManagerModule.factory('usefUtils', usefUtils);

usefUtils.$inject = ['usefStatuses', 'usefMessages'];
function usefUtils(usefStatuses, usefMessages) {
  return {
    humanReadableStatus: humanReadableStatus,
    mapStatusToMessage: mapStatusToMessage
  };

  function humanReadableStatus(status) {
    return get(usefStatuses, status);
  }

  function mapStatusToMessage(response) {
    if (!response.status) {
      return usefMessages.serverError;
    }
    switch (response.status) {
      case 'EXISTS_ALREADY':
        const link = '<a href=' + response.horseId + '"/n/horses/details/">' + response.usefHorseId + '</a>';
        return {
          text: 'Horse with USEF id ' + link + ' already exists',
          type: 'alert'
        };
      case 'CONSENT_REQUIRED':
        const text = 'Horse owner has to provide data sharing consent. ' +
          'We have requested it access from the horse owner, and horse owner should receive an email from USEF shortly. ' +
          'Or he can provide you consent manually on <a href="https://members.usef.org/preferences/barn-manager/data-sharing">USEF page</a>';
        return {
          text: text,
          type: 'alert'
        };
      case 'CONSENT_REQUESTED':
        return {
          text: 'The request has already been sent but not reviewed by the owner',
          type: 'alert'
        };
      case 'IMPORTED':
        return {
          text: 'Horse ' + response.usefHorseId + ' imported successfully!'
        };
      case 'CAN_BE_IMPORTED':
        return {
          text: 'Horse ' + response.usefHorseId + ' can be imported'
        };
      case 'USEF_ERROR':
        return {
          text: 'A horse ' + response.usefHorseId + ' could not be found. We cannot import horses if an owner is a California resident or a minor. If you think it is an error - contact us.',
          type: 'error'
        };
      case 'CONSENT_CANNOT_BE_GRANTED_OWNER_IS_MINOR':
        return {
          text: 'Consent cannot be granted. Owner is a minor.',
          type: 'error'
        };
      default:
        return {
          text: humanReadableStatus(response.status),
          type: 'error'
        };
    }
  }

}
