import {barnManagerModule} from '../../index.module';
import {isEmpty, merge} from 'lodash';

barnManagerModule.controller('SignupController', SignupController);

SignupController.$inject = ['StripeCheckout', '$rootScope', 'billingPlanRepository', 'rOnboarding', 'titleService', 'goNative'];
function SignupController(StripeCheckout, $rootScope, billingPlanRepository, rOnboarding, titleService, goNative) {
  const vm = this;
  $rootScope.$broadcast('$showLoader', false);
  titleService.setTitle('Sign Up');
  $rootScope.pageConf.class = 'page--barn';
  $rootScope.pageConf.htmlTagClass = 'entry-page';
  vm.message = '';
  vm.submitAttempt = false;
  vm.message = '';
  vm.signupProcess = signupProcess;
  vm.barn = {};
  vm.showThankYou = false;
  vm.plans = [];
  vm.plan = {};
  vm.isGoNative = goNative.isGoNative();

  load();

  function load() {
    if (vm.isGoNative) {
      return;
    }
    billingPlanRepository.all().then(function(plans) {
      vm.plans = plans;
      vm.plans.push({
        'id' : 'monthly_small',
        'amount' : 4000,
        'name': 'Legacy monthly small'
      });
      vm.plan = vm.plans[0];
    });
  }

  function signupProcess(form) {
    vm.loading = true;
    vm.message = '';
    if (!form.$valid) {
      vm.loading = false;
      vm.submitAttempt = true;
      return;
    }

    StripeCheckout.configure().open({
      name: 'Barn Manager',
      image: '/assets/icons/android-chrome-144x144.png',
      description: vm.plan.name + ' Subscription',
      amount: 0, //vm.plan.amount,
      panelLabel: 'Start Free Trial',
      token: function(token) {
        // Send token to backend
        const createBarnRequest = {
          barnName: vm.barn.barnName,
          customerFirstName: vm.barn.firstName,
          customerLastName: vm.barn.lastName,
          customerEmail: token.email,
          spt: token.id,
          planId: vm.plan.id,
          usefId: vm.usefId
        };
        if (!isEmpty(vm.couponId)) {
          merge(createBarnRequest, { couponId: vm.couponId });
        }

        rOnboarding.save(createBarnRequest).$promise.then(function() {
          vm.showThankYou = true;
        }).catch(function(error) {
          vm.message = error.data.message;
          vm.loading = false;
          vm.submitAttempt = true;
        });
      }
    }).then(function() { //result
      vm.loading = false;
      // Do UI changes
      //alert('Got Stripe token: ' + result[0].id);
    }, function() {
      vm.loading = false;
      //alert('Stripe Checkout closed without making a sale :(');
    });
  }
}
