import {barnManagerModule} from '../../../index.module';

barnManagerModule
  .component('bmListActionsMenu', {
    templateUrl: 'list-actions-menu.html',
    controller: listActionsMenuController,
    controllerAs: 'vm',
    bindings: {
      list: '=',
      horseId: '=',
      showPrint: '=',
      onSuccessArchive: '<',
      onSuccessRestore: '<',
      onSuccessRemove: '<'
    }
  });

listActionsMenuController.$inject = [
  'hasPermission',
  'barnListService',
  'goNative',
  'listRepository',
  'FileOpener',
  'responseHandler',
  'confirmDialog',
  'htmlGenerator'
];
function listActionsMenuController(
  hasPermission,
  barnListService,
  goNative,
  listRepository,
  FileOpener,
  responseHandler,
  confirmDialog,
  htmlGenerator
) {
  const vm = this;

  vm.hasAdminFullPermission = hasPermission('admin:full');
  vm.hasListsFullPermission = hasPermission('lists:full');
  vm.isGoNative = goNative.isGoNative();

  vm.duplicate = duplicate;
  vm.print = print;
  vm.exportToCsv = exportListToCsv;
  vm.toggleArchive = toggleArchive;
  vm.remove = remove;

  function duplicate() {
    barnListService.duplicate(vm.list.id);
  }

  function print() {
    window.print();
  }

  function exportListToCsv() {
    listRepository.exportToCsv(vm.list.id).then(function(data) {
      const fileName = vm.list.title ? vm.list.title.replace(/\s+/g, '_').toLowerCase() : 'export';
      FileOpener(data, `${fileName}.csv`);
    }).catch(responseHandler.processError);
  }

  function toggleArchive() {
    if (!vm.list.archived) {
      confirmDialog.open('Are you sure you want to archive this list?').then(function() {
        doToggleArchive();
      }).catch(function() {});
    } else {
      doToggleArchive();
    }
  }

  function doToggleArchive() {
    vm.list.archived = !vm.list.archived;

    if (vm.list.archived) {
      vm.archiving = true;
      let entityLink;
      if (vm.horseId > 0) {
        entityLink = htmlGenerator.link('listHorseDetails', { id: vm.horseId, listId: vm.list.id, author: vm.list.authorId }, vm.list.title);
      } else {
        entityLink = htmlGenerator.link('listDetails', { id: vm.list.id, author: vm.list.authorId }, vm.list.title);
      }
      listRepository.archive(vm.list.id).then(function() {
        if (vm.onSuccessArchive) {
          vm.onSuccessArchive();
        }
        responseHandler.successMessage('You successfully archived ' + entityLink + '.');
      }).finally(function() {
        vm.archiving = false;
      });
    } else {
      listRepository.restore(vm.list.id).then(function() {
        if (vm.onSuccessRestore) {
          vm.onSuccessRestore();
        }
        responseHandler.successMessage('You successfully unarchived ' + vm.list.title + '.');
      }).finally(function() {
        vm.archiving = false;
      });
    }
  }

  function remove() {
    confirmDialog.open('Are you sure you want to delete this list?').then(function() {
      listRepository.remove(vm.list.id).then(function() {
        if (vm.onSuccessRemove) {
          vm.onSuccessRemove();
        }
        responseHandler.successMessage('List successfully deleted');
      }).catch(responseHandler.processError);
    }).catch(function() {});
  }
}
