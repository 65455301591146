import {barnManagerModule} from '../../index.module';
import {isNil} from 'lodash';

barnManagerModule.directive('bmPreviewRecordDetailsCard', previewRecordDetailsCard);

function previewRecordDetailsCard() {

  PreviewRecordDetailsCardController.$inject = ['$element', 'utils', 'titlesService'];

  return {
    restricted: 'E',
    templateUrl: 'preview-record-details-card.html',
    controller: PreviewRecordDetailsCardController,
    controllerAs: 'vm',
    bindToController: true,
    transclude: true,
    scope: {
      item: '=',
      recordType: '='
    }
  };

  function PreviewRecordDetailsCardController($element, utils, titlesService) {
    const vm = this;

    vm.timezone = utils.timezone;
    vm.addHours = utils.addHours;
    vm.getInitials = titlesService.getInitialsLabel;

    vm.canShowVendor = canShowVendor;

    function canShowVendor() {
      if (isNil(vm.recordType) || isNil(vm.item.vendor)) {
        return false;
      }

      return vm.recordType.vendorRestriction.filter(vm.item.vendor);
    }
  }
}
