import {barnManagerModule} from '../index.module';

barnManagerModule
  .factory('feedingChangeRepository', ['$http', ($http) =>{
    const url = '/api/feeding/feeding-changes';

    return {
      search: search
    };

    function search(horseId) {
      return $http.get(url, {
        params: {
          horseId: horseId
        }
      }).then(function(results) {
        return results.data;
      });
    }
  }]);

