import {barnManagerModule} from '../../index.module';

barnManagerModule.directive('bmToggleSwitch', buttonLoader);

function buttonLoader() {
  return {
    restricted: 'E',
    templateUrl: 'toggle-switch.html',
    controller: ToggleSwitch,
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      'checked': '='
    }
  };

  function ToggleSwitch() {
  }
}
