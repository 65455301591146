import {ChangeDetectionStrategy, Component, Inject, Input, ViewEncapsulation} from '@angular/core';
import {IS_GO_NATIVE} from '../go-native.service';
import {windowOpen} from '../../utils/window-open';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'shared-go-native-external-link',
  templateUrl: './go-native-external-link.component.html',
  styleUrls: ['./go-native-external-link.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule
  ]
})
export class GoNativeExternalLinkComponent {
  @Input() customUrl: string;
  host: string = window.location.host;

  get url(): string {
    return `${this.href}?goNativeExternal=true`;
  }

  get href(): string {
    return this.customUrl ?? window.location.href;
  }

  constructor(
    @Inject(IS_GO_NATIVE) public isGoNative: boolean
  ) {
    console.log(window.location);
  }

  redirectToBrowser(): void {
    windowOpen(this.url);
  }
}
