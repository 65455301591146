import {barnManagerModule} from '../index.module';

barnManagerModule
  .factory('rConversations', ['$resource', ($resource) =>{

    return  $resource('/api/conversations', {
      }, {
        'query':  {
          method: 'GET'
        }
      });
  }]);
