import {barnManagerModule} from '../index.module';

barnManagerModule
  .factory('imageRepository', ['$http', ($http) =>{
    return {
      find: find
    };

    function find(id) {
      const config = {
        responseType: 'arraybuffer'
      };
      return $http.get('/api/images/' + id, config).then(function(result) {
        return {
          data: result.data
        };
      });
    }

  }]);
