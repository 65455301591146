import {barnManagerModule} from '../../../index.module';
import {find, forEach, isNil, join, map} from 'lodash';
import {copy} from 'angular';

barnManagerModule.controller('ContactDetailsController', ContactDetailsController);

ContactDetailsController.$inject = [
  '$state',
  '$rootScope',
  'backLinkHistory',
  'rContacts',
  'horseRepository',
  'userRepository',
  'rAttachments',
  'responseHandler',
  'DefaultAvatars',
  'ContactRoles',
  'utils',
  '$q',
  'goNative',
  'titleService',
  'hasPermission'
];
function ContactDetailsController(
  $state,
  $rootScope,
  backLinkHistory,
  rContacts,
  horseRepository,
  userRepository,
  rAttachments,
  responseHandler,
  DefaultAvatars,
  ContactRoles,
  utils,
  $q,
  goNative,
  titleService,
  hasPermission
) {
  const vm = this;

  vm.hasContactsFullPermission = hasPermission('contacts:full');
  vm.isGoNative = goNative.isGoNative();
  vm.attachmentsReady = false;
  vm.error = null;
  vm.loaded = false;
  vm.roles = ContactRoles;
  vm.DefaultAvatars = DefaultAvatars;

  vm.pinContact = pinContact;
  vm.onSuccessArchive = onSuccessArchive;

  function pinContact() {
    rContacts.toggleContactPin({ id: vm.contact.id }).$promise.then(function() {
      vm.contact.pinned = !vm.contact.pinned;
    });
  }

  function onSuccessArchive() {
    $state.go('contacts');
  }

  function buildAddress(address) {
    if (isNil(address)) {
      return '';
    }

    const addressParts = [];
    forEach(Object.keys(address), function(key) {
      if (!isNil(address[key]) && address[key] !== '') {
        addressParts.push(address[key]);
      }
    });

    return join(addressParts, ', ');
  }

  function init() {
    titleService.setTitle('Contact Details');
    $rootScope.pageConf.class = 'page--interior';

    const currentStateName = $state.current.name;

    if (currentStateName === 'contactDetails' && (isNaN($state.params.id) || !$state.params.id)) {
      $state.go('contacts');
      return;
    }

    $q.all({
      attachments: rAttachments.query({ modelType: 'CONTACT', modelId: $state.params.id }).$promise,
      users: userRepository.all(),
      horses: horseRepository.allIncludingArchived(),
      contact: rContacts.get({ contactId: $state.params.id }).$promise
    }).then(function(result) {
      backLinkHistory.pushLink(result.contact.name + '\'s details');
      vm.attachments = result.attachments;
      vm.attachmentsReady = true;

      const allUsers = result.users;
      const horses = result.horses;
      const contact = copy(result.contact);

      contact.mailingAddress = buildAddress(contact.primaryContactMailingAddress);
      if (contact.primaryContactBillingAddressSameAsMailing) {
        contact.billingAddress = contact.mailingAddress;
      } else {
        contact.billingAddress = buildAddress(contact.primaryContactBillingAddress);
      }

      contact.horseOwnerships = map(contact.horseOwnerships, function(horseOwnership) {
        horseOwnership.horse = find(horses, { id: horseOwnership.horseId });

        return horseOwnership;
      });
      contact.horseOwnerships = utils.sortByCaseInsensitive(contact.horseOwnerships, 'name');

      contact.memberships = map(contact.memberships, function(membership) {
        membership.reminders = map(membership.reminders, function(reminder) {
          reminder.recipientNames = map(reminder.recipientIds, (id) => find(allUsers, { id: id })?.displayName);

          return reminder;
        });

        return membership;
      });

      titleService.setTitle(contact.name + ' :: Contact Details');

      vm.contact = contact;
      vm.loaded = true;

      return result.contact;
    })
    .catch(function(error) {
      return responseHandler.handleNotFoundOrAccess(error, 'contact', function(errorMessage) {
        vm.error = errorMessage;
        vm.loaded = true;
      });
    });
  }

  init();
}
