import {barnManagerModule} from '../../index.module';

barnManagerModule.controller('UserProfileController', UserProfileController);

UserProfileController.$inject = [
  '$scope',
  'backLinkHistory',
  'ScrollToMessageFrame',
  'responseHandler',
  'sessionService',
  'userStorage',
  'rUser',
  'PATTERNS',
  'rModelLists',
  'NotificationsReceiveChannelType',
  'NotificationsReceiveChannelAdapter'
];
function UserProfileController(
  $scope,
  backLinkHistory,
  ScrollToMessageFrame,
  responseHandler,
  sessionService,
  userStorage,
  rUser,
  PATTERNS,
  rModelLists,
  NotificationsReceiveChannelType,
  NotificationsReceiveChannelAdapter
) {
  const vm = this;

  vm.notificationsReceiveChannelType = NotificationsReceiveChannelType;
  vm.user = {};
  vm.passwordPattern = PATTERNS.password;
  vm.emailPattern = PATTERNS.email;
  vm.newHorseNotificationsEnabled = false;
  vm.newWhiteboardNotificationsEnabled = false;
  vm.tenantHorses = [];
  vm.status = {
    updating: false,
    submitAttempt: false
  };
  vm.updatePassword = false;

  vm.updateProfile = updateProfile;
  vm.updatePreferences = updatePreferences;
  init();

  function watchProfileFormInit() {
    $scope.$watch('profileForm', (function(profileForm) {
      if (profileForm && profileForm.email && vm.user.email) {
        profileForm.email.$setDirty();
      }
    }));
  }

  function watch() {
    $scope.$watch('vm.user.email', (function(scope) {
      if (!scope.profileForm.email.$valid && scope.profileForm.email.$error.uniqueEmail) {
        scope.profileForm.email.$setValidity('uniqueEmail', true);
        scope.profileForm.email.$setDirty();
      }
    }).bind(vm, $scope));
  }

  function updateProfile(form) {
    if (!form.$valid) {
      vm.updating = false;
      return;
    }

    let updatedUser;

    vm.status.updating = true;
    vm.status.submitAttempt = true;

    form.email.$setValidity('uniqueEmail', true);

    updatedUser = {
      id: vm.user.id,
      firstName: vm.user.firstName,
      lastName: vm.user.lastName,
      email: vm.user.email,
      tenantId: vm.user.getBarn().id,
      roleNames: ['Admin'],
      archived: vm.user.archived,
      sysAdmin: vm.user.sysAdmin,
      allHorsesAccess: vm.user.allHorsesAccess
    };

    if (form.confirmPassword) {
      if (form.confirmPassword.$valid && form.confirmPassword.$dirty && vm.updatePassword) {
        updatedUser.password = vm.confirmPassword;
      }
    }

    rUser.update({ id: vm.user.id }, updatedUser).$promise.then(done).catch(errorHandler.bind(vm, form));
  }

  function done(user) {
    vm.status.updating = false;
    sessionService.clean();
    sessionService.updateUser(user);
    responseHandler.successOnSave('Profile', true);
  }

  function errorHandler(form, error) {
    vm.status.updating = false;
    const isUniqueEmailError = error.data.message.indexOf('Duplicated username') > -1;
    ScrollToMessageFrame();
    responseHandler.processError(error, textTransform);
    if (isUniqueEmailError) {
      form.email.$setValidity('uniqueEmail', false);
    }
  }

  function textTransform(data) {
    const text = data.message.replace('Duplicated username', 'This email address is already in use.');
    return 'User was not saved. Error: ' + text;
  }

  function updatePreferences() {
    const preferences = {
      userId: vm.user.id,
      pushNotificationEnabled: NotificationsReceiveChannelAdapter.isPropertyEnabled(vm.notificationsReceiveChannel, vm.notificationsReceiveChannelType.PUSH),
      emailNotificationEnabled: NotificationsReceiveChannelAdapter.isPropertyEnabled(vm.notificationsReceiveChannel, vm.notificationsReceiveChannelType.EMAIL),
      newHorseNotificationsEnabled: vm.newHorseNotificationsEnabled,
      newWhiteboardNotificationsEnabled: vm.newWhiteboardNotificationsEnabled
    };

    rModelLists.updateUserPreferences(preferences);
  }

  function init() {
    watch();
    watchProfileFormInit();
    vm.user = userStorage.getUser();
    backLinkHistory.pushLink(vm.user.displayName + '\'s profile');
    rModelLists.getUserPreferences({ userId: vm.user.id }).$promise.then(function(response) {
      vm.notificationsReceiveChannel = NotificationsReceiveChannelAdapter.getNotificationsReceiveChannel(response.pushNotificationEnabled, response.emailNotificationEnabled);
      vm.newHorseNotificationsEnabled = response.newHorseNotificationsEnabled;
      vm.newWhiteboardNotificationsEnabled = response.newWhiteboardNotificationsEnabled;
    });
  }
}
