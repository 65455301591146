import {barnManagerModule} from '../index.module';
import {HorseLocation} from '../../app/horse/horse-location';

barnManagerModule.factory('horseLocationRepository', HorseLocationRepository);

HorseLocationRepository.$inject = ['$http', 'barnStorage'];
function HorseLocationRepository($http, barnStorage) {
  const url = '/api/horse-locations';

  return {
    all: all,
    archive: archive,
    restore: restore
  };

  function all(): Promise<HorseLocation[]> {
    return $http.get(url, {
      params: {
        tenantEnvironmentId: barnStorage.getEnvId()
      }
    }).then(results => results.data);
  }

  function archive(id: string): Promise<void> {
    return $http.put(`${url}/${id}/archive`).then(result => result.data);
  }

  function restore(id: string): Promise<void> {
    return $http.put(`${url}/${id}/restore`).then(result => result.data);
  }
}
