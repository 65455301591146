import {barnManagerModule} from '../index.module';
import {LocalDate, nativeJs, ZonedDateTime} from '@js-joda/core';

barnManagerModule.directive('validateMinDate', validateMinDate);

validateMinDate.$inject = ['DATE_PICKER_FORMAT', 'DATE_INPUT_MIN_VALUE'];
function validateMinDate(DATE_PICKER_FORMAT, DATE_INPUT_MIN_VALUE) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, elm, attrs, ctrl) {
      ctrl.$validators.minDate = function (value) {
        if (value === undefined || value === null || value === '' || value === false) {
          return true;
        }

        const minValue = LocalDate.parse(DATE_INPUT_MIN_VALUE);
        const ctrlValue = ZonedDateTime.from(nativeJs(value)).toLocalDate();
        return ctrlValue.isAfter(minValue) || ctrlValue.isEqual(minValue);
      };
    }
  };
}
