import {barnManagerModule} from '../../index.module';
import {copy, forEach} from 'angular';
import {get, omit, reject} from 'lodash';
import moment from 'moment';

barnManagerModule.directive('bmComments', comments);

function comments() {

  Comments.$inject = [
    '$scope',
    '$q',
    'userStorage',
    'barnStorage',
    'commentRepository',
    'responseHandler',
    'rUser',
    'rAttachments',
    'listsService',
    'utils',
    'confirmDialog',
    'dateTimeUpgraded',
    'hasPermission'
  ]

  return {
    restricted: 'E',
    templateUrl: 'comments.html',
    controller: Comments,
    controllerAs: 'vm',
    bindToController: true,
    scope : {
      modelType: '@',
      ngModel : '=',
      members: '=',
      allUsers: '=',
      showLegacyCommentsProperty: '@'
    }
  };

  function Comments(
    $scope,
    $q,
    userStorage,
    barnStorage,
    commentRepository,
    responseHandler,
    rUser,
    rAttachments,
    listsService,
    utils,
    confirmDialog,
    dateTimeUpgraded,
    hasPermission
  ) {
    let vm = this;
    let removedFiles = [];
    let currentUser = userStorage.getUser();
    let barn = barnStorage.getEnv();

    vm.$onInit = function() {

      vm.users = [];
      vm.files = {};
      vm.comments = [];
      vm.legacyComments = [];
      vm.refreshComments = getComments;
      vm.remove = remove;
      vm.showErrors = false;
      vm.maxCommentLength = 500;

      vm.queueDelete = queueDelete;
      vm.close = close;
      vm.edit = edit;
      vm.update = update;
      vm.serverTimeStampToLocalDateTimeFormatted = dateTimeUpgraded.serverTimeStampToLocalDateTimeFormatted;
      vm.onAdd = onAdd;
      vm.membersChanged = membersChanged;

      init();

      function queueDelete(file) {
        removedFiles.push(file);
      }

      function close(comment) {
        comment.editMode = false;
        // cancel backup
        let oldComment = copy(comment._default);
        copy(comment._default, comment);
        comment._default = oldComment;
      }

      function onAdd(comment) {
        comment._default = copy(comment);
        vm.comments.push(comment);
      }

      function edit(comment) {
        comment.editMode = true;
        vm.showErrors = false;
      }

      function remove(comment) {
        confirmDialog.open('Are you sure you want to delete this comment?').then(function() {
          commentRepository.remove(vm.modelType, vm.ngModel, comment.id).then(function() {
            responseHandler.successMessage('Comment successfully deleted');
            if (comment.legacy) {
              vm.legacyComments = reject(vm.legacyComments, e => e === comment);
            } else {
              vm.comments = reject(vm.comments, e => e === comment);
            }
          }).catch(responseHandler.processError);
        }).catch(function() {});
      }

      function membersChanged(model, comment) {
        comment.mentionedUserIds = comment.memberIds = model.memberIds;
        comment.allTeamMembersNotifications = model.allTeamMembersNotifications;
      }

      function update(comment) {
        if (vm.loading) {
          return;
        }
        if (get(comment.message, 'length', 0) > vm.maxCommentLength) {
          vm.showErrors = true;
          return;
        }

        vm.loading = true;

        let body = {
          allTeamMembersNotifications: comment.allTeamMembersNotifications,
          mentionedUserIds: comment.mentionedUserIds ? comment.mentionedUserIds : [],
          message: get(comment, 'message', '')
        };

        commentRepository.update(vm.modelType, vm.ngModel, comment.id, body, comment.isNotified).then(function() {
          responseHandler.successOnSave('Comment', true);
          listsService.resetTo('comments', 'COMMENT');
          listsService.updateAttachments(comment.files, removedFiles, {
            id: comment.id
          });
          listsService.ready(function() {
            removedFiles = [];
            comment.updated = utils.localizedDateTime(new Date, 'MMM DD YYYY, h:mm a', -2);
            comment.formattedUpdatedAt = moment().format('l, h:mm a');
            rAttachments.query({ modelType: 'Comment', modelId: comment.id }).$promise.then(function(attachments) {
              comment.files = attachments;
              comment.editMode = false;
              // cancel backup
              comment._default = copy(omit(comment, '_default'));
            });
          });
        }).catch(responseHandler.processError).finally(() => vm.loading = false);
      }

      function getComments(model) {
        commentRepository.all(vm.modelType, model).then((comments: any[]) => {
          vm.comments = [];
          vm.legacyComments = [];
          comments.forEach(comment => {
            mapComment(comment, vm.modelType, model);
            !comment.legacy ? vm.comments.push(comment) : vm.legacyComments.push(comment);
          });
        }).catch(responseHandler.processError);
      }

      function mapComment(comment: any, modelType: string, model: any) {
        if (modelType === 'events') {
          comment.legacy = !comment.instanceId || comment.instanceId !== model.instance;
        }
        comment.notifications = false;
        comment.isNotified = comment.isNotified || false;
        comment.formattedUpdatedAt = dateTimeUpgraded.serverTimeStampToLocalDateTimeFormatted(comment.updated);
        comment.memberIds = comment.mentionedUserIds;
        $q.all({
          attachments: rAttachments.query({ modelType: 'Comment', modelId: comment.id }).$promise,
          user: rUser.simple({ id: comment.createdBy }).$promise
        }).then(res => {
          comment.files = res.attachments;
          comment.canEdit = comment.createdBy == currentUser.id || hasPermission('admin:full');
          comment.displayName = res.user.displayName;
          comment.initials = res.user.firstName.charAt(0) + res.user.lastName.charAt(0);
          // cancel backup
          comment._default = copy(comment);
        });
      }

      function watch() {
       let unregister = $scope.$watch('vm.ngModel', function(newVal) {
          if (typeof newVal !== 'undefined' && !!vm.ngModel.$promise) {
            if (typeof vm.ngModel.id !== 'undefined') {
              getComments(vm.ngModel);
              unregister();
            } else {
              vm.ngModel.$promise.then(function(resource) {
                getComments(resource);
                unregister();
              });
            }
          } else if (typeof newVal !== 'undefined' && vm.ngModel.id) {
            getComments(vm.ngModel);
            unregister();
          }
        });
      }

      function loadAllUsers() {
        return rUser.search({
          tenantEnvironmentId: barn.id,
          archived: false,
          pagesize: 999
        }).$promise.then(function(response) {
          vm.users = response.records;
        });
      }

      function init() {
        if (!vm.allUsers) {
          loadAllUsers();
        } else {
          vm.users = vm.allUsers;
        }
        watch();
      }
    };
  }
}
