import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BmNgSelectModule} from '@shared-ui/bm-ng-select/bm-ng-select.module';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {SharedPipesModule} from '@shared/lib/pipes/shared-pipes.module';
import {ReportsService} from './reports.service';
import {ReportsComponent} from './reports.component';
import {ReportFiltersComponent} from './report-filters/report-filters.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {NgSelectModule} from '@shared/lib/ng-select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BmNgSelectModule,
    NgxDaterangepickerMd,
    SharedPipesModule,
    NgxDaterangepickerMd.forRoot(),
    MatProgressBarModule,
    MatTooltipModule,
    ScrollingModule,
    MatCheckboxModule,
    NgSelectModule
  ],
  declarations: [
    ReportsComponent,
    ReportFiltersComponent
  ],
  exports: [
    ReportFiltersComponent
  ],
  providers: [
    ReportsService
  ]
})
export class ReportsModule {
}
