import {barnManagerModule} from '../../../index.module';
import {copy} from 'angular';

barnManagerModule
  .component('bmFeedingNotes', {
    templateUrl: 'feeding-notes.html',
    controller: FeedingNotesCtrl,
    controllerAs: 'vm',
    bindings: {
      notes: '<',
      onUpdate: '&',
      editable: '<'
    }
  });

FeedingNotesCtrl.$inject = ['hasPermission'];
function FeedingNotesCtrl(hasPermission) {
  const vm = this;

  vm.$onInit = function() {

    vm.hasFeedFullPermission = hasPermission('feed:full');
    vm.maxNoteLength = 1000;
    vm.showErrors = false;

    vm.notesFormValue = copy(vm.notes);
    vm.isForm = false;

    vm.showForm = function() {
      vm.isForm = true;
      vm.showErrors = false;
      vm.notesFormValue = copy(vm.notes);
    };

    vm.deleteNotes = function() {
      vm.showErrors = false;
      vm.notesFormValue = '';
      vm.isForm = false;
      vm.onUpdate({ notes: vm.notesFormValue });
    };

    vm.cancel = function() {
      vm.isForm = false;
    };

    vm.submit = function() {
      if (vm.notesFormValue.length > vm.maxNoteLength) {
        vm.showErrors = true;
        return;
      }
      vm.isForm = false;
      vm.onUpdate({ notes: vm.notesFormValue });
    };

  };
}
