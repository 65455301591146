import {ChangeDetectionStrategy, Component, Input, ViewChild} from '@angular/core';
import {ReportTypeFilterValues, ReportTypeSpecification} from './ReportTypeSpecification';
import {BehaviorSubject, from, Observable, of} from 'rxjs';
import {catchError, debounceTime, finalize, map, shareReplay, switchMap} from 'rxjs/operators';
import {BuildReportResponse, BuildReportResponseHeaderType, ReportsService} from './reports.service';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {MatDialog} from '@angular/material/dialog';
import {FeedFileType, FeedReportParams} from '../feed';
import {FeedExportRequestModel, FeedService} from '../feed/feed.service';
import {FileOpenerService} from '../core/utils/file-opener.service';

@Component({
  selector: 'bm-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportsComponent {
  @Input() horseId: number;
  @ViewChild('scrollViewport', { static: false }) private cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  readonly filters$: BehaviorSubject<ReportTypeFilterValues & { valid: boolean }> = new BehaviorSubject(null);
  readonly reportLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly builtReport$: Observable<BuildReportResponse> = this.getBuiltReport$();
  readonly containerHeight$: Observable<string> = this.calculateContainerHeight();
  readonly BuildReportResponseHeaderType = BuildReportResponseHeaderType;
  altMessage: string;

  constructor(
    private reportsService: ReportsService,
    private matDialog: MatDialog,
    private feedService: FeedService,
    private fileOpenerService: FileOpenerService
  ) { }

  private getBuiltReport$(): Observable<BuildReportResponse> {
    return this.filters$
      .pipe(
        switchMap(filters => {
          if (!filters?.valid) {
            return of(null);
          } else {
            this.reportLoading$.next(true)
            return this.reportsService.buildReport(filters).pipe(
              finalize(() => this.reportLoading$.next(false)),
              catchError(() => (of(null)))
            );
          }
        }),
        shareReplay(1)
      )
  }

  private calculateContainerHeight(): Observable<string> {
    return this.builtReport$.pipe(
      debounceTime(0),
      map(reports => {
        const numberOfItems = reports.rows.length > 0 ? reports.rows.length + 1 : 0;
        // This should be the height of your item in pixels
        const itemHeight = 40;
        // The final number of items you want to keep visible
        const visibleItems = 15;
        setTimeout(() => this.cdkVirtualScrollViewport?.checkViewportSize(), 0);
        if (numberOfItems <= visibleItems) {
          return `${itemHeight * numberOfItems + 20}px`;
        }
        return `${itemHeight * visibleItems}px`;
      }),
      shareReplay(1)
    );
  }

  getRequiredFieldsMessage(): string {
    if (this.altMessage) {
      return this.altMessage;
    }
    if (!this.filters$.value?.type) {
      return 'Select Report Type to Begin';
    } else {
      return 'Select Required Filters';
    }
  }

  onDownloadFeedReport(params: FeedReportParams): void {
    let method: (model: FeedExportRequestModel) => Observable<string>;
    let requestModel: FeedExportRequestModel;
    switch (params.fileType) {
      case FeedFileType.PDF_BE: {
        method = this.feedService.exportToPdf.bind(this.feedService);
        requestModel = {
          types: params.selectedEntryTypes.map(type => type.value),
          horseIds: this.horseId ? [this.horseId] : [...params.selectedHorses.map(horse => horse.id)],
          orientation: params.orientation,
          fontSize: params.fontSize,
          horsePerPage: String(params.horsePerPage)
        };
        break;
      }
      case FeedFileType.CSV: {
        method = this.feedService.exportToCsv.bind(this.feedService);
        requestModel = {
          types: params.selectedEntryTypes.map(type => type.value),
          horseIds: this.horseId ? [this.horseId] : [...params.selectedHorses.map(horse => horse.id)]
        };
        break;
      }
    }

    this.downloadFeedReport(method, requestModel);
  }

  private downloadFeedReport(method: (model: FeedExportRequestModel) => Observable<string>, requestModel: FeedExportRequestModel): void {
    this.reportLoading$.next(true);
    this.altMessage = 'Feed Report is Loading...';
    method(requestModel)
      .pipe(
        switchMap(url => {
          const fileName = 'feed';
          return from(this.fileOpenerService.downloadFile(url));
        }),
        finalize(() => {
          this.reportLoading$.next(false);
          this.altMessage = null;
        })
      )
      .subscribe();
  }

  downloadReport(): void {
    if (this.filters$.value.type != ReportTypeSpecification.FeedReport) {
      this.reportLoading$.next(true);
      this.altMessage = 'Loading the report...';
      this.reportsService.exportToCsv(this.filters$.value)
        .pipe(
          switchMap(url => {
            return from(this.fileOpenerService.downloadFile(url));
          }),
          finalize(() => {
            this.reportLoading$.next(false);
            this.altMessage = null;
          })
        )
        .subscribe();
    }
  }
}
