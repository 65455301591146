import {Router} from '@angular/router';

const oldPublicRoutes = ['login', 'forgot-password', 'token-invitation', 'reset-password', 'set-password'];
const unprefixedRoutes = ['auth'];
const prefix = 'n';

export function performRedirectOnAppInitialize(router: Router): () => void {
  return () => {
    let url = `${window.location.pathname}${window.location.search}`;
    const segments = (window.location.hash || window.location.pathname).split('/');
    segments.shift();
    if (segments?.length > 1) {
      if (oldPublicRoutes.includes(segments[0])) {
        url = `/auth/${segments.join('/')}${window.location.search}`;
      } else if (segments[0] !== prefix && !unprefixedRoutes.includes(segments[0])) {
        url = `/${prefix}/${segments.join('/')}${window.location.search}`;
      } else if (segments[0] === prefix && unprefixedRoutes.includes(segments[1])) {
        segments.shift();
        url = `/${segments.join('/')}${window.location.search}`;
      }
    }

    router.navigateByUrl(url);
  };
}
