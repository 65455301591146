import {barnManagerModule} from '../../../index.module';
import {includes} from 'lodash';

barnManagerModule
  .component('bmRecordActionsMenu', {
    templateUrl: 'record-actions-menu.html',
    controller: recordActionsMenuController,
    controllerAs: 'vm',
    bindings: {
      record: '=',
      horseId: '=',
      lessee: '=',
      lessor: '=',
      loadingDownload: '=',
      onSuccessArchive: '<',
      onSuccessRestore: '<',
      onSuccessRemove: '<'
    }
  });

recordActionsMenuController.$inject = [
  'hasPermission',
  'goNative',
  'reportGenerator',
  'confirmDialog',
  'htmlGenerator',
  'recordRepository',
  'responseHandler',
  'horseRepository',
  'FileOpener'
];
function recordActionsMenuController(
  hasPermission,
  goNative,
  reportGenerator,
  confirmDialog,
  htmlGenerator,
  recordRepository,
  responseHandler,
  horseRepository,
  FileOpener
) {
  const vm = this;

  vm.hasAdminFullPermission = hasPermission('admin:full');
  vm.hasRecordsFullPermission = hasPermission('records:full');
  vm.isGoNative = goNative.isGoNative();

  vm.downloadAsPDF = downloadAsPDF;
  vm.downloadAsPDFNew = downloadAsPDFNew;
  vm.toggleArchive = toggleArchive;
  vm.remove = remove;

  vm.assignedHorses = [];

  function loadAllHorses() {
    return horseRepository.all().then(horses => {
      vm.assignedHorses = horses.filter(horse => includes(vm.record.horseIds, horse.id));
      return horses;
    });
  }

  function downloadAsPDFNew() {
    if (vm.loadingDownload) {
      return;
    }
    vm.loadingDownload = true;

    recordRepository.detailsReport(vm.record.id)
      .then((data: Blob) => {
        const fileName = 'recordReportDetails';
        FileOpener(data, `${fileName}.pdf`);
      })
      .finally(() => vm.loadingDownload = false);
  }

  function downloadAsPDF() {
    loadAllHorses().then(() => {
      const resource: any = {
        resource: vm.record,
        recordType: vm.record.recordType,
        assignedHorses: vm.assignedHorses
      };
      if (vm.lessee) {
        resource.lessee = vm.lessee;
      }
      if (vm.lessor) {
        resource.lessor = vm.lessor;
      }

      vm.loadingDownload = true;

      return reportGenerator.printRecordReport(resource).finally(function() {
        vm.loadingDownload = false;
      });
    });
  }

  function toggleArchive() {
    if (!vm.record.archived) {
      confirmDialog.open('Are you sure you want to archive this record?').then(function() {
        doToggleArchive();
      }).catch(function() {});
    } else {
      doToggleArchive();
    }
  }

  function doToggleArchive() {
    vm.record.archived = !vm.record.archived;

    if (vm.record.archived) {
      vm.archiving = true;
      let entityLink;
      if (vm.horseId > 0) {
        entityLink = htmlGenerator.link('recordHorseDetails', { id: vm.horseId, recordId: vm.record.id, author: vm.record.authorId }, vm.record.title);
      } else {
        entityLink = htmlGenerator.link('recordDetails', { id: vm.record.id, author: vm.record.authorId }, vm.record.title);
      }
      recordRepository.archive(vm.record.id).then(function() {
        if (vm.onSuccessArchive) {
          vm.onSuccessArchive();
        }
        responseHandler.successMessage('You successfully archived ' + entityLink + '.');
      }).finally(function() {
        vm.archiving = false;
      });
    } else {
      recordRepository.restore(vm.record.id).then(function() {
        if (vm.onSuccessRestore) {
          vm.onSuccessRestore();
        }
        responseHandler.successMessage('You successfully unarchived ' + vm.record.title + '.');
      }).finally(function() {
        vm.archiving = false;
      });
    }
  }

  function remove() {
    confirmDialog.open('Are you sure you want to delete this record?').then(function() {
      vm.deletingRecord = true;
      recordRepository.remove(vm.record.id).then(function() {
        if (vm.onSuccessRemove) {
          vm.onSuccessRemove();
        }
        responseHandler.successMessage('Record successfully deleted');
      }).finally(function() {
        vm.deletingRecord = false;
      });
    }).catch(function() {});
  }
}
