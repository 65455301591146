import {barnManagerModule} from '../index.module';
import * as angular from 'angular';

barnManagerModule.factory('horseCache', HorseCache);

HorseCache.$inject = ['sessionStorageService'];
function HorseCache(sessionStorageService) {
  let horse = {}, loaded = false;

  function setHorse(_horse) {
    loaded = true;
    horse = angular.copy(_horse);
    sessionStorageService.setHorse(horse);
  }

  function loadFromStorage() {
    loaded = true;
    horse = sessionStorageService.getHorse();
    return getHorse();
  }

  function clean() {
    horse = {};
    loaded = false;
    sessionStorageService.setHorse(false);
  }

  function getHorse() {
    return angular.copy(horse);
  }

  function inStorage() {
    return sessionStorageService.getHorse();
  }

  function isLoaded() {
    return angular.copy(loaded);
  }

  return {
    setHorse: setHorse,
    horse: getHorse,
    loaded: isLoaded,
    clean: clean,
    loadFromStorage: loadFromStorage,
    inStorage: inStorage
  };
}
