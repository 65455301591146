import {barnManagerModule} from '../index.module';
import {LocalDate, nativeJs} from '@js-joda/core';

barnManagerModule.directive('validateEndDate', validateEndDate);

function validateEndDate() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, elm, attrs, ctrl) {

      ctrl.$validators.invalidEndDate = function (value) {
        return validateEndDate(attrs.startdate, value);
      };

      scope.$watch(function () {
        return elm.attr('startDate');
      }, function (startDate) {
        const endDateValidity = validateEndDate(startDate, ctrl.$modelValue);
        ctrl.$setValidity('invalidEndDate', endDateValidity);
        if (!endDateValidity) {
          ctrl.$setTouched();
          ctrl.$setDirty();
        }
      });

      function validateEndDate(sDateValue, eDateValue) {
        if (!eDateValue || !sDateValue) {
          return true;
        }
        const parsedStartDate = new Date(JSON.parse(sDateValue));
        const startDate = LocalDate.from(nativeJs(parsedStartDate));
        const endDate = LocalDate.from(nativeJs(eDateValue));
        return startDate.isBefore(endDate) || startDate.isEqual(endDate);
      }
    }
  };
}
