import {barnManagerModule} from '../../index.module';
import {mapValues} from 'lodash';

barnManagerModule
  .component('bmCheckboxWeekdaySelect', {
    templateUrl: 'checkbox-weekday-select.html',
    controller: checkboxWeekdaySelectController,
    controllerAs: 'vm',
    bindings: {
      selected: '<',
      onChange: '<',
      error: '<'
    }
  });

checkboxWeekdaySelectController.$inject = ['$scope', 'WEEKDAYS'];
function checkboxWeekdaySelectController($scope, WEEKDAYS) {
  const vm = this;

  vm.$onInit = function() {
    vm.items = mapValues(WEEKDAYS, function(day) {
      return {
        value: day.value,
        label: day.label.substr(0, 3)
      };
    });
  };
}
