import {barnManagerModule} from '../../index.module';

barnManagerModule
  .component('bmHorseSelect', {
    templateUrl: 'horse-select.html',
    controller: horseSelectController,
    controllerAs: 'vm',
    bindings: {
      selected: '<',
      items: '<',
      onChange: '<',
      multiple: '<',
      elementTitle: '@',
      elementPlaceholder: '@',
      appendTo: '@',
      elementDisabled: '<',
      dropdownPosition: '@'
    }
  });

function horseSelectController() {
  let vm = this;

  vm.onChangeSelected = onChangeSelected;

  function onChangeSelected(selected: any) {
    vm.selected = selected;
    vm.onChange(vm.selected);
  }
}
