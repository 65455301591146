import {barnManagerModule} from '../../index.module';

barnManagerModule.factory('PermissionTypes', PermissionTypes);

PermissionTypes.$inject = ['AccessTypes'];
function PermissionTypes(AccessTypes) {
  function PermissionType(name: string, label: string, accessTypes?: any) {
    this.name = name;
    this.label = label;
    this.accessTypes = [AccessTypes.none, AccessTypes.read, AccessTypes.full];
    if (typeof accessTypes !== 'undefined') {
      this.accessTypes = accessTypes;
    }
  }

  PermissionType.prototype.default = function() {
    return this.accessTypes[0];
  };

  return {
    whiteboard: new PermissionType('whiteboard', 'White Board'),
    horses: new PermissionType('horses', 'Horses', [AccessTypes.read, AccessTypes.full]),
    conversations: new PermissionType('conversations', 'Conversations'),
    lists: new PermissionType('lists', 'Lists'),
    contacts: new PermissionType('contacts', 'Contacts'),
    feed: new PermissionType('feed', 'Feed'),
    records: new PermissionType('records', 'Records'),
    events: new PermissionType('events', 'Calendar - Non Lesson Events'),
    lessons: new PermissionType('lessons', 'Calendar - Lessons Only'),
    vendors: new PermissionType('vendors', 'Vendors'),
    reports: new PermissionType('reports', 'Reports', [AccessTypes.none, AccessTypes.full]),
    invoicing: new PermissionType('invoicing', 'Invoicing', [AccessTypes.none, AccessTypes.full]),
    admin: new PermissionType('admin', 'Admin <i>(Access to User & Permissions, Account Settings and Payment Settings)</i>', [AccessTypes.none, AccessTypes.full])
  };
}
