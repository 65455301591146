import {barnManagerModule} from '../index.module';
import * as angular from 'angular';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';

barnManagerModule.factory('dateTimeUtils', dateTimeUtils);

// TODO: move here all the logic related to date/time modifications from Utils
function dateTimeUtils() {
  return {
    resetTimezone: resetTimezone,
    calculateRelativeBarnTime: calculateRelativeBarnTime,
    serverMixedDateToLocal: serverMixedDateToLocal,
  };

  // converts time of format "2019-01-15T16:51:01" to a Date object
  // in a way that is consistent across browsers
  function serverMixedDateToLocal(date) {
    return moment(date, 'YYYY-MM-DDTHH:mm:ss').toDate();
  }

  function resetTimezone(date) {
    if (date == null) {
      return null;
    }
    let d = angular.copy(date);

    return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0));
  }

  function calculateRelativeBarnTime(time, timezone) {
    let hours = parseInt(moment(time).tz(timezone).format('HH'));
    let minutes = parseInt(moment(time).tz(timezone).format('mm'));

    let d = new Date();
    d.setHours(hours, minutes, 0, 0);
    return d;
  }
}
