<section class="csv-import content-area content-area--divider">
  <div class="wrapper">
    <h3 class="primary-heading">CSV Import</h3>
    <p class="import-message">
      Import many horses at once using a CSV file. See the <a target="_blank" href="./assets/horses.csv">Sample CSV File</a> for the column definitions and additional notes.
    </p>

    <label class="attachment-upload btn-icon">
      <svg role="img" aria-hidden="true" class="attachment-upload__icon" viewBox="0 0 15 16">
        <use xlink:href="assets/icons/defs.svg#icon-attach"></use>
      </svg>
      <span class="btn-icon__text">Select a File</span>
      <input #fileInput type="file" accept=".csv" class="hide-input">
    </label>
    <div *ngIf="file$ | async as file" class="attached-file">
      {{ file.name }}
      <button type="button" class="btn-icon" title="Remove" (click)="clearFile()">
        <svg class="attachment-upload__icon" viewBox="0 0 15 15">
          <use xlink:href="assets/icons/defs.svg#icon-close"></use>
        </svg>
      </button>
    </div>

    <ng-container *ngIf="rowData$ | async as rowData">
      <div class="app-table border-bottom" *ngIf="(fileLoading$ | async) === true; else rowsLoaded">
        <div class="app-table__placeholder">...Loading</div>
      </div>
      <ng-template #rowsLoaded>
        <ng-container *ngIf="rowData.length > 0; else noRows">
          <div class="horses-table">
            <table class="table table-striped">
              <thead>
              <tr>
                <th scope="col" class="col-check">
                  <input type="checkbox" [checked]="selection.hasValue() && isAllSelected(rowData)" (change)="$event ? toggleAllRows(rowData) : null" value="all" />
                </th>
                <th *ngFor="let col of columnDefs" scope="col" class="cursor-pointer">
                  <div>
                    <span>{{ col.name }}</span>
                  </div>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let row of rowData; let i = index" [ngClass]="row.rowCssClass">
                <td class="col-check">
                  <div class="form-check">
                    <input class="form-check-input position-static"
                           type="checkbox"
                           value="1"
                           [checked]="selection.isSelected(i)"
                           (change)="$event ? selection.toggle(i) : null" />
                  </div>
                </td>
                <td *ngFor="let cell of row.row">
                  <span [ngClass]="{'invalid': !!cell.error}" #tooltip="matTooltip" matTooltipClass="bm-tooltip" [matTooltip]="cell.error">{{ cell.value }}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
        <ng-template #noRows>
          <div class="app-table border-bottom" *ngIf="!!(file$ | async)">
            <div class="app-table__placeholder font-italic">No horses</div>
          </div>
        </ng-template>
      </ng-template>

      <div *ngIf="rowData.length > 0 && (fileLoading$ | async) === false" class="submit-buttons">
        <shared-button-loader
          [type]="'submit'"
          (btnClick)="importAllHorses()"
          [disabled]="(allImporting$ | async) || (selectedImporting$ | async)"
          [loading]="allImporting$ | async">
          Import All
        </shared-button-loader>
        <shared-button-loader
          [type]="'button'"
          (btnClick)="importSelectedHorses()"
          [disabled]="!selection.hasValue() || (allImporting$ | async) || (selectedImporting$ | async)"
          [loading]="selectedImporting$ | async">
          Import Selected
        </shared-button-loader>
      </div>
    </ng-container>
  </div>
</section>
