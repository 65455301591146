import {barnManagerModule} from '../../index.module';

barnManagerModule
  .component('bmDatesArea', {
    templateUrl: 'dates-area.html',
    controller: datesAreaController,
    controllerAs: 'vm',
    bindings: {
      event: '<'
    }
  });

datesAreaController.$inject = ['$scope']

function datesAreaController($scope) {
  const vm = this;
  $scope.$watch('vm.event', function() {
    vm.event = vm.event;
  });

}
