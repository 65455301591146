import {barnManagerModule} from '../index.module';

barnManagerModule
  .constant('Modules', {
    noaccess: new Module('noaccess'),
    reactivate: new Module('reactivate'),
    profile: new Module('profile'),
    activity: new Module('activity'),
    search: new Module('search'),
    admin: new Module('admin'),
    horses: new Module('horses'),
    conversations: new Module('conversations'),
    lists: new Module('lists'),
    contacts: new Module('contacts'),
    feed: new Module('feed'),
    records: new Module('records'),
    events: new Module('events'),
    vendors: new Module('vendors'),
    reports: new Module('reports')
  });

function Module(name) {
  this.name = name;
}
