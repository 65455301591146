import {NameValueType} from '../../core/utils/name-value-type';

export class EventTypes {
  static get All(): NameValueType[] {
    return [
      EventTypes.Vet,
      EventTypes.Dentist,
      EventTypes.Farrier,
      EventTypes.Vaccination,
      EventTypes.Deworming,
      EventTypes.Medication,
      EventTypes.Show,
      EventTypes.Lesson,
      EventTypes.Other
    ];
  }

  static AllEventTypes: NameValueType = { name: 'All Event Types', value: 'ALL' };
  static Vet: NameValueType = { name: 'Vet Appointment', value: 'VET_APPOINTMENT' };
  static Dentist: NameValueType = { name: 'Dentist Appointment', value: 'DENTIST_APPOINTMENT' };
  static Farrier: NameValueType = { name: 'Farrier Appointment', value: 'FARRIER_APPOINTMENT' };
  static Vaccination: NameValueType = { name: 'Vaccination', value: 'VACCINATION' };
  static Deworming: NameValueType = { name: 'Deworming ', value: 'DEWORMING' };
  static Medication: NameValueType = { name: 'Medication', value: 'MEDICATION' };
  static Show: NameValueType = { name: 'Show', value: 'SHOW' };
  static Lesson: NameValueType = { name: 'Lesson', value: 'LESSON' };
  static Other: NameValueType = { name: 'Other', value: 'OTHER' };
}
