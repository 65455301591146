import {barnManagerModule} from '../../../index.module';
import {fromJson} from 'angular';

barnManagerModule.controller('PaymentController', PaymentController);

PaymentController.$inject = [
  'backLinkHistory',
  'responseHandler',
  'MessageFrames',
  'billingPlan',
  'subscriptionStorage',
  'barnStorage',
  'userStorage',
  'titleService',
  'goNative'
];
function PaymentController(
  backLinkHistory,
  responseHandler,
  MessageFrames,
  billingPlan,
  subscriptionStorage,
  barnStorage,
  userStorage,
  titleService,
  goNative
) {
  const vm = this;
  backLinkHistory.pushLink('Payment Setting');

  const barn = barnStorage.getEnv();
  const errorUsefMessage = 'USEF ID authentication unsuccessful. Please check to make sure you entered the correct ID and that your USEF membership is active. Please visit https://www.usef.org/ for more information.';

  titleService.setTitle('Payment Information');

  vm.user = userStorage.getUser();

  vm.couponMessage = '';
  vm.usefId = '';
  vm.usefMessage = '';
  vm.discountCoupon = '';
  vm.discount = null;
  vm.usef = null;
  vm.subscription = null;

  vm.submitCoupon = submitCoupon;
  vm.submitUSEF = submitUSEF;
  vm.updateSubscriptionInfo = updateSubscriptionInfo;
  vm.loaded = false;
  vm.isGoNative = goNative.isGoNative();

  init();

  function init() {
    if (vm.isGoNative) {
      return;
    }
    subscriptionStorage.loadSubscription().then(function(subscription) {
      vm.subscription = subscription;
    }).finally(function() {
      vm.loaded = true;
    });
  }

  function updateSubscriptionInfo(subscription) {
    vm.subscription = subscription;
    vm.discount = vm.subscription.discount;
    vm.usef = vm.subscription.usef;
  }

  function submitCoupon() {
    vm.couponMessage = 'Applying Coupon...';
    billingPlan.coupon.submit({
      teid: barn.id,
      coupon: vm.discountCoupon
    }).$promise.then(function(response) {
      vm.couponMessage = '';
      responseHandler.successMessage('Coupon Successfully Applied.');
      vm.discount = fromJson(response.discount);
    }).catch(function(error) {
      vm.couponMessage = '';
      responseHandler.errorMessage(error.data.message.replace('Stripe error: ', ''), MessageFrames.TOP);
    });
  }

  function submitUSEF() {
    vm.usefMessage = 'Applying USEF ID...';
    billingPlan.usef.submit({
      teid: barn.id,
      usefId: vm.usefId
    }).$promise.then(function(response) {
      subscriptionStorage.loadSubscription();
      vm.usefId = '';
      vm.usef = fromJson(response);
      if (vm.usef.accountActive) {
        vm.usefMessage = '';
        responseHandler.successMessage('USEF ID authentication successful.');
      } else {
        vm.usefMessage = '';
        responseHandler.errorMessage(errorUsefMessage, MessageFrames.TOP);
      }
    }).catch(function() {
      vm.usefId = '';
      vm.usefMessage = '';
      responseHandler.errorMessage(errorUsefMessage, MessageFrames.TOP);
    });
  }
}
