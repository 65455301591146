import {barnManagerModule} from '../index.module';

barnManagerModule.service('versionCheckService', VersionCheckService);

VersionCheckService.$inject = ['$http', '$interval', '$log', '$location', 'currentAppJS'];
function VersionCheckService($http, $interval, $log, $location, currentAppJS) {
  let onNewVersionCallback = function() {};

  return {
    init: init,
    onNewVersion: onNewVersion
  };

  function checkVersion() {
    $http.get('/rev-manifest.json').then(function(response) {
      $log.info('got version: ' + response.data['scripts/app.js']);
      if ($location.host() === 'localhost') {
        $log.log('host: localhost');
        return;
      }
      if (currentAppJS !== response.data['scripts/app.js']) {
        onNewVersionCallback();
      }
    });
  }

  function onNewVersion(callback) {
    onNewVersionCallback = callback;
  }

  function init() {
    $log.info('current version: ' + currentAppJS);
    checkVersion();

    $interval(checkVersion, 5 * 60 * 1000);
  }
}
