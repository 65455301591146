import {Injectable} from '@angular/core';
import {RestService} from '@shared/lib/rest/rest.service';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {HorseLocation} from './horse-location';
import {AuthorizedStorage} from '../../core/authorized';

@Injectable({
  providedIn: 'root'
})
export class HorseLocationHttpService {
  constructor(
    private rest: RestService,
    private authorizedStorage: AuthorizedStorage
  ) {
  }

  all(): Observable<HorseLocation[]> {
    return this.rest.get({
      path: 'horse-locations', params: { tenantEnvironmentId: this.authorizedStorage.tenantEnvironmentId }
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    )
  }

  archive(id: number): Observable<any> {
    return this.rest.put({
      path: `horse-locations/${id}/archive`
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    )
  }

  restore(id: number): Observable<any> {
    return this.rest.put({
      path: `horse-locations/${id}/restore`
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    )
  }
}
