import {barnManagerModule} from '../index.module';

barnManagerModule
  .factory('Paginator', function() {
    return function(initialPageSize, doLoad) {
      let totalPages = 0;
      let loading = false;
      let currentPageSize = initialPageSize;

      function initialLoaded(response) {
        totalPages = response.totalPages;
      }

      function loadMore() {
        if (isLoading() || !hasMore()) {
          return null;
        }

        currentPageSize += initialPageSize;
        return load(currentPageSize);
      }

      function load(pageSize) {
        loading = true;
        return doLoad(pageSize).then(function(response) {
          totalPages = response.totalPages;
          loading = false;

          return response;
        });
      }

      function reload() {
        return load(currentPageSize);
      }

      function hasMore() {
        return totalPages > 1;
      }

      function isLoading() {
        return loading;
      }

      return {
        initialLoaded: initialLoaded,
        loadMore: loadMore,
        hasMore: hasMore,
        isLoading: isLoading,
        reload: reload
      };
    };
  });
