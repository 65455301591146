import {barnManagerModule} from '../../../index.module';
import {get, includes} from 'lodash';
import moment from 'moment';
import {HorseDetails} from '../../../../app/horse';

barnManagerModule.controller('HorseDetailsController', HorseDetailsController);

HorseDetailsController.$inject = [
  '$state',
  'backLinkHistory',
  'horseRepository',
  'rAttachments',
  'userStorage',
  'barnStorage',
  'moduleAccessService',
  'utils',
  'goNative',
  'horseCache',
  'titleService',
  'hasPermission',
  'HorseNameChangeLog',
  'FileOpener'
];
function HorseDetailsController(
  $state,
  backLinkHistory,
  horseRepository,
  rAttachments,
  userStorage,
  barnStorage,
  moduleAccessService,
  utils,
  goNative,
  horseCache,
  titleService,
  hasPermission,
  HorseNameChangeLog,
  FileOpener
) {
  const vm = this;
  const horseId = $state.params.id;
  backLinkHistory.pushHorseLink('details', horseId);

  vm.hasHorsesFullPermission = hasPermission('horses:full');
  vm.canShowDischargeReport = moduleAccessService.hasModuleAccess('feed');
  vm.barn = {};
  vm.attachments = [];
  vm.attachmentsFiltered = {};
  vm.files = [];
  vm.downloadDischargeReportLoading = false;
  vm.error = null;
  vm.pinHorse = pinHorse;
  vm.openDischargeReport = openDischargeReport;
  vm.printDischargeReportNew = printDischargeReportNew;
  vm.print = print;
  vm.isGoNative = goNative.isGoNative();
  vm.onSuccessArchive = onSuccessArchive;

  initializeHorse();

  function print(event) {
    event.preventDefault();
    if (vm.downloadDetailsLoading) {
      return;
    }
    vm.downloadDetailsLoading = true;

    horseRepository.horseDetailsReport(vm.horse.id)
      .then((data: Blob) => {
        const fileName = 'horseDetails';
        FileOpener(data, `${fileName}.pdf`);
      })
      .finally(() => vm.downloadDetailsLoading = false);
  }

  function filterAttachmentsByIds() {
    return vm.horse.registrations.reduce(function(acc, organization, index) {
      acc[index] = vm.attachments.filter(function(file) {
        return includes(organization.attachmentIds, file.id);
      });
      return acc;
    }, {});
  }

  function openDischargeReport() {
    vm.showDischargeReportModal = true;
  }

  function printDischargeReportNew(includeVetRecords, vetRecordsPeriod, notes) {
    vm.downloadDischargeReportLoading = true;
    const barn = barnStorage.getEnv();

    horseRepository.dischargeReport(vm.horse.id, includeVetRecords, vetRecordsPeriod, notes)
      .then((data: Blob) => {
        const fileName = 'dischargeReport';
        FileOpener(data, `${fileName}.pdf`);
      })
      .finally(() => vm.downloadDischargeReportLoading = false);
  }

  function pinHorse() {
    vm.horse.pinned = !vm.horse.pinned;
    horseRepository.togglePin(vm.horse.id);
  }

  function initializeHorse() {
    vm.horse = {};
    vm.barn = barnStorage.getEnv();
    vm.user = userStorage.getUser();

    const horse = horseCache.horse();
    if (horse && Number(horse.id) === Number(horseId)) {
      titleService.setTitle('Details :: ' + horse.name);
      vm.horse = new HorseDetails(horse);
      vm.error = null;
      vm.horse.type = utils.capitalizeString(horse.type);

      if (vm.horse.insuranceDate) {
        vm.horse.insuranceDate = moment(vm.horse.insuranceDate.replace(/Z/, '')).toDate();
      }

      // FIXME: wtf? this makes all reports render empty list of registrations
      if (!get(vm.horse, 'registrations.length')) {
        vm.horse.registrations = [{}];
      }
      rAttachments.query({modelType: 'HORSE_ORGANIZATION', modelId: vm.horse.id}, function(attachments) {
        vm.attachments = attachments;
        vm.attachmentsFiltered = filterAttachmentsByIds();
        vm.attachmentsReady = true;
      }, function() {
        vm.attachmentsReady = true;
      });
      rAttachments.query({modelType: 'HORSE', modelId: vm.horse.id}, function(attachments) {
        vm.files = attachments;
      });

      loadHorseNameChangeLog();
    } else {
      vm.error = 'This horse either doesn\'t exist or you don\'t have access to it.';
    }
  }

  function onSuccessArchive() {
    $state.go('horses');
  }

  function loadHorseNameChangeLog() {
    horseRepository.nameChanges(vm.horse.id).then(response => {
      vm.horseNameChangeLog = response.data.map(log => new HorseNameChangeLog(log));
    });
  }
}
