import {barnManagerModule} from '../index.module';

barnManagerModule.factory('horseBreedRepository', HorseBreedRepository);

HorseBreedRepository.$inject = ['$http', 'barnStorage'];
function HorseBreedRepository($http, barnStorage) {
  const url = '/api/horse-breeds';

  return {
    all: all
  };

  function all() {
    return $http.get(url, {
      params: {
        tenantEnvironmentId: barnStorage.getEnvId()
      }
    }).then(function(results) {
      return results.data;
    });
  }
}
