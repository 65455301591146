import {barnManagerModule} from '../../index.module';
import {convert, LocalDate, LocalDateTime, LocalTime} from '@js-joda/core';
import moment from 'moment';
import {extend, forEach, merge} from 'angular';

barnManagerModule.controller('CalendarController', CalendarController);

CalendarController.$inject = [
  '$rootScope',
  'backLinkHistory',
  'responseHandler',
  'MessageFrames',
  'calendarEventTitle',
  'calendarConfig',
  'rEvents',
  'userStorage',
  'barnStorage',
  '$scope',
  '$state',
  '$location',
  '$timeout',
  'hasPermission',
  'confirmDialog',
  '$templateRequest',
  'eventsService',
  'utils',
  'goNative',
  '$stateParams',
  'dateTimeUpgraded',
  'titleService',
  'horseRepository',
  'rContacts'
];
function CalendarController(
  $rootScope,
  backLinkHistory,
  responseHandler,
  MessageFrames,
  calendarEventTitle,
  calendarConfig,
  rEvents,
  userStorage,
  barnStorage,
  $scope,
  $state,
  $location,
  $timeout,
  hasPermission,
  confirmDialog,
  $templateRequest,
  eventsService,
  utils,
  goNative,
  $stateParams,
  dateTimeUpgraded,
  titleService,
  horseRepository,
  rContacts
) {
  let vm = this,
    barn,
    events = [],
    timeout,
    firstLoad = true;

  $templateRequest('custom-calendarWeekView.html', false);
  $templateRequest('custom-calendarDayView.html', false);
  const colorMap = {
    VET_APPOINTMENT: '#653739',
    DENTIST_APPOINTMENT: '#df5123',
    FARRIER_APPOINTMENT: '#197278',
    VACCINATION: '#104a4e',
    DEWORMING: '#47800c',
    MEDICATION: '#184f72',
    SHOW: '#b47207',
    LESSON: '#8b4d50',
    OTHER: '#655a58'
  };

  vm.hasEventsFullPermission = hasPermission('events:full') ;
  vm.hasLessonsFullPermission = hasPermission('lessons:full');


  vm.availableTypes = [
    { name: 'All Events', section: 'Activity', value: null },
    { name: 'Vet Appointment', section: 'Event Type', value: 'VET_APPOINTMENT' },
    { name: 'Dentist Appointment', section: 'Event Type', value: 'DENTIST_APPOINTMENT' },
    { name: 'Farrier Appointment', section: 'Event Type', value: 'FARRIER_APPOINTMENT' },
    { name: 'Vaccination', section: 'Event Type', value: 'VACCINATION' },
    { name: 'Deworming ', section: 'Event Type', value: 'DEWORMING' },
    { name: 'Medication', section: 'Event Type', value: 'MEDICATION' },
    { name: 'Show', section: 'Event Type', value: 'SHOW' },
    { name: 'Lesson', section: 'Event Type', value: 'LESSON' },
    { name: 'Other', section: 'Event Type', value: 'OTHER' }
  ];
  vm.type = vm.availableTypes[0].value;
  vm.keyword = '';
  vm.selectedHorsesIds = [];
  vm.selectedContactsIds = [];
  vm.horses = [];
  vm.contacts = [];

  vm.calendarView = 'month';
  vm.viewDate = convert(LocalDate.now()).toDate();
  vm.status = {
    busy: true,
    message: ''
  };
  vm.cellIsOpen = false;
  vm.subscribeToken = null;
  vm.isGoNative = goNative.isGoNative();

  vm.eventClicked = eventClicked;
  vm.eventTapped = eventTapped;
  vm.toggle = toggle;
  vm.timespanClicked = timespanClicked;
  vm.generateSubscribeToken = generateSubscribeToken;
  vm.changeCalendarView = changeCalendarView;
  vm.changeType = changeType;
  calendarEventTitle.monthView = eventTitle;
  calendarEventTitle.monthViewTooltip = tooltipText;

  vm.onSelectedHorseChange = onSelectedHorseChange;
  vm.onSelectedContactsChange = onSelectedContactsChange;

  init();

  function changeCalendarView(calendarView) {
    vm.calendarView = calendarView;
    vm.cellIsOpen = false;
  }

  function changeType(type) {
    vm.type = type;
  }

  function tooltipText(event) {
    if (event.allDay) {
      return 'All Day - ' + event.title;
    }
    return dateTimeUpgraded.timeFormatted(event.startTimeWall || '00:00') + ' - ' + event.title;
  }

  function eventTitle(event) {
    if (event.allDay) {
      return event.title + ' (All Day)';
    } else if (event.eventType === 'SHOW') {
      return event.title;
    }
    return event.title + ' (' + dateTimeUpgraded.timeFormatted(event.startTimeWall) + ') ';
  }

  vm.touchedRecentrly = false;

  function eventTapped(calendarEvent, $event, day) {
    $event.preventDefault();
    $event.stopPropagation();
    vm.cellIsOpen = !(vm.cellIsOpen && isSameDay(day.date, vm.viewDate));
    vm.viewDate = day.date;
    vm.touchedRecentrly = true;

    /* eslint-disable */
    // performance optimisation
    setTimeout(function() {
      vm.touchedRecentrly = false;
    });
    /* eslint-enable */
  }

  function eventClicked(eventInstance) {
    if (vm.touchedRecentrly) {
      return;
    }

    const params = {
      id: eventInstance.id,
      author: eventInstance.authorId,
      instance: eventInstance.dateDate
    };

    $state.go('eventDetails', params);
  }

  function remove(args) {
    eventsService.remove(args.calendarEvent, args.calendarEvent.dateDate).then(function() {
      queryEvents();
    });
  }

  function edit(args) {
    const curHorseId = $state.current.name === 'eventHorseDetails' ? $stateParams.id : undefined;
    eventsService.navigateToEdit(args.calendarEvent, curHorseId, args.calendarEvent.dateDate);
  }

  function toggle($event, field, event) {
    $event.preventDefault();
    $event.stopPropagation();
    event[field] = !event[field];
  }

  function timespanClicked(date, cell) {
    if (vm.calendarView === 'month') {
      if (vm.cellIsOpen && isSameDay(date, vm.viewDate) ||
        cell.events.length === 0 || !cell.inMonth) {
        vm.cellIsOpen = false;
      } else {
        vm.cellIsOpen = true;
        vm.viewDate = date;
      }
    } else if (vm.calendarView === 'year') {
      if ((vm.cellIsOpen && moment(date).startOf('month').isSame(moment(vm.viewDate).startOf('month'))) ||
        cell.events.length === 0) {
        vm.cellIsOpen = false;
      } else {
        vm.cellIsOpen = true;
        vm.viewDate = date;
      }
    }
  }

  function watch() {
    $scope.$watchGroup(['vm.calendarView', 'vm.viewDate'], function(newVals, oldVals) {
      if (newVals !== oldVals) {
        buildEvents();
        $location.search('calendarView', newVals[0]);
        $location.search('viewDate', moment(newVals[1]).format('YYYY-MM-DD'));
        backLinkHistory.pushLink('Calendar');
      }
    });
  }

  function getEventTimeLimit(event) {
    let startTimeLocalDateTime;
    let endTimeLocalDateTime;
    if (event.eventType === 'SHOW') {
      startTimeLocalDateTime = LocalDate.parse(event.dateDate).atStartOfDay();
      endTimeLocalDateTime = LocalDate.parse(event.endsDate).atTime(LocalTime.MAX);
    } else if (event.allDay) {
      startTimeLocalDateTime = LocalDate.parse(event.dateDate).atStartOfDay();
      endTimeLocalDateTime = LocalDate.parse(event.dateDate).atTime(LocalTime.MAX);
    } else {
      // TODO: handle missing startTimeWall, give some defaults
      startTimeLocalDateTime = LocalDateTime.of(LocalDate.parse(event.dateDate), LocalTime.parse(event.startTimeWall));
      endTimeLocalDateTime = LocalDateTime.of(LocalDate.parse(event.dateDate), LocalTime.parse(event.endTimeWall));
    }
    return {
      startsAt: convert(startTimeLocalDateTime).toDate(),
      endsAt: convert(endTimeLocalDateTime).toDate()
    };
  }

  /**
   * Calculate relative luminance and pick color for text
   * Based on https://en.wikipedia.org/wiki/Luma_(video)#Rec._601_luma_versus_Rec._709_luma_coefficients
   * luminance = (red * 0.299 + green * 0.587 + blue * 0.114)
  */
  function pickTextColorBasedOnBgColor(hex, lightColor, darkColor) {
    if (!hex || !(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex))) {
      return null;
    }

    const r = parseInt(hex.slice(1, 3), 16),
          g = parseInt(hex.slice(3, 5), 16),
          b = parseInt(hex.slice(5, 7), 16);

    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ? darkColor : lightColor;
  }

  function adjust(hex, amount) {
    if (!hex || !(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex))) {
      return null;
    }
    return '#' + hex
      .replace(/^#/, '')
      .replace(/../g, hex => ('0' + Math.min(255, Math.max(0, parseInt(hex, 16) + amount)).toString(16)).slice(-2));
  }

  function buildEvents() {
    vm.events = events.reduce(function(acc, event) {
      const colorConfig: any = {
        primary: colorMap[event.eventType],
        secondary: '#fce7c6'
      };

      // Logic for custom background color
      if (event.color) {
        colorConfig.backgroundColor = event.color;

        const lightColor = '#ffffff';
        const darkColor = '#000000';
        const textColor = pickTextColorBasedOnBgColor(event.color, lightColor, darkColor);
        if (textColor) {
          colorConfig.primary = textColor;
        }

        const borderColor = adjust(event.color, -40);
        if (borderColor) {
          colorConfig.borderColor = borderColor;
        }
      }

      return acc.concat(extend({}, event, getEventTimeLimit(event), { colorConfig: colorConfig }));
    }, []);
    vm.status.busy = false;
  }

  function generateSubscribeToken() {
    rEvents.subscribeToken(
      {
        tenantEnvironmentId: barn.id
      },
      function(result) {
        vm.subscribeToken = result.token;
      }
    );
  }

  function queryEvents(extendedQuery?: any) {
    const query = extendedQuery || {};
    // events = [];

    vm.startDate = moment(vm.viewDate)
      .startOf(vm.calendarView)
      .format('YYYY-MM-DDTHH:mm');
    vm.endDate = moment(vm.viewDate)
      .endOf(vm.calendarView)
      .format('YYYY-MM-DDTHH:mm');

    rEvents.query(
      merge({}, query, {
        pagesize: 2000,
        startDate: vm.startDate,
        endDate: vm.endDate,
        tenantEnvironmentId: barn.id
      }),
      function(_events) {
        events = [];
        forEach(_events, function(event) {
          const newEvent = {
            colorConfig: calendarConfig.colorTypes.info,
            title: event.title,
            draggable: false,
            resizable: false,
            actions: actionsForEvent(event)
          };

          events.push(extend({}, event, newEvent));
        });

        buildEvents();
        if (firstLoad) {
          watchFilters();
          firstLoad = false;
        }
      }
    );
  }

  function actionsForEvent(event) : any[] {
    let actionable = []
    if (event.eventType === 'LESSON') {
      if (vm.hasLessonsFullPermission) {
        return [
          {
            cssClass: 'event-item-action__edit',
            label: '<i class="fa fa-pencil-square-o" aria-hidden="true"></i>',
            onClick: edit
          },
          {
            cssClass: 'event-item-action__remove',
            label: '<i class="fa fa-trash-o" aria-hidden="true"></i>',
            onClick: remove
          }
        ];
      }
    } else {
      if (vm.hasEventsFullPermission) {
        return [
          {
            cssClass: 'event-item-action__edit',
            label: '<i class="fa fa-pencil-square-o" aria-hidden="true"></i>',
            onClick: edit
          },
          {
            cssClass: 'event-item-action__remove',
            label: '<i class="fa fa-trash-o" aria-hidden="true"></i>',
            onClick: remove
          }
        ];
      }
    }
    return actionable;
  }

  function isSameDay(date1, date2) {
    return moment(date1).startOf('day').isSame(moment(date2).startOf('day'));
  }

  function isSameCalendarView(date1, date2) {
    return moment(date1).startOf(vm.calendarView).isSame(moment(date2).startOf(vm.calendarView));
  }

  function isInMonthDateRange(date) {
    return moment(date).isBetween(vm.startDate, vm.endDate, undefined, '[]');
  }

  function watchDates() {
    function triggerSearch(newDate, oldDate) {
      if (!isSameCalendarView(newDate, oldDate)) {
        search();
      }
    }
    $scope.$watch('vm.viewDate', function(newDate, oldDate) {
      if (typeof newDate !== 'undefined') {
        if (!isSameCalendarView(newDate, oldDate)) {
          if (!isInMonthDateRange(newDate)) {
            events = [];
          }
          triggerSearch(newDate, oldDate);
          $location.search('viewDate', moment(newDate).format('YYYY-MM-DD'));
          backLinkHistory.pushLink('Calendar');
        }
      }
    });
    $scope.$watch('vm.calendarView', function(newVal, oldVal) {
      if (newVal !== oldVal) {
        events = [];
        $location.search('calendarView', newVal);
        backLinkHistory.pushLink('Calendar');
        triggerSearch(newVal, oldVal);
      }
    });
  }

  function watchFilters() {
    let timeoutPromise, delay = 600;

    $scope.$watch('vm.keyword', function(newKeyword, oldKeyword) {
      if (newKeyword !== oldKeyword) {
        $timeout.cancel(timeoutPromise);
        timeoutPromise = $timeout(function() {
          $location.search('keyword', newKeyword?.length > 0 ? newKeyword : null);
          backLinkHistory.pushLink('Calendar');
          search();
        }, delay);
      }
    }, true);

    $scope.$watch('vm.type', function(newType, oldType) {
      if (newType !== oldType) {
        $location.search('type', newType);
        backLinkHistory.pushLink('Calendar');
        search();
      }
    }, true);

    $scope.$watch('vm.selectedHorsesIds', function(newSelectedHorsesIds, oldSelectedHorsesIds) {
      if (newSelectedHorsesIds !== oldSelectedHorsesIds) {
        $timeout.cancel(timeoutPromise);
        timeoutPromise = $timeout(function() {
          $location.search('horsesIds', vm.selectedHorsesIds.length > 0 ? [vm.selectedHorsesIds] : null);
          backLinkHistory.pushLink('Calendar');
          search();
        }, delay);
      }
    }, true);

    $scope.$watch('vm.selectedContactsIds', function(newSelectedContactsIds, oldSelectedContactsIds) {
      if (newSelectedContactsIds !== oldSelectedContactsIds) {
        $timeout.cancel(timeoutPromise);
        timeoutPromise = $timeout(function() {
          $location.search('contactsIds', vm.selectedContactsIds.length > 0 ? [vm.selectedContactsIds] : null);
          backLinkHistory.pushLink('Calendar');
          search();
        }, delay);
      }
    }, true);
  }

  function search() {
    timer();
    // vm.status.busy = true;
    const queryParams = {
      horseId: $state.params.id,
      text: vm.keyword,
      type: vm.type,
      horseIds: vm.selectedHorsesIds,
      contactIds: vm.selectedContactsIds
    };
    queryEvents(queryParams);
  }

  function timer() {
    vm.status.message = '';
    $timeout.cancel(timeout);
    timeout = $timeout(function() {
      vm.status.message = 'Oops! It is taking more time than expected.';
    }, 4000);
  }

  function setUpFilters() {
    const ls = $location.search();
    if (ls['calendarView']) {
      vm.calendarView = ls['calendarView'];
    }
    if (ls['type']) {
      vm.type = ls['type'];
    }
    if (ls['keyword']) {
      vm.keyword = ls['keyword'];
    }
    if (ls['viewDate']) {
      vm.viewDate = moment(ls['viewDate']).toDate();
    }
    if (ls['horsesIds'] && ls['horsesIds'] !== '') {
       ls['horsesIds'].split(',')?.forEach(id => {
         if (!isNaN(id)) {
           vm.selectedHorsesIds.push(Number(id));
         }
      });
    }
    if (ls['contactsIds'] && ls['contactsIds'] !== '') {
      ls['contactsIds'].split(',')?.forEach(id => {
        if (!isNaN(id)) {
          vm.selectedContactsIds.push(Number(id));
        }
      });
    }
  }

  function init() {
    titleService.setTitle('Calendar');
    barn = barnStorage.getEnv();
    backLinkHistory.pushLink('Calendar');

    loadHorses();
    loadContacts();

    setUpFilters();
    watch();
    watchDates();
    search();
    generateSubscribeToken();
  }

  function loadHorses() {
    horseRepository.allIdWithName().then(function(horses) {
      vm.horses = horses;
      unselectNonExistentEntities(vm.horses, vm.selectedHorsesIds);
    });
  }

  function loadContacts() {
    const queryParams = {
      tenantEnvironmentId: barn.id,
      pagesize: 10000,
      archived: 0
    };
    rContacts.idWithName(queryParams, function(response) {
      vm.contacts = response.records;
      unselectNonExistentEntities(vm.contacts, vm.selectedContactsIds);
    });
  }

  function unselectNonExistentEntities(entities, selected) {
    const ids = entities.map(i => i.id);
    if (ids?.length > 0) {
      selected.forEach((id, index) => {
        if (!ids.includes(id)) {
          selected.splice(index, 1);
        }
      });
    }
  }

  function onSelectedHorseChange(selected) {
    vm.selectedHorsesIds = selected.map(i => i.id);
  }

  function onSelectedContactsChange(selected) {
    vm.selectedContactsIds = selected.map(i => i.id);
  }
}
