import {barnManagerModule} from '../index.module';
import {LocalTime, nativeJs} from '@js-joda/core';

barnManagerModule.directive('validateEndTime', validateEndTime);

function validateEndTime() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, elm, attrs, ctrl) {

      ctrl.$validators.invalidEndTime = function (value) {
        return validateEndTime(attrs.starttime, value);
      };

      scope.$watch(function () {
        return elm.attr('startTime');
      }, function (startDate) {
        const endDateValidity = validateEndTime(startDate, ctrl.$modelValue);
        ctrl.$setValidity('invalidEndTime', endDateValidity);
        if (!endDateValidity) {
          ctrl.$setTouched();
          ctrl.$setDirty();
        }
      });

      function validateEndTime(sDateValue, eDateValue) {
        if (!eDateValue || !sDateValue) {
          return true;
        }
        const parsedStartDate = new Date(JSON.parse(sDateValue));
        const startTime = LocalTime.from(nativeJs(parsedStartDate));
        const endTime = LocalTime.from(nativeJs(eDateValue));
        return startTime.isBefore(endTime) || startTime.equals(endTime);
      }
    }
  };
}
