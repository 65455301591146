import {barnManagerModule} from '../../index.module';
import {merge} from 'angular';
import {AllHorseCategories} from '../../resources/horseCategoryRepository';
import {HorseLocation} from '../../../app/horse/horse-location';

barnManagerModule.controller('HorsesController', HorsesController);

HorsesController.$inject = [
  'backLinkHistory',
  'htmlGenerator',
  'responseHandler',
  '$scope',
  '$timeout',
  'userStorage',
  'utils',
  'horseLocationRepository',
  'horseRepository',
  'goNative',
  'titleService',
  'hasPermission',
  'horseCategoryRepository',
  'selectDialog',
  '$state',
  'FileOpener'
];

function HorsesController(
  backLinkHistory,
  htmlGenerator,
  responseHandler,
  $scope,
  $timeout,
  userStorage,
  utils,
  horseLocationRepository,
  horseRepository,
  goNative,
  titleService,
  hasPermission,
  horseCategoryRepository,
  selectDialog,
  $state,
  FileOpener
) {
  let vm = this, timeout;
  backLinkHistory.pushLink('Horses');

  titleService.setTitle('Horses');

  vm.hasHorsesFullPermission = hasPermission('horses:full');
  vm.totalPages = 1;
  vm.horses = [];
  vm.currentPage = 1;
  vm.maxSize = 12;
  vm.currentView = 'CARD_VIEW';
  vm.isGoNative = goNative.isGoNative();
  vm.onSuccessArchiveOrRestore = onSuccessArchiveOrRestore;
  vm.printDischargeReportNew = printDischargeReportNew;

  vm.status = {
    error: false,
    empty: false,
    loaded: false,
    busy: false,
    message: '',
    errorMessage: ''
  };

  vm.sortBy = [
    { name: 'All Horses',        section: 'Activity', value: {}, order: 0 },
    { name: 'Archived',          section: 'Activity', value: { 'archived': 1 }, order: 1 },
    { name: 'Active',            section: 'Activity', value: { 'archived': 0 }, order: 2 },
    { name: 'Gelding',           section: 'Type',     value: { 'type': 'GELDING', 'archived': 0 }, order: 6 },
    { name: 'Mare',              section: 'Type',     value: { 'type': 'MARE', 'archived': 0 }, order: 7 },
    { name: 'Stallion',          section: 'Type',     value: { 'type': 'STALLION', 'archived': 0 }, order: 8 }
  ];

  vm.defaultFilter = 2;

  // mimics bmFiltersArea scope, default value
  vm.filters = {
    by: vm.sortBy[vm.defaultFilter]
  };

  vm.horsesPerPage = [ 12, 24, 36 ];
  vm.openDischargeReport = openDischargeReport;
  vm.printDischargeReportNew = printDischargeReportNew;
  vm.search = search;
  vm.togglePin = togglePin;
  vm.unArchive = confirmUnArchive;
  vm.onFiltersChanged = onFiltersChanged;
  vm.navigateToImport = navigateToImport;

  vm.isReactivating = false;

  init();

  function openDischargeReport() {
    vm.visible = true;
    vm.horse.showDischargeReportModal = true;
  }

  function printDischargeReportNew(includeVetRecords, vetRecordsPeriod, notes) {
    vm.downloadDischargeReportLoading = true;
    horseRepository.dischargeReport(vm.horse.id, includeVetRecords, vetRecordsPeriod, notes)
      .then((data: Blob) => {
        const fileName = 'dischargeReport';
        FileOpener(data, `${fileName}.pdf`);
      })
      .finally(() => vm.downloadDischargeReportLoading = false);
  }


  function confirmUnArchive(horse) {
    if (vm.isReactivating) {
      return;
    }

    vm.isReactivating = true;
    horse.loading = true;
    horse.archived = 0;
    horseRepository.reactivate(horse.id).then(function() {
      vm.isReactivating = false;
      horse.loading = false;
      const detailsLink = htmlGenerator.link('horseDetails', { id: horse.id }, 'Details');
      responseHandler.successMessage('You successfully reactivated ' + horse.name + '. ' + detailsLink + '.');
      search();
    });
  }

  function loadAllHorseCategories() {
    horseCategoryRepository.all().then((categories: AllHorseCategories) => {
      let categoriesForFilter = [];
      for (const [key, value] of Object.entries(categories)) {
        switch (key) {
          case 'statuses': {
            categoriesForFilter = [
              ...categoriesForFilter,
              ...value.sort((a, b) => a.localeCompare(b)).map(category => {
                return { name: category, section: 'Status', value: { 'status': category, 'archived': 0 }, order: 3 };
              })
            ];
            break;
          }
          case 'healths': {
            categoriesForFilter = [
              ...categoriesForFilter,
              ...value.sort((a, b) => a.localeCompare(b)).map(category => {
                return { name: category, section: 'Health', value: { 'health': category, 'archived': 0 }, order: 4 };
              })
            ];
            break;
          }
          case 'functions': {
            categoriesForFilter = [
              ...categoriesForFilter,
              ...value.sort((a, b) => a.localeCompare(b)).map(category => {
                return { name: category, section: 'Function', value: { 'function': category, 'archived': 0 }, order: 5 };
              })
            ];
            break;
          }
        }
      }

      addItemsToSortBy(categoriesForFilter);
    });
  }

  function loadAllHorseLocations() {
    horseLocationRepository.all().then(items => {
      const locations = items.map((item: HorseLocation) => {
        return { name: item.location, section: 'Location', value: { 'location': item.location, 'archived': 0 }, order: 9 };
      })
      addItemsToSortBy(locations);
    });
  }

  function addItemsToSortBy(items) {
    vm.sortBy = [...vm.sortBy, ...items].sort((a, b) => (a.order < b.order ? -1 : 1));
  }

  function init() {
    loadAllHorseCategories();
    loadAllHorseLocations();

    timer();
    vm.user = userStorage.getUser();

    queryHorses().then(function(response) {
      vm.status.loaded = true;
      vm.horses = utils.preprocessHorses(response.records);
      vm.totalPages = response.totalPages;
      vm.status.empty = !response.records.length;
      vm.totalRecords = response.totalRecords;
      watchFilters();
    }, function(error) {
      vm.status.loaded = true;
      vm.status.empty = true;
      vm.status.error = true;
      vm.currentPage = 1;
      responseHandler.processError(error);
    });
  }

  function timer() {
    vm.status.message = '';
    $timeout.cancel(timeout);
    timeout = $timeout(function() {
      vm.status.message = 'Oops! It is taking more time than expected.';
    }, 4000);
  }

  function watchFilters() {
    let timeoutPromise, delay = 600;
    $scope.$watch('vm.filters', function(newFilters, oldFilters) {
      if (newFilters !== oldFilters) {
        vm.currentPage = 1;
        if (newFilters.keyword !== (oldFilters || {}).keyword) {
          $timeout.cancel(timeoutPromise);
          timeoutPromise = $timeout(search, delay);
        } else {
          search();
        }
      }
    }, true);
  }

  // first step of removing $watch on vm.filters, only maxSize change triggers it
  function onFiltersChanged(filters) {
    vm.currentPage = 1;
    vm.maxSize = filters.maxSize;
    search();
  }

  function queryHorses() {
    const queryParams = {
      page: vm.currentPage - 1,
      pagesize: vm.maxSize,
      text: vm.filters.keyword,
      sort: 'name'
    };
    return horseRepository.search(merge({}, queryParams, vm.filters.by.value));
  }

  function search() {
    timer();
    vm.status.busy = true;
    queryHorses().then(function(response) {
      vm.status.busy = false;
      vm.status.empty = !response.records.length;
      vm.horses = utils.preprocessHorses(response.records);
      vm.totalPages = response.totalPages;
      vm.totalRecords = response.totalRecords;
    }, responseHandler.processError);
  }

  function togglePin(horse) {
    horse.pinned = horse.pinned ? 0 : 1;
    horseRepository.togglePin(horse.id).then(() => {
      vm.horses = vm.horses.sort((a, b) => a.name.localeCompare(b.name)).sort((a, b) => b.pinned - a.pinned);
    }).catch(() => horse.pinned = horse.pinned ? 0 : 1);
  }

  function onSuccessArchiveOrRestore() {
    search();
  }

  function navigateToImport() {
    return selectDialog.open('Import horses', [
      {option: 'horseImportUsef', label: 'USEF'},
      {option: 'horseImportCsv', label: 'CSV'}
    ], "Import").then((option: string) => $state.go(option));
  }
}
