import {barnManagerModule} from '../../index.module';

barnManagerModule.directive('bmDropdownMenu', dropdownMenu);

function dropdownMenu() {
  return {
    restricted: 'E',
    templateUrl: 'dropdown-menu.html',
    controller: DropdownMenuController,
    controllerAs: 'dropdown',
    bindToController: true,
    transclude: true,
    scope: {
      isReactivatePage: '<',
      logout: '@',
      logoutAction: '&',
      dropdownClick: '&',
      text: '@'
    }
  };

  function DropdownMenuController() {
  }
}
