import {barnManagerModule} from '../../index.module';

barnManagerModule
  .component('bmDropdownSettingsModal', {
    templateUrl: 'dropdown-settings-modal.html',
    controller: DropdownSettingsModalController,
    controllerAs: 'vm',
    transclude: {
      'content': '?modalContent',
      'buttons': '?modalButtons'
    },
    bindings: {
      visible: '=',
      showModalContent: '=',
      modalTitle: '<',
      customIdentifier: '<',
      customLabel: '<',
      items: '<',
      onArchive: '<',
      onRestore: '<'
    }
  });
function DropdownSettingsModalController() {
  const vm = this;
  vm.visible = false;
  vm.close = close;
  vm.toggleArchive = toggleArchive;

  function close() {
    vm.visible = false;
    if (vm.showModalContent) {
      vm.showModalContent = false;
    }
  }

  function toggleArchive(item) {
    if (!item.archived) {
      vm.onArchive(item);
    } else {
      vm.onRestore(item);
    }
  }
}
