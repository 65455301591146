import {barnManagerModule} from '../index.module';
import * as angular from 'angular';
import moment from 'moment';
import {get} from 'lodash';

barnManagerModule.factory('subscriptionStorage', SubscriptionStorage);

SubscriptionStorage.$inject = ['$q', '$rootScope', 'Subscription', 'barnStorage', 'userStorage', 'billingPlan'];
function SubscriptionStorage($q, $rootScope, Subscription, barnStorage, userStorage, billingPlan) {
  let subscription = null;
  let loading = false;
  let promise = null;

  let legacySubscription = {
    'legacy': true,
    'trialStart': null,
    'billing': 'charge_automatically',
    'status': 'active',
    'start': 1549463258,
    'created': 1496345608,
    'currentPeriodEnd': moment().add(1, 'month'),
    'currentPeriodStart': moment().unix(),
    'endedAt': null,
    'trialEnd': null,
    'cancelAtPeriodEnd': false,
    'cancelAtPeriodEndFormatted': '2022-12-31',
    'planName': 'Legacy',
    'planId': 'plan_v2_monthly_essential',
    'discount': null,
    'usef': null
  };

  return {
    reactivate: reactivate,
    resubscribe: resubscribe,
    updatePaymentPlan: updatePaymentPlan,
    updatePaymentInfo: updatePaymentInfo,
    loadSubscription: loadSubscription,
    loadSubscriptionByEnvId: loadSubscriptionByEnvId,
    getSubscription: getSubscription,
    getSubscriptionPromise: getSubscriptionPromise,
    hasSubscription: hasSubscription,
    cancelSubscription: cancelSubscription,
    paymentInfo: paymentInfo,
    clean: clean
  };

  function reactivate() {
    return billingPlan.stripe.reactivate({
      tenantEnvironmentId: barnStorage.getEnvId()
    }).$promise.then(loadSubscription);
  }

  function resubscribe(sourceToken, planId) {
    return billingPlan.stripe.resubscribe({
      tenantEnvironmentId: barnStorage.getEnvId(),
      sourceToken: sourceToken,
      planId: planId
    }).$promise.then(loadSubscription);
  }

  // TODO: BILLING: updatePaymentPlan new
  function updatePaymentPlan(planId) {
    return billingPlan.stripe.updatePaymentPlan({
      tenantEnvironmentId: barnStorage.getEnvId(),
      planId: planId
    }).$promise;
  }

  function updatePaymentInfo(sourceToken) {
    return billingPlan.stripe.updatePaymentInfo({
      tenantEnvironmentId: barnStorage.getEnvId(),
      sourceToken: sourceToken
    }).$promise;
  }

  function loadSubscription() {
    return loadSubscriptionByEnvId(barnStorage.getEnvId());
  }

  function loadSubscriptionByEnvId(barnId) {
    // prevent subscription loading while previous loading is active and use previous promise
    if (loading) {
      return promise;
    }
    const deferred = $q.defer();
    loading = true;
    promise = deferred.promise;
    billingPlan.stripe.subscription({
      tenantEnvironmentId: barnId
    }).$promise
      .then(function(subscription) {
        loading = false;
        deferred.resolve(setSubscription(subscription));
      })
      .catch(function(error) {
        if (error.status !== 404) {
          loading = false;
          deferred.reject(error);
          return;
        }
        loading = false;
        deferred.resolve(setSubscription(legacySubscription));
      });
    return promise;
  }

  function cancelSubscription() {
    return billingPlan.stripe.cancel({
      tenantEnvironmentId: barnStorage.getEnvId()
    }).$promise.then(loadSubscription);
  }

  function paymentInfo() {
    return billingPlan.stripe.paymentInfo({
      tenantEnvironmentId: barnStorage.getEnvId()
    }).$promise;
  }

  function getSubscription() {
    return subscription;
  }

  function getSubscriptionPromise() {
    if (hasSubscription()) {
      return $q.resolve(subscription);
    } else if (userStorage.getUser()) {
      return loadSubscriptionByEnvId(userStorage.getUser().getBarn().id);
    }
    return $q.reject();
  }

  function hasSubscription() {
    return !!get(subscription, 'start');
  }

  function setSubscription(_subscription) {
    subscription = new Subscription(_subscription);
    subscription.discount = angular.fromJson(_subscription.discount);
    subscription.usef = angular.fromJson(_subscription.usef);

    return subscription;
  }

  function clean() {
    subscription = null;
  }
}
