import {Injectable} from '@angular/core';
import {UpgradeModule} from '@angular/upgrade/static';
import {barnManagerNg1ModuleName} from '../../../bm-ng1';
import {setUpLocationSync} from '@angular/router/upgrade';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class Ng1BootstrapService {

  private _ng1Bootstrapped: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  ng1Bootstrapped$: Observable<boolean> = this._ng1Bootstrapped.asObservable();

  private _ng1Active: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  ng1Active$: Observable<boolean> = this._ng1Active.asObservable();

  set ng1Active(state: boolean) {
    this._ng1Active.next(state);
  }

  constructor(
    private upgrade: UpgradeModule,
  ) {
  }

  bootstrap(elRef: any): void {
    this.upgrade.bootstrap(elRef, [barnManagerNg1ModuleName], { strictDi: true });
    setUpLocationSync(this.upgrade);
    this._ng1Bootstrapped.next(true);
  }
}
