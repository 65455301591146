import {barnManagerModule} from '../index.module';

barnManagerModule
  .factory('vendorRepository', ['$http', 'barnStorage', 'userStorage', ($http, barnStorage, userStorage) => {

    return {
      add: add,
      find: find,
      update: update,
      remove: remove,
      all: all,
      allIncludingArchived: allIncludingArchived
    };

    function url() {
      const currentUser = userStorage.getUser();
      return '/api/users/' + currentUser.id + '/vendors';
    }

    function recordUrl(id) {
      return url() + '/' + id;
    }

    function remove(id) {
      return $http.delete(recordUrl(id), getConfig()).then(transformResult);
    }

    function add(body) {
      return $http.post(url(), body, getConfig()).then(transformResult);
    }

    function update(id, body) {
      return $http.put(recordUrl(id), body, getConfig()).then(transformResult);
    }

    function find(id) {
      return $http.get(recordUrl(id), getConfig()).then(transformResult);
    }

    function all() {
      return $http.get(url(), {
        params: {
          tenantEnvironmentId: barnStorage.getEnvId(),
          archived: 0,
          pagesize: 500
        }
      }).then(transformResult);
    }

    function allIncludingArchived() {
      return $http.get(url(), {
        params: {
          tenantEnvironmentId: barnStorage.getEnvId(),
          pagesize: 500
        }
      }).then(transformResult);
    }

    function getConfig() {
      return {
        params: {
          tenantEnvironmentId: barnStorage.getEnvId()
        }
      };
    }

    function transformResult(result) {
      return result.data;
    }
  }]);
