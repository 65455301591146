import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatRippleModule} from '@angular/material/core';
import {RestService} from '@shared/lib/rest/rest.service';
import {ApiType} from '@shared/lib/rest/api-base-parameters';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {GoNativeService} from '@shared/lib/go-native/go-native.service';
import {takeUntil} from 'rxjs/operators';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {MatIconModule} from '@angular/material/icon';
import {saveAs} from 'file-saver';

interface AttachedPdfFile {
  contentType: string;
  name: string;
  imageSrc: string;
  blobUrl: string;
  new?: boolean;
}

interface PdfPreviewModalData {
  file: AttachedPdfFile;
}

@Component({
  selector: 'bm-pdf-preview-modal',
  templateUrl: './pdf-preview-modal.component.html',
  styleUrls: ['./pdf-preview-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatRippleModule,
    PdfViewerModule,
    MatIconModule
  ]
})
export class PdfPreviewModalComponent implements OnInit, OnDestroy {

  @HostBinding('class') cssClass = 'bm-pdf-preview-modal';
  @ViewChild('matDialogContent', { static: true }) matDialogContent: ElementRef;
  @ViewChild('pageInput', { static: true }) pageInput: ElementRef;
  @ViewChild('zoomInput', { static: true }) zoomInput: ElementRef;

  file: AttachedPdfFile;
  isLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private destroy$ = new Subject<void>();

  page$: BehaviorSubject<number> = new BehaviorSubject(1);
  totalPages: number;

  readonly zoomMin: number = 25;
  readonly zoomMax: number = 500;
  readonly zoomStep: number = 25;
  readonly zoomInitial: number = 100;
  zoom$: BehaviorSubject<number> = new BehaviorSubject(this.zoomInitial);

  get isPrintAvailable(): boolean {
    return /Chrome/.test(navigator.userAgent) && !this.goNativeService.isGoNative;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: PdfPreviewModalData,
    private rest: RestService,
    private goNativeService: GoNativeService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.file = {...this.data.file };
    this.subscribeOnPageChange();
    this.loadFileByUrl(this.file).subscribe(data => {
      this.file.blobUrl = URL.createObjectURL(data);
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  afterLoadComplete(pdfData: any) {
    this.page$.next(1);
    this.totalPages = pdfData.numPages;
    this.isLoaded$.next(true);
  }

  private subscribeOnPageChange(): void {
    this.page$.pipe(takeUntil(this.destroy$)).subscribe(value => this.adjustPageInputWidth(String(value)));
  }

  adjustPageInputWidth(value: string): void {
    const width = value.length * 8 + 12; // 8px per character
    this.renderer.setStyle(this.pageInput.nativeElement, 'width', width + 'px');
  }

  onPageChange(pageValue: string): void {
    const page = Number(pageValue);
    let result;
    if (page < 1) {
      result = 1;
    } else if (page > this.totalPages) {
      result = this.totalPages;
    } else {
      result = page;
    }
    this.page$.next(result);
    this.pageInput.nativeElement.value = this.page$.value;
  }

  onZoomChange(zoomValue: string): void {
    const zoom = Number(zoomValue);
    let result;
    if (zoom < this.zoomMin) {
      result = this.zoomMin;
    } else if (zoom > this.zoomMax) {
      result = this.zoomMax;
    } else {
      result = zoom;
    }
    this.zoom$.next(result);
    this.zoomInput.nativeElement.value = this.zoom$.value;
  }

  zoomOut(): void {
    const zoom = this.zoom$.value;
    if (zoom > this.zoomMin) {
      this.zoom$.next(zoom - this.zoomStep);
    }
  }

  zoomIn(): void {
    const zoom = this.zoom$.value;
    if (zoom < this.zoomMax) {
      this.zoom$.next(zoom + this.zoomStep);
    }
  }

  download(): void {
    if (this.goNativeService.isAndroid()) {
      this.goNativeService.downloadFile(this.file.imageSrc);
    } else {
      saveAs(this.file.blobUrl, this.file.name);
    }
  }

  print(): void {
    const iframe = this.renderer.createElement('iframe');
    this.renderer.setStyle(iframe, 'display', 'none');
    this.renderer.setAttribute(iframe, 'src', this.file.blobUrl);
    this.renderer.appendChild(this.matDialogContent.nativeElement, iframe);
    iframe.contentWindow.print();
  }

  private loadFileByUrl(file: AttachedPdfFile): Observable<Blob> {
    return this.rest.get({
      type: ApiType.externalApi,
      path: file.imageSrc,
      skipIntercept: true,
      responseType: 'blob'
    });
  }
}
