import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ApiBaseParameters, ApiType, Headers, InterceptorSkipHeader} from './api-base-parameters';
import {ENVIRONMENT, EnvironmentType} from '../models/environment-type';
import {LocalStorage} from 'ngx-webstorage';
import {StorageKeys} from '../storage-keys';

@Injectable()
export class RestService {
  @LocalStorage(StorageKeys.TEST_API_ENDPOINT) testApiEndpoint: string;

  constructor(
    @Inject(ENVIRONMENT) public environment: EnvironmentType,
    public http: HttpClient
  ) {
  }

  private getUrl(path: string, endpoint?: string, type: ApiType = ApiType.defaultApi): string {
    const testApiEndpoint = this.testApiEndpoint ?? '';
    switch (type) {
      case ApiType.defaultApi: {
        if (endpoint) {
          return `${endpoint}api/${path}`;
        } else {
          return `${testApiEndpoint}${this.environment.apiUrl}api/${path}`;
        }
      }
      case ApiType.externalApi: {
        return path;
      }
      default: {
        throw new Error('The type of API not defined or incorrect');
      }
    }
  }

  private getHeaders(headers: Headers, skipIntercept: boolean): Headers {
    if (skipIntercept) {
      return { ...headers, [InterceptorSkipHeader]: '' };
    }
    return headers;
  }

  get<T = any>(parameters: ApiBaseParameters): Observable<T> {
    const { path, params, headers, responseType, endpoint, type, skipIntercept} = parameters;
    return this.http.get<T>(this.getUrl(path, endpoint, type), {
      params,
      headers: this.getHeaders(headers, skipIntercept),
      responseType: responseType as 'json' || 'json'
    }).pipe(
      catchError(error => throwError(error))
    );
  }

  post<T = any>(parameters: ApiBaseParameters): Observable<T> {
    const { path, body, headers, params, responseType, endpoint, type, skipIntercept} = parameters;
    return this.http.post<T>(this.getUrl(path, endpoint, type), body, {
      params,
      headers: this.getHeaders(headers, skipIntercept),
      responseType: responseType as 'json' || 'json'
    }).pipe(
      catchError(error => throwError(error))
    );
  }

  put<T = any>(parameters: ApiBaseParameters): Observable<T> {
    const { path, body, headers, params, endpoint, type, skipIntercept} = parameters;
    return this.http.put<T>(this.getUrl(path, endpoint, type), body, {
      params,
      headers: this.getHeaders(headers, skipIntercept)
    }).pipe(
      catchError(error => throwError(error))
    );
  }

  patch<T = any>(parameters: ApiBaseParameters): Observable<T> {
    const { path, body, headers, params, endpoint, type, skipIntercept} = parameters;
    return this.http.patch<T>(this.getUrl(path, endpoint, type), body, {
      params,
      headers: this.getHeaders(headers, skipIntercept)
    }).pipe(
      catchError(error => throwError(error))
    );
  }

  delete<T = any>(parameters: ApiBaseParameters): Observable<T> {
    const { path, body, headers, params, endpoint, type, skipIntercept} = parameters;
    return this.http.patch<T>(this.getUrl(path, endpoint, type), body, {
      params,
      headers: this.getHeaders(headers, skipIntercept)
    }).pipe(
      catchError(error => throwError(error))
    );
  }
}
