import {barnManagerModule} from '../index.module';

barnManagerModule
  .factory('hasPermission', ['userStorage', 'Permission', (userStorage, Permission) =>{
    return function(permissionName) {
      const permission = Permission.fromString(permissionName);
      const currentUser = userStorage.getUser();
      if (!currentUser) {
        return false;
      }
      return currentUser.hasPermission(permission);
    };
  }]);
