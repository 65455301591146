import {barnManagerModule} from '../index.module';

barnManagerModule.factory('rModelLists', ['$resource', ($resource) => {

  return $resource('/api/users/:userId/:model', {
      userId: '@userId',
      model: '@model'
    }, {
      'getUsers': {
        method: 'GET',
        url: '/api/users/:userId/:model/:modelId/users',
        params: {
          userId: '@userId',
          modelId: '@modelId',
          model: '@model'
        },
        isArray: true
      },
      'addUsers': {
        method: 'POST',
        url: '/api/users/:userId/:model/:modelId/users',
        params: {
          userId: '@userId',
          modelId: '@modelId',
          model: '@model'
        }
      },
      'removeUsers': {
        method: 'DELETE',
        url: '/api/users/:userId/:model/:modelId/users/?:usersIds',
        params: {
          userId: '@userId',
          modelId: '@modelId',
          usersIds: '@horsesIds',
          model: '@model'
        }
      },
      'getUserPreferences': {
        method: 'GET',
        url: '/api/users/:userId/preferences',
        params: {
          userId: '@userId'
        },
        isArray: false
      },
      'updateUserPreferences': {
        method: 'PUT',
        url: '/api/users/:userId/preferences',
        params: {
          userId: '@userId',
          pushNotificationEnabled: '@pushNotificationEnabled',
          emailNotificationEnabled: '@emailNotificationEnabled',
          newHorseNotificationsEnabled: '@newHorseNotificationsEnabled',
          newWhiteboardNotificationsEnabled: '@newWhiteboardNotificationsEnabled'
        },
        isArray: false
      },
      'patchUserPreferences': {
        method: 'PATCH',
        url: '/api/users/:userId/preferences',
        params: {
          userId: '@userId'
        },
        isArray: false
      }
    });

  }]);
