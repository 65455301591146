import {barnManagerModule} from '../../index.module';

barnManagerModule
  .component('bmConfirmDialog', {
    templateUrl: 'confirm-dialog.html',
    controller: ConfirmDialogController,
    controllerAs: 'vm'
  });

ConfirmDialogController.$inject = ['confirmDialog', '$scope'];
function ConfirmDialogController(confirmDialog, $scope) {
  const vm = this;

  vm.close = function() {
    vm.visible = false;
    vm.text = '';
    vm.color = '';
    confirmDialog.closed();
  };

  vm.confirmAction = function() {
    vm.visible = false;
    confirmDialog.confirmed();
  };

  vm.open = function(text, type, confirmText, cancelText, headerText) {
    vm.text = text;
    vm.type = type;
    vm.visible = true;
    vm.confirmText = confirmText;
    if (confirmText === 'Delete') {
      vm.color = '#C41E3A';
    }
    vm.cancelText = cancelText;
    vm.headerText = headerText;
  };

  vm.$onInit = function() {
    confirmDialog.subscribe(vm);
    $scope.$on('$locationChangeStart', function() {
      if (vm.visible) {
        vm.close();
      }
    });
  };
}
